import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AlertController } from "@ionic/angular";
import { DayTimeRanges, ScheduleCalendar, Store, TimeRange, WeekSchedule } from "src/app/backend-api";
import { DataTranslateService } from "../../services/data-translate.service";
import { TimezoneService } from "../../services/timezone.service";
import {getDay, parseISO, parseJSON} from "date-fns";

interface ITimePickerButtonHandlerData {
	hour: {
		text: string;
		value: number;
	};
	minute: {
		text: string;
		value: number;

	};
	second: {
		text: string;
		value: number;

	};
}

interface IDatePickerButtonHandlerData {
	day: {
		text: string;
		value: number;
	};
	month: {
		text: string;
		value: number;

	};
	year: {
		text: string;
		value: number;
	};
}

@Component({
	selector: "app-calendar-management",
	templateUrl: "./calendar-management.component.html",
	styleUrls: ["./calendar-management.component.scss"],
})
export class CalendarManagementComponent implements OnInit {

	fm: FormGroup;
	es: FormGroup;
	cs: FormGroup;

	readonly TIME_FORMAT = "HH:mm"; // "HH:mm:ss";
	readonly DATE_FORMAT = "DD MMM, YYYY";

	labels: {
		ok?: string;
		error?: string;
		cancel?: string;
		clear?: string
	};

	readonly daysString = [
		"monday",
		"tuesday",
		"wednesday",
		"thursday",
		"friday",
		"saturday",
		"sunday"
	];

	standardScheduleVisible = false;
	extraSchedulesVisible = false;
	cancelledSchedulesVisible = false;
	timezoneList: string[];
	timezone: string;
	sc: ScheduleCalendar = {} as any;
	nextYear = new Date().getFullYear() + 1; // anno prox come max
	overrideExtraSchedule: boolean;
	overrideCancelledSchedule: boolean;
	readonly overrideDays: {[day: string]: boolean} = {};

	@Input()
	baseCalendar: ScheduleCalendar;

	@Output() calendarSaved = new EventEmitter<ScheduleCalendar>();

	@Input()
	set defTimezone(s: string) {
		this.timezone = s;
	}

	@Input()
	set calendar(sc: ScheduleCalendar) {

		// assegna per la vista
		this.sc = sc || {} as any;

		const weekSchedule = sc?.weekSchedule;
		if (weekSchedule) {

			// prende i dati in un secondo array
			const days = this.daysString.map((d) => {
				return weekSchedule[d];
			})

			// carica i dati
			this.daysString.forEach((d, i) => {
				const ws: TimeRange[] = days[i];
				if (ws?.length > 0) {
					this.fm.controls[d + "Start1"].setValue(ws[0].from);
					this.fm.controls[d + "End1"].setValue(ws[0].to);
				}
				if (ws?.length > 1) {
					this.fm.controls[d + "Start2"].setValue(ws[1].from);
					this.fm.controls[d + "End2"].setValue(ws[1].to);
				}
			});

		}

		this.setCalendarOverrides();
	}

	constructor(
		private formBuilder: FormBuilder,
		public dts: DataTranslateService,
		public timezoneService: TimezoneService,
		private alertCtrl: AlertController,

	) {

		//
		// Standard Schedule
		//
		const groupConfig = {};
		this.daysString.forEach((d) => {
			groupConfig[d + "Start1"] = [""];
			groupConfig[d + "End1"] = [""];
			groupConfig[d + "Start2"] = [""];
			groupConfig[d + "End2"] = [""];
		});
		this.fm = this.formBuilder.group(groupConfig);

		//
		// Extra Schedule
		//
		const esGroupConfig = {};
		// tslint:disable-next-line: no-string-literal
		esGroupConfig["esDay"] = [""];
		// tslint:disable-next-line: no-string-literal
		esGroupConfig["esStart1"] = [""];
		// tslint:disable-next-line: no-string-literal
		esGroupConfig["esEnd1"] = [""];
		// tslint:disable-next-line: no-string-literal
		esGroupConfig["esStart2"] = [""];
		// tslint:disable-next-line: no-string-literal
		esGroupConfig["esEnd2"] = [""];
		this.es = this.formBuilder.group(esGroupConfig);

		//
		// Cancelled Schedule
		//
		const csGroupConfig = {};
		// tslint:disable-next-line: no-string-literal
		csGroupConfig["csDay"] = [""];
		// tslint:disable-next-line: no-string-literal
		csGroupConfig["csStart1"] = [""];
		// tslint:disable-next-line: no-string-literal
		csGroupConfig["csEnd1"] = [""];
		// tslint:disable-next-line: no-string-literal
		csGroupConfig["csStart2"] = [""];
		// tslint:disable-next-line: no-string-literal
		csGroupConfig["csEnd2"] = [""];
		this.cs = this.formBuilder.group(csGroupConfig);

		this.timezoneList = this.timezoneService.getAvailableTimezones();
		this.timezoneList.sort();
	}

	ngOnInit() {

		this.labels = {
			ok: this.dts.translateService.instant("OK"),
			error: this.dts.translateService.instant("ERROR"),
			cancel: this.dts.translateService.instant("ANNULLA"),
			clear: this.dts.translateService.instant("CLEAR"),
		}
		this.setCalendarOverrides();
	}

	private setCalendarOverrides() {
		if (this.baseCalendar) {
			if (this.sc.cancelledSchedules === null || this.sc.cancelledSchedules?.length) {
				this.overrideCancelledSchedule = true;
			}
			else {
				this.overrideCancelledSchedule = false;
			}

			if (this.sc.extraSchedules === null || this.sc.extraSchedules?.length) {
				this.overrideExtraSchedule = true;
			}
			else {
				this.overrideExtraSchedule = false;
			}

			for (const d of this.daysString) {
				if (this.sc.weekSchedule && (this.sc.weekSchedule[d] === null || (this.sc.weekSchedule[d] && this.sc.weekSchedule[d].length))) {
					this.overrideDays[d] = true;
				}
				else {
					this.overrideDays[d] = false;
				}
			}
		}
		else {
			//if there is no base calendar, assume the intention is to save everything
			this.overrideCancelledSchedule = true;
			this.overrideExtraSchedule = true;
			for (const d of this.daysString) {
				this.overrideDays[d] = true;
			}
		}
	}

// elimina una schedulazione extra
	deleteExtraSchedule(i: number) {
		this.sc?.extraSchedules.splice(i, 1);
	}

	deleteCancelledSchedule(i: number) {
		this.sc?.cancelledSchedules.splice(i, 1);
	}

	doAddExtraSchedule() {

		const esDay = this.es.controls.esDay.value;
		if (!esDay) {
			return;
		}

		const esStart1 = this.es.controls.esStart1.value;
		const esEnd1 = this.es.controls.esEnd1.value;
		const esStart2 = this.es.controls.esStart2.value;
		const esEnd2 = this.es.controls.esEnd2.value;

		const el: DayTimeRanges = {
			day: esDay
		};

		if (esStart1 && esEnd1) {
			el.timeRanges = el.timeRanges || [];
			el.timeRanges.push({
				from: esStart1,
				to: esEnd1
			});

			if (esStart2 && esEnd2) {
				el.timeRanges = el.timeRanges || [];
				el.timeRanges.push({
					from: esStart2,
					to: esEnd2
				});
			}
		}

		// aggiunge
		if (!this.sc?.extraSchedules) {
			this.sc.extraSchedules = [];
		}
		this.sc.extraSchedules.push(el);

		// pulisce la pagina
		this.es.controls.esDay.setValue("");
		this.es.controls.esStart1.setValue("");
		this.es.controls.esEnd1.setValue("");
		this.es.controls.esStart2.setValue("");
		this.es.controls.esEnd2.setValue("");
	}

	doAddCancelledSchedule() {

		const csDay = this.cs.controls.csDay.value;
		if (!csDay) {
			return;
		}

		const csStart1 = this.cs.controls.csStart1.value;
		const csEnd1 = this.cs.controls.csEnd1.value;
		const csStart2 = this.cs.controls.csStart2.value;
		const csEnd2 = this.cs.controls.csEnd2.value;

		const el: DayTimeRanges = {
			day: csDay
		};

		if (csStart1 && csEnd1) {
			el.timeRanges = el.timeRanges || [];
			el.timeRanges.push({
				from: csStart1,
				to: csEnd1
			});

			if (csStart2 && csEnd2) {
				el.timeRanges = el.timeRanges || [];
				el.timeRanges.push({
					from: csStart2,
					to: csEnd2
				});
			}
		}

		// aggiunge
		if (!this.sc?.cancelledSchedules) {
			this.sc.cancelledSchedules = [];
		}
		this.sc.cancelledSchedules.push(el);

		// pulisce la pagina
		this.cs.controls.csDay.setValue("");
		this.cs.controls.csStart1.setValue("");
		this.cs.controls.csEnd1.setValue("");
		this.cs.controls.csStart2.setValue("");
		this.cs.controls.csEnd2.setValue("");
	}

	canAddExtraSchedule(): boolean {
		const esDay = this.es.controls.esDay.value;
		if (esDay) {
			return true;
		}
	}

	canAddCancelledSchedule(): boolean {
		const csDay = this.cs.controls.csDay.value;
		if (csDay) {
			return true;
		}
	}

	// ritorna le opzioni del picker della data/ora
	getTimePickerOptions(fg, n) {
		return {
			buttons: [
				{
					text: this.labels.clear,
					handler: () => {
						fg.controls[n].setValue(null);
					}
				},
				{
					text: this.labels.cancel,
					handler: () => {
					}
				},
				{
					text: this.labels.ok,
					handler: (item: ITimePickerButtonHandlerData) => {

						const v = item.hour.text + ":" + item.minute.text + ":00"; // + item.second.text;
						fg.controls[n].setValue(v);
					}
				},
			]
		};
	}

	getDatePickerOptions(fg, n) {
		return {
			buttons: [
				{
					text: this.labels.clear,
					handler: () => {
						fg.controls[n].setValue(null);
					}
				},
				{
					text: this.labels.cancel,
					handler: () => {
					}
				},
				{
					text: this.labels.ok,
					handler: (item: IDatePickerButtonHandlerData) => {
						const v = item.year.text + "-" + (item.month.value + "").padStart(2, "0") + "-" + item.day.text;
						fg.controls[n].setValue(v);
					}
				},
			]
		};
	}

	doSaveClicked(): ScheduleCalendar {

		// sicurezza
		if (!this.fm.valid) {

			this.alertCtrl.create({
				header: this.dts.translateService.instant("account.DATI_ERRATI"),
				message: this.dts.translateService.instant("account.CORREGGERE_DATI_PER_PROSEGUIRE"),
				buttons: [this.dts.translateService.instant("OK")]
			}).then(errMsg => {
				errMsg.present();
			});

			return;
		}

		// riporta i dati sull'oggetto
		const weekSchedule: WeekSchedule = {};

		// carica i dati
		this.daysString.forEach((d, i) => {
			if (!this.baseCalendar || this.overrideDays[d]) {
				const s1 = this.fm.controls[d + "Start1"].value;
				const e1 = this.fm.controls[d + "End1"].value;
				const s2 = this.fm.controls[d + "Start2"].value;
				const e2 = this.fm.controls[d + "End2"].value;
				if (s1 && e1) {
					weekSchedule[d] = [];
					weekSchedule[d].push({
						from: s1,
						to: e1
					});

					if (s2 && e2) {
						weekSchedule[d].push({
							from: s2,
							to: e2
						});
					}
				}
				else if (!s1 && !s2 && !e1 && !e2) {
					if (!this.baseCalendar) {
						//use empty array to erase in case there is no baseCalendar, in this way if a baseCalendar
						//will be attached, it won't have null values that means a different thing
						weekSchedule[d] = [];
					}
					else {
						//set null in weekschedule to erase data from db
						weekSchedule[d] = null;
					}
				}
			}
			//else: this day has not been overridden, so the base calendar value will be inherited by passing an empty array
			else {
				weekSchedule[d] = [];
			}
		});

		const ret: ScheduleCalendar = {
			weekSchedule,
			//for extra and cancelled, we must pass [] if the base calendar must be inherited
			extraSchedules: !this.baseCalendar || this.overrideExtraSchedule ? this.sc?.extraSchedules : [],
			cancelledSchedules: !this.baseCalendar || this.overrideCancelledSchedule ? this.sc?.cancelledSchedules : [],
			timezone: this.timezone
		};

		// emette l'evento di salvataggio avvenuto correttamente
		this.calendarSaved.emit(ret);
	}

	useStoreCalendar_standard(d: string) {
		this.overrideDays[d] = !this.overrideDays[d];
	}

	useStoreCalendar_extra() {
		this.overrideExtraSchedule = !this.overrideExtraSchedule;
	}

	useStoreCalendar_cancelled() {
		this.overrideCancelledSchedule = !this.overrideCancelledSchedule;
	}

	/**
	 * Smart inheritance check for extraSchedule (default behaviour of overrideScheduleCalendar function in backend, check that function for
	 * further details
	 */
	isDaySmartInherited(dtr: DayTimeRanges) {
		if (!this.overrideExtraSchedule) {
			const iDay = getDay(parseISO(dtr.day));
			if ((iDay === 0 && this.overrideDays.sunday) ||
				(iDay === 1 && this.overrideDays.monday) ||
				(iDay === 2 && this.overrideDays.tuesday) ||
				(iDay === 3 && this.overrideDays.wednesday) ||
				(iDay === 4 && this.overrideDays.thursday) ||
				(iDay === 5 && this.overrideDays.friday) ||
				(iDay === 6 && this.overrideDays.saturday)) {
				return true;
			}
		}
		return false;
	}

	getLocalizedDayOfWeek(dtr: DayTimeRanges) {
		const iDay = getDay(parseISO(dtr.day));
		const oDaysOfWeek = this.dts.translateService.instant("days_of_week");
		switch (iDay) {
			case 0:
				return oDaysOfWeek.sunday;
			case 1:
				return oDaysOfWeek.monday;
			case 2:
				return oDaysOfWeek.tuesday;
			case 3:
				return oDaysOfWeek.wednesday;
			case 4:
				return oDaysOfWeek.thursday;
			case 5:
				return oDaysOfWeek.friday;
			case 6:
				return oDaysOfWeek.saturday;
			default:
				throw new Error("Invalid day of week: " + iDay);
		}
	}
}
