import { Component, OnInit, Input } from "@angular/core";
import { DataTranslateService } from "src/app/core/services/data-translate.service";

@Component({
	selector: "app-authorized-amount-info",
	templateUrl: "./authorized-amount-info.component.html",
	styleUrls: ["./authorized-amount-info.component.scss"],
})
export class AuthorizedAmountInfoComponent implements OnInit {

	@Input() paymentAuthorizationMinAmountIncrease: number;
	@Input() paymentAuthorizationPercentIncrease: number;
	@Input() authorizationOnlyMaxAmountIncrease: number;

	constructor(
		public dts: DataTranslateService
	) { }

	ngOnInit() { }
}
