/**
 * Provvede alla gestione della traduzione dei soli dati applicativi secondo la seguente logica:
 * - cerca prima il dato nella lingua dell'utente
 * - poi in ordine nella lingua store/app/navigator
 */

import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LocalizedString } from "src/app/backend-api";

export const LANGUAGES_DESCRIPTION = {
	it: "Italiano",
	en: "English",
	de: "Deutsch"
};

@Injectable({
	providedIn: "root"
})
export class DataTranslateService {

	private _navLang: string;
	private _storeDefaultLang: string;
	private _appDefaultLang: string;

	constructor(
		public translateService: TranslateService // visibile anche da fuori
	) {

		// lingua navigator
		const deviceLang = navigator.language;
		this._navLang = this.getLanguageFromLocaleOrUndef(deviceLang);
	}

	/**
	 * Aggiorna le info delle lingue; solo di quelle passate
	 */
	updateDataLanguages(conf: {
		storeDefaultLang?: string;
		appDefaultLang?: string;
	}) {

		conf = conf || {};

		if (conf.storeDefaultLang) {
			this._storeDefaultLang = conf.storeDefaultLang;
		}

		if (conf.appDefaultLang) {
			this._appDefaultLang = conf.appDefaultLang;
		}
	}

	/**
	 * Ritorna la lingua primaria
	 */
	get currentLang(): string {
		return this.translateService.currentLang;
	}

	/**
	 * Ritorna la lingua alternativa alla primaria
	 */
	get storeOrAppDefaultLang(): string {
		return this._storeDefaultLang || this._appDefaultLang || this._navLang;
	}

	/**
	 * Ritorna la lista lingue (MAX 2) da usare per la query dei dati per visualizzazione
	 */
	getLanguagesForQueries(): string[] {

		if (this.storeOrAppDefaultLang && this.storeOrAppDefaultLang !== this.currentLang) {
			return [this.currentLang, this.storeOrAppDefaultLang];
		}
		return [this.currentLang];
	}

	/**
	 * Effettua la traduzione di un dato applicativo con eventuale default
	 */
	dataTranslate(s: LocalizedString, def?: string): string {
		if (s) {
			return s[this.currentLang] || s[this.storeOrAppDefaultLang] || s.invariant || def;
		}
		return def;
	}

	/**
	 * Ritorna la lingua del navigator
	 */
	getNavigatorLanguage(): string {
		return this._navLang;
	}

	// Ritorna la lingua preferita dall'app
	getAppDefaultLanguage(): string {
		return this._appDefaultLang;
	}

	// Ritorna la lingua preferita dallo store corrente
	getStoreDefaultLanguage(): string {
		return this._storeDefaultLang;
	}

	/**
	 * Ritorna la lingua calcolata dal locale
	 */
	getLanguageFromLocaleOrUndef(locale: string): string | undefined {
		if (locale) {
			const components = locale.split("-");
			if (components && components.length >= 1) {
				return components[0];
			}
		}
	}
}
