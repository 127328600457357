import { Component, OnInit, Input } from "@angular/core";
import { AppConfigurationService } from "../../services/app-configuration.service";
import { ModalController } from "@ionic/angular";
import {GalleryItem} from "../../../backend-api";

@Component({
	selector: "app-show-gallery",
	templateUrl: "./show-gallery.page.html",
	styleUrls: ["./show-gallery.page.scss"],
})
export class ShowGalleryPage implements OnInit {

	@Input() items: GalleryItem[];

	constructor(
		public appConfigService: AppConfigurationService,
		private modalController: ModalController
	) { }

	ngOnInit() {
	}

	close() {
		this.modalController.dismiss();
	}

}
