import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { LANGUAGE } from "src/app/backend-api";
import { LANGUAGES_DESCRIPTION } from "../../services/data-translate.service";

@Component({
	selector: "app-choose-language",
	templateUrl: "./choose-language.page.html",
	styleUrls: ["./choose-language.page.scss"],
})
export class ChooseLanguagePage implements OnInit {

	availableLanguages = Object.values(LANGUAGE);
	langDescription = LANGUAGES_DESCRIPTION;

	@Input() supportedLanguages: LANGUAGE[];
	@Input() defaultLanguage: LANGUAGE;

	constructor(
		private modalCtrl: ModalController
	) { }

	ngOnInit() {
		// inizializza l'array
		this.supportedLanguages = this.supportedLanguages || [];
	}

	// aggiunge o rimuove una certa lingua da quelle supportate dall'app
	toggleLang(lang: LANGUAGE): void {
		const isThere = this.supportedLanguages.indexOf(lang) !== -1;

		// rimuove
		if (isThere) {

			// lascia passare tutti tranne quello selezionato
			this.supportedLanguages = this.supportedLanguages.filter((l: LANGUAGE) => {
				return lang !== l;
			});
		}

		else {
			// inserisce solo le info minime, le altre restano invariate
			this.supportedLanguages.push(lang);

		}
	}

	async doSave() {
		this.modalCtrl.dismiss({
			confirmed: true,
			supportedLanguages: this.supportedLanguages,
			defaultLanguage: this.defaultLanguage
		});
	}

}
