import { Component, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { SpinnerButtonService } from "./spinner-button.service";
import { ISpinnerSubject } from "./spinner-subject.interface";

@Component({
	selector: "app-spinner-button",
	templateUrl: "./spinner-button.component.html",
	styleUrls: ["./spinner-button.component.scss"],
})
export class SpinnerButtonComponent implements OnInit, OnDestroy {

	@Input() color: "primary" | "secondary" | "tertiary" | "success" | "warning" | "danger" | "light" | "medium" | "dark" | undefined;
	@Input() size: "small" | "default" | "large" | undefined;
	@Input() expand: "block" | "full" | undefined;
	@Input() fill: "clear" | "outline" | "solid" | undefined;

	@Input() disabled = false;
	@Input() autoDisabled = false;
	_autoDisabledValue = false;

	// toglie lo spinner e i padding rendendolo come un button ma con il disabled automatico
	@Input() hideSpinner = false;
	@Input() spinner = false;
	@Input() autoSpinner = false;
	_autoSpinnerValue = false; // valore dell'autospinner cosi non sovrascrivo quello passato

	@Input() callKey: string;
	private _callKeyCounter = 0;
	private _spinnerButtonSubscription: Subscription;

	public mainClass = "spinner-button";

	// per fare in modo che quando il pulsante è disabled il componente non scateni un click
	// è necessario lasciare sempre dentro al componente qualcosa di cliccabile e gestire
	// internamente l'evento click
	@Output() click = new EventEmitter();

	constructor(
		private sbs: SpinnerButtonService
	) {

	}

	ngOnInit() {

		// classe senza padding
		if (this.hideSpinner) {
			this.mainClass = "no-spinner-button";
		}

		if (this.autoSpinner || this.autoDisabled) {

			this._spinnerButtonSubscription = this.sbs.spinnerButtonSubject.subscribe((v: ISpinnerSubject) => {

				// se la chiave passata è la mia (o nessuna delle due è stata passata)
				if (v.callKey === this.callKey || this.callKey === "*") {

					// gestione del contatore chiamate
					if (v.working) {
						this._callKeyCounter++;
					}
					else {
						this._callKeyCounter--;
						if (this._callKeyCounter < 0) {
							this._callKeyCounter = 0;

							// notifica che c'è un problema sull'autoSpinner
							console.warn("spinner-button autoSpinner problem");
						}
					}

					// gestione spinner
					if (this.autoSpinner) {
						if (this._callKeyCounter > 0) {
							this._autoSpinnerValue = true;
						}
						else if (this._callKeyCounter <= 0) {
							this._autoSpinnerValue = false;
						}
					}

					// gestione disabled
					if (this.autoDisabled) {
						if (this._callKeyCounter > 0) {
							this._autoDisabledValue = true;
						}
						else if (this._callKeyCounter <= 0) {
							this._autoDisabledValue = false;
						}
					}

				}

			});
		}
	}

	// ritorna vero se il pulsante è disabilitato
	get isDisabled(): boolean {
		return this.disabled || this._autoDisabledValue;
	}

	// sovrascrive il click di default
	doClick(event: Event) {
		event.stopPropagation();
		event.preventDefault();
		if (!this.isDisabled) {
			this.click.emit(event);
		}
	}

	ngOnDestroy(): void {

		if (this._spinnerButtonSubscription) {
			this._spinnerButtonSubscription.unsubscribe();
			this._spinnerButtonSubscription = null;
		}

	}
}
