<app-header [title]="'account.ADDRESS_EDIT' | translate"></app-header>
<ion-content class="kaleidos-page kaleidos-page-transparent">

	<ion-card *ngIf="currentAddress">
		<ion-card-header>
			<ion-card-title>{{ 'account.ADDRESS_INS' | translate }}</ion-card-title>
		</ion-card-header>
		<ion-card-content>
			<form novalidate [formGroup]="fm">

				<ion-item *ngIf="!addressOnly">
					<ion-label position="floating">{{ "account.ADDRESS_NAME" | translate }}
					</ion-label>
					<ion-input type="text" formControlName="name">
					</ion-input>
				</ion-item>
				<ion-text color="danger" *ngIf="!addressOnly">
					<p class="ion-padding-start"
						[hidden]="!fm.controls.name.hasError('required') || (!fm.controls.name.dirty && !formOKClicked)">
						{{"REQUIRED_FIELD" | translate}}</p>
					<p class="ion-padding-start"
						[hidden]="!fm.controls.name.hasError('minlength') || (!fm.controls.name.dirty && !formOKClicked)">
						{{ "MIN_LENGTH" | translate: {'n': 3} }}</p>
				</ion-text>

				<ion-item>
					<ion-label position="floating">{{ "account.ADDRESS_STREET" | translate }}
					</ion-label>
					<ion-input type="text" formControlName="street" (ionChange)="onAddressChange()">
					</ion-input>
				</ion-item>
				<ion-text color="danger">
					<p class="ion-padding-start"
						[hidden]="!fm.controls.street.hasError('required') || (!fm.controls.street.dirty && !formOKClicked)">
						{{"REQUIRED_FIELD" | translate}}</p>
					<p class="ion-padding-start"
						[hidden]="!fm.controls.street.hasError('minlength') || (!fm.controls.street.dirty && !formOKClicked)">
						{{ "MIN_LENGTH" | translate: {'n': 3} }}</p>
				</ion-text>

				<ion-item>
					<ion-label position="floating">{{ "account.ADDRESS_NUMBER" | translate }}
					</ion-label>
					<ion-input type="text" formControlName="streetn" (ionChange)="onAddressChange()">
					</ion-input>
				</ion-item>
				<ion-text color="danger">
					<p class="ion-padding-start" [hidden]="fm.controls['streetn'].valid">{{"WRONG_FIELD" | translate}}
					</p>
				</ion-text>

				<ion-item *ngIf="!addressOnly">
					<ion-label position="floating">{{ "account.ADDRESS_INTERCOM" | translate }}
						({{ 'account.OPTIONAL' | translate }})
					</ion-label>
					<ion-input type="text" formControlName="intercom">
					</ion-input>
				</ion-item>
				<ion-text color="danger" *ngIf="!addressOnly">
					<p class="ion-padding-start" [hidden]="fm.controls['intercom'].valid">{{"WRONG_FIELD" | translate}}
					</p>
				</ion-text>

				<ion-item *ngIf="!addressOnly">
					<ion-label position="floating">{{ "account.ADDRESS_INTERIOR" | translate }}
						({{ 'account.OPTIONAL' | translate }})
					</ion-label>
					<ion-input type="text" formControlName="interior">
					</ion-input>
				</ion-item>
				<ion-text color="danger" *ngIf="!addressOnly">
					<p class="ion-padding-start" [hidden]="fm.controls['interior'].valid">{{"WRONG_FIELD" | translate}}
					</p>
				</ion-text>

				<ion-item>
					<ion-label position="floating">{{ "account.ADDRESS_CITY" | translate }}</ion-label>
					<app-select-search #selectCity [options]="cityOptions" controlName="city"
						(ionChange)="onAddressChange()">
					</app-select-search>
				</ion-item>
				<ion-text color="danger">
					<p class="ion-padding-start"
						[hidden]="!fm.controls.city.hasError('required') || (!fm.controls.city.dirty && !formOKClicked)">
						{{"REQUIRED_FIELD" | translate}}</p>
				</ion-text>

				<ion-item>
					<ion-label position="floating">{{ "account.ADDRESS_ZIP" | translate }}
					</ion-label>
					<ion-select formControlName="zip" okText="{{'FATTO' | translate}}"
						cancelText="{{'ANNULLA' | translate}}">
						<ion-select-option *ngFor="let zip of getCapsFromCurrentAddress()" [value]="zip">{{zip}}
						</ion-select-option>
					</ion-select>
				</ion-item>
				<ion-text color="danger">
					<p class="ion-padding-start"
						[hidden]="!fm.controls.zip.hasError('required') || (!fm.controls.zip.dirty && !formOKClicked)">
						{{"REQUIRED_FIELD" | translate}}</p>
					<p class="ion-padding-start"
						[hidden]="!fm.controls.zip.hasError('minlength') || (!fm.controls.zip.dirty && !formOKClicked)">
						{{ "MIN_LENGTH" | translate: {'n': 3} }}</p>
				</ion-text>

			</form>
		</ion-card-content>
	</ion-card>

	<div style="padding: 10px;font-size: 20px;font-weight: bold;">
		{{ 'account.MOVE_MARKER_CORRECT_POSITION' | translate }}
	</div>
	<app-google-geocoder #geocoder [height]="'300px'"></app-google-geocoder>
</ion-content>
<ion-footer *ngIf="currentAddress">
	<ion-grid>
		<ion-row>
			<ion-col class="ion-text-left">
				<ion-button color="danger" (click)="doDeleteAddress()" *ngIf="currentAddress && currentAddress.id">
					{{ "account.DELETE_ADDRESS" | translate}}
				</ion-button>
			</ion-col>
			<ion-col class="ion-text-right">
				<ion-button color="success" (click)="doSave()">
					{{ "SAVE" | translate}}
				</ion-button>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-footer>