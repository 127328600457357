import { Component, ElementRef, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PaymentService } from "../../services/payment.service";
import {
	PaymentMethod,
	PAYMENT_METHOD,
	PaymentClaim,
	User,
	StripeSavePaymentMethodCustomData,
	ClaimPaymentMethodResult, StripeClaimPaymentCustomData, StripeClaimPaymentResultCustomData, Order
} from "../../../backend-api";
import { AppConfigurationService } from "../../../core/services/app-configuration.service";
import { environment } from "../../../../environments/environment";
import { AlertController, LoadingController, PopoverController } from "@ionic/angular";
import { DataTranslateService } from "src/app/core/services/data-translate.service";
import { AppNavigatorService } from "src/app/core/services/app-navigator.service";
import { loadStripe } from "@stripe/stripe-js/pure";
import {
	ConfirmCardPaymentData,
	CreatePaymentMethodData, PaymentIntent,
	Stripe,
	StripeError,
	StripeCardCvcElementChangeEvent,
	StripeCardElement,
	StripeCardElementOptions,
	StripeElements,
	StripeElementsOptions,
	StripeElementsOptionsClientSecret
} from "@stripe/stripe-js";
import { AuthorizedAmountInfoComponent } from "../../components/authorized-amount-info/authorized-amount-info.component";
import { OrderingService } from "../../../ordering/services/ordering.service";

@Component({
	selector: "app-recap-and-pay",
	templateUrl: "./recap-and-pay.page.html",
	styleUrls: ["./recap-and-pay.page.scss"],
})
export class RecapAndPayPage implements OnInit {

	@Input() inCart = false;
	@Input() orderMessage: string;
	@Input() inputPaymentClaim: PaymentClaim;
	@Input() orderAmount: number;
	@Input() paymentAuthorizationMinAmountIncrease: number;
	@Input() paymentAuthorizationPercentIncrease: number;
	@Input() authorizationOnlyMaxAmountIncrease: number;

	@Output() paymentPerformed = new EventEmitter();

	platformStripe: Stripe;
	stripe: Stripe;
	requestCode: string;
	paymentClaim: PaymentClaim;
	sentOrder: Order;

	elements: StripeElements;
	card: StripeCardElement;
	stripeForm: FormGroup;
	clientSecret: string;
	cardErrors: string;
	currentUser: User;

	public wrongRequestCode: boolean;
	private connectedStripeAccount: string;
	userPaymentMethods: PaymentMethod[];
	bDefaultPaymentMethodRequested: boolean;

	@ViewChild("cardElement") cardElement: ElementRef;
	selectedPaymentMethodId: string;
	NEW_CARD = "NEW_CARD";
	CARD_PAYMENT_TYPE = PAYMENT_METHOD.stripe_card;
	savePaymentMethod: boolean;
	private paymentMethodId: string;
	public getAmount = PaymentService.getAmount;

	constructor(
		private route: ActivatedRoute,
		private paymentService: PaymentService,
		private orderingService: OrderingService,
		private fb: FormBuilder,
		private appConfigurationService: AppConfigurationService,
		public dts: DataTranslateService,
		private loadingCtrl: LoadingController,
		private alertCtrl: AlertController,
		private popoverController: PopoverController,
		private appNav: AppNavigatorService
	) {
	}

	async ngOnInit() {
		this.currentUser = this.appConfigurationService.loggedUser;

		this.stripeForm = this.fb.group({
			name: ["", Validators.required]
		});

		//this.requestCode = this.route.snapshot.paramMap.get("requestCode");

		//if (this.requestCode && this.requestCode.trim().length > 0) {
		//	const requestCode = await this.paymentService.retrieveRequestCode(this.requestCode);

		if (this.inputPaymentClaim) {
			this.paymentClaim = this.inputPaymentClaim;
		}

		if (!this.paymentClaim) {
			this.paymentClaim = await this.retrievePaymentClaim();
		}

		if (this.paymentClaim) {
			//this.paymentClaim = requestCode.paymentClaim;
			this.wrongRequestCode = false;

			const oJSON = JSON.parse(this.paymentClaim.providerCustomDataJson);

			this.clientSecret = oJSON.paymentIntent.client_secret;

			this.platformStripe = await loadStripe(environment.Stripe_public_key);
			this.connectedStripeAccount = await this.getStoreOrAppStripeAccount();
			this.stripe = await loadStripe(environment.Stripe_public_key, {
				stripeAccount: this.connectedStripeAccount
			});

			// optional parameters
			const elementsOptions: StripeElementsOptionsClientSecret = {
				locale: this.dts.currentLang as any
			};

			if (this.currentUser) {
				this.elements = this.platformStripe.elements(elementsOptions);
			}
			else {
				this.elements = this.stripe.elements(elementsOptions);
			}
			// Only mount the element the first time
			if (!this.card) {
				this.card = this.elements.create("card", {
					style: {
						base: {
							iconColor: "#666EE8",
							color: "#31325F",
							lineHeight: "40px",
							fontWeight: "300",
							fontFamily: "\"Helvetica Neue\", Helvetica, sans-serif",
							fontSize: "18px",
							"::placeholder": {
								color: "#ccc"
							}
						}
					},
					hidePostalCode: true
				} as StripeCardElementOptions);

				this.card.mount(this.cardElement.nativeElement);

				this.card.on("change" as any, ((event: StripeCardCvcElementChangeEvent) => {
					this.cardErrors = event.error?.message || "";
				}) as any);
			}

			try {
				const readPaymentMethods = await this.paymentService.getPaymentMethods();
				if (readPaymentMethods?.length) {
					this.userPaymentMethods = [];
					for (const pm of readPaymentMethods) {
						this.userPaymentMethods.push(pm);
					}
				}
			}
			catch (e) {
				//ignore this error, this will simply force the user to save again the payment method
			}

			this.bDefaultPaymentMethodRequested = true;

			if (this.userPaymentMethods?.length) {
				const defaultPaymentMethod = this.userPaymentMethods.find(pm => pm.defaultPaymentMethod);
				if (defaultPaymentMethod) {
					this.selectedPaymentMethodId = defaultPaymentMethod?.stripePaymentMethodId;
				}
				else {
					//if for any reason a default payment method is not specified, select the first one
					this.selectedPaymentMethodId = this.userPaymentMethods[0]?.stripePaymentMethodId;
				}
			}
			else {
				this.selectedPaymentMethodId = this.NEW_CARD;
			}
		}
		else {
			this.wrongRequestCode = true;
		}
		//}
	}

	async infoAmountToAuthorizeClicked(e: Event) {

		const popover = await this.popoverController.create({
			component: AuthorizedAmountInfoComponent,
			event: e,
			cssClass: "infoAmountToAuthorizePopoverClass",
			mode: "ios",
			componentProps: {
				paymentAuthorizationMinAmountIncrease: this.paymentAuthorizationMinAmountIncrease,
				paymentAuthorizationPercentIncrease: this.paymentAuthorizationPercentIncrease,
				authorizationOnlyMaxAmountIncrease: this.authorizationOnlyMaxAmountIncrease
			}
		});

		await popover.present();
	}

	private async getStoreOrAppStripeAccount() {
		if (this.paymentClaim?.store?.businessInfo?.stripeConnectInfo?.connectedAccountId) {
			return this.paymentClaim.store.businessInfo.stripeConnectInfo.connectedAccountId;
		}
		else {
			const app = await this.appConfigurationService.getOrLoadAndSaveGloballyAppConfiguration(this.paymentClaim.appId, false);
			return app?.company?.businessInfo?.stripeConnectInfo?.connectedAccountId;
		}
	}

	private async retrievePaymentClaim(): Promise<PaymentClaim | undefined> {
		const requestCodeId = this.route.snapshot.paramMap.get("requestCode");
		const orderId = this.route.snapshot.paramMap.get("orderId");

		if (requestCodeId && requestCodeId.trim().length > 0) {
			const requestCode = await this.paymentService.retrieveRequestCode(requestCodeId);

			if (requestCode) {
				return requestCode.paymentClaim;
			}
			else {
				return undefined;
			}
		}
		if (orderId && orderId.trim().length > 0) {
			return await this.paymentService.retrieveValidPaymentClaimByOrder(this.appNav.appId(), orderId);
		}
	}

	async pay() {
		let paymentIntentResult;
		const loading = await this.loadingCtrl.create({
			message: this.dts.translateService.instant("JUST_A_MOMENT")
		});
		await loading.present();
		let prepared = false;
		try {
			const saveStripeCustomerError = await this.obtainPaymentMethodId();
			if (saveStripeCustomerError) {
				await loading.dismiss();
				this.genericError(saveStripeCustomerError);
				this.paymentPerformed.emit({ status: "ko" });
				return;
			}

			//call send order preparation, if there is an order connected to this payment
			if (this.inCart) {
				this.sentOrder = await this.orderingService.prepareSendOrder(this.paymentClaim.appId, this.orderMessage);
				prepared = true;
			}
			const paymentOptions: Partial<ConfirmCardPaymentData> = {
				payment_method: this.paymentMethodId
			};
			if (this.savePaymentMethod) {
				paymentOptions.setup_future_usage = "off_session";
			}
			paymentIntentResult = await this.stripe.confirmCardPayment(this.clientSecret, paymentOptions);
		}
		catch (e) {
			//in case of error, try to call the rollback method
			if (prepared) {
				this.sentOrder = null;
				try {
					await this.orderingService.rollbackPrepareSendOrder(this.paymentClaim.appId);
				}
				catch (ignore) { }
			}

			const errorDescription = this.appConfigurationService.queryManager.getErrorDescription(e);
			this.alertCtrl.create({
				header: this.dts.translateService.instant("ERROR"),
				message: errorDescription,
				buttons: [this.dts.translateService.instant("OK")]
			}).then(errMsg => {
				errMsg.present();
			});
		}
		finally {
			await loading.dismiss();
		}
		await this.handlePaymentResult(paymentIntentResult);
	}

	private async obtainPaymentMethodId(): Promise<string> {
		if (this.selectedPaymentMethodId && this.selectedPaymentMethodId !== this.NEW_CARD) {
			//if a saved payment method exists we just need to convert the payment method
			try {
				const result = await this.paymentService.claimPayment(this.paymentClaim.appId, this.paymentClaim.id, JSON.stringify({
					platformPaymentMethodId: this.selectedPaymentMethodId,
					paymentClaimId: this.paymentClaim.id
				} as StripeClaimPaymentCustomData));
				this.paymentMethodId = (JSON.parse(result.providerCustomDataJson) as StripeClaimPaymentResultCustomData).connectedPaymentMethodId;
			}
			catch (e) {
				return this.appConfigurationService.queryManager.getErrorDescription(e);
			}
		}
		else if (this.selectedPaymentMethodId === this.NEW_CARD) {
			let platformPaymentMethodId;
			const paymentMethodData: CreatePaymentMethodData = {
				type: "card",
				card: this.card,
			};
			if (this.currentUser) {
				paymentMethodData.billing_details = {
					name: this.currentUser.firstName + " " + this.currentUser.lastName,
					email: this.currentUser.email,
					phone: this.currentUser.telephoneNumber
				}
			}

			let createPaymentMethodResult;
			if (this.currentUser) {
				//if there is a user, use platformStripe to create there the paymentMethod
				createPaymentMethodResult = await this.platformStripe.createPaymentMethod(paymentMethodData);
				if (createPaymentMethodResult.error) {
					return createPaymentMethodResult.error.message;
				}
				platformPaymentMethodId = createPaymentMethodResult.paymentMethod.id;

				if (this.savePaymentMethod) {
					try {
						await this.paymentService.savePaymentMethod(JSON.stringify({
							platformPaymentMethodId
						} as StripeSavePaymentMethodCustomData));
					}
					catch (e) {
						return this.appConfigurationService.queryManager.getErrorDescription(e);
					}
				}
				let resultClaim: ClaimPaymentMethodResult;
				try {
					resultClaim = await this.paymentService.claimPayment(this.paymentClaim.appId, this.paymentClaim.id,
						JSON.stringify({ platformPaymentMethodId } as StripeClaimPaymentCustomData));
				}
				catch (e) {
					return this.appConfigurationService.queryManager.getErrorDescription(e);
				}
				if (resultClaim && resultClaim.providerCustomDataJson) {
					const customDataResult = JSON.parse(resultClaim.providerCustomDataJson) as StripeClaimPaymentResultCustomData;
					this.paymentMethodId = customDataResult.connectedPaymentMethodId;
				}
			}
			else {
				//if there is no user, create the paymentMethod directly into the connected account, no platform user will be created, and
				//the payment method used will be this
				createPaymentMethodResult = await this.stripe.createPaymentMethod(paymentMethodData);
				if (createPaymentMethodResult.error) {
					return createPaymentMethodResult.error.message;
				}
				this.paymentMethodId = createPaymentMethodResult.paymentMethod.id;
			}
		}

		//we should always have a paymentMethodId here..
		if (!this.paymentMethodId) {
			return this.dts.translateService.instant("payment.PAYMENT_UNEXPECTED_STATE");
		}
	}

	private async handlePaymentResult(paymentIntentResult: { paymentIntent?: PaymentIntent; error?: StripeError }) {
		if (paymentIntentResult.error) {
			this.sentOrder = null;
			try {
				await this.orderingService.rollbackPrepareSendOrder(this.paymentClaim.appId);
			}
			catch (ignore) { }

			if (paymentIntentResult.error.code === "payment_intent_unexpected_state") {
				this.unexpectedStateError();
			}
			else {
				this.genericError(paymentIntentResult.error.message);
			}

			this.paymentPerformed.emit({ status: "ko" });
		}
		else if (paymentIntentResult.paymentIntent.status === "succeeded") {
			const alrt: HTMLIonAlertElement = await this.alertCtrl.create({
				header: this.dts.translateService.instant("payment.PAYMENT_SUCCESS_SHORT"),
				message: this.dts.translateService.instant("payment.PAYMENT_SUCCESS"),
				backdropDismiss: false,
				buttons: [{
					text: this.dts.translateService.instant("OK"),
					handler: () => {
						alrt.dismiss().then(() => {

							if (!this.inputPaymentClaim) {
								if (this.appConfigurationService.loggedUser) {
									this.appNav.navigateForward("/payment-history", {
										replaceUrl: true
									});
								}
								else {
									this.appNav.pop();
								}
							}

							this.paymentPerformed.emit({ status: "ok", sentOrder: this.sentOrder });
						});

						return false;
					}
				}]
			});

			alrt.present();
		}
		else if (paymentIntentResult.paymentIntent.status === "requires_capture") {
			const alrt: HTMLIonAlertElement = await this.alertCtrl.create({
				header: this.dts.translateService.instant("payment.PAYMENT_AUTHORIZATION_SUCCESS_SHORT"),
				message: this.dts.translateService.instant("payment.PAYMENT_AUTHORIZATION_SUCCESS"),
				backdropDismiss: false,
				buttons: [{
					text: this.dts.translateService.instant("OK"),
					handler: () => {
						alrt.dismiss().then(() => {

							if (!this.inputPaymentClaim) {
								if (this.appConfigurationService.loggedUser) {
									this.appNav.navigateForward("/payment-history", {
										replaceUrl: true
									});
								}
								else {
									this.appNav.pop();
								}
							}

							this.paymentPerformed.emit({ status: "ok", sentOrder: this.sentOrder });
						});

						return false;
					}
				}]
			});

			alrt.present();
		}
	}

	private unexpectedStateError() {
		this.alertCtrl.create({
			header: this.dts.translateService.instant("ERROR"),
			message: this.dts.translateService.instant("payment.PAYMENT_UNEXPECTED_STATE"),
			buttons: [this.dts.translateService.instant("OK")]
		}).then(errMsg => {
			errMsg.present();
		});
	}

	private genericError(message: string) {
		this.alertCtrl.create({
			header: this.dts.translateService.instant("ERROR"),
			message,
			buttons: [this.dts.translateService.instant("OK")]
		}).then(errMsg => {
			errMsg.present();
		});
	}
}
