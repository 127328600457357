import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from "@angular/core";
import {CALENDAR_UNIT, CalendarAmount} from "src/app/backend-api";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
	selector: "app-calendar-amount",
	templateUrl: "./calendar-amount.component.html",
	styleUrls: ["./calendar-amount.component.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CalendarAmountComponent),
			multi: true
		}
	]
})
export class CalendarAmountComponent implements ControlValueAccessor {
	CALENDAR_UNIT = CALENDAR_UNIT;

	@Input()
	amount: CalendarAmount = {} as CalendarAmount;

	@Input()
	hideMilliseconds?: boolean;
	@Input()
	hideSeconds?: boolean;
	@Input()
	hideMinutes?: boolean;
	@Input()
	hideHours?: boolean;
	@Input()
	hideDays?: boolean;

	constructor(
	) { }

	writeValue(ca: any) {
		this.amount.value = ca?.value;
		this.amount.measureUnit = ca?.measureUnit;
	}

	registerOnChange(fn) {
		this.propagateChange = fn;
	}

	registerOnTouched(fn) {
	}

	private propagateChange = (_:any) => {};

	updateAmount() {
		if (this.amount.value !== null && this.amount.value !== undefined && this.amount.value >= 0 && this.amount.measureUnit) {
			this.propagateChange(this.amount);
		}
	}
}
