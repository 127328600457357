import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { PopOverButtonMenuComponent, IPopOverButtonMenuItem } from "./pop-over-button-menu/pop-over-button-menu.component";

@Component({
	selector: "app-pop-over-button",
	templateUrl: "./pop-over-button.component.html",
	styleUrls: ["./pop-over-button.component.scss"],
})
export class PopOverButtonComponent implements OnInit {

	// asincrona per le traduzioni
	@Input() items: IPopOverButtonMenuItem[] = null;
	@Input() context: any;
	// pulsanti aggiuntivi
	@Input() moreItems: IPopOverButtonMenuItem[] = null;

	@Output() itemClicked = new EventEmitter();

	constructor(
		private popoverController: PopoverController
	) { }

	ngOnInit() { }

	// click su apertura menu
	buttonClicked(ev: Event) {
		ev.stopPropagation();
		ev.preventDefault();

		this.presentPopover(ev);
	}

	// click su pulsanti aggiuntivi
	moreItemClicked(mi: IPopOverButtonMenuItem, ev: Event) {
		ev.stopPropagation();
		ev.preventDefault();

		this.itemClicked.emit({
			item: mi,
			context: this.context
		});
	}

	async presentPopover(ev): Promise<any> {

		const popover = await this.popoverController.create({
			component: PopOverButtonMenuComponent,
			componentProps: {
				items: this.items
			},
			//translucent: true,
			backdropDismiss: true,
			showBackdrop: true,
			event: ev,
			mode: "ios", // arrow
			//cssClass: "popOverMenuButton"
		});
		await popover.present();
		const ret = await popover.onDidDismiss() as any; // torna data.confirmed

		// solo se clicco su di una voce
		if (ret.data) {

			this.itemClicked.emit({
				item: ret.data.item,
				context: this.context
			});
		}

	}

}
