import { NgModule } from "@angular/core";
import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
import { HttpLinkModule, HttpLink } from "apollo-angular-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { statics } from "./core/statics";
import { HttpHeaders } from "@angular/common/http";
import { ApolloLink } from "apollo-link";
import { environment } from "../environments/environment";

const uri = environment.graphqlUrl;

const middleware = new ApolloLink((operation, forward) => {
	const token = statics.jwt;
	if (token) {
		operation.setContext({
			headers: new HttpHeaders().set("Authorization", `Bearer ${token}`)
		});
	}
	if (operation.variables) {
		const omitTypename = (key, value) => (key === "__typename" ? undefined : value);
		// eslint-disable-next-line no-param-reassign
		operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
	}
	return forward(operation);
});

export function createApollo(httpLink: HttpLink) {
	return {
		link: middleware.concat(httpLink.create({ uri })),
		cache: new InMemoryCache(),
		defaultOptions: {
			watchQuery: {
				fetchPolicy: "no-cache",
				errorPolicy: "ignore",
			},
			query: {
				fetchPolicy: "no-cache",
				errorPolicy: "all",
			},
		}
	};
}

@NgModule({
	exports: [ApolloModule, HttpLinkModule],
	providers: [
		{
			provide: APOLLO_OPTIONS,
			useFactory: createApollo,
			deps: [HttpLink],
		},
	],
})
export class GraphQLModule { }
