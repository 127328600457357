<ion-grid class="main-grid ion-no-padding ion-text-center" *ngIf="!showAsLabel">
	<ng-container *ngIf="orderingService.queryOrderItemsByProduct(product).length>1; else lessThan2">
		<ion-row class="ion-align-items-center ion-no-padding ion-nowrap">
			<ion-col class="ion-text-nowrap">
				<div class="manyInCart">
					{{ 'ALREADY_IN_CART' | translate }}
				</div>
			</ion-col>
		</ion-row>
	</ng-container>
	<ng-template #lessThan2>
		<ion-row class="ion-align-items-center ion-no-padding ion-nowrap" *ngIf="orderingService.getTotalRequestedQuantityByProduct(product)>0">
			<ion-col class="ion-no-padding">
				<ion-button class="smallButtons" color="danger" size="small" fill="outline"
					(click)="removeClicked(product)" [disabled]="disabled || updatingOrderItem">
					<ion-icon name="remove"></ion-icon>
				</ion-button>
			</ion-col>
			<ion-col class="quantityText ion-text-nowrap">
				<ion-text color="success">
					{{ quantityStringByProduct(product)}}
				</ion-text>
			</ion-col>
			<ion-col class="ion-no-padding">
				<ion-button class="smallButtons"
					[disabled]="disabled || !orderingService.canAddMore(product) || updatingOrderItem" color="success"
					size="small" fill="outline" (click)="addClicked(product)">
					<ion-icon name="add"></ion-icon>
				</ion-button>
			</ion-col>
		</ion-row>

		<ion-row class="ion-align-items-center ion-nowrap"
			*ngIf="orderingService.getTotalRequestedQuantityByProduct(product)<=0">
			<ion-col>
				<ion-button class="firstAddButton" color="success" size="small" (click)="addClicked(product)"
					[disabled]="disabled || updatingOrderItem">
					<ion-icon name="cart" style="margin-right:3px;"></ion-icon>
					{{ quantityStringByProduct(product, product.minimumLot)}}
				</ion-button>
			</ion-col>
		</ion-row>
	</ng-template>
</ion-grid>
<span *ngIf="showAsLabel">
	{{ quantityStringByProduct(product)}}
</span>