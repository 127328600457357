<app-header [title]="'payment.SEND_PAYMENT' | translate" [hidden]="inCart">
</app-header>

<ion-content>
	<ion-text *ngIf="wrongRequestCode === true" color="danger">
		<p class="label medium ion-text-center initial-uppercase">{{ 'payment.WRONG_PAYMENT_CODE' | translate }}.</p>
	</ion-text>
	<div [hidden]="!paymentClaim || inCart" class="title-box label large initial-uppercase">
		<p>{{'payment.PAYMENT_INFORMATION' | translate}}</p>
	</div>
	<ion-grid [hidden]="!paymentClaim">
		<ion-row *ngIf="orderAmount">
			<ion-col size="4" class="center-content-vertically ion-margin-bottom">
				<ion-label [ngClass]="{'label': true, 'medium': !inCart, 'initial-uppercase': true}">
					{{'payment.ORDER_AMOUNT' | translate}}
				</ion-label>
			</ion-col>
			<ion-col class="center-content-vertically ion-margin-bottom">
				<ion-label [ngClass]="{'value': true, 'medium': !inCart}">
					{{ orderAmount | currency:'EUR':'symbol':'1.2-2':dts.currentLang }}
				</ion-label>
			</ion-col>
		</ion-row>
		<ion-row>
			<ion-col size="4" class="center-content-vertically ion-margin-bottom">
				<ion-label *ngIf="!orderAmount"
					[ngClass]="{'label': true, 'medium': !inCart, 'initial-uppercase': true}">
					{{'payment.AMOUNT' | translate}}
				</ion-label>
				<ion-label *ngIf="orderAmount"
					[ngClass]="{'label': true, 'medium': !inCart, 'initial-uppercase': true}">
					{{'payment.AMOUNT_TO_AUTHORIZE' | translate}}</ion-label>
			</ion-col>
			<ion-col class="center-content-vertically ion-margin-bottom">
				<ion-label [ngClass]="{'value': true, 'medium': !inCart}">
					{{paymentClaim ? (getAmount(paymentClaim) | currency:'EUR':'symbol':'1.2-2':dts.currentLang) : ""}}
				</ion-label>
				<ion-button *ngIf="orderAmount" fill="clear" (click)="infoAmountToAuthorizeClicked($event)"
					style="margin-left: 5px;">
					<ion-icon name="help-circle-outline"></ion-icon>
				</ion-button>
			</ion-col>
		</ion-row>
		<ion-row>
			<ion-col size="4" class="center-content-vertically ion-margin-bottom">
				<ion-label [ngClass]="{'label': true, 'medium': !inCart, 'initial-uppercase': true}">
					{{'payment.IN_FAVOUR_OF' | translate}}
				</ion-label>
			</ion-col>
			<ion-col class="center-content-vertically ion-margin-bottom">
				<ion-label [ngClass]="{'value': true, 'medium': !inCart}">
					{{paymentClaim?.store?.name ? (paymentClaim.store.name | dataTranslate) : ""}}
				</ion-label>
			</ion-col>
		</ion-row>
		<ion-row>
			<ion-col size="4" class="center-content-vertically ion-margin-bottom">
				<ion-label [ngClass]="{'label': true, 'medium': !inCart, 'initial-uppercase': true}">
					{{'payment.REASON' | translate}}
				</ion-label>
			</ion-col>
			<ion-col size="8" class="center-content-vertically ion-margin-bottom">
				<ion-label [ngClass]="{'value': true, 'medium': !inCart}">
					{{paymentClaim ? paymentClaim.description : ""}}
				</ion-label>
			</ion-col>
		</ion-row>
		<ion-row [hidden]="!userPaymentMethods?.length">
			<ion-col>
				<ion-radio-group #radio [value]="selectedPaymentMethodId"
					(ionChange)="selectedPaymentMethodId=$event.detail.value">
					<ion-item *ngFor="let pm of userPaymentMethods">
						<ion-label class="label small ion-text-left initial-uppercase" style="white-space: normal;">
							{{pm?.type === CARD_PAYMENT_TYPE ? ('payment.PAY_SAVED_METHOD_CARD' | translate : {last_digits: pm.lastDigits}) :
								('payment.PAY_SAVED_METHOD' | translate)}}
						</ion-label>
						<ion-radio slot="start" value="{{pm.stripePaymentMethodId}}"></ion-radio>
						<ion-icon slot="start" name="star" color="primary" *ngIf="pm.defaultPaymentMethod"></ion-icon>
					</ion-item>
					<ion-item>
						<ion-label class="label small ion-text-left initial-uppercase" style="white-space: normal;">
							{{'payment.INSERT_CREDIT_CARD' | translate}}
						</ion-label>
						<ion-radio slot="start" value="{{NEW_CARD}}"></ion-radio>
					</ion-item>
				</ion-radio-group>
			</ion-col>
		</ion-row>
		<ion-row [hidden]="!bDefaultPaymentMethodRequested || !!userPaymentMethods?.length">
			<ion-col size="12">
				<ion-label class="label medium initial-uppercase" style="font-weight: bold;display: block;">
					{{'payment.INSERT_CREDIT_CARD' | translate}}
				</ion-label>
			</ion-col>
		</ion-row>
		<ion-row [hidden]="!bDefaultPaymentMethodRequested || radio.value !== NEW_CARD">
			<ion-col>
				<div class="credit-card-container">
					<form novalidate [formGroup]="stripeForm">
						<div #cardElement class="field"></div>
						<ion-text *ngIf="!!cardErrors" color="danger">
							<div style="margin:10px 0;">{{cardErrors}}</div>
						</ion-text>
					</form>
					<div [hidden]="!currentUser" class="credit-card-divider"></div>
					<ion-item [hidden]="!currentUser" class="no-border-bottom">
						<ion-checkbox [(ngModel)]="savePaymentMethod"></ion-checkbox>
						<ion-label class="label small ion-text-left initial-uppercase"
							style="padding-left: 10px; white-space: normal;">
							{{'payment.SAVE_PAYMENT_METHOD' | translate}}
						</ion-label>
					</ion-item>
				</div>
			</ion-col>
		</ion-row>
		<ion-row *ngIf="!inCart">
			<ion-col>
				<ion-button expand="block" (click)="pay()">{{'payment.PAY' | translate}}</ion-button>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-content>
<app-footer *ngIf="!inCart"></app-footer>