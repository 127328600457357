import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from "@angular/router";
import { Observable } from "rxjs";
import { AppConfigurationService, tAppMapPropertyName } from "../services/app-configuration.service";
import { BootstrapService } from "../services/bootstrap.service";
import { AppNavigatorService } from "../services/app-navigator.service";
import { IPageConfig } from "src/app/routes";
import { LoginService } from "src/app/account/services/login.service";
import { safeAwaitOrDefault } from "../utils";
import { statics } from "../statics";
import { App } from "src/app/backend-api";
import { DataTranslateService } from "../services/data-translate.service";

@Injectable({
	providedIn: "root"
})
export class AppLoadedGuard implements CanActivate {

	constructor(
		private appNav: AppNavigatorService,
		private appConfig: AppConfigurationService,
		private bootstrapService: BootstrapService,
		private loginService: LoginService,
		private dts: DataTranslateService
	) {
	}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

		// prende l'appId dal prox stato
		const destAppId = this.appNav.appId(state.url);

		// app corrente inizializzata
		const destApp: App = this.appConfig.appMap.get("currentApp", destAppId);

		if (next.routeConfig.path === "" || next.routeConfig.path === "bootstrap") {
			return !destApp;
		} else {

			if (destApp) {

				return new Promise(async (resolve, reject) => {
					const uc = await this._canActivate_userCheck(next, state, destAppId) && this._canActivate_appCheck(next, state, destAppId);
					if (uc) {

						// gestione cambio tema
						const destAppTheme = destApp.theme || "default";
						if (this.appConfig.currentAppTheme !== destAppTheme) {
							this.appConfig.currentAppTheme = destAppTheme;
						}

						resolve(true);
						return;
					}
					reject();
				})


			} else {
				// dav 221119
				this.bootstrapService.startupRedirectRoute = state.url;

				// naviga sulla bootstrap passando l'appId Corretto
				this.appNav.navigateRoot("/bootstrap", state.url);
				return false;
			}
		}
	}

	// check per controllo permessi utente
	private async _canActivate_userCheck(next: ActivatedRouteSnapshot, state: RouterStateSnapshot, destAppId: string): Promise<boolean> {

		// se l'utente non è loggato ritorna sempre true
		const uea = this.appConfig.isUserOptEnrolledForThisApp(this.appConfig.loggedUser, destAppId);
		if (!uea) {

			// enrolla l'utente, se non esiste un utente ritorna 'null'
			const tempUser = await safeAwaitOrDefault(this.loginService.enrollCurrentUserInApp(destAppId, false));
			if (tempUser) {
				// aggiorna le info utente con i suoi permessi
				this.appConfig.setLoggedUser(statics.jwt, tempUser);
			}
		}

		const nextRouteData: IPageConfig = (next.data || {}) as any;

		// dav 020520, added isPageVisibleForApp check
		return this.appConfig.userCanAccessPage(nextRouteData, destAppId);
	}

	// check per controllo permessi app sulla pagina
	private _canActivate_appCheck(next: ActivatedRouteSnapshot, state: RouterStateSnapshot, destAppId: string): boolean {
		const nextRouteData: IPageConfig = (next.data || {}) as any;

		return this.appConfig.isPageVisibleForAppOrStore(nextRouteData, destAppId);
	}
}
