import {Pipe, PipeTransform} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Pipe({name: "translateWithDefault"})
export class TranslateWithDefaultPipe implements PipeTransform {
	constructor(
		public translate: TranslateService) {

	}

	transform(translationKey: string, defaultTranslationKey: string = ""): string {
		const translation = this.translate.instant(translationKey);
		if (defaultTranslationKey === "") {
			return translation; // if the default is not provided, the first translation will be returned
		}
		else if (translation === translationKey) {
			return this.translate.instant(defaultTranslationKey);
		}
		return translation;
	}

}
