import { Pipe, PipeTransform } from "@angular/core";
import { DataTranslateService } from "../services/data-translate.service";
import { LocalizedString } from "src/app/backend-api";

@Pipe({
	name: "dataTranslate"
})
export class DataTranslatePipe implements PipeTransform {

	constructor(
		private dts: DataTranslateService
	) {

	}

	transform(value: LocalizedString, ...args: any[]): any {
		return this.dts.dataTranslate(value, ...args);
	}

}
