import { Injectable } from "@angular/core";
import {Store, Pagination, StorePaginatedResponse, User, FeatureRequest} from "src/app/backend-api";
import { QueryManagerService } from "../services/query-manager.service";
import {
	DELETE_STORE,
	UPDATE_STORE,
	CREATE_STORE,
	GET_STORES_BY_DISTANCE_PAGINATED,
	SAVE_CURRENT_USER_FAVOURITE_STORE,
	SEARCH_STORES,
	GET_STORE
} from "./store-queries";
import { Geolocation } from "src/app/backend-api";
import { safeAwaitOrDefault } from "../utils";

@Injectable({
	providedIn: "root"
})
export class QueryStoreService {

	constructor(
		private queryManager: QueryManagerService,
	) { }

	updateStore(st: Store): Promise<Store> {
		const params = {
			st
		};
		return this.queryManager.mutate(UPDATE_STORE, params);
	}

	createStore(st: Partial<Store>, requestedFeatures?: FeatureRequest[], targetSubscriptionId?: string): Promise<Store> {
		const params = {
			st,
			requestedFeatures,
			targetSubscriptionId
		};
		return this.queryManager.mutate(CREATE_STORE, params);
	}

	async deleteStore(appId: string, storeId: string): Promise<Store> {
		return this.queryManager.mutate(DELETE_STORE, { appId, storeId });
	}

	getStoresByDistance(
		options: {
			appId: string,
			tags?: string[],
			location: Partial<Geolocation>,
			pagination: Pagination,
			searchString?: string
		}
	): Promise<StorePaginatedResponse> {

		options = options || {} as any;

		const params = {
			appId: options.appId,
			tags: options.tags,
			location: options.location,
			searchString: options.searchString
		};

		return this.queryManager.execute(GET_STORES_BY_DISTANCE_PAGINATED, params, {
			pagination: options.pagination
		});
	}

	getStore(appId: string, id: string): Promise<Store> {
		const params = {
			appId,
			id
		};

		return this.queryManager.execute(GET_STORE, params);
	}

	// salva lo store preferito per utente e app
	async saveCurrentUserFavouriteStore(appId: string, storeId: string): Promise<boolean> {
		const params = {
			appId,
			storeId
		};

		const user: User = await safeAwaitOrDefault(this.queryManager.mutate(SAVE_CURRENT_USER_FAVOURITE_STORE, params));
		return !!user?.id;
	}

	admin_searchStores(searchString: string, first?: number, after?: string, appId?: string): Promise<StorePaginatedResponse> {
		return this.queryManager.execute(SEARCH_STORES, { searchString }, { pagination: { first, after }, showLoading: false });
	}

}
