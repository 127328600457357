import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { AppConfigurationService } from "../../core/services/app-configuration.service";
import {
	CHANGE_PASSWORD,
	GET_USER_DATA,
	GET_USER_STORES,
	REQUEST_CREDENTIALS,
	UPDATE_USER_DATA,
	ENROLL_USER_IN_APP,
	GET_APPS_MANAGED_BY_USER
} from "./account-queries";
import { LocalStorageService } from "src/app/core/services/local-storage.service";
import { AlertController } from "@ionic/angular";
import { statics } from "src/app/core/statics";
import * as firebase from "firebase";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { DataTranslateService } from "src/app/core/services/data-translate.service";
import { FEATURE, LoginResult, SignupInput, Store, User, AppPaginatedResponse } from "src/app/backend-api";
import { environment } from "src/environments/environment";
import { IAddressEditOutput } from "../pages/address-edit/address-edit.page";
import { QueryStoreService } from "src/app/core/queries/query-store.service";
import { safeAwaitOrDefault } from "src/app/core/utils";
import { QueryAppService } from "src/app/core/queries/query-app.service";
import { CrossPlatformToastService } from "src/app/core/services/cross-platform-toast.service";

export interface IFirebaseAuthConfig {
	user: {
		refreshToken: string;
		uid: string;
		displayName: string;
		photoURL: string;
		email: string;
		emailVerified: boolean;
		phoneNumber: string;
		isAnonymous: boolean;
		tenantId: string;
		metadata: any;
		providerData: any;
	};
	credential: {
		idToken: string;
		accessToken: string;
		providerId: "google.com" | "apple.com";
		signInMethod: "google.com" | "apple.com";
	};
	operationType: "signIn";
	additionalUserInfo: {
		isNewUser: boolean;
		profile: any;
		providerId: string;
		username?: string | null;
	};
}

@Injectable({
	providedIn: "root"
})
export class LoginService {

	private headers: HttpHeaders;
	private options: {
		headers: HttpHeaders
	};

	constructor(
		private http: HttpClient,
		private appConfigService: AppConfigurationService,
		private localStorageService: LocalStorageService,
		private toastService: CrossPlatformToastService,
		private dts: DataTranslateService,
		private alertCtrl: AlertController,
		private iab: InAppBrowser,
		private queryStore: QueryStoreService) {
		this.headers = new HttpHeaders({ "Content-Type": "application/json" });
		this.options = { headers: this.headers };
	}

	// dav 230620 - fixes input data
	sanitizeUserData(user: Partial<User>): void {
		if (user.email) { user.email = user.email.trim() }
		if (user.username) { user.username = user.username.trim() }
		if (user.firstName) { user.firstName = user.firstName.trim() }
		if (user.lastName) { user.lastName = user.lastName.trim() }
		if (user.telephoneNumber) { user.telephoneNumber = user.telephoneNumber.trim() }
	}

	register(data: {
		language: string,
		name: string;
		surname: string;
		telephoneNumber: string;
		email: string;
		password: string;
	}): Observable<any> {

		const o: SignupInput = {
			username: undefined, // dav 190820, rimosso
			password: data.password,
			firstName: data.name,
			lastName: data.surname,
			telephoneNumber: data.telephoneNumber,
			email: data.email,
			appId: statics.appId, // app singola o piattaforma
			locale: data.language  // TODO e' giusto usare language come locale? il locale dovrebbe essere qualcosa tipo it-IT
		};

		// sistema i dati in input
		this.sanitizeUserData(o);

		return this.http.post(environment.backendUrl + "/auth/signup", o, this.options);
	}

	login(data: {
		language: string,
		username: string;
		password: string;
		appVersion: string;
	}): Observable<any> {

		const o = {
			username: data.username,
			password: data.password,
			locale: data.language,
			appVersion: data.appVersion,
			appId: statics.appId // app singola o piattaforma
		};

		// sistema i dati in input
		this.sanitizeUserData(o);

		return this.http.post(environment.backendUrl + "/auth/login", o, this.options);
	}

	async resendSignupVerificationEmail(data: {
		username: string;
		pushRegistrationId: string;
	}): Promise<boolean> {

		return await this.http.get(environment.backendUrl + "/auth/resendSignupVerificationEmail" +
			"?appId=" + encodeURIComponent(statics.appId) + /* app singola o piattaforma */
			"&username=" + encodeURIComponent(data.username) +
			"&pushRegistrationId=" + encodeURIComponent(data.pushRegistrationId)).toPromise() as boolean;
	}

	getUserData(): Promise<User> {
		return this.appConfigService.queryManager.execute(GET_USER_DATA);
	}

	// verrà richiamata quando dal servizio di login ricevo un token JWT e lo devo salvare sui dati utente
	async JWTSaveUserInfo(jwt: string, appId: string): Promise<any> {

		await this.localStorageService.saveJwtToken(jwt);

		// questa istruzione mi permette di leggere le info dell'utente senza aver effettuato
		// la setLoggedUser
		statics.jwt = jwt;

		// lo rilegge al volo in modo da aggiornare la lingua per le query, in questo punto si potrebbe fare un query personalizzata
		const ud = await this.getUserData();

		// imposta i dati dell'utente nel posto giusto
		await this.appConfigService.setLoggedUser(jwt, ud, {
			raiseStateChange: true,
			stateChangeAppId: appId
		});

		// mostra il messaggio di connessione avvenuta
		this.toastService.showToast({
			message: this.dts.translateService.instant("account.LOGGEDIN")
		});
	}

	// aggiorna le info utente ma non torna niente indietro
	async updateUserData(user: User, callKey?: string): Promise<User> {

		// sistema i dati in input
		this.sanitizeUserData(user);

		const vars = {
			user
		};

		return await this.appConfigService.queryManager.mutate(UPDATE_USER_DATA, vars, { callKey });
	}

	requestCredentials(email: string): Promise<void> {
		const vars = {
			email
		};

		return this.appConfigService.queryManager.mutate(REQUEST_CREDENTIALS, vars);
	}

	callPrivacy() {
		this.iab.create("https://plazar.app/privacy/privacy_it.html", "_system",
			"location=no,clearcache=yes,clearsessioncache=yes,zoom=no,closebuttoncaption=" + this.dts.translateService.instant("BACK"));
	}

	// dopo l'accesso con provider GOOGLE ottiene un token utilizzabile da questa app
	async bindTokenWithGoogleAuth(accessToken: string, sourceAppId: string): Promise<any> {

		try {

			const authAppId = statics.appId; // app o piattaforma
			const loginResult: LoginResult = await this.http.get(environment.backendUrl + "/auth/google/sso?appId=" +
				encodeURIComponent(authAppId) + "&access_token=" + encodeURIComponent(accessToken)).toPromise() as any;

			// alla fine della fiera qui serve per il ricarico del carrello
			await this.JWTSaveUserInfo(loginResult.jwt, sourceAppId);

			// si disconnette da google sempre
			await firebase.default.auth().signOut();

			// visualizza una finestra di login
			await this.alertCtrl.create({
				header: this.dts.translateService.instant("INFO"),
				message: this.dts.translateService.instant("account.LOGGEDIN_WITH_GOOGLE"),
				buttons: [this.dts.translateService.instant("OK")]
			}).then(async (ac) => {
				await ac.present();
			});

		} catch (err) {

			await this.alertCtrl.create({
				header: this.dts.translateService.instant("ERROR"),
				message: this.appConfigService.queryManager.getErrorDescription(err),
				buttons: [this.dts.translateService.instant("OK")]

			}).then(async (ac) => {
				await ac.present();
			});
		}
	}

	async bindTokenWithAppleAuth(idToken: string, userInfo: firebase.default.User, sourceAppId: string): Promise<any> {

		try {

			const authAppId = statics.appId; // app o piattaforma
			const loginResult: LoginResult = await this.http.post(environment.backendUrl + "/auth/apple/sso", {
				appId: authAppId,
				idToken,
				userInfo,
				locale: this.dts.currentLang
			}).toPromise() as any;

			// alla fine della fiera qui serve per il ricarico del carrello
			await this.JWTSaveUserInfo(loginResult.jwt, sourceAppId);

			// si disconnette da google sempre
			await firebase.default.auth().signOut();

			// visualizza una finestra di login
			await this.alertCtrl.create({
				header: this.dts.translateService.instant("INFO"),
				message: this.dts.translateService.instant("account.LOGGEDIN_WITH_APPLE"),
				buttons: [this.dts.translateService.instant("OK")]
			}).then(async (ac) => {
				await ac.present();
			});

		} catch (err) {

			await this.alertCtrl.create({
				header: this.dts.translateService.instant("ERROR"),
				message: this.appConfigService.queryManager.getErrorDescription(err),
				buttons: [this.dts.translateService.instant("OK")]

			}).then(async (ac) => {
				await ac.present();
			});
		}
	}

	async saveFavouriteStore(store: Store, appId: string): Promise<void> {

		// salva il default dello store
		this.dts.updateDataLanguages({
			storeDefaultLang: store.defaultLanguage
		});

		const lu = this.appConfigService.loggedUser;
		if (!lu) {
			this.appConfigService.appMap.set("unloggedUserCurrentStoreId", store.id, appId);
			await this.localStorageService.saveUnloggedCurrentStoreId(store.id, appId);
		}
		else {

			// appEnrollment corrente
			const ae = this.appConfigService.getUserAppEnrollment(lu, appId);
			if (ae) {
				// salva le nuove info
				if (await safeAwaitOrDefault(this.queryStore.saveCurrentUserFavouriteStore(appId, store.id))) {

					// aggiorna il locale
					ae.favouriteStoreId = store.id;
				}
			}
		}
	}

	changePassword(data: {
		id: string;
		oldPassword: string;
		newPassword: string;
	}, callKey?: string): Promise<void> {

		const vars = {
			oldPassword: data.oldPassword,
			newPassword: data.newPassword
		};

		return this.appConfigService.queryManager.mutate(CHANGE_PASSWORD, vars, { callKey });
	}

	logout(jwt: string): Observable<any> {

		const o = {
		};

		const httpHeaders = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: "Bearer " + jwt
		});

		const options = { headers: httpHeaders };

		return this.http.post(environment.backendUrl + "/auth/logout", o, options);
	}

	// Ritorna gli store in cui l'utente e' enrollato, controllando opzionalmente una lista di permessi e di feature richiesti per lo store
	getUserStores(
		appId: string,
		features: FEATURE[],
		matchAllFeatures: boolean,
		permissions: string[],
		matchAllPermissions: boolean): Promise<Store[]> {
		const params = {
			appId,
			features,
			matchAllFeatures,
			permissions,
			matchAllPermissions
		};
		return this.appConfigService.queryManager.execute(GET_USER_STORES, params);
	}

	// enroll degli utenti loggati al primo accesso ad un'app della piattaforma
	async enrollCurrentUserInApp(appId: string, sendWelcomeMail = true): Promise<User> {

		// esegue la chiamata solo se esiste un utente collegato
		const lu = this.appConfigService.loggedUser;
		if (!lu) { return null; } // se non passo un utente ritorna null

		const params = {
			appId,
			sendWelcomeMail
		};
		return await this.appConfigService.queryManager.mutate(ENROLL_USER_IN_APP, params);
	}

	private async _getAppsManagedByUser(platformAppId: string): Promise<AppPaginatedResponse> {
		const params = {
			appId: platformAppId
		};

		return await this.appConfigService.queryManager.execute(GET_APPS_MANAGED_BY_USER, params);
	}

	// viene usata dal menu a sx
	async saveAndDispatchMessage_AppsManagedByCurrentUserWithConfigHints(): Promise<void> {
		const lu = this.appConfigService.loggedUser;
		if (!lu) {
			this.appConfigService.appsManagedByUser = [];
		}
		else {
			// lettura e salvataggio app gestite dall'utente corrente
			const paginatedResponse = await safeAwaitOrDefault(this._getAppsManagedByUser(statics.appId));
			if (paginatedResponse) {
				this.appConfigService.appsManagedByUser = paginatedResponse.edges.map(e => e.node);
			}
			else {
				this.appConfigService.appsManagedByUser = [];
			}
		}

		// per evitare un loop di dipendenze
		this.appConfigService.dispatchStateChanged({
			reason: "refreshAppMenuPages"
		});
	}

	// da chiamare dopo l'aggiornamento dei deliveryAddresses
	async deliveryAddressEditCompleted(
		returnData: IAddressEditOutput,
		inserting: boolean,
		user: User): Promise<void> {

		// se vado in inserimento lo aggiungo solo dopo la conferma del popup
		if (returnData) {

			// address viene aggiornato con le nuove info
			user.deliveryAddresses = user.deliveryAddresses || [];

			// modifica o inserimento
			if (returnData.confirmed) {

				if (inserting) {
					user.deliveryAddresses.push(returnData.address);
				}

				await this.callUpdateUserInfoAddresses(user, "user-delivery-addresses");
			}
			else if (returnData.deleted) {

				// toglie l'indirizzo che ho scelto di rimuovere
				user.deliveryAddresses = user.deliveryAddresses.filter((addr) => {
					return addr.id !== returnData.address.id;
				});

				await this.callUpdateUserInfoAddresses(user, "user-delivery-addresses");
			}
		}
	}

	// aggiorna le info utente con le sole voci degli indirizzi
	async callUpdateUserInfoAddresses(user: User, callKey?: string): Promise<void> {

		// aggiorna solo le info di delivery
		const updObj = {
			id: user.id,
			deliveryAddresses: user.deliveryAddresses
		};

		// salva e si fa ritornare i dati aggiornati dell'utente
		const retUser = await this.updateUserData(updObj, callKey);

		// aggiorna le prop senza cambiare il riferimento
		Object.assign(user, retUser);
	}

}
