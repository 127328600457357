import { Component, OnInit, Input } from "@angular/core";
import { CachedImageService } from "./cached-image.service";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";

@Component({
	selector: "app-cached-image-div",
	templateUrl: "./cached-image-div.component.html",
	styleUrls: ["./cached-image-div.component.scss"],
})
export class CachedImageDivComponent implements OnInit {

	private _src: string;
	readonly noImage = "assets/images/no-image.jpg";
	readonly pleaseWaitImage = "assets/images/please_wait.jpg";

	@Input() borderRadius: string;
	@Input() noCache = false;
	bgImageUrl: SafeStyle;

	constructor(
		private cachedImageService: CachedImageService,
		private sanitizer: DomSanitizer
	) {

		this._src = this.pleaseWaitImage;
		this.bgImageUrl = this._makeURL(this._src);
	}

	ngOnInit() {
	}

	@Input()
	set src(imageUrl: string) {

		// dav 250120
		if (this._src !== imageUrl) {

			// clona
			this._src = imageUrl;

			if (!this._src) {
				this.bgImageUrl = this._makeURL(this.noImage);
			}
			else {
				if (this.noCache) {
					this.bgImageUrl = this._makeURL(this._src);
				}
				else {
					this.cachedImageService.ready().then(() => {

						// cache e risolve l'immagine reale
						this.cachedImageService.getImageWithCacheManagement(this._src).then((source: string) => {
							this.bgImageUrl = this._makeURL(source);
						});
					});
				}
			}
		}
	}
	get src(): string {
		return this._src;
	}

	// necessario sanificare altrimenti sul compilato ha problemi a caso
	private _makeURL(src: string): SafeStyle {
		return this.sanitizer.bypassSecurityTrustStyle("url(" + src + ")");
	}

}
