<app-header [title]="options.popupTitle"></app-header>

<ion-searchbar #search (ionChange)="filterChanged()" [(ngModel)]="filterValue" debounce="250" animated
	[placeholder]="options && options.searchPlaceholder" (ionClear)="onClearSearch()">
</ion-searchbar>

<ion-content class="kaleidos-page">
	<div class="ion-text-center">
		<ion-spinner [hidden]="!searching"></ion-spinner>
	</div>

	<ion-card>
		<ion-list *ngIf="items && items.length > 0">
			<ion-item *ngFor="let itm of items" (click)="itemSelected(itm)" style="cursor: pointer;">
				<ion-label>{{ options.itemRenderer(itm) }}</ion-label>
			</ion-item>
		</ion-list>
		
	</ion-card>
	<div class="noItems subtitle" *ngIf="!filterValue && loaded && !searching">
		{{ options.popupInfo }}
	</div>
	<div class="noItems subtitle" *ngIf="filterValue && loaded && !searching && items && !items.length">
		{{'select_search.noItems' | translate }}
	</div>

	<!-- Per la ricerca prodotto -->
	<ion-infinite-scroll threshold="100px" (ionInfinite)="ionInfiniteArrivedOnSearchProduct($event)">
		<ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="{{ 'LOADING' | translate }}">
		</ion-infinite-scroll-content>
	</ion-infinite-scroll>
</ion-content>
