<app-header [title]="'printers.add_printer_ls_title' | translate"></app-header>
<ion-content>

	<form novalidate [formGroup]="fm">

		<ion-card>
			<ion-card-header>
				<ion-card-title>{{ 'printers.search_on_server' | translate }}</ion-card-title>
			</ion-card-header>
			<ion-card-content>
				<ion-item>
					<ion-label position="floating">{{ "printers.server" | translate }}</ion-label>
					<ion-input type="text" formControlName="server"
						placeholder="{{ 'printers.server_placeh' | translate }}">
					</ion-input>
				</ion-item>
				<ion-text color="danger">
					<p class="ion-padding-start" [hidden]="fm.controls['server'].valid">
						{{"WRONG_FIELD" | translate}}</p>
				</ion-text>

				<ion-item>
					<ion-label position="floating">{{ "printers.port" | translate }}</ion-label>
					<ion-input type="number" formControlName="port"
						placeholder="{{ 'printers.port_placeh' | translate }}">
					</ion-input>
				</ion-item>
				<ion-text color="danger">
					<p class="ion-padding-start" [hidden]="fm.controls['port'].valid">
						{{"WRONG_FIELD" | translate}}</p>
				</ion-text>

				<div class="ion-text-center">
					<app-spinner-button [spinner]="working" [disabled]="!this.fm.valid || working" [autoSpinner]="true" [autoDisabled]="true"
						callKey="save" color="success" (click)="doSearch()">
						{{ "printers.search_printers" | translate}}
					</app-spinner-button>
				</div>
			</ion-card-content>
		</ion-card>
		<div *ngIf="printers?.length">
			<p style="padding-left:10px;">{{ "printers.printers_found" | translate }}</p>
			<ion-item *ngFor="let p of printers">
				<ion-icon name="print" slot="start"></ion-icon>
				<ion-label>{{p.name}} ({{p.type}})</ion-label>
				<ion-button (click)="addPrinterClicked(p);">{{ 'printers.list_add' | translate }}</ion-button>
			</ion-item>
		</div>
		<div *ngIf="printers===null" class="ion-text-center">
			<br>
			<ion-text color="danger">
				{{ 'printers.server_error' | translate }}
			</ion-text>
		</div>
	</form>

</ion-content>