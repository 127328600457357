import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ISpinnerSubject } from "./spinner-subject.interface";

@Injectable({
	providedIn: "root"
})
export class SpinnerButtonService {

	spinnerButtonSubject = new Subject<ISpinnerSubject>();

	constructor() { }

	// notifica ai sottoscritti che sto lavorando
	dispatchWorking(key: string) {
		this.spinnerButtonSubject.next({
			working: true,
			callKey: key
		});
	}

	// notifica ai sottoscritti che sono fermo
	dispatchIdle(key: string) {
		this.spinnerButtonSubject.next({
			working: false,
			callKey: key
		});
	}


}
