import { Injectable } from "@angular/core";
import { City, Pagination, CityPaginatedResponse, Province, ProvincePaginatedResponse, Country, CountryPaginatedResponse } from "src/app/backend-api";
import { GET_CITY, GET_CITIES, GET_PROVINCE, GET_PROVINCES, GET_COUNTRY, GET_COUNTRIES, SEARCH_CITIES, SEARCH_PROVINCES, SEARCH_COUNTRIES } from "./localization-queries";
import { QueryManagerService } from "../services/query-manager.service";

@Injectable({
	providedIn: "root"
})
export class QueryLocalizationService {

	constructor(
		private queryManager: QueryManagerService,
	) { }


	// trova le info di una city dato l'id
	getCity(id: string): Promise<City> {
		return this.queryManager.execute(GET_CITY, {
			id
		}, {
			useCache: true
		});
	}

	// elenco città
	getCities(pagination: Pagination): Promise<CityPaginatedResponse> {
		return this.queryManager.execute(GET_CITIES, null, {
			pagination
		});
	}

	// ricerca per città attraverso stringa
	searchCities(
		pagination: Pagination,
		searchString?: string): Promise<CityPaginatedResponse> {

		const params = {
			searchString
		};
		return this.queryManager.execute(SEARCH_CITIES, params, {
			pagination
		});
	}

	// ritorna la provincia dato l'id
	getProvince(id: string): Promise<Province> {
		return this.queryManager.execute(GET_PROVINCE, {
			id
		}, {
			useCache: true
		});
	}

	// elenco province paginato
	getProvinces(pagination: Pagination): Promise<ProvincePaginatedResponse> {
		return this.queryManager.execute(GET_PROVINCES, null, {
			pagination
		});
	}

	// ricerca province per stringa
	searchProvinces(
		pagination: Pagination,
		searchString?: string): Promise<ProvincePaginatedResponse> {

		const params = {
			searchString
		};
		return this.queryManager.execute(SEARCH_PROVINCES, params, {
			pagination
		});
	}

	// trova le info di una country dato l'id
	getCountry(id: string): Promise<Country> {
		return this.queryManager.execute(GET_COUNTRY, {
			id
		}, {
			useCache: true
		});
	}

	// elenco province paginato
	getCountries(pagination: Pagination): Promise<CountryPaginatedResponse> {
		return this.queryManager.execute(GET_COUNTRIES, null, {
			pagination
		});
	}

	// ricerca country per stringa
	searchCountries(
		pagination: Pagination,
		searchString?: string): Promise<CountryPaginatedResponse> {

		const params = {
			searchString
		};
		return this.queryManager.execute(SEARCH_COUNTRIES, params, {
			pagination
		});
	}

}
