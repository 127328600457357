import { Injectable } from "@angular/core";
import { UPLOAD_FILE } from "./upload-queries";
import { StorageFile } from "../../backend-api";
import { QueryManagerService } from "../services/query-manager.service";

export type tUploadMimeType = "image/jpeg" | "image/png" | string;

enum UploadCategory {
	product = "product"
}

@Injectable({
	providedIn: "root"
})

export class UploadService {

	public upoadCategoryEnum = UploadCategory;

	constructor(
		private queryManager: QueryManagerService,
	) {
	}

	async uploadFile(
		appId: string,
		data: string | File,
		mimeType: tUploadMimeType,
		category: string,
		resize = false): Promise<StorageFile> {

		let resizedImage = data;
		if (resize) {
			resizedImage = await this.inputFileSelected(data);
		}

		const vars = {
			storageFile: {
				appId,
				category,
				data: resizedImage,
				mimeType
			}
		};

		return await this.queryManager.mutate(UPLOAD_FILE, vars);
	}

	async inputFileSelected(file: File | Blob | string): Promise<string | undefined> {

		if (!file) {
			return undefined;
		}

		if (file instanceof File || file instanceof Blob) {

			// ci sono delle callback interne
			return new Promise((resolve, reject) => {

				const reader = new FileReader();
				reader.onload = (readerEvent) => {

					const image = new Image();
					image.onload = () => {
						const res = this.resizeImage(image);
						resolve(res);
					};

					// tslint:disable-next-line: no-string-literal
					image.src = readerEvent.target["result"] as string;
				};
				reader.readAsDataURL(file);
			});

		}
		else {
			const image = new Image();
			image.src = file;
			return this.resizeImage(image);
		}
	}

	public resizeImage(image: HTMLImageElement): string {

		let b64Data: string;
		// Resize the image
		const canvas = document.createElement("canvas");

		// tslint:disable-next-line: no-string-literal
		canvas["imageSmoothingQuality"] = "high"; // "low|medium|high"
		const maxSize = 1000;

		let width = image.width;
		let height = image.height;

		if (width > height) {
			if (width > maxSize) {
				height *= maxSize / width;
				width = maxSize;
			}
		}
		else {
			if (height > maxSize) {
				width *= maxSize / height;
				height = maxSize;
			}
		}

		canvas.width = width;
		canvas.height = height;
		canvas.getContext("2d").drawImage(image, 0, 0, width, height);

		b64Data = canvas.toDataURL("image/jpeg");
		b64Data = b64Data.substring("data:image/jpeg;base64,".length);

		// dav - rimuovo il canvas creato poco fa
		canvas.remove();

		return b64Data;
	}

}
