import Stripe from "stripe";
export let PLATFORM_APP_ID = "000000000000000000000001";
export let PLAZAR_TAG_ID = "000000000000000000000002";
export let TEXT_INDEX_NAME = "text_index";
export enum SORT {
	asc = 1,
	desc = -1,
	geo = "2dsphere"
}
export class ModelClass {
}
export class Pagination {
	first?: number;
	after?: string;
}
export class PageInfo {
	startCursor?: string;
	endCursor?: string;
	hasMoreData?: boolean;
}
export class Edge<N> {
	node: N;
	cursor: string;
}
export class PaginatedResponse<E> {
	edges?: Edge<E>[];
	pageInfo?: PageInfo;
}
export let createPaginatedResponse: <T>(TClass: new (...args: any[]) => T) => new (...args: any[]) => PaginatedResponse<T>;
export class LocalizedValue {
	value: string;
	language: string;
}
export class LocalizedString extends ModelClass {
	private _it?;
	private _en?;
	private _de?;
	private _invariant?;
	it: string;
	en: string;
	de: string;
	invariant: string;
}
export enum LANGUAGE {
	it = "it",
	en = "en",
	de = "de"
}
export let INDEXED_TERMS_FIELD = "terms";
export let INDEXED_TERMS_SUFFIX: string;
export let TERM_INDEX_NAME: string;
export class Identity extends ModelClass {
	_id?: string;
	id: string;
}
export class NamedIdentity extends Identity {
	name?: LocalizedString;
}
export enum ERROR_CODE {
	INTERNAL_SERVER_ERROR = -1,
	NOT_AUTHORIZED = 1,
	AUTHENTICATION_FAILURE = 2,
	DATA_NOT_FOUND = 3,
	MISSING_ID = 4,
	UNIQUE_CONSTRAINT_VIOLATION = 5,
	MISSING_APP_ID = 6,
	MISSING_PARAMETER = 7,
	USER_ALREADY_EXISTS = 8,
	EMAIL_CONFIRMATION_NEEDED = 9,
	USER_NOT_FOUND = 10,
	ORDER_NOT_FOUND = 11,
	ORDER_CANNOT_BE_DELETED = 12,
	CANNOT_OPEN_MULTIPLE_CARTS = 13,
	APP_ENROLLMENT_NOT_FOUND = 14,
	ORDER_OPERATION_NOT_AUTHORIZED = 15,
	MISSING_STORAGE_FILE_CATEGORY = 16,
	STORAGE_FILE_NOT_FOUND = 17,
	ERROR_READING_STORAGE_FILE = 18,
	ERROR_WRITING_STORAGE_FILE = 19,
	USER_ALREADY_REGISTERED_IN_PLATFORM = 20,
	INVALID_BOOKING_DATE = 21,
	BOOKING_REQUEST_NOT_AVAILABLE = 22,
	SERVICE_FULL = 23,
	MISSING_DELIVERY_SERVICE = 24,
	MISSING_DELIVERY_DATE = 25,
	INVALID_USERNAME = 26,
	INVALID_EMAIL = 27,
	EMAIL_ALREADY_EXISTS = 28,
	STRIPE_UNHANDLED_ERROR = 29,
	PAYMENT_SYSTEM_UNAVAILABLE = 30,
	MISSING_PAYMENT_INFORMATION = 31,
	PAYMENT_REQUEST_NOT_FOUND = 32,
	CARD_DECLINED = 33,
	INVALID_PAYMENT_AMOUNT = 34,
	SUBSCRIPTION_NOT_FOUND = 35,
	PRICING_STRATEGY_NOT_FOUND = 36,
	PAYMENT_REQUIRED = 37,
	ORDER_STATUS_NOT_PERMITTED = 38,
	ORDER_PAYMENT_REQUEST_ALREADY_FOUND = 39,
	ORDER_PAYMENT_AMOUNT_CANNOT_FULFILL = 40,
	REFUND_INVALID_PAYMENT_STATUS = 41,
	PAYMENT_ALREADY_REFUNDED = 42,
	INVALID_REFUND_AMOUNT = 43,
	PAYMENT_AMOUNT_TOO_SMALL = 44,
	SUBSCRIPTION_CANCELED = 45,
	SUBSCRIPTION_PAYMENT_METHOD_AUTHORIZATION_FAILED = 46,
	MAX_SERVICE_CARDINALITY = 47,
	SERVICE_DURATION_SCHEDULED_DAYS_NOT_SUPPORTED = 48,
	STRIPE_ACCOUNT_DETAILS_MISSING = 49,
	APP_NOT_PUBLISHED = 50,
	FEATURE_NOT_ACTIVE = 51,
	MISSING_BUSINESS_INFO = 52,
	DELETED_STRIPE_CUSTOMER = 53,
	VAT_INVALID = 54,
	INVALID_PAYMENT_STATUS = 55,
	WRONG_RESET_PWD_PROVIDER_GOOGLE = 56,
	WRONG_RESET_PWD_PROVIDER_APPLE = 57
}
export enum FEATURE {
	landing_page = "landing_page",
	product_catalog = "product_catalog",
	booking = "booking",
	ordering = "ordering",
	newsletter = "newsletter",
	payment = "payment",
	administration = "administration"
}
export class LogInput extends ModelClass {
	appId: string;
	userId: string;
	message: string;
	data: string;
}
export let CURRENT_SCHEMA_VERSION = 1;
export class SystemInfo extends Identity {
	schemaVersion: number;
	minAppVersion: string;
}
export enum SYSTEM_PERMISSION {
	admin = "admin"
}
export class Job<T extends JobPayload> extends Identity {
	creationDate: Date;
	startProcessingDate: Date;
	runAfter: Date;
	immediate: boolean;
	jobName: string;
	errorCount: number;
	private _payload;
	payload: T;
}
export abstract class JobPayload {
	appId?: string;
}
export class SearchOptions {
	allTermsOptional?: boolean;
	excludeFullTextEngine?: boolean;
	language?: string;
}
export class FeeConfiguration {
	from?: number;
	to?: number;
	fixed?: number;
	variable?: number;
}
export enum CORE_PERMISSION {
	manage_app = "manage_app",
	manage_store = "manage_store",
	manage_company = "manage_company",
	manage_tags = "manage_tags",
	manage_billings = "manage_billings"
}
export interface AppData {
	appId?: string;
	app?: any;
}
export class AppIdentity extends Identity implements AppData {
	appId?: string;
	app?: any;
}
export class NamedAppIdentity extends AppIdentity implements AppData {
	name?: LocalizedString;
}
export interface StoresFilterable {
	stores?: Store[];
}
export interface StoreFilterable {
	store?: Store;
}
export class Permission extends NamedIdentity {
	code?: string;
}
export class Role extends NamedIdentity {
	code?: string;
	appId?: string;
	storeId?: string;
	permissions?: Permission[];
}
export class Clearance extends Identity {
	role?: Role;
	appId?: string;
	storeIds?: string[];
}
export enum GLOBAL_ROLE {
	admin = "admin",
	app_manager = "app_manager",
	store_manager = "store_manager",
	company_manager = "company_manager",
	worker = "worker",
	user = "user"
}
export class SignupInput extends ModelClass {
	username?: string;
	password: string;
	firstName: string;
	lastName: string;
	email: string;
	telephoneNumber?: string;
	appId: string;
	locale: string;
}
export class SSOProfile extends ModelClass {
	thirdPartyId?: string;
	displayName?: string;
	fistName?: string;
	lastName?: string;
	email?: string;
	phoneNumber?: string;
	photoURL?: string;
	locale?: string;
	provider: AUTH_PROVIDER;
}
export class LoginResult extends ModelClass {
	jwt: string;
	user: Partial<User>;
}
export class Country extends NamedIdentity {
	code?: string;
}
export let CountryPaginatedResponse: new (...args: any[]) => PaginatedResponse<Country>;
export type CountryPaginatedResponse = InstanceType<typeof CountryPaginatedResponse>;
export class Province extends NamedIdentity {
	code?: string;
	abbreviation?: string;
	country?: Country;
}
export let ProvincePaginatedResponse: new (...args: any[]) => PaginatedResponse<Province>;
export type ProvincePaginatedResponse = InstanceType<typeof ProvincePaginatedResponse>;
export class City extends NamedIdentity {
	code?: string;
	zip?: string[];
	province?: Province;
}
export let CityPaginatedResponse: new (...args: any[]) => PaginatedResponse<City>;
export type CityPaginatedResponse = InstanceType<typeof CityPaginatedResponse>;
export enum COORDINATES_TYPE {
	point = "point"
}
export class Coordinates extends ModelClass {
	latitude?: number;
	longitude?: number;
}
export class Geolocation extends Identity {
	private _coordinates?;
	private _type?;
	coordinates: Coordinates[];
	type: COORDINATES_TYPE;
	distance?: string;
}
export class Location extends Identity {
	coordinates?: number[];
	type?: string;
}
export class Address extends Identity {
	name?: string;
	street?: string;
	number?: string;
	city?: City;
	zip?: string;
	geolocation?: Geolocation;
	location?: Location;
}
export class DeliveryAddress extends Address {
	isDefault?: boolean;
	intercomName?: string;
	interior?: string;
}
export enum NOTIFICATION_CHANNEL {
	push = "push",
	email = "email",
	sms = "sms"
}
export enum AUTH_PROVIDER {
	email = "email",
	google = "google",
	apple = "apple"
}
export class AppNotification extends ModelClass {
	feature: string;
	event: string;
	reference?: string;
	timestamp: Date;
}
export class AppConnectionEnrollment extends Identity {
	appId?: string;
	pushRegistrationIds?: string[];
}
export class AppEnrollment extends Identity {
	appId?: string;
	notificationChannels?: NOTIFICATION_CHANNEL[];
	pushRegistrationIds?: string[];
	notifications?: AppNotification[];
	disableNotificationsToPlatforms?: boolean;
	favouriteStoreId?: string;
	appPermissions?: string[];
	storePermissions?: StorePermissions[];
	favourite?: boolean;
}
export class StripeConnectInfo extends Identity {
	timestamp?: Date;
	connectedAccountId?: string;
	accountDetailsSubmitted?: boolean;
	chargesEnabled?: boolean;
	payoutsEnabled?: boolean;
	stripeConnectAccountLink?: string;
}
export class StripeCustomer extends Identity {
	stripeCustomerId?: string;
	customerName?: string;
	customerEmail?: string;
	customerPhone?: string;
	customerAddress?: Address;
	customerTaxId?: string;
}
export class StorePermissions extends ModelClass {
	storeId?: string;
	permissions?: string[];
}
export class User extends Identity {
	enrolledApps?: AppEnrollment[];
	clearances?: Clearance[];
	systemPermissions?: string[];
	username?: string;
	password?: string;
	passwordReset?: boolean;
	passwordResetVerificationCode?: string;
	firstName?: string;
	lastName?: string;
	email?: string;
	emailVerificationAppId?: string;
	emailVerificationCode?: string;
	emailVerificationPending?: boolean;
	emailVerificationPendingPushToken?: string;
	emailVerificationResendDate?: Date;
	telephoneNumber?: string;
	creationDate?: Date;
	provider?: AUTH_PROVIDER;
	thirdPartyId?: string;
	locale?: string;
	deliveryAddresses?: DeliveryAddress[];
	stripePlatformCustomer?: StripeCustomer;
}
export let UserPaginatedResponse: new (...args: any[]) => PaginatedResponse<User>;
export type UserPaginatedResponse = InstanceType<typeof UserPaginatedResponse>;
export class DataLog {
	user?: User;
	timestamp?: Date;
}
export enum STORAGE_CATEGORY {
	logo = "logo",
	galleryItem = "galleryItem",
	productImage = "productImage",
	catalogSectionImage = "catalogSectionImage"
}
export class ImageData extends Identity {
	width?: number;
	height?: number;
	skipThumbnail?: boolean;
	thumbnailWidth?: number;
	thumbnailHeight?: number;
	thumbnailQuality?: number;
	thumbnailSize?: number;
	thumbnail?: string;
}
export class StorageFile extends NamedAppIdentity implements Taggable {
	category?: STORAGE_CATEGORY;
	mimeType?: string;
	tags?: Tag[];
	creationDate?: Date;
	size?: number;
	data?: string;
	imageData?: ImageData;
}
export interface Zoned {
	timezone?: string;
}
export class TimeRange extends ModelClass {
	from?: string;
	to?: string;
}
export class DayTimeRanges extends ModelClass {
	timeRanges?: TimeRange[];
	day?: string;
}
export class WeekSchedule extends ModelClass {
	sunday?: TimeRange[];
	monday?: TimeRange[];
	tuesday?: TimeRange[];
	wednesday?: TimeRange[];
	thursday?: TimeRange[];
	friday?: TimeRange[];
	saturday?: TimeRange[];
}
export class ScheduleCalendar extends ModelClass implements Zoned {
	weekSchedule?: WeekSchedule;
	extraSchedules?: DayTimeRanges[];
	cancelledSchedules?: DayTimeRanges[];
	timezone: string;
}
export interface Schedulable {
	scheduleCalendar?: ScheduleCalendar;
	currentWeekSchedule?: WeekSchedule;
}
export enum CALENDAR_UNIT {
	milliseconds = "milliseconds",
	seconds = "seconds",
	minutes = "minutes",
	hours = "hours",
	scheduled_days = "scheduled_days"
}
export class CalendarAmount extends ModelClass {
	value: number;
	measureUnit: CALENDAR_UNIT;
}
export class DateRange extends ModelClass {
	startDate?: Date;
	endDate?: Date;
}
export enum TAG_SCOPE {
	private = "private",
	public = "public"
}
export enum TAG_TYPE {
	category = "category",
	place = "place",
	product = "product",
	event = "event",
	brand = "brand",
	app = "app",
	company = "company",
	shop_category = "shop_category"
}
export interface Taggable {
	tags?: Tag[];
}
export class Tag extends NamedAppIdentity {
	description?: LocalizedString;
	scope?: TAG_SCOPE;
	type?: TAG_TYPE;
	internalCode?: string;
	validFrom?: Date;
	validTo?: Date;
	certified?: boolean;
	typePriority?: number;
	like?: number;
	pictureStorageFile?: StorageFile;
}
export let TagPaginatedResponse: new (...args: any[]) => PaginatedResponse<Tag>;
export type TagPaginatedResponse = InstanceType<typeof TagPaginatedResponse>;
export enum PRICE_TYPE {
	one_time = "one_time",
	recurring = "recurring"
}
export enum PRICE_RECURRING_INTERVAL {
	day = "day",
	month = "month",
	week = "week",
	year = "year"
}
export enum PRICE_USAGE {
	licensed = "licensed",
	metered = "metered"
}
export enum PRICE_AGGREGATE_USAGE {
	last_during_period = "last_during_period",
	last_ever = "last_ever",
	max = "max",
	sum = "sum"
}
export enum PRICE_BILLING_SCHEME {
	per_unit = "per_unit",
	tiered = "tiered"
}
export enum PRICE_TIERS_MODE {
	graduated = "graduated",
	volume = "volume"
}
export class PriceRecurring extends Identity {
	interval?: PRICE_RECURRING_INTERVAL;
	intervalCount?: number;
	trialPeriodDays?: number;
	usageType?: PRICE_USAGE;
	aggregateUsage?: PRICE_AGGREGATE_USAGE;
}
export class PriceTier extends Identity {
	flatAmount?: number;
	unitAmount?: number;
	upTo?: number;
}
export class PricingStrategy extends Identity {
	active?: boolean;
	stripePriceId?: string;
	recurring?: PriceRecurring;
	billingScheme?: PRICE_BILLING_SCHEME;
	tiers?: PriceTier[];
	tiersMode?: PRICE_TIERS_MODE;
	currency?: string;
	unitAmount?: number;
	discountPercent?: number;
	type?: PRICE_TYPE;
}
export abstract class FeatureConfiguration {
}
export class FeatureDependency extends ModelClass {
	and?: FeatureDependency;
	or?: FeatureDependency;
	featureIds?: string[];
}
export class Feature extends NamedIdentity {
	description?: LocalizedString;
	version?: string;
	type?: FEATURE;
	publicLandingRoute?: string;
	privateLandingRoute?: string;
	pricingStrategies?: PricingStrategy[];
	stripeProductId?: string;
	mandatory?: boolean;
	dependsOn?: FeatureDependency;
}
export class PurchasedFeature extends Identity {
	feature?: Feature;
	expiration?: Date;
	activation?: Date;
	validity?: number;
	pricingStrategyId?: string;
	stripeSubscriptionItemId?: string;
	quantity?: number;
}
export class ConfiguredFeature extends Identity {
	feature?: Feature;
	storeIds?: string[];
	configurationJson?: string;
}
export enum SOCIAL {
	facebook = "facebook",
	twitter = "twitter",
	instagram = "instagram",
	whatsapp = "whatsapp",
	messenger = "messenger",
	telegram = "telegram"
}
export enum CONTACT_TYPE {
	social = "social",
	web = "web",
	phone = "phone",
	mail = "mail"
}
export enum CONTACT_SUBTYPE {
	link = "link",
	phone = "phone",
	cellphone = "cellphone",
	fax = "fax",
	billing_address = "billing_address"
}
export class ContactListItem extends Identity {
	value?: string;
	type?: CONTACT_TYPE;
	favourite?: boolean;
	subtype?: CONTACT_SUBTYPE;
	social?: SOCIAL;
	position?: number;
}
export class ContactList extends Identity {
	items?: ContactListItem[];
}
export enum VAT_CODE_VERIFICATION_STATUS {
	pending = "pending",
	unavailable = "unavailable",
	unverified = "unverified",
	verified = "verified"
}
export class VatCodeVerification extends Identity {
	status: VAT_CODE_VERIFICATION_STATUS;
	verifiedAddress: string;
	verifiedName: string;
}
export class BusinessInfo extends Identity {
	businessName?: LocalizedString;
	vatCode?: string;
	vatCodeVerification?: VatCodeVerification;
	fiscalCode?: string;
	address?: Address;
	owners?: User[];
	currency?: string;
	contactList?: ContactList;
	stripePlatformCustomer?: StripeCustomer;
	stripeConnectInfo?: StripeConnectInfo;
}
export class Store extends NamedAppIdentity implements Schedulable, Zoned, Taggable {
	description?: LocalizedString;
	address?: Address;
	contactList?: ContactList;
	tags?: Tag[];
	scheduleCalendar?: ScheduleCalendar;
	currentWeekSchedule?: WeekSchedule;
	defaultLanguage?: LANGUAGE;
	timezone?: string;
	businessInfo?: BusinessInfo;
	published?: boolean;
}
export let StorePaginatedResponse: new (...args: any[]) => PaginatedResponse<Store>;
export type StorePaginatedResponse = InstanceType<typeof StorePaginatedResponse>;
export class Company extends NamedIdentity implements StoresFilterable {
	description?: LocalizedString;
	apps?: App[];
	logoStorageFile?: StorageFile;
	pictureStorageFile?: StorageFile;
	stores?: Store[];
	contactList?: ContactList;
	address?: Address;
	businessInfo?: BusinessInfo;
}
export let CompanyPaginatedResponse: new (...args: any[]) => PaginatedResponse<Company>;
export type CompanyPaginatedResponse = InstanceType<typeof CompanyPaginatedResponse>;
export class GalleryItem extends Identity {
	description?: string;
	storageFile: StorageFile;
}
export class Gallery extends Identity {
	items?: GalleryItem[];
	slideShowSpeed?: number;
}
export class LandingPage extends Identity {
	gallery?: Gallery;
	feature?: Feature;
}
export class Message extends Identity {
	timestamp?: Date;
	sender?: User;
	message?: string;
}
export class Chat extends AppIdentity implements Taggable {
	timestamp: Date;
	participants?: User[];
	messages?: Message[];
	tags?: Tag[];
}
export class FeatureRequest extends Identity {
	pricingStrategyId?: string;
}
export enum ONBOARDING_TYPE {
	complete = "complete",
	company_subscription = "company_subscription",
	store_subscription = "store_subscription",
	new_stores_subscription = "new_stores_subscription"
}
export class SubscriptionRequest extends Identity {
	onboardingType?: ONBOARDING_TYPE;
	timestamp?: Date;
	user?: User;
	requestedFeatures?: FeatureRequest[];
	collectionMethod?: SUBSCRIPTION_COLLECTION_METHOD;
	stripePaymentMethodId?: string;
	stripeConnectAccountId?: string;
	targetSubscriptionId?: string;
	completed?: boolean;
}
export enum SUBSCRIPTION_COLLECTION_METHOD {
	charge_automatically = "charge_automatically",
	send_invoice = "send_invoice"
}
export enum SUBSCRIPTION_STATUS {
	waiting_stripe_subscription = "waiting_stripe_subscription",
	active = "active",
	canceled = "canceled",
	incomplete = "incomplete",
	incomplete_expired = "incomplete_expired",
	past_due = "past_due",
	trialing = "trialing",
	unpaid = "unpaid"
}
export enum PAYMENT_METHOD {
	unknown = "unknown",
	stripe_card = "stripe_card",
	stripe_sepa_debit = "stripe_sepa_debit",
	cash = "cash",
	bank_check = "bank_check"
}
export class PaymentMethod {
	stripePaymentMethodId?: string;
	stripeFingerprint?: string;
	type?: PAYMENT_METHOD;
	lastDigits?: string;
	defaultPaymentMethod?: boolean;
}
export class Subscription extends AppIdentity implements StoresFilterable {
	stores?: Store[];
	stripeSubscriptionId?: string;
	user?: User;
	stripePlatformCustomer?: StripeCustomer;
	collectionMethod?: SUBSCRIPTION_COLLECTION_METHOD;
	purchasedFeatures?: PurchasedFeature[];
	startDate?: Date;
	currentPeriodStart?: Date;
	currentPeriodEnd?: Date;
	cancelAt?: Date;
	canceledAt?: Date;
	cancelAtPeriodEnd?: boolean;
	daysUntilDue: number;
	paymentMethod: PaymentMethod;
	status: SUBSCRIPTION_STATUS;
	trialStart: Date;
	trialEnd: Date;
	disablePlatformFeesDuringTrial: boolean;
}
export enum INVOICE_STATUS {
	draft = "draft",
	open = "open",
	paid = "paid",
	uncollectible = "uncollectible",
	void = "void"
}
export enum INVOICE_COLLECTION_METHOD {
	charge_automatically = "charge_automatically",
	send_invoice = "send_invoice"
}
export class Invoice {
	stripeInvoiceId?: string;
	stripeSubscriptionId?: string;
	stripeCustomerId?: string;
	stripeHostedInvoiceUrl?: string;
	status?: INVOICE_STATUS;
	collectionMethod?: INVOICE_COLLECTION_METHOD;
	periodStart?: Date;
	periodEnd?: Date;
	creationDate?: Date;
	currency?: string;
	dueDate?: Date;
	tax?: number;
	subtotal?: number;
	total?: number;
	invoicePdf?: string;
	invoiceNumber: string;
}
export class StoreConfigurationHints extends ModelClass {
	storeId?: string;
	storeNotPublished?: boolean;
	missingStoreCalendar?: boolean;
	needStripeInfo?: boolean;
	missingCatalogs?: boolean;
	missingNewsletters?: boolean;
	missingOrderingDelivery?: boolean;
	missingOrderingShipmentConfiguration?: boolean;
	missingOrderingPickupConfiguration?: boolean;
}
export class ConfigurationHints extends ModelClass {
	needStripeInfoForCompany?: boolean;
	missingLandingPageGalleryItem?: boolean;
	storeHints?: StoreConfigurationHints[];
}
export class App extends NamedIdentity implements Taggable, StoresFilterable {
	description?: LocalizedString;
	published?: boolean;
	theme?: string;
	company?: Company;
	stores?: Store[];
	icon?: StorageFile;
	expiration?: Date;
	supportedLanguages?: LANGUAGE[];
	defaultLanguage?: LANGUAGE;
	tags?: Tag[];
	platformTag?: Tag;
	platformConnections?: Tag[];
	template?: string;
	templateUrl?: string;
	landingPage?: LandingPage;
	androidAppId?: string;
	iosAppId?: string;
	subscriptions?: Subscription[];
	configuredFeatures?: ConfiguredFeature[];
	configurationHints?: ConfigurationHints;
	dataLog: AppDataLog[];
}
export class AppDataLog extends App implements DataLog {
	user?: User;
	timestamp?: Date;
}
export let AppPaginatedResponse: new (...args: any[]) => PaginatedResponse<App>;
export type AppPaginatedResponse = InstanceType<typeof AppPaginatedResponse>;
export enum DELIVERY_TYPE {
	none = "none",
	pickup_store = "pickup_store",
	shipment = "shipment"
}
export enum PAYMENT_PERMISSION {
	manage_payments = "manage_payments",
	issue_payments = "issue_payments",
	confirm_payments = "confirm_payments",
	receive_payments_notifications = "receive_payments_notifications"
}
export enum PAYMENT_PROVIDER {
	stripe = "stripe"
}
export enum PAYMENT_STATUS {
	issued = "issued",
	authorized = "authorized",
	paid = "paid",
	partially_refunded = "partially_refunded",
	refunded = "refunded",
	cancelled = "cancelled",
	processing = "processing",
	failed = "failed"
}
export enum PAYMENT_REFUND_REASON {
	duplicate = "duplicate",
	fraudulent = "fraudulent",
	requested_by_customer = "requested_by_customer",
	expired_uncaptured_charge = "expired_uncaptured_charge",
	collect_authorized_amount = "collect_authorized_amount"
}
export enum BOOKING_PERMISSION {
	service_management = "service_management",
	resource_management = "resource_management"
}
export enum SERVICE_TEMPORALITY {
	starts_at = "starts_at",
	ends_by = "ends_by",
	range = "range"
}
export class ServiceCustomData extends ModelClass {
}
export class ServiceResource extends NamedAppIdentity {
	stores?: Store[];
	scheduleCalendar?: ScheduleCalendar;
	enabledServices?: Service[];
	quantity?: number;
}
export class Service extends NamedAppIdentity implements Taggable {
	description?: LocalizedString;
	stores?: Store[];
	pricePerHour?: number;
	fixedPrice?: number;
	serviceType?: string;
	temporality?: SERVICE_TEMPORALITY;
	timeSlot?: CalendarAmount;
	availableResources?: ServiceResource[];
	averageDuration?: CalendarAmount;
	bookingNotice?: CalendarAmount;
	maxBookableTime?: CalendarAmount;
	parallelRate?: number;
	autoConfirmation?: boolean;
	allowBookingInThePast?: boolean;
	gallery?: Gallery;
	pictureStorageFile?: StorageFile;
	tags?: Tag[];
	customDataJson?: string;
}
export class BookingCustomData extends ModelClass {
}
export enum BOOKING_STATUS {
	requested = "requested",
	confirmed = "confirmed",
	completed = "completed",
	cancelled = "cancelled"
}
export class Booking extends AppIdentity {
	service?: Service;
	store?: Store;
	bookedService?: Service;
	interval?: DateRange;
	bookedTimeSlot?: DateRange;
	estimatedDuration?: CalendarAmount;
	price?: number;
	parallelRate?: number;
	status?: BOOKING_STATUS;
	confirmed?: boolean;
	customDataJson?: string;
}
export class BookingRequest extends AppIdentity {
	service?: Service;
	requestDate?: Date;
}
export enum SERVICE_AVAILABILITY_LEVEL {
	high = "high",
	medium = "medium",
	low = "low",
	unavailable = "unavailable"
}
export class ServiceAvailability extends ModelClass {
	slot?: DateRange;
	totalBookable?: number;
	remainingBookable?: number;
	overbooked?: number;
	price?: number;
	availabilityLevel?: SERVICE_AVAILABILITY_LEVEL;
}
export class BookingConfiguration extends FeatureConfiguration {
}
export enum NEWSLETTER_PERMISSION {
	manage_news = "manage_news"
}
export class Newsletter extends AppIdentity implements StoresFilterable, Taggable {
	stores?: Store[];
	user?: User;
	timestamp?: Date;
	title?: LocalizedString;
	subtitle?: LocalizedString;
	body?: LocalizedString;
	gallery?: Gallery;
	tags?: Tag[];
}
export let NewsletterPaginatedResponse: new (...args: any[]) => PaginatedResponse<Newsletter>;
export type NewsletterPaginatedResponse = InstanceType<typeof NewsletterPaginatedResponse>;
export enum NEWSLETTER_NOTIFICATION_EVENT {
	received_newsletter = "received_newsletter"
}
export enum PRODUCT_CATALOG_PERMISSION {
	manage_catalogs = "manage_catalogs",
	manage_sections = "manage_sections",
	manage_products = "manage_products",
	update_products = "update_products",
	manage_product_price = "manage_product_price"
}
export enum MEASURE_UNIT {
	unit = "unit",
	mt = "mt",
	kg = "kg",
	mq = "mq",
	lt = "lt"
}
export class ProductComponent extends NamedAppIdentity implements Taggable {
	description?: LocalizedString;
	pictureStorageFile?: StorageFile;
	tags?: Tag[];
	decomposable?: boolean;
	decomposableMin?: number;
	decomposableMax?: number;
	measureUnit?: MEASURE_UNIT;
}
export class Product extends NamedAppIdentity implements Taggable, StoresFilterable {
	externalCode?: string;
	shortSpecification?: LocalizedString;
	specification?: LocalizedString;
	shortDescription?: LocalizedString;
	description?: LocalizedString;
	pictureStorageFile?: StorageFile;
	tags?: Tag[];
	stores?: Store[];
	productComponents?: ProductComponent[];
	available?: boolean;
	availableQuantity?: number;
	availabilityCheckActive?: boolean;
	autoConfirmIfAvailable?: boolean;
	hideUnavailableProducts?: boolean;
	measureUnit?: MEASURE_UNIT;
	minimumLot?: number;
	quantityStep?: number;
	pricePerMeasureUnit?: number;
	exactQuantity?: boolean;
	priceBlocked?: boolean;
}
export let ProductPaginatedResponse: new (...args: any[]) => PaginatedResponse<Product>;
export type ProductPaginatedResponse = InstanceType<typeof ProductPaginatedResponse>;
export class CatalogSection extends NamedIdentity implements Taggable {
	description?: LocalizedString;
	pictureStorageFile?: StorageFile;
	active?: boolean;
	tags?: Tag[];
	sections?: CatalogSection[];
	products?: Product[];
	withoutImages?: boolean;
}
export class Catalog extends NamedAppIdentity implements Taggable, StoresFilterable {
	description?: LocalizedString;
	active?: boolean;
	tags?: Tag[];
	sections?: CatalogSection[];
	dynamicSections?: CatalogSection[];
	stores?: Store[];
	hideUnavailableProducts?: boolean;
	availabilityCheckActive?: boolean;
	exactQuantity?: boolean;
	priceBlocked?: boolean;
	pictureStorageFile?: StorageFile;
	withoutImages?: boolean;
	showFlatStructure?: boolean;
}
export let CatalogPaginatedResponse: new (...args: any[]) => PaginatedResponse<Catalog>;
export type CatalogPaginatedResponse = InstanceType<typeof CatalogPaginatedResponse>;
export enum ORDERING_PERMISSION {
	cart = "cart",
	order_management = "order_management",
	receive_order_summary_email = "receive_order_summary_email"
}
export enum ORDER_STATUS {
	cart = "cart",
	sending = "sending",
	sent = "sent",
	proposed = "proposed",
	accepted = "accepted",
	confirmed = "confirmed",
	cancelled = "cancelled",
	ready = "ready",
	shipped = "shipped",
	delivered = "delivered",
	new_proposal = "new_proposal",
	rejected = "rejected"
}
export enum ORDER_ITEM_STATUS {
	requested = "requested",
	accepted = "accepted",
	rejected = "rejected",
	new_proposal = "new_proposal",
	proposed = "proposed",
	confirmed = "confirmed",
	cancelled = "cancelled",
	modified = "modified"
}
export class OrderItem extends Identity {
	user?: User;
	product?: Product;
	itemStatus?: ORDER_ITEM_STATUS;
	timestamp?: Date;
	amount?: number;
	requestedQuantity?: number;
	confirmedQuantity?: number;
	rejectedQuantity?: number;
	proposedOrderItems?: OrderItem[];
	orderedProduct?: Product;
	reasonOfProposal?: LocalizedString;
}
export enum ADJUSTMENT_REASONS {
	px_qty_adjustment = "px_qty_adjustment",
	discount = "discount",
	surcharge = "surcharge",
	special_offer = "special_offer"
}
export class AmountAdjustment extends Identity {
	user?: User;
	timestamp?: number;
	amountAdjustment?: number;
	percentageAdjustment?: number;
	adjustedAmount?: number;
	reason?: string;
	rememberAdjustment?: boolean;
}
export class PaymentInfo extends Identity {
	paymentStatus?: PAYMENT_STATUS;
	paymentMethod?: PAYMENT_METHOD;
}
export class Order extends AppIdentity implements StoreFilterable {
	orderProgressive?: string;
	store?: Store;
	users?: User[];
	orderItems?: OrderItem[];
	status?: ORDER_STATUS;
	timestamp?: Date;
	amount?: number;
	amountAdjustment?: AmountAdjustment;
	requestedItems?: number;
	confirmedItems?: number;
	modifiedItems?: number;
	rejectedItems?: number;
	chat?: Chat;
	bookings?: Booking[];
	deliveryDate: Date;
	paymentInfo?: PaymentInfo;
	dataLog: OrderDataLog[];
}
export class OrderDataLog extends Order implements DataLog {
	user?: User;
	timestamp?: Date;
}
export let OrderPaginatedResponse: new (...args: any[]) => PaginatedResponse<Order>;
export type OrderPaginatedResponse = InstanceType<typeof OrderPaginatedResponse>;
export class ProductOrderStatistics extends ModelClass {
	products?: Product[];
	cartCount?: number;
}
export let DELIVERY_SHIPMENT_TYPE = "delivery_shipment";
export class ZipCodeShipmentSchedule {
	schedule?: ScheduleCalendar;
	zipCodes?: string[];
	shipmentPriceOrderAmountAware?: ShipmentPriceOrderAmountAwareRange[];
	resources?: string[];
}
export class ShipmentPriceOrderAmountAwareRange {
	fromOrderAmount?: number;
	toOrderAmount?: number;
	price?: number;
}
export class DeliveryShipmentServiceCustomData extends ServiceCustomData {
	zipCodeShipmentSchedules?: ZipCodeShipmentSchedule[];
}
export class DeliveryShipmentBookingCustomData extends BookingCustomData {
	address?: DeliveryAddress;
	orderAmountWithoutBooking?: number;
}
export let DELIVERY_PICKUP_STORE_TYPE = "delivery_pickup_store";
export class DeliveryPickupServiceCustomData extends ServiceCustomData {
}
export class DeliveryPickupBookingCustomData extends BookingCustomData {
	orderAmountWithoutBooking?: number;
}
export class OrderingConfiguration extends FeatureConfiguration {
	inStorePickupConfiguration: InStorePickupConfiguration;
	shipmentConfiguration: ShipmentConfiguration;
	orderItemsConfirmedByDefault: boolean;
	turnIntoReadyAutomatically: boolean;
	authorizationOnlyMinAmountIncrease: number;
	authorizationOnlyMaxAmountIncrease: number;
	authorizationOnlyPercent: number;
}
export class InStorePickupConfiguration {
	enabled: boolean;
	allowStripeCardPayment: boolean;
	allowCashPayment: boolean;
}
export class ShipmentConfiguration {
	enabled: boolean;
	allowStripeCardPayment: boolean;
	allowCashPayment: boolean;
}
export enum ORDERING_NOTIFICATION_EVENT {
	order_manager = "order_manager",
	order_user = "order_user"
}
export enum CURRENCY_SYMBOL {
	eur = "\u20AC",
	usd = "$",
	aud = "AU$",
	nzd = "NZ$",
	hkd = "HK$",
	gbp = "\u00A3"
}
export class PaymentRefund extends Identity {
	amount?: number;
	currency?: string;
	reason?: PAYMENT_REFUND_REASON;
	refundDate?: Date;
}
export class PaymentClaim extends AppIdentity implements StoreFilterable, Taggable {
	requestCode?: string;
	store?: Store;
	issuerUser?: User;
	issueDate?: Date;
	expirationDate?: Date;
	paymentProvider?: PAYMENT_PROVIDER;
	paymentMethod?: PAYMENT_METHOD;
	providerCustomDataJson?: string;
	authorizedAmount?: number;
	authorizationExpiration?: Date;
	amount?: number;
	refunds?: PaymentRefund[];
	refundedAmount?: number;
	virtualRefundedAmount?: number;
	currency?: string;
	description?: string;
	feeAmount?: number;
	feeBillingDate?: Date;
	feeInvoiceNumber?: string;
	status?: PAYMENT_STATUS;
	order?: Order;
	paymentDate?: Date;
	payingUser?: User;
	transactionId?: string;
	transactionMessage?: string;
	tags?: Tag[];
	dataLog: PaymentClaimDataLog[];
}
export class PaymentClaimDataLog extends PaymentClaim implements DataLog {
	user?: User;
	timestamp?: Date;
}
export let PaymentClaimPaginatedResponse: new (...args: any[]) => PaginatedResponse<PaymentClaim>;
export type PaymentClaimPaginatedResponse = InstanceType<typeof PaymentClaimPaginatedResponse>;
export class PaymentClaimCustomData extends ModelClass {
}
export class SetupPaymentMethodResult {
	providerCustomDataJson?: string;
}
export class SavePaymentMethodResult {
	providerCustomDataJson?: string;
}
export class ClaimPaymentMethodResult {
	providerCustomDataJson?: string;
}
export class PaymentRequestCode extends AppIdentity {
	requestCode?: string;
	paymentClaim?: PaymentClaim;
	timestamp?: Date;
	issuer?: User;
}
export class PaymentRefundableAmount {
	amount?: number;
	currency?: string;
}
export class StripePaymentClaimCustomData extends PaymentClaimCustomData {
	paymentIntent?: Stripe.PaymentIntent;
	stripeAccountId: string;
}
export class StripeSetupPaymentMethodCustomData extends ModelClass {
	paymentMethods: PAYMENT_METHOD[];
}
export class StripeSetupPaymentMethodResultCustomData extends ModelClass {
	clientSecret?: string;
}
export class StripeSavePaymentMethodCustomData extends ModelClass {
	platformPaymentMethodId?: string;
}
export class StripeClaimPaymentCustomData extends ModelClass {
	platformPaymentMethodId?: string;
}
export class StripeClaimPaymentResultCustomData extends ModelClass {
	connectedPaymentMethodId?: string;
}
export class StripeRemovePaymentMethodCustomData extends ModelClass {
	platformPaymentMethodId?: string;
}
export class StripeSetDefaultPaymentMethodCustomData extends ModelClass {
	platformPaymentMethodId?: string;
}
export enum PAYMENT_NOTIFICATION_EVENT {
	payment_request = "payment_request",
	payment_status = "payment_status"
}
export class PaymentConfiguration extends FeatureConfiguration {
	paymentRequestExpiration: number;
}
