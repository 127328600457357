import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Toast } from "@ionic-native/toast/ngx";
import { AlertController, ModalController, ToastController } from "@ionic/angular";
import { ILSPrinter } from "print/printer.interface.public";
import { CrossPlatformToastService } from "src/app/core/services/cross-platform-toast.service";
import { safeAwaitArray, safeAwaitOrDefault } from "src/app/core/utils";
import { PrinterService } from "../../printer.service";

@Component({
	selector: "app-add-ls-printer",
	templateUrl: "./add-ls-printer.page.html",
	styleUrls: ["./add-ls-printer.page.scss"],
})
export class AddLsPrinterPage implements OnInit {

	fm: FormGroup;
	printers: ILSPrinter[] = undefined; // vuoto
	working = false;

	constructor(
		private printerService: PrinterService,
		private modalController: ModalController,
		private formBuilder: FormBuilder,
		private alertCtrl: AlertController,
		private toastService: CrossPlatformToastService,
	) {

		const groupConfig = {};
		// tslint:disable-next-line: no-string-literal
		groupConfig["server"] = [
			"",
			Validators.compose([Validators.required, Validators.minLength(3)])
		];
		// tslint:disable-next-line: no-string-literal
		groupConfig["port"] = [
			"",
			Validators.compose([Validators.required])
		];

		this.fm = this.formBuilder.group(groupConfig);
	}

	ngOnInit() {
	}

	// ricerca stampanti sul server specificato
	async doSearch(): Promise<void> {

		// tslint:disable-next-line: no-string-literal
		const server = this.fm.controls["server"].value;
		// tslint:disable-next-line: no-string-literal
		const port = this.fm.controls["port"].value;

		// legge la lista delle stampanti del server
		let err;
		this.working = true;
		[err, this.printers] = await safeAwaitArray(this.printerService.getLocalServerPrinters(server, port));
		if (err) {
			this.printers = null; // errore di chiamata
		}
		this.working = false;
	}

	async addPrinterClicked(p: ILSPrinter) {
		await this.printerService.addLSPrinter(p);

		// visualizza un messaggio
		this.toastService.showToast({
			message: "Stampante aggiunta!"
		});
	}

}
