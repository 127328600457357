import { Query } from "../services/query";

export const TAG_INFO_FULL =
	`
	name {
		...language
	}
	description {
		...language
	}
	scope
	type
	internalCode
	validFrom
	validTo
	certified
	typePriority
	like
	pictureStorageFile {
		id
	}
	`;


// // altre query di questo tipo su administration-queries
// export const GET_TAGS_PAGINATED = new Query(
// 	`
// 	{
// 		tags {
// 			${TAG_INFO_FULL}
// 		}
// 	}
// 	`
// );

// TAG QUERIES
export const DELETE_TAG_ADMIN = new Query(
	`
	mutation _($tagId: String) {
		deleteTag(tagId: $tagId) {
			id
		}
	}
	`
);

export const SEARCH_TAGS = new Query(
	`
	query _($searchString: String, $scope: TAG_SCOPE, $type: TAG_TYPE, $appId: String) {
		searchTags(searchString: $searchString, scope: $scope, type: $type, appId: $appId) {
			id
			name {...language}
			description {...language}
			scope
			type
		}
	}
	`
);

export const FIND_TAG_BY_KEY = new Query(
	`
	query _($name: LocalizedStringInput, $appId: String, $scope: TAG_SCOPE, $type: TAG_TYPE) {
		findTagByKey(name: $name, appId: $appId, scope: $scope, type: $type) {
			id
			name {...language}
			description {...language}
			scope
			type,
			internalCode
		}
	}
	`
);

export const FIND_TAG_BY_INTERNAL_CODE = new Query(
	`
	query _($appId: String, $internalCode: String, $type: TAG_TYPE) {
		findTagsByInternalCode(appId: $appId, internalCode: $internalCode, type: $type) {
			id
			name {...language}
			description {...language}
			scope
			type
			internalCode
		}
	}
	`
);
