import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Platforms } from "@ionic/core";

@Injectable({
	providedIn: "root"
})
export class Platform2Service {

	constructor(
		public platform: Platform
	) { }

	ready(): Promise<string> {
		return this.platform.ready();
	}

	is(platformName: Platforms): boolean {
		return this.platform.is(platformName);
	}

	/**
	 * Personalizzata Plazar, tramite l'useragent modificato testiamo se
	 * il browser corrente è dentro l'app compilata o meno
	 */
	isIntoCompiledApp(): boolean {
		const compiledApp = this.platform.testUserAgent("plazar/app");
		return compiledApp;
	}
}
