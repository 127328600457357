<div class="listItemContainer">
	<div class="listItemView" (click)="itemClicked()">
		<ng-template></ng-template>
	</div>
	<div class="actionButtons">
		<ion-button color="danger" size="small" (click)="deleteClicked()">
			<ion-icon name="trash"></ion-icon>
		</ion-button>
	</div>
</div>
