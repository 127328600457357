import { Injectable } from "@angular/core";
import { App, AppPaginatedResponse, ConfigurationHints, FEATURE, Pagination } from "src/app/backend-api";
import {IQueryManagerMutationOptions, QueryManagerService} from "../services/query-manager.service";
import { safeAwaitOrDefault } from "../utils";
import {
	CREATE_APP,
	DELETE_APP,
	GET_APP,
	GET_APP_ADMIN,
	GET_APP_SUBSCRIPTIONS,
	GET_APPS,
	GET_CURRENT_USER_FAVOURITE_APPS,
	GET_FEATURE_CONFIGURATION,
	IS_APP_NAME_AVAILABLE, SAVE_FEATURE_CONFIGURATION,
	SEARCH_APPS_ADMIN,
	SEARCH_APPS_ALL,
	UPDATE_APP,
	UPDATE_APP_MANAGER,
	IS_PLATFORM_APP, REMOVE_FEATURE_CONFIGURATION
} from "./app-queries";

@Injectable({
	providedIn: "root"
})
export class QueryAppService {

	constructor(
		private queryManager: QueryManagerService,
	) { }

	async loadApp(appId: string): Promise<App> {
		const params = {
			appId
		};

		return this.queryManager.execute(GET_APP, params,
			{
				allLanguages: true // dav 3011219, fix cambio utente con cambio lingua, non rinfrescava le info dell'app in lingua
			}
		);

	}

	// /**
	//  * Ritorna i configurationHints del'app passata
	//  */
	// async loadAppConfigurationHints(appId: string): Promise<ConfigurationHints> {
	// 	const params = {
	// 		appId
	// 	};

	// 	const app: App = await safeAwaitOrDefault(this.queryManager.execute(GET_APP_CONFIGURATION_HINTS, params, {}));
	// 	return app?.configurationHints;
	// }

	async loadAppSubscriptions(appId: string): Promise<App> {
		return this.queryManager.execute(GET_APP_SUBSCRIPTIONS,
			{
				appId
			}
		);
	}

	searchApps_admin(searchString: string, first?: number, after?: string): Promise<AppPaginatedResponse> {
		return this.queryManager.execute(SEARCH_APPS_ADMIN, { searchString }, { pagination: { first, after }, showLoading: false });
	}

	searchApps(options: {
		tags?: string[];
		pagination: Pagination;
		searchString?: string;
		showLoading?: boolean;
		excludeApps?: string[];
	}): Promise<AppPaginatedResponse> {

		return this.queryManager.execute(SEARCH_APPS_ALL, {
			searchString: options.searchString,
			excludeApps: options.excludeApps,
			tags: options.tags
		}, {
			pagination: options.pagination,
			showLoading: options.showLoading
		});
	}

	getApps(options: {
		pagination: Pagination;
		filterIds?: string[];
		showLoading?: boolean;
	}): Promise<AppPaginatedResponse> {

		return this.queryManager.execute(GET_APPS, {
			filterIds: options.filterIds
		}, {
			pagination: options.pagination,
			showLoading: options.showLoading
		});

	}

	getAppAdmin(appId: string): Promise<App> {

		const params = {
			appId
		};
		return this.queryManager.execute(GET_APP_ADMIN, params, {
			allLanguages: true
		});
	}

	isAppNameAvailable(name: string): Promise<boolean> {

		const params = {
			name
		};
		return this.queryManager.execute(IS_APP_NAME_AVAILABLE, params);
	}

	async deleteApp(appId: string) {
		return this.queryManager.mutate(DELETE_APP, { appId });
	}

	updateAppAsAdmin(app: App): Promise<App> {
		const params = {
			app
		};
		return this.queryManager.mutate(UPDATE_APP, params);
	}

	// update app da parte del manager
	updateAppAsManager(app: App, options?: IQueryManagerMutationOptions): Promise<App> {
		const params = {
			app
		};
		return this.queryManager.mutate(UPDATE_APP_MANAGER, params, options);
	}

	createAppAsAdmin(app: App): Promise<App> {
		const params = {
			app
		};
		return this.queryManager.mutate(CREATE_APP, params);
	}

	getCurrentUserFavouriteApps(pagination: Pagination): Promise<AppPaginatedResponse> {
		const params = {
		};
		return this.queryManager.execute(GET_CURRENT_USER_FAVOURITE_APPS, params, {
			pagination
		});
	}

	isPlatformApp(appId: string): Promise<boolean> {
		const params = {
			appId
		};
		return this.queryManager.execute(IS_PLATFORM_APP, params);
	}

	getFeatureConfiguration(appId: string, feature: FEATURE, storeId?: string, strictMode = false): Promise<string> {
		const params = {
			appId, storeId, feature, strictMode
		};
		return this.queryManager.execute(GET_FEATURE_CONFIGURATION, params);
	}

	saveFeatureConfiguration(appId: string, feature: FEATURE, featureConfigurationJson: string, storeId?: string, strictMode = false): Promise<boolean> {
		const params = {
			appId, storeId, feature, featureConfigurationJson, strictMode
		};
		return this.queryManager.mutate(SAVE_FEATURE_CONFIGURATION, params);
	}

	removeFeatureConfiguration(appId: string, feature: FEATURE, storeId?: string, strictMode = false): Promise<boolean> {
		const params = {
			appId, storeId, feature, strictMode
		};
		return this.queryManager.mutate(REMOVE_FEATURE_CONFIGURATION, params);
	}
}
