<ion-grid>
	<ion-row>
		<ion-col>
			<ion-label>
				{{ additionalData.titleInfo(item).title }}
			</ion-label>
		</ion-col>
	</ion-row>
	<ion-row>
		<ion-col>
			<ion-label>
				{{ additionalData.titleInfo(item).subtitle }}
			</ion-label>
		</ion-col>
	</ion-row>
	<ion-row *ngIf="!!additionalData.titleInfo(item).other">
		<ion-col>
			<span [innerHTML]="additionalData.titleInfo(item).other | safeHtml"></span>
		</ion-col>
	</ion-row>
</ion-grid>