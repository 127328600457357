import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { IonToggle } from "@ionic/angular";

@Component({
	selector: "app-round-signal",
	templateUrl: "./round-signal.component.html",
	styleUrls: ["./round-signal.component.scss"],
})
export class RoundSignalComponent implements OnInit {

	@Output() changed = new EventEmitter();

	private _active: boolean;

	@Input()
	set active(b: boolean) {
		this._active = b;
	}
	get active(): boolean {
		return this._active;
	}

	constructor() { }

	ngOnInit() {

	}

	ionChanged(el: IonToggle) {
		this.changed.emit(el.checked);
	}

	stopClick(evt: Event) {
		evt.stopPropagation();
	}


}
