import gql, { disableFragmentWarnings } from "graphql-tag";
import { Apollo } from "apollo-angular";
import { Query } from "./query";
import { Injectable } from "@angular/core";
import { ShowLoaderService } from "./show-loader.service";
import { ApolloError, ApolloQueryResult, FetchPolicy } from "apollo-client";
import { ERROR_CODE, LANGUAGE, Pagination } from "../../backend-api";
import { DataTranslateService } from "./data-translate.service";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { FetchResult } from "apollo-link";
import { GraphQLError } from "graphql";
import { HttpErrorResponse } from "@angular/common/http";
import { ShowOverlayService } from "./show-overlay.service";
import { SpinnerButtonService } from "../components/spinner-button/spinner-button.service";

export interface IQueryManagerExecuteOptions {
	useCache?: boolean;
	clearCacheBeforeExecution?: boolean;
	cacheValidityInMinutes?: number;
	cacheKey?: string;
	allLanguages?: boolean;
	pagination?: Pagination;
	showLoading?: boolean;
	alwaysReject?: boolean; // dav 280520, per testare i reject da vari punti del codice
	callKey?: string; // dav 020121
}

export interface IQueryManagerMutationOptions {
	allLanguages?: boolean;
	alwaysReject?: boolean; // dav 280520, per testare i reject da vari punti del codice
	callKey?: string; // dav 020121
}

@Injectable({
	providedIn: "root"
})
export class QueryManagerService {

	// contiene la lista statica di tutte le lingue supportate dal sistema
	private _allLanguages = Object.values(LANGUAGE);

	// dav 100120, per aggiunta del tempo di vita alla cache Apollo
	private _cache: {
		[key: string]: any;
	} = {};

	constructor(
		private apollo: Apollo,
		private dts: DataTranslateService,
		private showLoaderService: ShowLoaderService,
		private showOverlayService: ShowOverlayService,
		private spinnerButtonService: SpinnerButtonService
	) {
		//avoid duplicated fragment warning for language, because we use the same name in every query
		disableFragmentWarnings();
	}

	//if showLoading flag value is not passed, assume loading is enabled
	private _shouldPresentLoading(options: IQueryManagerExecuteOptions) {
		return !options || options.showLoading === undefined || options.showLoading === null || options.showLoading;
	}

	// svuota la cache
	clearCache(): void {
		this._cache = {};
	}

	// svuota la cache
	clearCacheItem(key: string): void {
		delete this._cache[key];
	}

	async execute(
		queryTemplate: Query,
		params?: {
			[name: string]: any;
		},
		options?: IQueryManagerExecuteOptions
	): Promise<any> {

		options = options || {};

		// fake reject per test su chiamate
		if (options.alwaysReject) {
			return new Promise(async (resolve, reject) => {
				reject("fake reject");
			});
		}

		// visualizza la finestra di attesa
		if (this._shouldPresentLoading(options)) {
			await this.showLoaderService.presentLoading();
		}
		// mostra l'overlay sopra a tutto
		this.showOverlayService.showOverlay();

		// notifica che sto lavorando
		this.spinnerButtonService.dispatchWorking(options.callKey);

		return new Promise(async (resolve, reject) => {

			// eventuale chiave passata dal client che posso usare per cancellare elementi puntuali
			let cacheKey = options.cacheKey;
			if (!cacheKey) {
				// chiave dell'elemento nella cache per il calcolo del tempo di vita rimanente
				cacheKey = queryTemplate.query + "-" + (queryTemplate.hasLanguageFragment ? "L" : "*") + "-" + JSON.stringify(params || {});
			}

			// no cache e senza aggiornamento della cache
			let fetchPolicy: FetchPolicy = "no-cache";
			if (options.useCache) {

				// legge il time di esecuzione precedente
				const lastExecutionTime = this._cache[cacheKey] || 0;
				const thisExecutionTime = new Date().getTime();

				if (options.clearCacheBeforeExecution) {
					// no cache ma con update della cache
					fetchPolicy = "network-only";
				}
				else {

					// dati in cache
					fetchPolicy = "cache-first";

					// se ho specificato il tempo di vita della cache controllo che non sia passato
					if (options.cacheValidityInMinutes) {

						// se il tempo è passato esegue senza cache ma aggiorna i dati in cache
						const diffInMinutes = (thisExecutionTime - lastExecutionTime) / 1000 / 60;
						if (diffInMinutes > options.cacheValidityInMinutes) {
							// no cache ma con update della cache
							fetchPolicy = "network-only";
						}
					}
				}

				// salva la data di ultima esecuzione
				this._cache[cacheKey] = thisExecutionTime;

			}
			else { // no-cache

				// elimina qualsiasi informazione precedente dalla cache
				delete this._cache[cacheKey];

				// fetchPolicy è già "no-cache"
			}

			// esecuzione della query
			this._perform(
				queryTemplate,
				params,
				false,
				options.allLanguages,
				options.pagination,
				fetchPolicy

			).then((data) => {

				resolve(data);

			}).catch((...args) => {

				reject(...args);

			}).finally(async () => {

				// chiude il messaggio di attesa
				if (this._shouldPresentLoading(options)) {
					await this.showLoaderService.dismissLoading();
				}
				// mostra l'overlay sopra a tutto
				this.showOverlayService.hideOverlay();

				// notifica che ho finito
				this.spinnerButtonService.dispatchIdle(options.callKey);
			});

		});

	}

	async mutate(
		queryTemplate: Query, params?: {
			[name: string]: any;
		},
		options?: IQueryManagerMutationOptions): Promise<any> {

		// default per le opzioni
		options = options || {};

		// fake reject per test su chiamate
		if (options.alwaysReject) {
			return new Promise(async (resolve, reject) => {
				reject("fake reject");
			});
		}

		// visualizza la finestra di attesa
		if (this._shouldPresentLoading(options)) {
			await this.showLoaderService.presentLoading();
		}
		// mostra l'overlay sopra a tutto
		this.showOverlayService.showOverlay();

		// notifica che sto lavorando
		this.spinnerButtonService.dispatchWorking(options.callKey);

		try {
			const ret = await this._perform(
				queryTemplate,
				params,
				true,
				options.allLanguages,
				undefined,
				undefined);
			return ret;
		}
		finally {
			// chiude il messaggio di attesa
			if (this._shouldPresentLoading(options)) {
				await this.showLoaderService.dismissLoading();
			}
			// mostra l'overlay sopra a tutto
			this.showOverlayService.hideOverlay();

			// notifica che sto lavorando
			this.spinnerButtonService.dispatchIdle(options.callKey);
		}
	}

	private _perform(
		queryTemplate: Query,
		params: {
			[name: string]: any;
		},
		isMutation: boolean,
		allLanguages: boolean,
		pagination: Pagination,
		queryFetchPolicy: FetchPolicy): Promise<any> {

		if (!this.dts.currentLang) {
			throw Error("Controllare la lingua corrente!!!");
		}

		// inserisce nella query tutte le lingue o solo quella corrente
		const languages = allLanguages ? this._allLanguages.join(" ") : this.dts.getLanguagesForQueries().join(" ");
		const fragment = `fragment language on LocalizedString {` + languages + ` invariant}`;

		let strQuery: string = queryTemplate.query;
		if (pagination) {
			if (pagination.first || pagination.after) {
				strQuery = strQuery.replace(/(query[^{]*\{[^{( ]*)(?:\(([^)]*)\))?/g,
					(match, p1, p2) => {
						let finalStr = p1 + "(";
						if (p2) {
							//there was already a parameter list, so concatenate with the existing parameters
							finalStr += p2 + ", ";
						}
						if (pagination.first) {
							finalStr += "first: " + pagination.first;
						}
						if (pagination.after) {
							if (pagination.first) {
								finalStr += ", ";
							}
							finalStr += "after: \"" + pagination.after + "\"";
						}
						finalStr += ")";
						return finalStr;
					});
			}
			//wrap original query with pagination info
			strQuery = strQuery.replace(/(query[^{]*\{[^{]*\{)([\s\S]*)\}[\s]*\}[\s]*$/g,
				"$1\npageInfo {\nstartCursor\nendCursor\nhasMoreData\n}\nedges {\ncursor\nnode {\n$2\n}\n}\n}\n}");
		}
		if (queryTemplate.hasLanguageFragment) {
			strQuery += fragment;
		}

		// dav 291119 - controllo congruenza chiamata mutate o execute
		if (isMutation && strQuery.indexOf("mutation") === -1) {
			throw Error("QueryManagerService: called 'mutate' on a non mutation query");
		}
		else if (!isMutation && strQuery.indexOf("mutation") !== -1) {
			throw Error("QueryManagerService: called 'execute' on a non query");
		}

		const gqlquery = gql(strQuery);
		return new Promise((resolve, reject) => {
			let q: Observable<FetchResult | ApolloQueryResult<any>>;
			if (isMutation) {
				q = this.apollo.mutate({
					mutation: gqlquery,
					variables: params
				});
			}
			else {
				q = this.apollo.query({
					query: gqlquery,
					variables: params,
					fetchPolicy: queryFetchPolicy
				});
			}

			const sub = q.subscribe((qr) => {
				// errore della query, simulo un errore normale
				if (qr?.errors?.length) {
					const err1: GraphQLError = qr.errors[0];
					this.logError(err1, queryTemplate.name);
					reject(err1);
				}
				else {
					const result = qr.data[queryTemplate.name];
					resolve(result);
				}
			}, (err) => {
				let innerError;
				if (err?.graphQLErrors?.length) {
					innerError = err.graphQLErrors[0];
				}
				else {
					innerError = err;
				}
				this.logError(innerError, queryTemplate.name);
				reject(innerError);
			}, () => {
				sub.unsubscribe();
			});
		});
	}

	/**
	 * Returns a user friendly error description, localized if possible.
	 */
	getErrorDescription(err: any, fallbackTranslationKey?: string): string {
		let key;
		if (err && typeof err === "string") {
			// error is already a string, cannot translate
			return err;
		}
		key = this._getErrorCodeString(err);
		if (!key && fallbackTranslationKey) {
			return this.dts.translateService.instant(fallbackTranslationKey);
		}
		else {
			return this.dts.translateService.instant("backend_errors." + (key || ERROR_CODE[ERROR_CODE.INTERNAL_SERVER_ERROR]));
		}
	}

	// ritorna il valore stringa dell'enu
	private _getErrorCodeString(err: GraphQLError | HttpErrorResponse): string {
		return ERROR_CODE[this.getErrorCode(err)];
	}

	/**
	 * Returns the error code, handling native GraphqlErrors from Apollo and HTTP errors from REST calls.
	 */
	getErrorCode(err: GraphQLError | HttpErrorResponse): ERROR_CODE {
		if ((err as GraphQLError)?.extensions?.exception?.response?.code) {
			return (err as GraphQLError).extensions.exception.response.code;
		}
		else if ((err as HttpErrorResponse)?.error?.code && (err as HttpErrorResponse).error.reason) {
			return (err as HttpErrorResponse).error.code;
		}
	}

	private logError(err: GraphQLError | ApolloError, queryTemplateName: string) {
		if (!environment.production) {
			let msg: string;
			let msgCode, msgReason;
			const errorCode = this.getErrorCode(err as any);
			if (errorCode) {
				msgCode = ERROR_CODE[errorCode];
				msgReason = errorCode;
			}
			else if (((err as ApolloError)?.networkError as HttpErrorResponse)?.error?.errors?.length > 0) {
				msgCode = "HTTP " + ((err as ApolloError)?.networkError as HttpErrorResponse).status;
				msgReason = ((err as ApolloError)?.networkError as HttpErrorResponse).error.errors[0].message;
			}
			else {
				msgCode = ERROR_CODE.INTERNAL_SERVER_ERROR;
				msgReason = JSON.stringify(err);
			}
			msg = "QueryManagerService" + (queryTemplateName ? "('" + queryTemplateName + "')" : "") + ": [" + msgCode + "] " + msgReason;

			// logga sulla console
			console.error(Date.now() + " >" + msg);
		}
	}
}
