import { Directive, OnInit } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
	selector: "[appAutotrim]"
})
export class AutotrimDirective implements OnInit {

	constructor(
		private ngControl: NgControl
	) {

	}

	ngOnInit(): void {
		this.ngControl.valueChanges.subscribe(
			(value: string) => {
				this.ngControl.control.setValue((value || "").trim(), { emitEvent: false });
			}
		);
	}
}
