import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
	providedIn: "root"
})
export class ShowLoaderService {

	// chiamate attive al momento
	static calls = 0;
	static _timerId = null;
	static _creating = false;
	static _created = false;

	private readonly _timeout = 1500;

	constructor(
		private loadingController: LoadingController,
		private translate: TranslateService
	) { }

	// visualizza il messaggio di attesa ma temporizzato di 2 secondi
	async presentLoading() {

		// visualizza la finestra di attesa
		ShowLoaderService.calls++;
		if (ShowLoaderService.calls > 0) {

			// se sta già gestendo la visualizzazione esce subito
			if (ShowLoaderService._timerId || ShowLoaderService._creating || ShowLoaderService._created) {
				return;
			}

			// attiva la temporizzazione
			ShowLoaderService._timerId = setTimeout(async (ctx) => {
				ShowLoaderService._timerId = null;

				// gestione del segnalino di attesa
				await ctx._manageLoadingDelayed();
			}, this._timeout, this);

		}
	}

	// chiude il messaggio di attesa
	async dismissLoading() {

		ShowLoaderService.calls--;
		if (ShowLoaderService.calls < 0) {
			ShowLoaderService.calls = 0;
		}

		if (ShowLoaderService.calls === 0) {
			if (ShowLoaderService._timerId) {
				clearTimeout(ShowLoaderService._timerId);
			}

			// sbianco il timerId nel caso chiuda prima di arrivare al timeout
			ShowLoaderService._timerId = null;

			if (ShowLoaderService._created) {
				await this.loadingController.dismiss();
				ShowLoaderService._created = false;
			}
		}
	}

	private async _manageLoadingDelayed() {

		// solo se ho ancora chiamate in corso
		if (ShowLoaderService.calls > 0) {
			ShowLoaderService._creating = true;

			// crea
			const loading = await this.loadingController.create({
				message: this.translate.instant("JUST_A_MOMENT"),
				translucent: true,
				cssClass: "",
				showBackdrop: true
			});

			// visualizza
			await loading.present();
			ShowLoaderService._created = true;

			ShowLoaderService._creating = false;

			// se ha già finito chiude subito
			if (ShowLoaderService.calls <= 0) {
				await this.loadingController.dismiss();
				ShowLoaderService._created = false;
			}
		}

	}

}
