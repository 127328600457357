import { Query } from "../services/query";

export const COUNTRY_INFO =
	`
	id
	name {
		...language
	}
	code
	`;

export const PROVINCE_INFO =
	`
	id
	name {
		...language
	}
	abbreviation
	code
	country {
		${COUNTRY_INFO}
	}
	`;

export const CITY_INFO =
	`
	id
	name {
		...language
	}
	zip
	code
	province {
		${PROVINCE_INFO}
	}
	`;

export const ADDRESS_INFO =
	`
	id
	name
	street
	number
	city {
		${CITY_INFO}
	}
	geolocation {
		id
		coordinates {
			latitude
			longitude
		}
		type
		distance
	}
	zip
	`
	;

export const DELIVERYADDRESSES_INFO =
	`
	deliveryAddresses {
		${ADDRESS_INFO}
		isDefault
		intercomName
		interior
	}
	`;

export const GET_CITY = new Query(
	`query _($id: String) {
		city(id: $id) {
			${CITY_INFO}
		}
	}`
);

export const GET_PROVINCE = new Query(
	`query _($id: String) {
		province(id: $id) {
			${PROVINCE_INFO}
		}
	}`
);

export const GET_COUNTRY = new Query(
	`query _($id: String) {
		country(id: $id) {
			${COUNTRY_INFO}
		}
	}`
);

export const GET_CITIES = new Query(
	`{
		cities {
			${CITY_INFO}
		}
	}`
);

export const SEARCH_CITIES = new Query(
	`query _($searchString: String) {
		searchCities(searchString: $searchString) {
			${CITY_INFO}
		}
	}`
);

export const GET_PROVINCES = new Query(
	`{
		provinces {
			${PROVINCE_INFO}
		}
	}`
);

export const SEARCH_PROVINCES = new Query(
	`query _($searchString: String) {
		searchProvinces(searchString: $searchString) {
			${PROVINCE_INFO}
		}
	}`
);

export const GET_COUNTRIES = new Query(
	`{
		countries {
			${COUNTRY_INFO}
		}
	}`
);

export const SEARCH_COUNTRIES = new Query(
	`query _($searchString: String) {
		searchCountries(searchString: $searchString) {
			${COUNTRY_INFO}
		}
	}`
);
