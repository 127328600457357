<app-header title="{{ 'LANG' | translate }}"></app-header>
<ion-content class="kaleidos-page">

	<ion-list>
		<ion-list-header>
			<ion-label>{{ 'account.SUPPORTED_LANGUAGES' | translate }}</ion-label>
		</ion-list-header>
		<ion-item *ngFor="let lang of availableLanguages">
			<ion-label>{{ langDescription[lang] }}</ion-label>
			<ion-toggle [checked]="supportedLanguages?.indexOf(lang) !== -1" (ionChange)="toggleLang(lang)">
			</ion-toggle>
		</ion-item>
	</ion-list>

	<ion-list>
		<ion-list-header>
			<ion-label>{{ 'account.DEFAULT_LANGUAGE' | translate }}</ion-label>
		</ion-list-header>
		<ion-item lines="none">
			<ion-select [(ngModel)]="defaultLanguage" okText="{{'FATTO' | translate}}"
				cancelText="{{'ANNULLA' | translate}}">
				<ion-select-option *ngFor="let lang of availableLanguages" [disabled]="supportedLanguages?.indexOf(lang) === -1" [value]="lang">{{ langDescription[lang] }}
				</ion-select-option>
			</ion-select>
		</ion-item>
	</ion-list>

</ion-content>
<ion-footer>
	<ion-grid>
		<ion-row>
			<ion-col class="ion-text-right">
				<ion-button color="success" (click)="doSave()">
					{{ "SAVE" | translate}}
				</ion-button>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-footer>