<div style="padding: 10px;font-size: 13px;">
	<div>
		{{ 'payment.AUTHORIZATION_INCREASE_EXPLANATION' | translate }}.
	</div>
	<div *ngIf="paymentAuthorizationPercentIncrease" style="margin: 5px 0;">
		{{ 'payment.AUTHORIZATION_INCREASE_PERCENT' | translate: {'percentIncrease': paymentAuthorizationPercentIncrease} }}.
	</div>
	<div *ngIf="paymentAuthorizationMinAmountIncrease" style="margin: 5px 0;">
		{{ 'payment.AUTHORIZATION_INCREASE_MIN' | translate: {'minAmountIncrease': (paymentAuthorizationMinAmountIncrease | currency:'EUR':'symbol':'1.2-2':dts.currentLang)} }}.
	</div>
	<div *ngIf="authorizationOnlyMaxAmountIncrease" style="margin: 5px 0;">
		{{ 'payment.AUTHORIZATION_INCREASE_MAX' | translate: {'maxAmountIncrease': (authorizationOnlyMaxAmountIncrease | currency:'EUR':'symbol':'1.2-2':dts.currentLang)} }}.
	</div>
</div>