export class Query {

	readonly name: string;
	readonly hasLanguageFragment: boolean;

	constructor(
		readonly query: string
	) {
		this.name = query.match(/[\s\S]*?{[\s\n]*([^({} \n]+)/)[1];
		this.hasLanguageFragment = query.indexOf("...language") !== -1;
	}
}
