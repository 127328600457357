import { Component, OnInit, Input } from "@angular/core";
import { PopoverController } from "@ionic/angular";

export interface IPopOverButtonMenuItem {
	key: string;	// chiave
	title: string;	// descrizione
	icon: string;	// icona
	iconColor: string;
}

@Component({
	selector: "app-pop-over-button-menu",
	templateUrl: "./pop-over-button-menu.component.html",
	styleUrls: ["./pop-over-button-menu.component.scss"],
})
export class PopOverButtonMenuComponent implements OnInit {

	@Input() items: IPopOverButtonMenuItem[] = null;

	constructor(
		private popoverController: PopoverController
	) { }

	ngOnInit() { }

	// quando clicco un elemento
	itemClicked(item: IPopOverButtonMenuItem) {
		this.popoverController.dismiss({
			item
		}); // indico che ho confermato
	}

}
