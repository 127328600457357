import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { Camera } from "@ionic-native/camera/ngx";
import { LaunchNavigator } from "@ionic-native/launch-navigator/ngx";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { Market } from "@ionic-native/market/ngx";
import { Toast } from "@ionic-native/toast/ngx";
import { Sim } from "@ionic-native/sim/ngx";
import { BarcodeScanner } from "@ionic-native/barcode-scanner/ngx";
import { SocialSharing } from "@ionic-native/social-sharing/ngx";
import { NativeGeocoder } from "@ionic-native/native-geocoder/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClient, HttpClientModule } from "@angular/common/http";

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { GraphQLModule } from "./graphql.module";
import { IonicStorageModule } from "@ionic/storage";

import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { LoggingModule } from "./core/services/logging.module";
import { CoreModule } from "./core/core.module";
import { GlobalErrorHandler } from "./core/GlobalErrorHandler";
import { NotificationModule } from "./notification/notification.module";
import { WebView } from "@ionic-native/ionic-webview/ngx";
import { File } from "@ionic-native/file/ngx";
import { Network } from "@ionic-native/network/ngx";
//import { Deeplinks } from "@ionic-native/deeplinks/ngx";
//import { FirebaseDynamicLinks } from "@ionic-native/firebase-dynamic-links/ngx";
import { ServiceWorkerModule } from "@angular/service-worker";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BackgroundMode } from "@ionic-native/background-mode/ngx";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { FormsModule } from "@angular/forms";
import { PrinterModule } from "./printer/printer.module";
import { environment } from "src/environments/environment";
import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		FormsModule,
		IonicModule.forRoot({
			mode: "md" // forzo lo stile Material
		}),
		IonicStorageModule.forRoot(),
		AppRoutingModule,
		HttpClientModule,
		GraphQLModule, // per Apollo
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			}
		}),
		CoreModule,
		FontAwesomeModule,
		LoggingModule,
		NotificationModule,
		PrinterModule,
		ServiceWorkerModule.register("combined-sw.js", {
			enabled: environment.production
		}),
		BrowserAnimationsModule
	],
	providers: [
		StatusBar,
		SplashScreen,
		InAppBrowser,
		Camera,
		LaunchNavigator,
		AppVersion,
		Market,
		Toast,
		Sim,
		BarcodeScanner,
		WebView,
		File,
		Network,
		//Deeplinks,
		//FirebaseDynamicLinks,
		SocialSharing,
		NativeGeocoder,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: ErrorHandler, useClass: GlobalErrorHandler },
		BackgroundMode,
		Geolocation,
		FCM // l'ho messo anche qui per capire se questo causa il problema dell'app che parte e resta bianca, ci sarebbe già dentro NotificationModule
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(library: FaIconLibrary) {
		library.addIconPacks(fas);
		library.addIconPacks(far);
		library.addIconPacks(fab);
	}
}
