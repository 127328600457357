import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
	selector: "app-hslider",
	templateUrl: "./hslider.component.html",
	styleUrls: ["./hslider.component.scss"],
})
export class HsliderComponent implements OnInit {

	_items: any[];
	currentItem: any;

	@Input()
	get items(): any[] {
		return this._items;
	}
	set items(a: any[]) {
		this._items = a;
		this.selectItem(a?.[0]);
	}

	@Input() itemRenderer: (item: any) => string;
	@Output() itemSelected = new EventEmitter();

	constructor() { }

	ngOnInit() { }

	selectItem(o: any) {
		this.currentItem = o;
		this.itemSelected.emit(o);
	}

	renderItemContent(item: any): string {
		if (this.itemRenderer) {
			return this.itemRenderer(item);
		}
		return item;
	}

}
