import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: "hourToDate" })
export class HourToDatePipe implements PipeTransform {

	constructor() { }

	transform(value) {

		const aSplitted = value.split(":");

		const iHours: number = parseInt(aSplitted[0], 10);
		const iMinutes: number = parseInt(aSplitted[1], 10);

		let iSeconds: number;

		if (aSplitted[2]) {
			iSeconds = parseInt(aSplitted[2], 10);
		}
		else {
			iSeconds = 0;
		}

		const today: Date = new Date();

		const date: Date = new Date(today.getFullYear(), today.getMonth(), today.getDate(), iHours, iMinutes, iSeconds);

		return date;
	}
}
