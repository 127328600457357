import { Injectable } from "@angular/core";
import { Toast } from "@ionic-native/toast/ngx";
import { ToastController, Platform } from "@ionic/angular";
import { Platform2Service } from "./platform2.service";

@Injectable({
	providedIn: "root"
})
export class CrossPlatformToastService {

	constructor(
		private toastController: ToastController,
		private toast: Toast,
		private platform2: Platform2Service
	) { }

	/**
	 * Visualizza il toast multipiattaforma
	 */
	async showToast(options: {
		message: string;
		duration?: number;
		position?: "top" | "bottom";
		color?: "danger" | "warning";
	}): Promise<void> {

		if (!options.duration) { options.duration = 1000; }
		if (!options.position) { options.position = "top"; }

		await this.platform2.ready();

		const compiledApp = this.platform2.isIntoCompiledApp();
		if (compiledApp) {
			// dav 100320, removed await 
			return this.toast.show(options.message, options.duration + "", options.position).toPromise();
		}
		else {
			const toast = await this.toastController.create({
				message: options.message,
				duration: options.duration,
				position: options.position,
				animated: true,
				cssClass: "plazarToastClass",
				color: options.color
			});
			return toast.present();
		}


	}
}
