import { Injectable, ElementRef } from "@angular/core";
import { NavController } from "@ionic/angular";
import { statics } from "../statics";
import { AppConfigurationService } from "./app-configuration.service";
import { safeAwaitOrDefault } from "../utils";
import { Subject } from "rxjs";

// info che tiene in canna per pagina
export interface INavPageInfo {
	// dati mantenuti per il ritorno (ionViewDidEnter)
	dataStorage?: any;
	// riferimento alla pagina chiamante, serve per poter nascondere la pagina dopo la navigazione
	callerPageRef?: Element;
	// dati passati alla prox pagina e ritornati al ritorno, mixati con quelli passati indietro
	navigationCustomData?: any;
	// callback di ritorno
	returnCallback?: (returnData?: any) => void;
	// indica che sono in navigazione su di un popup emulato (returnsBackFromEmulatedPopup)
	emulatedPopup: boolean;
}

@Injectable({
	providedIn: "root"
})
export class AppNavigatorService {

	// parametri richiesti in tutti gli url
	private requiredParams = [
		"appId"
	];

	private _pageInfoArray: INavPageInfo[] = [];
	private _pageInfoCurrent: INavPageInfo;

	// per la sottoscrizione delle navigazioni
	navigationDone = new Subject();

	constructor(
		private navController: NavController,
		private appConfigService: AppConfigurationService
	) {
		// this.router.events.subscribe((event) => {
		// 	if (event instanceof NavigationEnd) {
		// 		debugger
		// 	}
		// });
	}

	/**
	 * Ritorna i dati custom passati come parametri e poi li svuota
	 */
	getPageInfo(): INavPageInfo {
		return this._pageInfoCurrent;
	}

	// ritorna vero se ha pagine precedenti
	hasPreviousPages(): boolean {
		return this._pageInfoArray.length > 0;
	}

	// indica che sto ritornando da un popup emulato
	returnsBackFromEmulatedPopup(): boolean {
		return this?._pageInfoCurrent?.emulatedPopup;
	}

	async navigateRoot(
		route: string,
		sourceLocation?: string): Promise<boolean> {

		const dest = this._getDestURL(route, sourceLocation);

		const ret = await safeAwaitOrDefault(this.navController.navigateRoot(dest, {
			queryParamsHandling: "preserve"
		}));

		if (ret) {
			this._pageInfoArray = [];
			this._pageInfoCurrent = undefined;
		}

		// lancia l'evento
		this.navigationDone.next();

		return ret;
	}

	async navigateForwardEmulatingPopup(
		route: string,
		options: {
			sourceLocation?: string;
			pageRef: Element;
			dataStorage?: any; // dati mantenuti in navigazione (opzionale)
			navigationCustomData: any;
			returnCallback?: (returnData?: any) => void;
		}): Promise<boolean> {

		const dest = this._getDestURL(route, options?.sourceLocation);

		// in avanti passo le cose essenziali
		this._pageInfoCurrent = {
			navigationCustomData: options?.navigationCustomData,
			emulatedPopup: true
		};

		// inserisce le info nello stack di navigazione
		this._pageInfoArray.push({
			dataStorage: options?.dataStorage,
			navigationCustomData: options?.navigationCustomData,
			callerPageRef: options?.pageRef,
			returnCallback: options?.returnCallback,
			emulatedPopup: true
		});

		const ret = await safeAwaitOrDefault(this.navController.navigateForward(dest, {
			queryParamsHandling: "preserve",
			skipLocationChange: true
		}));

		// se è andata male rimuovo
		if (!ret) {
			this._pageInfoCurrent = this._pageInfoArray.pop();
		}
		else {
			// nascondo la pagina passata
			const pr: Element = options.pageRef;
			if (pr) {
				pr.classList.add("appNavigatorHidePages");
			}
		}

		// lancia l'evento
		this.navigationDone.next();

		return ret;
	}

	async navigateForward(
		route: string,
		options?: {
			// standard
			replaceUrl?: boolean,
			skipLocationChange?: boolean;
			navigationOptionalData?: any; // 121020, dati di navigazione OPZIONALI non passabili sull'url
			// nostri
			sourceLocation?: string,
			dataStorage?: any; // dati mantenuti in navigazione
			returnCallback?: () => void;
		}): Promise<boolean> {

		const dest = this._getDestURL(route, options?.sourceLocation);

		// in avanti passo le cose essenziali
		this._pageInfoCurrent = {
			emulatedPopup: false,
			navigationCustomData: options?.navigationOptionalData
		};

		// inserisce le info nello stack di navigazione
		this._pageInfoArray.push({
			dataStorage: options?.dataStorage,
			emulatedPopup: false,
			returnCallback: options?.returnCallback,
			navigationCustomData: options?.navigationOptionalData
		});

		const ret = await safeAwaitOrDefault(this.navController.navigateForward(dest, {
			queryParamsHandling: "preserve",
			replaceUrl: options?.replaceUrl,
			skipLocationChange: options?.skipLocationChange
		}));

		// se è andata male rimuovo
		if (!ret) {
			this._pageInfoCurrent = this._pageInfoArray.pop();
		}

		// lancia l'evento
		this.navigationDone.next();

		return ret;
	}

	async pop(returnCustomData?: any): Promise<void> {

		// scarta il dato della corrente
		this._pageInfoCurrent = this._pageInfoArray.pop();

		// aggiorna il dato della pagina con il nuovo
		if (this._pageInfoCurrent) {

			// visualizzo la pagina precedente
			if (this._pageInfoCurrent.callerPageRef) {
				const pr: Element = this._pageInfoCurrent.callerPageRef;
				pr.classList.remove("appNavigatorHidePages");
			}

			if (returnCustomData) {
				// mixa l'oggetto al ritorno
				this._pageInfoCurrent.navigationCustomData = Object.assign(this._pageInfoCurrent.navigationCustomData || {}, returnCustomData);
			}
		}

		// poppa
		await safeAwaitOrDefault(this.navController.pop());

		// adesso chiamo la callback se impostata passando i dati all'indietro
		if (this._pageInfoCurrent) {
			if (this._pageInfoCurrent.returnCallback) {
				this._pageInfoCurrent.returnCallback(this._pageInfoCurrent.navigationCustomData);
			}
		}

		// lancia l'evento
		this.navigationDone.next();
	}

	private _getDestURL(destRouteWithParams: string, sourceLocation: string): string {

		const srcLoc = sourceLocation || location.toString();

		// vede se ho specificato un appId destinazione diverso dal corrente
		const destAddrAppId = this.getUrlParam(destRouteWithParams, "appId");

		// prende la route base
		const qm = destRouteWithParams.indexOf("?");
		const baseRoute = qm < 0 ? destRouteWithParams : destRouteWithParams.substring(0, qm);
		const routeParams = qm < 0 ? "" : "?" + destRouteWithParams.substring(qm + 1); // parametri con ? davanti

		let dest = destRouteWithParams;
		if (baseRoute === "/home" /*|| baseRoute === "/platform"*/) {
			dest = this.appConfigService.getHomeRoute(destAddrAppId || this.appId()) + routeParams;
		}

		// scorre tutti i parametri richiesti e se non ci sono li aggiunge
		let rps = "";
		for (const rp of this.requiredParams) {
			if (!this.hasUrlParam(dest, rp)) {
				const pv = this.getUrlParam(srcLoc, rp);

				// dal secondo accodato alla stringa in poi
				if (rps.length) {
					rps += "&";
				}

				rps += rp + "=" + encodeURIComponent(pv);
			}
		}


		if (rps.length) {
			if (dest.indexOf("?") === -1) {
				dest += "?" + rps;
			}
			else {
				dest += "&" + rps;
			}
		}

		return dest;
	}

	/**
	 * Legge l'appId dall'url corrente
	 */
	appId(sourceLocation?: string): string {

		const loc = sourceLocation || location.toString();

		const urlAppId = this.getUrlParam(loc, "appId");

		return urlAppId || statics.appId;
	}

	/**
	 * Ritorna un parametro di un URL
	 * @param url URL di cui si vuole leggere il parametro
	 * @param parameter Parametro da leggere
	 */
	private getUrlParam(url: string, parameter: string): string {
		const results: RegExpExecArray = new RegExp("[\?&]" + parameter + "=([^&#]*)").exec(url);

		if (!results) {
			return "";
		}
		else {
			return !!results[1] ? decodeURIComponent(results[1]) : "";
		}
	}

	/**
	 * Controlla se questo parametro esiste già, ritorna true anche se il parametro non ha valore
	 */
	private hasUrlParam(url: string, parameter: string): boolean {
		const results: RegExpExecArray = new RegExp("[\?&]" + parameter + "=([^&#]*)").exec(url);

		if (!results) {
			return false;
		}

		return true;
	}

}
