import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { AppConfigurationService } from "../../services/app-configuration.service";
import { NetworkConnectionService } from "../../services/network-connection.service";
import { AppNavigatorService } from "../../services/app-navigator.service";
import { Subscription } from "rxjs";
import { safeAwaitOrDefault } from "../../utils";
import { Router } from "@angular/router";
import { CORE_PERMISSION } from "src/app/backend-api";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {

	@Input() title: string;
	@Input() hideNavButtons: boolean;
	@Input() lock = false;
	@Output() lockChanged = new EventEmitter<boolean>();

	private _subscription: Subscription;

	hasPreviousPages: boolean;
	isIntoModalPopup: boolean;

	userCanManageApp = false;

	constructor(
		private modalCtrl: ModalController,
		private appConfigService: AppConfigurationService,
		public networkConnection: NetworkConnectionService,
		private appNav: AppNavigatorService
	) {

	}

	ngOnInit() {
		this._subscription = this.appNav.navigationDone.subscribe(() => {

			// vede se ha pagine precedenti
			this.hasPreviousPages = this.appNav.hasPreviousPages();

			// se arriva dalla navigazione non è certo in popup
			this.isIntoModalPopup = false;
		});

		// vede se è in un popup modale, aspetta un attimo per ottenere un risultato valido
		// anche nel caso abbia navigato dopo aver appena chiuso un popup
		setTimeout(async () => {
			this.isIntoModalPopup = !!await this.modalCtrl.getTop();
		}, 50);


		// utente con permessi di gestione app
		this.userCanManageApp = this.appConfigService.isUserAllowed(this.appNav.appId(), undefined, [CORE_PERMISSION.manage_app]);
	}

	ngOnDestroy() {

		// disiscrive
		this._subscription.unsubscribe();
	}

	lockClickedCB() {
		const b = !this.inlineChangesEnabled;
		this.inlineChangesEnabled = b;
		this.lockChanged.emit(b);
	}

	get inlineChangesEnabled() {
		return this.appConfigService.appMap.get("inlineChangesEnabled", this.appNav.appId());
	}

	set inlineChangesEnabled(b: boolean) {
		this.appConfigService.appMap.set("inlineChangesEnabled", b, this.appNav.appId());
	}

	async goBack() {

		this.isIntoModalPopup = !!await this.modalCtrl.getTop();
		if (this.isIntoModalPopup) {
			await safeAwaitOrDefault(this.modalCtrl.dismiss());
		}
		else {
			this.appNav.pop();
		}
	}

	// controlla se mostrare il pallino di notifiche nell'icona di menu
	hasBadge(): boolean {
		const hasMenuButton = !this.hideNavButtons && !(this.isIntoModalPopup || this.hasPreviousPages);
		return hasMenuButton && this.appConfigService.getUserNotifications().length > 0;
	}
}
