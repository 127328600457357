import { ErrorHandler, Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { NetworkConnectionService } from "./services/network-connection.service";
import { ILogService } from "./services/log.interface";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

	constructor(
		private toastController: ToastController,
		private translate: TranslateService,
		private nc: NetworkConnectionService,
		private logService: ILogService,
	) { }

	async presentToast(opt: {
		message: string;
		duration: number;
		color: "danger" | "warning"
	}) {
		const toast = await this.toastController.create(opt);
		toast.present();
	}

	async handleError(error) {

		let options: {
			message: string;
			duration: number;
			color: "danger" | "warning"
		};

		if (environment.production) {
			let message;
			if (!this.nc.isConnected) {
				message = this.translate.instant("bootstrap.NO_CONNECTION");
			}
			else {
				const isNetworkError = (error && error.rejection && error.rejection.networkError);

				if (isNetworkError) {
					message = this.translate.instant("bootstrap.CONNECTION_ERROR_MESSAGE");
				}
				else {
					message = this.translate.instant("backend_errors.INTERNAL_SERVER_ERROR");
				}
			}

			options = {
				message,
				duration: 5000,
				color: "warning"
			};

			const data = {
				date: Date.now(),
				language: navigator.language,
				platform: navigator.platform,
				userAgent: navigator.userAgent,
				//connectionDownlink: navigator["connection"].downlink,
				//connectionEffectiveType: navigator["connection"].effectiveType
			};
			//const stackTrace = await StackTrace.fromError(error, { offline: true });
			//const body = JSON.stringify({ ts: Date.now(), userAgent, stackTrace });

			//
			// TODO: se non c'è connessione bisognerebbe tenerselo in canna e riprovare dopo
			//

			options.message = JSON.stringify(data) + "\n" + options.message;

			// salva il log a server, senza scatenare errori, solo in produzione
			this.logService.error(options.message, error);
		}
		else {

			options = {
				message: error,
				duration: 2000,
				color: "danger"
			};

			// visualizza sulla console solo se sono in sviluppo
			console.error(error);

			// salva il log a server, senza scatenare errori, solo se non sono in localhost
			if (!environment.backendUrl.startsWith("http://localhost")) {
				this.logService.error(options.message, error);
			}
		}

		// visualizza il messaggio di allerta
		this.presentToast(options);
	}
}
