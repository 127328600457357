import { ChangeDetectorRef, Component, OnDestroy, OnInit, NgZone } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { AppConfigurationService, IStateChangeInfo, STATE_CHANGE_REASON } from "./core/services/app-configuration.service";
import { registerLocaleData } from "@angular/common";
import localeIt from "@angular/common/locales/it";
import { appPages, IPageConfig, IPageSectionConfig } from "./routes";
import { OrderingService } from "./ordering/services/ordering.service";
import { INotificationReceiver, INotificationReceiverData, NotificationService } from "./notification/notification.service";
import { BootstrapService } from "./core/services/bootstrap.service";
import { DataTranslateService } from "./core/services/data-translate.service";
import { AppNavigatorService } from "./core/services/app-navigator.service";
import { HttpParams } from "@angular/common/http";
import { statics } from "./core/statics";
import { App, CORE_PERMISSION, PLATFORM_APP_ID, StorageFile } from "./backend-api";
import { Subscription } from "rxjs";
import { QueryAppService } from "./core/queries/query-app.service";
import { Platform2Service } from "./core/services/platform2.service";

// As the handleOpenURL handler kicks in before the App is started,
// declare the handler function at the top of app.component.ts (outside the class definition)
// to track the passed Url
(window as any).handleOpenURL = (url: string) => {
	(window as any).handleOpenURL_LastURL = url;
};

@Component({
	selector: "app-root",
	templateUrl: "app.component.html",
	styleUrls: []
})
export class AppComponent implements OnInit, OnDestroy, INotificationReceiver {
	public aPages: IPageSectionConfig[] = appPages;
	private _showingWantToExit = false;
	private _stateChangedSubscription: Subscription;

	public CORE_PERMISSION = CORE_PERMISSION;

	constructor(
		private platform2: Platform2Service,
		private splashScreen: SplashScreen,
		public appConfig: AppConfigurationService,
		public dts: DataTranslateService,
		private bootstrapService: BootstrapService,
		public orderingService: OrderingService,
		private cdr: ChangeDetectorRef,
		private notificationService: NotificationService,
		public appNav: AppNavigatorService,
		private ngZone: NgZone,
		private modalCtrl: ModalController,
		private alertCtrl: AlertController,
		private queryApp: QueryAppService
	) {

		// override open handler to navigate on further custom url scheme actions
		(window as any).handleOpenURL = (url: string) => {
			// this context is called outside of angular zone!
			setTimeout(() => {
				// so we need to get back into the zone..
				this.ngZone.run(() => {
					// this is in the zone again..
					this.handleOpenUrl(url);
				});
			}, 0);
		};

		// check if app was opened by custom url scheme
		const lastUrl: string = (window as any).handleOpenURL_LastURL || "";
		if (lastUrl) {
			delete (window as any).handleOpenURL_LastURL;
			this.handleOpenUrl(lastUrl);
		}
	}

	private handleOpenUrl(url: string) {

		let httpParams: HttpParams;

		if (url.indexOf("requestCode=") > 0) {
			httpParams = new HttpParams({ fromString: url.split("?")[1] });

			const requestCode = httpParams.get("requestCode");
			// imposto qui il requestCode, poi quando la app si apre sulla home navighera' alla pagina giusta
			this.appConfig.navigationAfterLink.requestCode = requestCode;
		}
		else if (url.indexOf("onboardingReturnAppId=") > 0) {
			httpParams = new HttpParams({ fromString: url.split("?")[1] });

			const onboardingReturnAppId = httpParams.get("onboardingReturnAppId");
			this.appConfig.navigationAfterLink.onboardingReturnAppId = onboardingReturnAppId;
		}
		else if (url.indexOf("app=") > 0) {
			httpParams = new HttpParams({ fromString: url.split("?")[1] });

			const app = httpParams.get("app");
			this.appConfig.navigationAfterLink.appId = app;
		}
	}

	async ngOnInit(): Promise<void> {

		// aggiunge le lingue supportate (per la formattazione delle date)
		registerLocaleData(localeIt, "it");
		//...............................

		this.dts.translateService.setDefaultLang("it");

		// questa serve per il querymanager
		await this.dts.translateService.use(this.dts.getNavigatorLanguage()).toPromise();

		// inizializza firebase
		await this.appConfig.ensurePlatformReadyAndFirebaseDefaultAppInitialized();

		//this.statusBar.styleDefault();
		this.splashScreen.hide();

		// sottoscrizione alle notifiche runtime
		this.notificationService.registerAsReceiver(this);

		// imposta anche qui la callback dei messaggi
		this._stateChangedSubscription = this.appConfig.addStateChangedSubscription((sci: IStateChangeInfo) => {

			switch (sci.reason) {
				case "refreshAppMenuPages":
					// aggiorna il menu myApps
					for (const sec of appPages) {
						if (sec.group === "myApps") {

							// solo se gestore e se piattaforma
							if (this.appConfig.appsManagedByUser) {
								sec.pages = this.appConfig.appsManagedByUser.filter((app) => {
									return app.id !== this.appNav.appId();
								}).map((app) => {
									return {
										title: this.dts.dataTranslate(app.name),
										url: "home?appId=" + app.id,
										icon: "home-outline",
										ownerFeature: "any",
										iconEnd: this._appHasWarning(app) ? "warning" : undefined
									};
								});
							}
							else {
								sec.pages = []; // svuota la sezione
							}
						}
					}
					break;

			}
		});

		// quando la app torna in foreground faccio un check della versione
		this.platform2.platform.resume.subscribe(async () => {

			// fix dav 180121, se ritornava in background in caricamento perdeva il login
			if (!this.appConfig.mainAppLoaded) { return; }

			const bVersionOK: boolean = await this.bootstrapService.checkVersion();

			// se la versione non è valida ritorno alla bootstrap cosi' viene fuori il messaggio di errore
			if (!bVersionOK) {
				this.appNav.navigateRoot("/bootstrap");
				return;
			}

			// dav 070620, se il gestore si trova sulla degli ordini, cambia app ed in quel momento arriva un ordine
			// l'app veniva riesumata in quel punto senza aggiornare
			//
			// TODO: probabilmente è necessario un check più complesso basato sulle date; se passa più di un giorno riavviare l'app
			//
			this.appConfig.dispatchStateChanged({
				reason: "notification",
				appIdForAccountAndForNotifications: this.appNav.appId()
			});

			setTimeout(() => {

				// se la app e' stata aperta tramite link navigo
				const pageToNav = this.appConfig.getPageToNavigateAfterLink();

				if (pageToNav) {
					this.appNav.navigateForward(pageToNav);
				}

			}, 500);
		});

		// pulsante back di android
		this.platform2.platform.backButton.subscribeWithPriority(99999, async () => {

			// se sto già mostrando "Vuoi Uscire" e clicco ancora sul pulsante indietro esco
			if (this._showingWantToExit) {
				// tslint:disable-next-line: no-string-literal
				navigator["app"].exitApp();
				return;
			}

			try {
				// se c'e' un modal aperto (es. galleria) lo chiudo
				const element = await this.modalCtrl.getTop();
				if (element) {
					element.dismiss();
					return;
				}
			}
			catch (e) { }

			// dav 031120, se ci sono pagine precedenti torna indietro
			if (this.appNav.hasPreviousPages()) {
				this.appNav.pop();
				return;
			}

			const USCITA_DALL_APPLICAZIONE: string = this.dts.translateService.instant("USCITA_DALL_APPLICAZIONE");
			const VUOI_DAVVERO_USCIRE: string = this.dts.translateService.instant("VUOI_DAVVERO_USCIRE");
			const NO: string = this.dts.translateService.instant("NO");
			const SI: string = this.dts.translateService.instant("SI");

			this.alertCtrl.create({
				header: USCITA_DALL_APPLICAZIONE,
				message: VUOI_DAVVERO_USCIRE,
				backdropDismiss: false,
				buttons: [{
					text: NO,
					handler: () => {
						// reset del flag
						this._showingWantToExit = false;
					}
				},
				{
					text: SI,
					handler: () => {
						// reset del flag
						this._showingWantToExit = false;

						// tslint:disable-next-line: no-string-literal
						navigator["app"].exitApp();
					}
				}]
			}).then(lrt => {
				// salvo che sto già mostrando
				this._showingWantToExit = true;

				lrt.present();
			});
		});
	}

	// verifica se l'app è in stato di warning
	private _appHasWarning(app: App): boolean {
		const appHints = app.configurationHints;

		let ret = appHints?.needStripeInfoForCompany || appHints?.missingLandingPageGalleryItem;
		if (!ret && appHints?.storeHints?.length) {
			let storesWarning = false;
			for (const si of appHints.storeHints) {
				const storeHasWarning =
					si.missingCatalogs ||
					si.missingNewsletters ||
					si.missingOrderingDelivery ||
					si.missingOrderingPickupConfiguration ||
					si.missingOrderingShipmentConfiguration ||
					si.missingStoreCalendar ||
					si.storeNotPublished ||
					si.needStripeInfo;

				storesWarning = storesWarning || storeHasWarning;

				if (storesWarning) {
					break;
				}
			}
			ret = ret || storesWarning;
		}

		return ret;
	}

	ngOnDestroy(): void {
		// annullo sottoscrizione
		this.notificationService.unregisterAsReceiver(this);

		// sottoscrizione ai messaggi di cambio stato per app
		if (this._stateChangedSubscription) {
			this._stateChangedSubscription.unsubscribe();
		}
	}

	// Interfaccia NotificationReceiver
	async onNotifications(data: INotificationReceiverData) {
		this.cdr.detectChanges();
	}

	// rinfresco della pagina
	onAskRefresh() {
		this.cdr.detectChanges();
	}

	// conteggio delle notifiche per pagina
	countNotificationsByPageConfig(p: IPageConfig): number {
		return this.appConfig.getUserNotificationsByType(p.notification_feature, p.notification_event).length;
	}

	goToPage(p: IPageConfig) {
		this.appNav.navigateRoot(p.url); // dav 200820, fix passaggio shop1/back/shop2 che non rinfrescava
	}

	// usato nell'interfaccia
	getCurrentStoreInfo(): string {
		const appId = this.appNav.appId();
		const store = this.appConfig.getCurrentStore(appId);
		if (store) {
			const si = this.appConfig.getStoreInfo(store, appId);
			if (si.address && si.city) {
				return si.address + " " + si.city;
			}
		}
		return "";
	}

	// usato nell'interfaccia
	getCurrentStoreName(): string {
		const appId = this.appNav.appId();
		const store = this.appConfig.getCurrentStore(appId);
		if (store) {
			const si = this.appConfig.getStoreInfo(store, appId);
			return si.tname;
		}

		// se non lo trova ritorna questo
		return this.appConfig.appMap.get("currentAppName", appId);
	}

	// per icona warning su menu impostazioni
	settingsPageHasWarningIcon(): boolean {
		const appId = this.appNav.appId();
		const thisApp = this.appConfig.appsManagedByUser?.find((app) => {
			return app.id === appId;
		});
		if (thisApp?.configurationHints) {
			return this._appHasWarning(thisApp);
		}
		return false;
	}

	linkVisible(p: IPageConfig): boolean {

		const appId = this.appNav.appId();

		// l'item di plazar è visibile solo per le sottoapp
		if (p.plazarItem) {
			const vis = this.appNav.appId() !== statics.appId;
			if (vis) {
				const appAlone = this.appConfig.appMap.get("appAlone", appId);
				return !appAlone;
			}
			return false;
		}
		else {
			if (p.isMultiStore) {
				return this.linkVisibleMultiStore(p);
			}
			else {
				return this.appConfig.userCanAccessPage(p, appId) && this.appConfig.isPageVisibleForAppOrStore(p, appId);
			}
		}
	}

	linkVisibleMultiStore(p: IPageConfig): boolean {

		const key = p.url;
		if (p.isMultiStore) {
			if (this.appConfig.loggedUser) {
				return this.appConfig[p.propertyToCheck];
			}
			return false;
		}
		else {
			return this.linkVisible(p);
		}
	}

	isMenuSectionVisible(sec: IPageSectionConfig) {

		let _visible = false;

		// check menu myApps
		if (sec.group === "myApps") {
			if (this.appNav.appId() !== PLATFORM_APP_ID) {
				return false;
			}
		}

		for (const p of sec.pages) {
			_visible = _visible || this.linkVisible(p);
		}

		return _visible;
	}

	async uploadAppIconDone(uploadedFile: StorageFile) {

		const app = await this.appConfig.getOrLoadAndSaveGloballyAppConfiguration(this.appNav.appId(), false);

		app.icon = uploadedFile;

		const savedApp = await this.queryApp.updateAppAsManager(app);

		this.appConfig.appMap.set("currentAppIconStorageFileId", savedApp.icon.id, this.appNav.appId());
	}
}
