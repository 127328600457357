<ion-list *ngIf="installedPrinters">
	<ion-item *ngFor="let item of installedPrinters | keyvalue">
		<ng-container *ngIf="item.value.type==='bluetooth'">
			<ion-icon name="bluetooth" slot="start"></ion-icon>
			<ion-label>{{item.value.name}}</ion-label>
		</ng-container>
		<ng-container *ngIf="item.value.type==='local-server'">
			<ion-icon name="server" slot="start"></ion-icon>
			<ion-label>
				<strong>{{item.value.name}}</strong> ({{item.value.remoteData.type}})
				<br>
				{{item.value.remoteData.server + ":" + item.value.remoteData.port}}
			</ion-label>
			<ion-button *ngIf="editable" (click)="deleteClicked(item.value)" size="small" color="danger" shape="round">
				<ion-icon name="trash"></ion-icon>
			</ion-button>
		</ng-container>
	</ion-item>
</ion-list>
<div *ngIf="editable" class="ion-text-center">
	<app-spinner-button *ngIf="!addPrinterClicked" [autoSpinner]="false" [autoDisabled]="true"
		(click)="addPrinterClicked=true">
		{{'printers.add_printer' | translate}}
	</app-spinner-button>

	<div *ngIf="addPrinterClicked">

		<ion-card [button]="true" (click)="addLSPrinterCB()">
			<ion-card-header>
				<ion-card-title>
					<ion-icon name="server"></ion-icon>
					{{ 'printers.local_server_printer' | translate }}
				</ion-card-title>
			</ion-card-header>
			<ion-card-content>
				<p>
					{{ 'printers.local_server_printer_info1' | translate }}
				</p>
				<p>
					<strong>{{ 'printers.local_server_printer_info2' | translate }}</strong>
				</p>
			</ion-card-content>
		</ion-card>

		<ion-card disabled=true [button]="true" (click)="addBTPrinterCB()">
			<ion-card-header>
				<ion-card-title>
					<ion-icon name="bluetooth"></ion-icon>
					{{ 'printers.bt_printer' | translate }}
				</ion-card-title>
			</ion-card-header>
			<ion-card-content>
				{{ 'printers.bt_printer_info1' | translate }}
			</ion-card-content>
		</ion-card>

	</div>
</div>