import { Query } from "../services/query";
import { ADDRESS_INFO } from "./localization-queries";
import { STORE_INFO } from "./store-queries";

const CONTACT_LIST_ITEM =
	`
	value
	type
	subtype
	social
	position
	favourite
`;

export const APP_INFO =
	`
	id
	androidAppId
	iosAppId
	name {
		...language
	}
	subscriptions {
		stores {
			id
		}
		purchasedFeatures {
			...purchasedFeature
		}
	}
	published
	theme
	icon {
		id
	}
	stores {
		${STORE_INFO}
	}
	company {
		id
		name {
			...language
		}
		logoStorageFile {
			id
		}
		pictureStorageFile {
			id
		}
		contactList {
			items {
				${CONTACT_LIST_ITEM}
			}
		}
		address {
			${ADDRESS_INFO}
		}
		businessInfo {
			stripePlatformCustomer {
				stripeCustomerId
			}
			businessName {
				...language
			}
			vatCode
			fiscalCode
			address {
				${ADDRESS_INFO}
			}
			stripeConnectInfo {
				connectedAccountId
				accountDetailsSubmitted
				chargesEnabled
				payoutsEnabled
				stripeConnectAccountLink
			}
		}
	}
	landingPage {
		feature {
			id
		}
		gallery {
			slideShowSpeed
			items {
				storageFile {
					id
				}
			}
		}
	}
	template
	templateUrl
	supportedLanguages
	defaultLanguage
	subscriptions {
		...subscription
	}
	`;

// dav 110121
export const APP_CONFIGURATION_HINTS_INFO =
	`
	configurationHints {
		needStripeInfoForCompany
		missingLandingPageGalleryItem
		storeHints {
			storeId
			storeNotPublished
			missingStoreCalendar
			needStripeInfo
			missingCatalogs
			missingNewsletters
			missingOrderingDelivery
			missingOrderingShipmentConfiguration
			missingOrderingPickupConfiguration
		}
	}
`;

export const FRAGMENT_PURCHASED_FEATURE =
	`
	fragment purchasedFeature on PurchasedFeature {
		feature {
			id
			type
			name {
				...language
			}
			publicLandingRoute
			privateLandingRoute
			pricingStrategies {
				id
				active
				stripePriceId
				billingScheme
				currency
        		unitAmount
        		discountPercent
				recurring {
					interval
				}
			}
		}
		expiration
		activation
		validity
		quantity
		pricingStrategyId
	}
	`;

export const FRAGMENT_SUBSCRIPTION =
	`
	fragment subscription on Subscription {
		id
		currentPeriodStart
		currentPeriodEnd
		cancelAtPeriodEnd
		cancelAt
		status
		trialStart
		trialEnd
		collectionMethod
		paymentMethod {
			lastDigits
			stripePaymentMethodId
			stripeFingerprint
			type
		}
		stripePlatformCustomer {
			stripeCustomerId
			customerEmail
			customerName
		}
		stores {
			id
		}
		purchasedFeatures {
			...purchasedFeature
		}
	}
	`;

const TAG_INFO =
	`
	id
	name {
		...language
	}
`;

// info dell'app da modificare
const _APP_INFO_ADMIN =
	`
	id
	name {
		...language
	}
	description {
		...language
	}
	published
	theme
	company {
		id
		name {
			...language
		}
	}
	stores {
		id
		name {
			...language
		}
	}
	subscriptions {
		stores {
			id
		}
		purchasedFeatures {
			...purchasedFeature
		}
	}
	expiration
	supportedLanguages
	defaultLanguage
	tags {
		${TAG_INFO}
	}
	template
	templateUrl
	landingPage {
		gallery {
			slideShowSpeed
			items {
				storageFile {
					id
				}
			}
		}
	}
	androidAppId
	iosAppId
	`;


export const GET_APP_SUBSCRIPTIONS = new Query(
	`query _($appId: String) {
		app(id: $appId) {
			subscriptions {
				...subscription
			}
		}
	}
	${FRAGMENT_PURCHASED_FEATURE}
	${FRAGMENT_SUBSCRIPTION}
	`
);

export const GET_APP = new Query(
	`query _($appId: String) {
		app(id: $appId) {
			${APP_INFO}
		}
	}
	${FRAGMENT_PURCHASED_FEATURE}
	${FRAGMENT_SUBSCRIPTION}
	`
);

// // dav 110121
// export const GET_APP_CONFIGURATION_HINTS = new Query(
// 	`query _($appId: String) {
// 		app(id: $appId) {
// 			id
// 			${APP_CONFIGURATION_HINTS_INFO}
// 		}
// 	}
// 	`
// );

export const GET_APP_ADMIN = new Query(
	`
	query _($appId: String) {
		app(id: $appId) {
			${_APP_INFO_ADMIN}
		}
	}
	${FRAGMENT_PURCHASED_FEATURE}
	`
);

export const IS_APP_NAME_AVAILABLE = new Query(
	`
	query _($name: String) {
		isAppNameAvailable(name:$name)
	}
	`
);

export const UPDATE_APP = new Query(
	`
	mutation _($app: AppInput!) {
		updateApp(app: $app) {
			${_APP_INFO_ADMIN}
		}
	}
	${FRAGMENT_PURCHASED_FEATURE}
	`
);

export const UPDATE_APP_MANAGER = new Query(
	`
	mutation _($app: AppInput!) {
		updateApp(app: $app) {
			${APP_INFO}
		}
	}
	${FRAGMENT_PURCHASED_FEATURE}
	${FRAGMENT_SUBSCRIPTION}
	`
);

export const IS_PLATFORM_APP = new Query(
	`
	query _($appId: String) {
		isPlatformApp(appId: $appId)
	}
	`
);

export const CREATE_APP = new Query(
	`
	mutation _($app: AppInput!) {
		createApp(app: $app) {
			${_APP_INFO_ADMIN}
		}
	}
	${FRAGMENT_PURCHASED_FEATURE}
	`
);

export const SEARCH_APPS_ADMIN = new Query(
	`
	query _($searchString: String) {
		searchApps(searchString: $searchString) {
			id
			name {...language}
			description {...language}
		}
	}
	`
);

export const SEARCH_APP_BASIC_INFO =
	`
	id
	name {
		...language
	}
	icon {
		id
	}
	stores {
		${STORE_INFO}
	}
	company {
		id
		name {
			...language
		}
		logoStorageFile {
			id
		}
		pictureStorageFile {
			id
		}
	}						
	defaultLanguage
	tags {
		${TAG_INFO}
	}
`;

export const SEARCH_APPS_ALL = new Query(
	`
	query _($searchString: String, $excludeApps: [String], $tags: [String]) {
		searchApps(searchString: $searchString, excludeApps: $excludeApps, tags: $tags) {
			${SEARCH_APP_BASIC_INFO}
		}
	}
	`
);

// APP QUERIES
export const DELETE_APP = new Query(
	`
	mutation _($appId: String) {
		deleteApp(appId: $appId) {
			id
		}
	}
	`
);

export const GET_APPS = new Query(
	`
	query _($filterIds: [String]) {
		apps(filterIds: $filterIds) {
			${SEARCH_APP_BASIC_INFO}
		}
	}
	`
);

export const GET_CURRENT_USER_FAVOURITE_APPS = new Query(
	`
	query _ {
		userFavouriteApps() {
			${SEARCH_APP_BASIC_INFO}
		}
	}
	`
);

export const GET_FEATURE_CONFIGURATION = new Query(
	`
	query _($appId: String, $feature: FEATURE, $storeId: String, $strictMode: Boolean) {
		getFeatureConfiguration(appId: $appId, feature: $feature, storeId: $storeId, strictMode: $strictMode)
	}
	`
);

export const SAVE_FEATURE_CONFIGURATION = new Query(
	`
	mutation _($appId: String, $feature: FEATURE, $storeId: String, $featureConfigurationJson: String, $strictMode: Boolean) {
		saveFeatureConfiguration(appId: $appId, feature: $feature, storeId: $storeId, featureConfigurationJson: $featureConfigurationJson, strictMode: $strictMode)
	}
	`
);

export const REMOVE_FEATURE_CONFIGURATION = new Query(
	`
	mutation _($appId: String, $feature: FEATURE, $storeId: String, $strictMode: Boolean) {
		removeFeatureConfiguration(appId: $appId, feature: $feature, storeId: $storeId, strictMode: $strictMode)
	}
	`
);
