/**
 * Modulo che contiene le cosa statiche, viene incluso in app-configuration-service
 * Viene mantenuto perchè viene incluso in graphql.module.ts
 * Quando possibile usare il servizio
 */
import { appId } from "./app-id";

export let statics: {
	appId: string;	// app corrente, in plazar è quello della piattaforma
	appVersion: string;
	jwt: string;
	userId?: string; // aggiunto per utilizzo di logService
} = {
	appId,
	appVersion: null, // inizializzato dopo il login
	jwt: null, // inizializzato dopo il login
	userId: null
};
