import { Query } from "../services/query";

export const SCHEDULED_CALENDAR =
	`
	scheduleCalendar {
		weekSchedule {
			monday {
				from
				to
			}
			tuesday {
				from
				to
			}
			wednesday {
				from
				to
			}
			thursday {
				from
				to
			}
			friday {
				from
				to
			}
			saturday {
				from
				to
			}
			sunday {
				from
				to
			}
		}
		extraSchedules {
			day
			timeRanges {
				from
				to
			}
		}
		cancelledSchedules {
			day
			timeRanges {
				from
				to
			}
		}
		timezone
	}
	`;

export const IS_SCHEDULED = new Query(
	`
	query _($scheduleCalendar: ScheduleCalendarInput, $timestamp:Float) {
		isScheduled(scheduleCalendar:$scheduleCalendar, timestamp:$timestamp)
	}
	`
);

export const GET_DAY_SCHEDULE = new Query(
	`
	query _($scheduleCalendar: ScheduleCalendarInput, $timestamp:Float) {
		getDaySchedule(scheduleCalendar:$scheduleCalendar, timestamp:$timestamp) {
			from
			to
		}
	}
	`
);

export const GET_EXTRA_SCHEDULE = new Query(
	`
	query _($scheduleCalendar: ScheduleCalendarInput, $timestamp:Float) {
		getExtraSchedules(scheduleCalendar:$scheduleCalendar, timestamp:$timestamp) {
			timeRanges {
				from
				to
			}
			day
		}
	}
	`
);

export const GET_CANCELLED_SCHEDULE = new Query(
	`
	query _($scheduleCalendar: ScheduleCalendarInput, $timestamp:Float) {
		getCancelledSchedules(scheduleCalendar:$scheduleCalendar, timestamp:$timestamp) {
			timeRanges {
				from
				to
			}
			day
		}
	}
	`
);

export const CALCULATE_SCHEDULED_DATE = new Query(
	`
	query _($scheduleCalendar: ScheduleCalendarInput, $timestamp:Float, $amount:CalendarAmountInput) {
		calculateScheduledDate(scheduleCalendar:$scheduleCalendar, timestamp:$timestamp, amount:$amount)
	}
	`
);
