import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LogService } from "./log.service";
import { ILogService } from "./log.interface";
import { AppConfigurationService } from "./app-configuration.service";

@NgModule({
	declarations: [],
	imports: [
		CommonModule
	],
	providers: [
		{
			provide: ILogService,
			useClass: LogService
		},
		{
			provide: AppConfigurationService,
			useClass: AppConfigurationService
		}
	]
})
export class LoggingModule { }
