import {tLogLevel} from "./log.service";

export abstract class ILogService {
	abstract async info(message: string, skipRemote?: boolean);
	abstract async debug(message: string, skipRemote?: boolean);
	abstract async verbose(message: string, data: any, skipRemote?: boolean);
	abstract async error(message: string, error?, skipRemote?: boolean);
	abstract async warn(message: string, skipRemote?: boolean);
	abstract async log(message: string, level: tLogLevel, skipRemote?: boolean, data?: any);
}
