import { Query } from "../../core/services/query";
import { DELIVERYADDRESSES_INFO } from "src/app/core/queries/localization-queries";
import { APP_CONFIGURATION_HINTS_INFO, FRAGMENT_PURCHASED_FEATURE, FRAGMENT_SUBSCRIPTION } from "../../core/queries/app-queries";

export const ENROLLEDAPPS_INFO =
	`
	enrolledApps {
		id
		appId
		favouriteStoreId
		appPermissions
		storePermissions {
			storeId
			permissions
		}
		notifications {
			feature
			event
			reference
			timestamp
		}
		favourite
	}
	`;

export const USER_BASE_INFO =
	`
	id
	email
	firstName
	lastName
	telephoneNumber
	locale
	username
	systemPermissions
	provider
	stripePlatformCustomer {
		stripeCustomerId
	}
	`;

// ruoli amministrativi che consentono la creazione dei permessi lato client
export const CLEARANCES_INFO =
	`
	clearances {
		id
		role {
			id
			name {
				...language
			}
			code
			appId
			storeId
			permissions {
				id
				name {
					...language
				}
				code
			}
		}
		appId
		storeIds
	}
	`;

const USER_BASE_INFO_AND_CLEARANCES =
	`
	${USER_BASE_INFO}
	${CLEARANCES_INFO}
	`;

// dav 080420, info utente per gestione amministrativa
export const USER_INFO_ADMIN =
	`
	${USER_BASE_INFO_AND_CLEARANCES}
	${ENROLLEDAPPS_INFO}
	${DELIVERYADDRESSES_INFO}
	emailVerificationPending
	emailVerificationResendDate
	`;

export const USER_INFO =
	`
	${USER_BASE_INFO}
	${ENROLLEDAPPS_INFO}
	${DELIVERYADDRESSES_INFO}
	`;

export const GET_USER_DATA = new Query(
	`
	{
		user {
			${USER_INFO}
		}
	}
	`
);

export const UPDATE_USER_DATA = new Query(
	`
	mutation _($user: UserInput!) {
		updateUserData(user: $user) {
			${USER_INFO}
		}
	}
	`
);

export const CHANGE_PASSWORD = new Query(
	`
	mutation _($oldPassword: String!, $newPassword: String!) {
		changeUserPassword(oldPassword: $oldPassword, newPassword: $newPassword)
	}
	`
);

export const REQUEST_CREDENTIALS = new Query(
	`
	mutation _($email: String!) {
		requestUserPasswordReset(email: $email)
	}
	`
);

export const GET_USER_STORES = new Query(
	`
	query _($appId: String, $features: [FEATURE], $matchAllFeatures: Boolean, $permissions: [String], $matchAllPermissions: Boolean) {
		getUserStores(appId: $appId, features: $features, matchAllFeatures: $matchAllFeatures, permissions: $permissions, matchAllPermissions: $matchAllPermissions) {
			id
			appId
			name {
				...language
			}
			description {
				...language
			}
		}
	}
	`
);

export const GET_APPS_MANAGED_BY_USER = new Query(
	`
	query _($appId: String) {
		findConnectedAppsByUser(appId: $appId, permissions: ["manage_app"], includeSelf: true) {
			edges {
				node {
					id
					name {
						...language
					}
					icon {
						id
					}
					company {
						id
						name {
							...language
						}
						address {
							name
							street
							number
							city {
								name {
									...language
								}
								province {
									abbreviation
								}
							}
							zip
						}
						businessInfo {
							vatCode
							fiscalCode
						}
					}
					stores {
						id
						name {
							...language
						}
						address {
							city {
								name {
									...language
								}
								province {
									abbreviation
								}
							}
						}
					}
					supportedLanguages
					defaultLanguage
					landingPage {
						gallery {
							items {
								storageFile {
									id
								}
							}
						}
					}
					subscriptions {
						...subscription
					}
					${APP_CONFIGURATION_HINTS_INFO}
				}
			}
		}
	}
	${FRAGMENT_SUBSCRIPTION}
	${FRAGMENT_PURCHASED_FEATURE}
	`
);

export const ENROLL_USER_IN_APP = new Query(
	`
	mutation _($appId: String!, $sendWelcomeMail: Boolean) {
		enrollUserInApp(appId: $appId, sendWelcomeMail: $sendWelcomeMail) {
			${USER_INFO}
		}
	  }
	`
);

export const CANCEL_SUBSCRIPTION = new Query(
	`
	mutation _($appId: String, $subscriptionId: String) {
		cancelSubscription(appId: $appId, subscriptionId: $subscriptionId) {
			...subscription
		}
	}
	${FRAGMENT_SUBSCRIPTION}
	${FRAGMENT_PURCHASED_FEATURE}
	`
);

export const REACTIVATE_SUBSCRIPTION = new Query(
	`
	mutation _($appId: String, $subscriptionId: String) {
		reactivateSubscription(appId: $appId, subscriptionId: $subscriptionId) {
			...subscription
		}
	}
	${FRAGMENT_SUBSCRIPTION}
	${FRAGMENT_PURCHASED_FEATURE}
	`
);

export const SET_SUBSCRIPTION_COLLECTION_METHOD = new Query(
	`
	mutation _($appId: String, $subscriptionId: String, $collectionMethod: SUBSCRIPTION_COLLECTION_METHOD, $stripePaymentMethodId: String) {
		setSubscriptionCollectionMethod(appId: $appId, subscriptionId: $subscriptionId, collectionMethod: $collectionMethod, stripePaymentMethodId: $stripePaymentMethodId) {
			...subscription
		}
	}
	${FRAGMENT_SUBSCRIPTION}
	${FRAGMENT_PURCHASED_FEATURE}
	`
);

export const GET_SUBSCRIPTION_INVOICES = new Query(
	`
	query _($appId: String, $subscriptionId: String, $limit: Float) {
		getSubscriptionInvoices(appId: $appId, subscriptionId: $subscriptionId, limit: $limit) {
			invoiceNumber
			invoicePdf
			stripeHostedInvoiceUrl
			stripeInvoiceId
			total
			currency
			status
			periodStart
			periodEnd
			creationDate
		}
	}
	`
);

export const GET_PAYMENT_CLAIMS_WITH_FEE_IN_INVOICE = new Query(
	`
	query _($appId: String, $invoiceNumber: String) {
		getPaymentClaimsWithFeeInInvoice(appId: $appId, invoiceNumber: $invoiceNumber) {
			paymentDate
			amount
			feeAmount
			currency
		}
	}
	`
);
