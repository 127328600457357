import {format, getDay, parse} from "date-fns";
import {FormGroup, ValidationErrors} from "@angular/forms";
import {
	Booking,
	BOOKING_STATUS,
	DELIVERY_PICKUP_STORE_TYPE,
	DELIVERY_SHIPMENT_TYPE,
	DeliveryShipmentBookingCustomData,
	Order,
	PaginatedResponse,
	Service,
	Store,
	TimeRange
} from "../backend-api";
import {formatDate} from "@angular/common";
import {TranslateService} from "@ngx-translate/core";

export interface AllValidationErrors {
	control_name: string;
	error_name: string;
	error_value: any;
}

//
// Clona un oggetto o array nel modo corretto
//
export function cloneObject(o: any): any {
	if (o) {
		if (Array.isArray(o)) {
			return Array.from(o);
		}
		else {
			return Object.assign({}, o);
		}
	}
	return o;
}

/**
 * Ritorna la rappresentazione esadecimale a 2 caratteri di un numero decimale
 */
export function dec2hex(n: number) {
	return Number(n).toString(16).padStart(2, "0");
}

/**
 * Converts a string to a unique 32-bit int
 * @param s string to hash
 * @returns 32-bit int
 */
export function hashString(s: string): number {
	let hash = 0, char: number;

	if (s.length === 0) {
		return hash;
	}
	for (let i = 0; i < s.length; i++) {
		char = s.charCodeAt(i);
		// tslint:disable-next-line
		hash = (hash << 5) - hash + char;
		// tslint:disable-next-line
		hash = hash & hash;
	}
	return hash;
}

/**
 * Crea la rappresentazione stringa esadecimale di un url
 */
export function url2hex(s: string): string {
	let ret = "";

	for (let i = 0; i < s.length; i++) {
		const c = s.charAt(i);
		const charCode = s.charCodeAt(i);

		if (c === "/") {
			ret += "-";
		}
		else {
			ret += dec2hex(charCode);
		}
	}
	return ret;
}

export function dateToString(d: Date): string {
	return format(d, "yyyy-MM-dd");
}

export function stringToDate(s: string, sFormat: string): Date {
	return parse(s, sFormat, new Date());
}

export function getDayNameOfWeek(): string {

	const dayOfWeek: number = getDay(new Date());
	let day: string;

	switch (dayOfWeek) {
		case 1:
			day = "monday";
			break;
		case 2:
			day = "tuesday";
			break;
		case 3:
			day = "wednesday";
			break;
		case 4:
			day = "thursday";
			break;
		case 5:
			day = "friday";
			break;
		case 6:
			day = "saturday";
			break;
		case 7:
			day = "sunday";
			break;
		default:
			throw new Error("Wrong day of week: " + dayOfWeek);
	}

	return day;
}

export function isStoreClosedOnDay(store: Store, sDay: string): boolean {

	const oSchedule: TimeRange[] = store.scheduleCalendar.weekSchedule[sDay];

	if (oSchedule && oSchedule.length > 0) {
		return false;
	}
	else {
		return true;
	}
}

export function getFormValidationErrors(fm: FormGroup): AllValidationErrors[] {

	const controls = fm.controls;
	const errors: AllValidationErrors[] = [];

	Object.keys(controls).forEach(key => {

		const controlErrors: ValidationErrors = controls[key].errors;

		if (controlErrors) {
			Object.keys(controlErrors).forEach(keyError => {
				errors.push({
					control_name: key,
					error_name: keyError,
					error_value: controlErrors[keyError]
				});
			});
		}
	});

	return errors;
}

// utility per gestione più maneggevole degli await
// uso: const[error, ret] = await safeAwait(<func>);
export function safeAwaitArray(promise: Promise<any>) {
	return promise.then(data => {
		return [null, data];
	}).catch(error => {
		return [error]; // cosi non sporco il dato
	});
}

// Ritorna il dato o def se va in errore
export function safeAwaitOrDefault(promise: Promise<any>, def?: any) {
	return promise.then(data => {
		return data;
	}).catch(error => {
		return def; // cosi non sporco il dato
	});
}

// comoda funzione di timeout in versione Promise :-)
export function promiseTimeout(t: number): Promise<void> {
	return new Promise((resolve) => {
		setTimeout((r) => {
			r();
		}, t, resolve);
	});
}

export function getOrderDeliveryDateTime(order: Order, translateService: TranslateService): string {
	const booking: Booking = getBookingByType(order, [DELIVERY_SHIPMENT_TYPE, DELIVERY_PICKUP_STORE_TYPE]);
	if (booking) {
		this.service = getBookingService(this.deliveryBooking);
		if (booking.bookedTimeSlot.startDate && booking.bookedTimeSlot.endDate) {
			return formatDate(booking.bookedTimeSlot.startDate, "longDate", translateService.currentLang) +
				", " +
				translateService.instant("BETWEEN_TIME") +
				" " +
				formatDate(booking.bookedTimeSlot.startDate, "shortTime", translateService.currentLang) +
				" " +
				translateService.instant("AND_TIME") +
				" " +
				formatDate(booking.bookedTimeSlot.endDate, "shortTime", translateService.currentLang);
		}
		else if (booking.bookedTimeSlot.startDate) {
			return formatDate(booking.bookedTimeSlot.startDate, "longDate", translateService.currentLang) +
				" " +
				translateService.instant("AT_TIME") +
				" " +
				formatDate(booking.bookedTimeSlot.startDate, "shortTime", translateService.currentLang);
		}
		else if (booking.bookedTimeSlot.endDate) {
			return formatDate(booking.bookedTimeSlot.endDate, "longDate", translateService.currentLang) +
				" " +
				translateService.instant("WITHIN_TIME") +
				" " +
				formatDate(booking.bookedTimeSlot.endDate, "shortTime", translateService.currentLang);
		}
	}
	else {
		return null;
	}
}

export function getOrderDeliveryTimeInfo(order: Order, translateService: TranslateService, dateFormat = "d MMM", timeFormat = "HH:mm"): string {
	const booking: Booking = getBookingByType(order, [DELIVERY_SHIPMENT_TYPE, DELIVERY_PICKUP_STORE_TYPE]);
	if (booking) {
		if (booking.bookedTimeSlot.startDate && booking.bookedTimeSlot.endDate) {
			return formatDate(booking.bookedTimeSlot.startDate, dateFormat, translateService.currentLang) +
				", " +
				formatDate(booking.bookedTimeSlot.startDate, timeFormat, translateService.currentLang) +
				"-" +
				formatDate(booking.bookedTimeSlot.endDate, timeFormat, translateService.currentLang);
		}
		else if (booking.bookedTimeSlot.startDate) {
			return formatDate(booking.bookedTimeSlot.startDate, dateFormat, translateService.currentLang) +
				" " +
				translateService.instant("AT_TIME") +
				" " +
				formatDate(booking.bookedTimeSlot.startDate, timeFormat, translateService.currentLang);
		}
		else if (booking.bookedTimeSlot.endDate) {
			return formatDate(booking.bookedTimeSlot.endDate, dateFormat, translateService.currentLang) +
				" " +
				translateService.instant("WITHIN_TIME") +
				" " +
				formatDate(booking.bookedTimeSlot.endDate, timeFormat, translateService.currentLang);
		}
	}
	else {
		return null;
	}
}

export function getBookingService(booking: Booking): Service {
	if (booking) {
		if (booking.status === BOOKING_STATUS.confirmed && booking.bookedService) {
			return booking.bookedService;
		}
		else {
			return booking.service;
		}
	}
}

/**
 * Gets from the provided order the first booking found of the type specified.
 * If the type specified is an array, selects the first booking available that
 * matches one of the types provided.
 */
export function getBookingByType(order: Partial<Order>, type: string | string[]): Booking {
	if (order && type && order.bookings && order.bookings.length) {
		for (const b of order.bookings) {
			const service = getBookingService(b);
			if (typeof type === "string") {
				if (service.serviceType === type) {
					return b;
				}
			}
			//type is an array, pick the first available
			else if (type.includes(service.serviceType)) {
				return b;
			}
		}
	}
}

export function getOrderDeliveryPlaceInfo(order: Order, translateService: TranslateService): string {
	const booking: Booking = getBookingByType(order, [DELIVERY_SHIPMENT_TYPE, DELIVERY_PICKUP_STORE_TYPE]);
	if (booking) {
		const service = getBookingService(booking);
		const deliveryInformation = JSON.parse(booking.customDataJson) as DeliveryShipmentBookingCustomData;
		let placeShortDescription = "";
		if (service.serviceType === DELIVERY_PICKUP_STORE_TYPE) {
			placeShortDescription = translateService.instant("ordering.PICKUP_IN_STORE");
		}
		else if (service.serviceType === DELIVERY_SHIPMENT_TYPE) {
			const city = deliveryInformation.address.city;
			const cityName = city.name;
			const cityNameAsString = cityName[translateService.currentLang] ? cityName[translateService.currentLang] as string : cityName.invariant;
			placeShortDescription = cityNameAsString + " (" + deliveryInformation.address.zip + ")";
		}

		return placeShortDescription;
	}
	else {
		return null;
	}
}

// arrotonda il numero a 2 decimali
export function round3(num: number): number {
	return Math.round(num * 1000 + Number.EPSILON) / 1000;
}

// arrotonda il numero a 2 decimali
export function round2(num: number): number {
	return Math.round(num * 100 + Number.EPSILON) / 100;
}

// arrotonda il numero a 1 decimale
export function round1(num: number): number {
	return Math.round(num * 10 + Number.EPSILON) / 10;
}

// capitalizza la prima lettera della stringa
export function capitalizeFirstLetter(s: string): string {
	return s && s[0].toUpperCase() + (s.slice(1) || "").toLowerCase();
}

// trasforma un array semplice in paginatedResponse
export function toPaginatedResponse(ar: any[]): PaginatedResponse<any> {
	return {
		edges: ar.map((e, i) => {
			return {
				node: e,
				cursor: i + ""
			}
		}),
		pageInfo: {
			hasMoreData: false
		}
	}
}
