import { Query } from "../services/query";

export const UPLOAD_FILE = new Query(
	`
	mutation _($storageFile: StorageFileInput!) {
		storageFileUpload(storageFile:$storageFile) {
			id
			creationDate
			size
		}
	}
	`
);
