import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { PrintersComponent } from "./components/printers/printers.component";
import { PrinterComponent } from "./components/printer/printer.component";
import { AddBtPrinterPage } from "./pages/add-bt-printer/add-bt-printer.page";
import { AppLoadedGuard } from "../core/guards/app-loaded.guard";
import { AddLsPrinterPage } from "./pages/add-ls-printer/add-ls-printer.page";
import { RouterModule, Routes } from "@angular/router";
import { CoreModule } from "../core/core.module";

const routes: Routes = [
	{
		path: "add-bt-printer",
		component: AddBtPrinterPage,
		canActivate: [AppLoadedGuard]
	},
	{
		path: "add-ls-printer",
		component: AddLsPrinterPage,
		canActivate: [AppLoadedGuard]
	}
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		RouterModule.forChild(routes),
		TranslateModule,
		CoreModule,
		FontAwesomeModule,
		ReactiveFormsModule
	],
	declarations: [
		PrintersComponent,
		PrinterComponent,
		AddLsPrinterPage,
		AddBtPrinterPage
	],
	exports: [
		PrintersComponent,
		PrinterComponent
	]
})
export class PrinterModule { }
