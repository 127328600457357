import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { File, FileEntry, Metadata } from "@ionic-native/file/ngx";
import { fromEvent } from "rxjs";
import { first } from "rxjs/operators";
import * as utils from "../../utils";
import { promiseTimeout } from "../../utils";
import { WebView } from "@ionic-native/ionic-webview/ngx";
import { Platform2Service } from "../../services/platform2.service";

const config: {
	cacheEnabled: boolean;
	cacheFolderName: string;
	cacheMaxAge: number; // giorni o -1 per disattivarla
	imageReturnType: "base64" | "uri"
	base64ImageMimeType: "*/*" | "image/*"
} = {
	cacheEnabled: true,
	cacheFolderName: "plazar-image-cache",
	cacheMaxAge: 30,
	imageReturnType: "uri",
	base64ImageMimeType: "*/*"
};

declare const Ionic: any;

@Injectable({
	providedIn: "root"
})
export class CachedImageService {

	// per il controllo veloce dell'esistenza dei files in cache
	private _cacheKeys: {
		[key: string]: boolean;
	} = {};

	private _cacheAvailable = false;
	private _loaded = false;
	private _cacheFolder = undefined;

	constructor(
		private file: File,
		private http: HttpClient,
		public platform2: Platform2Service,
		private webview: WebView
	) {

		// dav 060620, sicurezza
		platform2.ready().then(() => {
			if (!platform2.is("cordova")) {
				// nothing to do
				this._loaded = true;
			}
			else {
				fromEvent(document, "deviceready")
					.pipe(first())
					.subscribe(async res => {
						if (File.installed()) {
							this._cacheAvailable = await this._init();
							// this.loaded --> impostata dalla initCache
						}
						else {
							// cache non disponibile
							this._loaded = true;
						}
					});
			}
		});

	}

	// dav 220220
	async ready(): Promise<void> {
		// se è pronta risolve subito
		if (this._loaded) {
			return;
		}

		// ciclone simpatico by dav :-)
		for (let i = 0; i < 100; i++) {
			await promiseTimeout(100);
			if (this._loaded) {
				return;
			}
		}
	}

	// funzione per la gestione della cache
	async getImageWithCacheManagement(uri: string): Promise<string> {

		if (this.cacheAvailable()) {

			// trova una chiave univoca
			const cacheKey = utils.hashString(uri).toString(16);

			// vede se esiste nella cache
			const existsInCache = await this.existsInCache(cacheKey);
			// esiste
			if (existsInCache === true) {

				// se esiste lo recupera
				const source = await this.getImageFromCache(cacheKey);
				if (source) {
					return source;
				}

				// non esiste
			} else if (existsInCache === false) {

				// altrimenti salva e recupera
				const saveRet = await this.saveInCache(uri, cacheKey);
				if (saveRet) {
					const source = await this.getImageFromCache(cacheKey);
					if (source) {
						return source;
					}
				}
			}
		}

		// default
		return uri;
	}

	private async _init(): Promise<boolean> {

		try {
			// se la cache è abilitata
			if (config.cacheEnabled) {

				let dirExists = false;
				try {
					dirExists = await this.file.checkDir(this.file.dataDirectory, config.cacheFolderName);
				}
				catch (e) { }

				// se la cartella cache non esiste la crea
				if (!dirExists) {
					await this.file.createDir(this.file.dataDirectory, config.cacheFolderName, false);
				}

				// imposta la cartella base
				this._cacheFolder = this.file.dataDirectory + "/" + config.cacheFolderName;
			}

			this._loaded = true;

			// variabile globale
			return config.cacheEnabled;
		}
		catch (e) {
			this._loaded = true;
			return false;
		}
	}

	// salva nella cache un certo url con una certa chiave, ritorna null in caso di errore
	async saveInCache(url: string, key: string): Promise<boolean | null> {

		if (this.cacheAvailable()) {

			try {
				const data: Blob = await this.http.get(url, {
					responseType: "blob"
				}).toPromise();

				await this.file.writeFile(this._cacheFolder, key, data, { replace: true });

				return true;
			}
			catch (e) {
				return null;
			}
		}
		return false;
	}

	// ritorna true/false/null se errore 
	async existsInCache(key: string): Promise<boolean> {

		if (this.cacheAvailable()) {

			// controllo veloce dell'elemento nella cache
			if (this._cacheKeys[key] === true) {
				return true;
			}

			try {
				const fe = await this.file.resolveLocalFilesystemUrl(this._cacheFolder + "/" + key);

				// se il tempo cache è stato dichiarato
				if (config.cacheMaxAge > -1) {
					const metadata = await this.getFileMetadataFromEntry(fe);
					if (metadata) {
						const timeDiff = (Date.now() - metadata.modificationTime.getTime()) / 8.64e+7; // da millisecondi a giorni
						// tempo cache scaduto, rimuovo il file
						if (timeDiff > config.cacheMaxAge) {
							await this.removeCacheEntry(key);
							return false;
						}
					}
				}

				this._cacheKeys[key] = true;
				return true;
			}
			catch (e) {
				delete this._cacheKeys[key]; // nel caso lo elimino dall'indice
				return false;
			}
		}
		return false;
	}

	async getImageFromCache(key: string): Promise<string | null> {

		if (this.cacheAvailable()) {
			try {

				/**
				 * Base64
				 */
				if (config.imageReturnType === "base64") {

					let dataURL = await utils.safeAwaitOrDefault(this.file.readAsDataURL(this._cacheFolder, key));
					if (dataURL) {

						const mime = config.base64ImageMimeType;

						// mobile
						dataURL = dataURL.replace("data:null", "data:" + mime);

						// FF
						dataURL = dataURL.replace("data:application/octet-stream", "data:" + mime);

						return dataURL;
					}
				}
				/**
				 * URI
				 */
				else if (config.imageReturnType === "uri") {

					const fe: FileEntry = await utils.safeAwaitOrDefault(this.file.resolveLocalFilesystemUrl(this._cacheFolder + "/" + key));
					if (fe) {

						// now check if iOS device & using WKWebView Engine.
						// in this case only the tempDirectory is accessible,
						// therefore the file needs to be copied into that directory first!
						//if (this._isIonicWKWebView()) {
						return this._normalizeUrl(fe);
						//}

						//if (fe.nativeURL) {
						// return native path
						//	return fe.nativeURL;
						//}
					}

				}
			}
			catch (e) {
				return null;
			}
		}
		return null;
	}

	private _normalizeUrl(fileEntry: FileEntry): string {
		// use new webview function to do the trick
		if (this.webview) {
			return this.webview.convertFileSrc(fileEntry.nativeURL);
		}
		return fileEntry.nativeURL;
	}

	//private _isWKWebView(): boolean {
	//	return (this.platform.is("ios") && (window as any).webkit && (window as any).webkit.messageHandlers);
	//}

	private _isIonicWKWebView(): boolean {
		return (
			//  Important: isWKWebview && isIonicWKWebview must be mutually excluse.
			//  Otherwise the logic for copying to tmp under IOS will fail.
			(this.platform2.is("android") && this.webview) ||
			(this.platform2.is("android")) && (location.host === "localhost:8080") ||
			(window as any).LiveReload
		);
	}

	/**
	 * Legge i metadati di un certo file data la chiave
	 */
	async getFileMetadata(key: string): Promise<Metadata | null> {

		if (this.cacheAvailable()) {
			try {
				const fe = await this.file.resolveLocalFilesystemUrl(this._cacheFolder + "/" + key) as FileEntry;
				return this.getFileMetadataFromEntry(fe);
			}
			catch (e) {
				return null;
			}
		}
		return null;
	}

	/**
	 * Legge i metadati di un certo file data l'entry
	 */
	async getFileMetadataFromEntry(fe: /*Entry*/ any): Promise<Metadata | null> {

		if (this.cacheAvailable()) {
			try {
				return await new Promise<any>((resolve, reject) => fe.getMetadata(resolve, reject));
			}
			catch (e) {
				return null;
			}
		}
		return null;
	}

	async removeCacheEntry(key: string): Promise<boolean> {

		if (this.cacheAvailable()) {
			delete this._cacheKeys[key]; // nel caso lo elimino dall'indice
			await this.file.removeFile(this._cacheFolder, key);
			return true;
		}
		return false;
	}

	async clearCache(): Promise<boolean> {

		if (this.cacheAvailable()) {
			this._cacheKeys = {}; // svuoto l'indice

			// cancella la cartella e quello che c'è dentro
			await this.file.removeRecursively(this.file.dataDirectory, config.cacheFolderName);

			// la ricrea al volo
			await this.file.createDir(this.file.dataDirectory, config.cacheFolderName, true);
			return true;
		}
		return false;
	}

	cacheAvailable(): boolean {

		// se non è pronto muore
		this._checkIfLoadedOrDie();

		return this._cacheAvailable && config.cacheEnabled;
	}

	private _checkIfLoadedOrDie(): void {
		if (!this._loaded) {
			throw Error("CachedImageService is not ready!!! Use .ready() method");
		}
	}

}
