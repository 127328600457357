import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

@NgModule({
	declarations: [],
	imports: [
		CommonModule
	],
	providers: [
	]
})
export class NotificationModule {
}
