import {AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2} from "@angular/core";
import {DomController} from "@ionic/angular";
import {Subscription} from "rxjs";

@Directive({
	selector: "[appScrollVanish]"
})
export class ScrollVanishDirective implements OnInit, AfterViewInit, OnDestroy {
	@Input("appScrollVanish") scrollArea;
	@Input() collapsedClass;
	@Input() expandedClass;
	@Input() collapsedElementId;
	@Input() expandedElementId;

	//example on https://gist.github.com/emanuer/f2a14897bf3084e5e0ce0a04c240a1c6
	//@ViewChild("expandedElement", {static: false}) expandedElement: ElementRef;
	//@ViewChild("collapsedElement", {static: false}) collapsedElement: ElementRef;
	//@ViewChildren('fa1View') fa1Views: QueryList<ElementRef>  //for list of elements

	private hidden = false;
	private triggerDistance = 20;

	private expandedElementHeigth: number;
	private collapsedElementHeigth: number;
	private subscription: Subscription;

	constructor(
		private element: ElementRef,
		private renderer: Renderer2,
		private domCtrl: DomController
	) {
	}

	ngOnInit() {
		this.initStyles();
		this.subscription = this.scrollArea.ionScroll.subscribe(scrollEvent => {
			const delta = scrollEvent.detail.deltaY;
			this.expandedElementHeigth = document.getElementById(this.expandedElementId).offsetHeight; //this.expandedElement.nativeElement.style.height;    // set height
			// if (scrollEvent.detail.currentY === 0 && this.hidden) {
			// 	this.show();
			// }
			// else if (!this.hidden && delta > this.triggerDistance) {
			// 	this.hide();
			// }
			// else if (this.hidden && delta < -this.triggerDistance) {
			// 	this.show();
			// }

			if (scrollEvent.detail.currentY > this.expandedElementHeigth) {
				if (!this.hidden) {
					this.hide();
				}
			}
			else {
				if (this.hidden) {
					this.show();
				}
			}

		});
	}

	ngOnDestroy(): void {
		//this.scrollArea.ionScroll.unsubscribe();
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}


	ngAfterViewInit() {
		//this.viewHeight = window.innerHeight - document.getElementById("parrent-Element").offsetTop;    // get max height
		//this.expandedElementHeigth = document.getElementById(this.expandedElementId).offsetHeight; //this.expandedElement.nativeElement.style.height;    // set height


	}

	initStyles() {
		this.domCtrl.write(() => {
			//this.renderer.setStyle(this.element.nativeElement, "transition",	"2s"	);

			this.show();
		});
	}

	hide() {
		this.domCtrl.write(() => {
			//this.renderer.setStyle(this.element.nativeElement, "min-height", "0px");
			//this.renderer.setStyle(this.element.nativeElement, "height", "80px");
			//			this.renderer.setStyle(this.element.nativeElement, "opacity", "0");
			//this.renderer.setStyle(this.element.nativeElement, "padding", "0");
			this.renderer.addClass(this.element.nativeElement, this.collapsedClass);
			this.renderer.removeClass(this.element.nativeElement, this.expandedClass);
		});

		this.hidden = true;
	}

	show() {
		this.domCtrl.write(() => {
			//			this.renderer.removeStyle(this.element.nativeElement, "height");
			//			this.renderer.removeStyle(this.element.nativeElement, "opacity");
			//			this.renderer.removeStyle(this.element.nativeElement, "min-height");
			//			this.renderer.removeStyle(this.element.nativeElement, "padding");
			this.renderer.addClass(this.element.nativeElement, this.expandedClass);
			this.renderer.removeClass(this.element.nativeElement, this.collapsedClass);
		});

		this.hidden = false;
	}
}
