import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ILSPrinter, ILSPrinterResponse } from "print/printer.interface.public";
import { Order } from "../backend-api";
import { DataTranslateService } from "../core/services/data-translate.service";
import { LocalStorageService } from "../core/services/local-storage.service";
import { safeAwaitArray, safeAwaitOrDefault } from "../core/utils";
import { IKBTPrinter, IKPrinter, IKPrinters } from "./printer.interface";

@Injectable({
	providedIn: "root"
})
export class PrinterService {

	private _installedPrinters: IKPrinters;
	private _loaded = false;

	constructor(
		private httpClient: HttpClient,
		private lss: LocalStorageService,
		private dts: DataTranslateService
	) {

	}

	async getInstalledPrinters(): Promise<IKPrinters> {
		if (!this._loaded) {
			this._installedPrinters = await safeAwaitOrDefault(this.lss.loadPrinters()) || {};
		}
		this._loaded = true;
		return this._installedPrinters;
	}

	async countInstalledPrinters(): Promise<number> {

		// cosi precarica
		await this.getInstalledPrinters();

		let n = 0;
		if (this._installedPrinters) {
			for (const k in this._installedPrinters) {
				if (this._installedPrinters.hasOwnProperty(k)) {
					n++;
				}
			}
		}
		return n;
	}

	/**
	 * Ritorna una lista delle stampanti gestite da Local Printer Server
	 */
	async getLocalServerPrinters(server: string, port: number): Promise<ILSPrinter[]> {

		if (!server.startsWith("http://")) {
			server = "http://" + server;
		}

		const remotePrinters: ILSPrinter[] = await safeAwaitOrDefault(this.httpClient.get(server + ":" + port + "/api/print/list").toPromise());
		for (const p of remotePrinters) {
			p.server = server;
			p.port = port;
		}
		return remotePrinters;
	}

	// ritorna la chiave usata nell'oggetto per identificare la stampante
	private _getPrinterKey(p: IKPrinter): string {
		if (p.type === "bluetooth") {
			return p.bluetoothData.macAddress;
		}
		else if (p.type === "local-server") {
			return p.remoteData.server + "-" + p.remoteData.port + "-" + p.remoteData.name;
		}
	}

	async addLSPrinter(p: ILSPrinter): Promise<void> {
		const ikp: IKPrinter = {
			friendlyName: p.name,
			type: "local-server",
			remoteData: p
		};

		this._installedPrinters[this._getPrinterKey(ikp)] = ikp;

		// salva
		await safeAwaitOrDefault(this.lss.savePrinters(this._installedPrinters));
	}

	async addBTPrinter(p: IKBTPrinter, name: string): Promise<any> {

		const ikp: IKPrinter = {
			friendlyName: name,
			type: "bluetooth",
			bluetoothData: p
		};

		this._installedPrinters[this._getPrinterKey(ikp)] = ikp;

		// salva
		await safeAwaitOrDefault(this.lss.savePrinters(this._installedPrinters));
	}

	async removePrinter(ikp: IKPrinter): Promise<void> {
		delete this._installedPrinters[this._getPrinterKey(ikp)];

		// salva
		await safeAwaitOrDefault(this.lss.savePrinters(this._installedPrinters));
	}

	/**
	 * Stampa dell'ordine
	 */
	async printOrder(order: Order, printerKey: string): Promise<{
		result: boolean;
		message: string;
	}> {

		const p: IKPrinter = (await safeAwaitOrDefault(this.getInstalledPrinters()))[printerKey];
		if (p) {

			let res: ILSPrinterResponse;
			let error: any;

			if (p.type === "bluetooth") {
				throw Error("Bluetooth printer not implemented");
			}
			else if (p.type === "local-server") {

				const pls = p.remoteData;
				[error, res] = await safeAwaitArray(this.httpClient.post(pls.server + ":" + pls.port + "/api/print/order/" + encodeURIComponent(pls.name), order).toPromise());
			}

			if (error) {
				return {
					result: false,
					message: this.dts.translateService.instant("printers.error_printing") + "\n" + (error?.message || error) + ""
				};
			}

			return {
				result: res.ret,
				message: res.ret ? "" : (this.dts.translateService.instant("printers.error_printing") + "\n" + res.message)
			};

		}
		return {
			result: false,
			message: this.dts.translateService.instant("printers.cannot_enumerate_printers")
		};
	}

}
