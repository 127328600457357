import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ILogService } from "./log.interface";
import { statics } from "../statics";
import { environment } from "src/environments/environment";

export type tLogLevel = "info" | "debug" | "verbose" | "error" | "warn";

@Injectable({
	providedIn: "root"
})
export class LogService implements ILogService {

	private headers: HttpHeaders;
	private options: {
		headers: HttpHeaders
	};

	constructor(
		private http: HttpClient
	) {
		this.headers = new HttpHeaders({ "Content-Type": "application/json" });
		this.options = { headers: this.headers };
	}

	async info(message: string, skipRemote?: boolean): Promise<any> {
		await this.log(message, "info", skipRemote, null);
	}

	async debug(message: string, skipRemote?: boolean): Promise<any> {
		await this.log(message, "debug", skipRemote, null);
	}

	async verbose(message: string, data: any, skipRemote?: boolean): Promise<any> {
		await this.log(message, "verbose", skipRemote, data);
	}

	async error(message: string, error?, skipRemote?: boolean): Promise<any> {
		if (error) {
			message += "\n";
			if (error.message) {
				message += error.message + "\n";
			}
			if (error.stack) {
				message += error.stack;
			}
			else {
				message += error.toString();
			}
		}
		await this.log(message, "error", skipRemote, null);
	}

	async warn(message: string, skipRemote?: boolean): Promise<any> {
		await this.log(message, "warn", skipRemote, null);
	}

	async log(message: string, level: tLogLevel, skipRemote?: boolean, data?: any) {

		const userId = statics.userId || -1; // NON utilizzare AppConfigService altrimenti va in ciclo

		const msg: any = {
			appId: this._appId(),
			userId,
			message,
		};
		if (data) {
			msg.data = JSON.stringify(data);
		}

		const url = environment.loggingBaseUrlPattern.replace("{level}", level);

		if (level === "error") {
			console.error("LogService: " + JSON.stringify(msg));
		}
		else {
			console.log("LogService: " + JSON.stringify(msg));
		}

		if (skipRemote) {
			return Promise.resolve();
		}
		else {
			return this.http.post(url, msg, this.options).toPromise();
		}
	}

	/**
	 * Legge l'appId dall'url corrente
	 */
	private _appId(sourceLocation?: string): string {

		const loc = sourceLocation || location.toString();

		const urlAppId = this.getUrlParam(loc, "appId");

		return urlAppId || statics.appId;
	}

	/**
	 * Ritorna un parametro di un URL
	 * @param url URL di cui si vuole leggere il parametro
	 * @param parameter Parametro da leggere
	 */
	private getUrlParam(url: string, parameter: string): string {
		const results: RegExpExecArray = new RegExp("[\?&]" + parameter + "=([^&#]*)").exec(url);

		if (!results) {
			return "";
		}
		else {
			return !!results[1] ? decodeURIComponent(results[1]) : "";
		}
	}

}
