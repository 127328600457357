import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { TranslateModule } from "@ngx-translate/core";
import { TranslateWithDefaultPipe } from "./pipes/TranslateWithDefaultPipe";
import { SafeHtmlPipe } from "./pipes/SafeHtmlPipe";
import { HourToDatePipe } from "./pipes/HourToDatePipe";
import { CachedImageComponent } from "./components/cached-image/cached-image.component";
import { TagsComponent } from "./components/tags/tags.component";
import { ProductCartButtonsComponent } from "./components/product-cart-buttons/product-cart-buttons.component";
import { CrudSearchComponent } from "./components/crud-list/crud-search.component";
import { CrudListItemComponent } from "./components/crud-list/crud-list-item.component";
import { SimpleCrudListItemComponent } from "./components/crud-list/simple-crud-list-item.component";
import { RouterModule } from "@angular/router";
import { GalleryListComponent } from "./components/gallery-list/gallery-list.component";
import { ShowGalleryPage } from "./components/gallery-list/show-gallery.page";
import { CachedImageDivComponent } from "./components/cached-image/cached-image-div.component";
import { ScrollVanishDirective } from "./directives/scroll-vanish.directive";
import { CameraUploadComponent } from "./components/camera-upload/camera-upload.component";
import { ExpandableDivComponent } from "./components/expandable-div/expandable-div.component";
import { PopOverButtonComponent } from "./components/pop-over-button/pop-over-button.component";
import { PopOverButtonMenuComponent } from "./components/pop-over-button/pop-over-button-menu/pop-over-button-menu.component";
import { SmartDateDisplayPipe } from "./pipes/SmartDateDisplayPipe";
import { DateStringToDatePipe } from "./pipes/DateStringToDatePipe";
import { RoundSignalComponent } from "./components/round-signal/round-signal.component";
import { DataTranslatePipe } from "./pipes/data-translate.pipe";
import { SelectSearchComponent } from "./components/select-search/select-search.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { SearchPanelPage } from "./components/select-search/search-panel.page";
import { FileUploadModule } from "ng2-file-upload";
import { TableFilterPipe } from "./pipes/table-filter.pipe";
import { ShowImagePage } from "./components/show-image/show-image.page";
import { AddressEditPage } from "../account/pages/address-edit/address-edit.page";
import { AutotrimDirective } from "./directives/autotrim.directive";
import { HsliderComponent } from "./components/hslider/hslider.component";
import { RecapAndPayPage } from "../payment/pages/recap-and-pay/recap-and-pay.page";
import { GoogleGeocoderComponent } from "./components/google-geocoder/google-geocoder.component";
import { AppLoadedGuard } from "./guards/app-loaded.guard";
import { OrderItemCartButtonsComponent } from "./components/order-item-cart-buttons/order-item-cart-buttons.component";
import { CalendarManagementComponent } from "./components/calendar-management/calendar-management.component";
import { CalendarAmountComponent } from "./components/calendar-amount/calendar-amount.component";
import { SpinnerButtonComponent } from "./components/spinner-button/spinner-button.component";
import { ChooseLanguagePage } from "./components/choose-language/choose-language.page";

@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: "address-edit", // fake navigation
				component: AddressEditPage,
				canActivate: [AppLoadedGuard],
			},
			{
				path: "choose-language", // fake navigation
				component: ChooseLanguagePage,
				canActivate: [AppLoadedGuard],
			}
		]),
		CommonModule,
		FormsModule,
		TranslateModule,
		IonicModule,
		ReactiveFormsModule,
		FontAwesomeModule,
		FileUploadModule
	],
	declarations: [
		HeaderComponent,
		FooterComponent,
		TranslateWithDefaultPipe,
		DateStringToDatePipe,
		SafeHtmlPipe,
		HourToDatePipe,
		SmartDateDisplayPipe,
		CachedImageComponent,
		CachedImageDivComponent,
		TagsComponent,
		ProductCartButtonsComponent,
		OrderItemCartButtonsComponent,
		CrudSearchComponent,
		CrudListItemComponent,
		SimpleCrudListItemComponent,
		GalleryListComponent,
		ShowGalleryPage,
		ScrollVanishDirective,
		CameraUploadComponent,
		ExpandableDivComponent,
		PopOverButtonComponent,
		PopOverButtonMenuComponent,
		RoundSignalComponent,
		DataTranslatePipe,
		TableFilterPipe,
		SelectSearchComponent,
		SearchPanelPage,
		ShowImagePage,
		AddressEditPage,
		RecapAndPayPage,
		AutotrimDirective,
		HsliderComponent,
		GoogleGeocoderComponent,
		CalendarManagementComponent,
		CalendarAmountComponent,
		SpinnerButtonComponent,
		ChooseLanguagePage
	],
	exports: [
		HeaderComponent,
		FooterComponent,
		TranslateWithDefaultPipe,
		SafeHtmlPipe,
		HourToDatePipe,
		SmartDateDisplayPipe,
		DateStringToDatePipe,
		CachedImageComponent,
		CachedImageDivComponent,
		TagsComponent,
		ProductCartButtonsComponent,
		OrderItemCartButtonsComponent,
		CrudSearchComponent,
		CrudListItemComponent,
		SimpleCrudListItemComponent,
		GalleryListComponent,
		ShowGalleryPage,
		CameraUploadComponent,
		ScrollVanishDirective,
		ExpandableDivComponent,
		PopOverButtonComponent,
		PopOverButtonMenuComponent,
		RoundSignalComponent,
		DataTranslatePipe,
		TableFilterPipe,
		SelectSearchComponent,
		SearchPanelPage,
		ShowImagePage,
		AddressEditPage,
		RecapAndPayPage,
		AutotrimDirective,
		HsliderComponent,
		GoogleGeocoderComponent,
		CalendarManagementComponent,
		CalendarAmountComponent,
		SpinnerButtonComponent,
		ChooseLanguagePage
	]
})
export class CoreModule {
}
