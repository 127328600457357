import { formatNumber } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { OrderItem, Product } from "src/app/backend-api";
import { OrderingService } from "src/app/ordering/services/ordering.service";
import { DataTranslateService } from "../../services/data-translate.service";
import { round3 } from "../../utils";

@Component({
	selector: "app-order-item-cart-buttons",
	templateUrl: "./order-item-cart-buttons.component.html",
	styleUrls: ["./order-item-cart-buttons.component.scss"],
})
export class OrderItemCartButtonsComponent implements OnInit {

	private _timerId = null;
	private readonly QuantityChangedDelayedTimeout = 1000;

	@Input() orderItem: OrderItem;
	@Input() showAsLabel: boolean;
	@Input() disabled: boolean;
	@Output() quantityHasChanged = new EventEmitter();

	// permette di disattivare i pulsanti + e - durante l'aggiornamento del carrello per un'azione precedente
	updatingOrderItem = false;

	private _diff = 0;

	constructor(
		readonly orderingService: OrderingService,
		private dts: DataTranslateService
	) {

	}

	ngOnInit() { }

	/**
	 * Ritorna una stringa esplicativa della quantità
	 */
	quantityStringByOrderItem(oi: OrderItem): string {
		let ret = "";
		const q = oi.requestedQuantity;

		if (!Number.isInteger(oi.product.minimumLot)) {
			ret += formatNumber(q, this.dts.currentLang, "1.2-2") + " " + this.dts.translateService.instant("measure_units.short." + oi.product.measureUnit);
		} else {
			ret += formatNumber(q, this.dts.currentLang, "1.0-0") + " " + this.dts.translateService.instant("measure_units.short." + oi.product.measureUnit);
		}
		return ret;
	}

	removeClicked(oi: OrderItem) {

		if (oi.requestedQuantity > 0) {

			const p = oi.product;

			// dav 030620, gestione quantityStep
			if (oi.requestedQuantity > p.minimumLot) {
				oi.requestedQuantity = round3(oi.requestedQuantity - (p.quantityStep || p.minimumLot));
				this._diff -= p.quantityStep || p.minimumLot;
			}
			else {
				oi.requestedQuantity = 0; // sicurezza
				this._diff -= p.minimumLot;
			}
			oi.amount = null; // metto null per mostrare lo spinner finche' non si carica il prezzo reale

			// timer delayed
			if (this._timerId) {
				clearTimeout(this._timerId);
			}
			this._timerId = setTimeout(() => {
				this.quantityChangedDelayed(p, oi);
			}, this.QuantityChangedDelayedTimeout, p, oi);
		}

	}

	addClicked(oi: OrderItem) {

		const p = oi.product;
		if (this.orderingService.canAddMore(p)) {

			if (!round3(oi.requestedQuantity)) {
				oi.requestedQuantity = round3(oi.requestedQuantity + p.minimumLot);
				this._diff += p.minimumLot;
			}
			else {
				oi.requestedQuantity = round3(oi.requestedQuantity + (p.quantityStep || p.minimumLot));
				this._diff += p.quantityStep || p.minimumLot;
			}

			oi.amount = null; // metto null per mostrare lo spinner finche' non si carica il prezzo reale

			// timer delayed
			if (this._timerId) {
				clearTimeout(this._timerId);
			}
			this._timerId = setTimeout(() => {
				this.quantityChangedDelayed(p, oi);
			}, this.QuantityChangedDelayedTimeout, p, oi);
		}

	}

	// chiamata in modo ritardato
	async quantityChangedDelayed(p: Product, oi: OrderItem): Promise<void> {

		this._timerId = null; // azzera il timer

		this.quantityHasChanged.emit({
			product: p,
			orderItem: oi
		});

		// per semplicità chiamo l'aggiornamento dell'item nel carrello già da qui altrimenti
		// in ogni punto in cui l'ho usato è necessario metterci questa chiamata
		const d = this._diff;
		this._diff = 0; // reset di diff

		this.updatingOrderItem = true;
		await this.orderingService.execUpdateOrderItem(p, oi, d);
		this.updatingOrderItem = false;
	}

}
