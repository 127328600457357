import { Component, OnInit, Input, ViewChild, AfterContentInit, DoCheck } from "@angular/core";

@Component({
	selector: "app-expandable-div",
	templateUrl: "./expandable-div.component.html",
	styleUrls: ["./expandable-div.component.scss"],
})
export class ExpandableDivComponent implements OnInit, AfterContentInit, DoCheck {
	@ViewChild("cont", { static: true }) container: any;

	private _initialHeight: number = null;
	private _loading = true;

	styleMaxHeight = "";
	styleCursor = "";
	styleOverflow = "";

	opened = false;
	overflows = false;

	private _lastSize = {
		width: 0,
		height: 0
	};

	@Input()
	set initialHeight(height: number) {
		this._initialHeight = height;

		// aggiorna lo stile
		if (!this._loading) {
			// aggiorna
			this.ngAfterContentInit();
		}
	}
	get initialHeight(): number {
		return this._initialHeight;
	}

	constructor(

	) { }

	ngOnInit() {

	}

	ngAfterContentInit() {

		// aggiorna col puntatore giusto
		this._loading = false;
	}

	containerClicked() {
		this.opened = true;

		// aggiorna lo stile
		this.updateStyleComponents();

	}

	ngDoCheck(): void {

		if (!this._loading) {

			const thisSize = {
				width: this.container.nativeElement.scrollWidth,
				height: this.container.nativeElement.scrollHeight
			};

			if (!this.opened) {

				if ((thisSize.width - this._lastSize.width !== 0) ||
					(thisSize.height - this._lastSize.height !== 0)) {

					// salva per dopo
					this._lastSize = thisSize;

					// aggiorna
					this.updateStyleComponents();
				}

			}

		}
	}


	// aggiorna lo stile
	updateStyleComponents(): void {

		if (this.opened) {
			this.styleMaxHeight = "";
			this.styleCursor = "";
			this.styleOverflow = "";
		}
		else {
			// altezza del container nativo
			const nativeContainerScrollHeight = this.container.nativeElement.scrollHeight;

			this.styleMaxHeight = this._initialHeight + "px";
			this.styleOverflow = "hidden";

			if (nativeContainerScrollHeight >= this._initialHeight + 10) {
				this.styleCursor = "pointer";

				// cambia anche la variabile
				this.overflows = true;
			}
			else {
				this.overflows = false;

			}

		}

	}

}
