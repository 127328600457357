import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import { IKPrinters } from "src/app/printer/printer.interface";
import { safeAwaitArray, safeAwaitOrDefault } from "../utils";
import { Platform2Service } from "./platform2.service";

@Injectable({
	providedIn: "root"
})
export class LocalStorageService {

	readonly C_KEY_JWT = "kaleidos-jwt";
	readonly C_KEY_CART_ID = "cart-id";
	readonly C_KEY_STOREID = "storeId";
	readonly C_KEY_FAV_APPS = "fav-apps";
	readonly C_KEY_PRINTERS = "printers";

	// dav 030520, per visualizzazione attesa su SSO
	readonly C_KEY_SSO = "doing-sso";
	readonly C_KEY_SSO_AA = "doing-sso-aa";

	// tslint:disable-next-line: variable-name
	readonly C_KEY_HomePlatformSubscribeMessageHidden = "hpsmh";

	constructor(
		private storage: Storage,
		private platform2: Platform2Service
	) {

	}

	// salvataggio ibrido
	private async _set(key: string, data: string): Promise<void> {
		await this.platform2.ready();
		if (this.platform2.isIntoCompiledApp()) {
			await this.storage.set(key, data);
		}
		else {
			localStorage.setItem(key, data);
		}
	}

	// lettura ibrida
	private async _get(key: string): Promise<string> {
		await this.platform2.ready();
		if (this.platform2.isIntoCompiledApp()) {
			return this.storage.get(key);
		}
		else {
			return localStorage.getItem(key);
		}
	}

	// cancellazione ibrida
	private async _clear(key: string): Promise<void> {
		await this.platform2.ready();
		if (this.platform2.isIntoCompiledApp()) {
			await this.storage.remove(key);
		}
		else {
			localStorage.removeItem(key);
		}
	}

	// salva il token jwt ottenuto dal login, sempre un solo login
	saveJwtToken(jwt: string): Promise<void> {
		return this._set(this.C_KEY_JWT, jwt);
	}

	// ritorna il token del login, sempre un solo login
	getJwtToken(): Promise<string> {
		return this._get(this.C_KEY_JWT);
	}
	// cancella le info di login dalle impostazioni locali, sempre un solo login
	clearJwtToken(): Promise<void> {
		return this._clear(this.C_KEY_JWT);
	}

	// salva il cartId nel local storage, viene usato per salvare e recuperare le info del carrello
	// quando l'utente non è loggato
	saveCartId(cartId: string, appId: string): Promise<void> {
		return this._set(appId + "-" + this.C_KEY_CART_ID, cartId);
	}
	loadCartId(appId: string): Promise<string> {
		return this._get(appId + "-" + this.C_KEY_CART_ID);
	}
	clearCartId(appId: string): Promise<void> {
		return this._clear(appId + "-" + this.C_KEY_CART_ID);
	}

	saveUnloggedCurrentStoreId(storeId: string, appId: string): Promise<void> {
		return this._set(appId + "-" + this.C_KEY_STOREID, storeId);
	}
	getUnloggedCurrentStoreId(appId: string): Promise<string> {
		return this._get(appId + "-" + this.C_KEY_STOREID);
	}
	clearUnloggedStoreId(appId: string): Promise<void> {
		return this._clear(appId + "-" + this.C_KEY_STOREID);
	}

	// dav 030520, per gestione migliore SSO
	async saveDoingSSOFlag(appAlone: boolean): Promise<void> {
		await this._set(this.C_KEY_SSO, "1");
		await this._set(this.C_KEY_SSO_AA, appAlone ? "1" : "0");
	}
	async isDoingSSO(): Promise<boolean> {
		const [e, v] = await safeAwaitArray(this._get(this.C_KEY_SSO));
		return v === "1";
	}
	async isDoingSSO_AppAlone(): Promise<boolean> {
		const [e, v] = await safeAwaitArray(this._get(this.C_KEY_SSO_AA));
		return v === "1";
	}
	async deleteDoingSSOFlag(): Promise<void> {
		await this._clear(this.C_KEY_SSO);
		await this._clear(this.C_KEY_SSO_AA);
	}

	// dav 021120, per il salvataggio locale delle app preferite
	saveFavApps(appIds: {
		[appId: string]: boolean;
	}): Promise<void> {

		// trova l'array con gli ID delle app preferite
		const ar = [];
		for (const k in appIds) {
			if (appIds.hasOwnProperty(k)) {
				ar.push(k);
			}
		}

		return this._set(this.C_KEY_FAV_APPS, ar.join(","));
	}
	async loadFavApps(): Promise<{
		[appId: string]: boolean;
	}> {
		const sApps = await this._get(this.C_KEY_FAV_APPS) || "";
		const aApps = sApps.split(",");

		const ret = {};
		for (const el of aApps) {
			if (el) {
				ret[el] = true;
			}
		}

		return ret;
	}
	clearFavApps(): Promise<void> {
		return this._clear(this.C_KEY_FAV_APPS);
	}

	setHomePlatformSubscribeMessageHidden(b: boolean): Promise<void> {
		return this._set(this.C_KEY_HomePlatformSubscribeMessageHidden, b ? "1" : "0");
	}
	async getHomePlatformSubscribeMessageHidden(): Promise<boolean> {
		const v = await safeAwaitOrDefault(this._get(this.C_KEY_HomePlatformSubscribeMessageHidden));
		return v === "1";
	}

	savePrinters(printers: IKPrinters): Promise<void> {
		return this._set(this.C_KEY_PRINTERS, JSON.stringify(printers));
	}
	async loadPrinters(): Promise<IKPrinters> {
		const sPrinters = await this._get(this.C_KEY_PRINTERS);
		if (sPrinters) {
			return JSON.parse(sPrinters);
		}
		return {};
	}
	clearPrinters(): Promise<void> {
		return this._clear(this.C_KEY_PRINTERS);
	}

}
