export class KeyPropertyMap<T, V> {

	map: {
		[key: string]: T;
	} = {};

	constructor() {

	}

	// imposta una proprietà diversa da chiave a chiave
	set(property: V, value: any, key: string): void {

		if (!this.map[key]) {
			this.map[key] = {} as T;
		}
		this.map[key][property + ""] = value;
	}

	// legge una prop specifica divisa per chiave
	get(property: V, key: string): any {
		return this.map[key] && this.map[key][property + ""];
	}
}
