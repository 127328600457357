import { ChangeDetectorRef, Component, Input, OnInit, Type, ViewChild } from "@angular/core";
import { Identity } from "../../../backend-api";
import { AppNavigatorService } from "../../services/app-navigator.service";
import { CrudListItemComponentInterface } from "./crud-list-item.component.interface";
import { ISimpleCrudListItemData } from "./simple-crud-list-item.component";

// parametri della chiamata di ricerca
export interface ICrudSearchExecOptions {
	searchString: string;
	first?: number;
	after?: string;
	appId?: string;
}

export interface ICrudSearch {
	listItemComponent: Type<CrudListItemComponentInterface>,
	execSearch: (options: ICrudSearchExecOptions) => Promise<any>;
	searchTitle: string;
	itemDetailsUrl: string;
	additionalData?: ISimpleCrudListItemData;
}

@Component({
	selector: "crud-search",
	templateUrl: "./crud-search.component.html",
	styleUrls: ["./crud-search.component.scss"],
})
export class CrudSearchComponent implements OnInit {
	@Input() crudSearch: ICrudSearch;

	private PAGE_SIZE = 50;
	searchString: string;
	nextCursor: string = null;
	private hasMoreData: boolean;
	private timerId;
	data: Identity[] = [];
	appId: string;

	constructor(
		private cdr: ChangeDetectorRef,
		private appNav: AppNavigatorService
	) {
	}

	ngOnInit() {
	}

	async execSearch() {
		await this.doSearch();
	}

	// esegue la paginazione in avanti
	async loadNextData(event) {
		if (this.hasMoreData) {
			await this.doSearch(false);
		}
		event.target.complete();
	}

	private async doSearch(newSearch = true) {
		const paginatedResponse = await this.crudSearch.execSearch({
			searchString: this.searchString,
			first: this.PAGE_SIZE,
			after: !newSearch ? this.nextCursor : null,
			appId: this.appId
		});
		this.nextCursor = paginatedResponse.pageInfo.endCursor;
		this.hasMoreData = paginatedResponse.pageInfo.hasMoreData;
		if (newSearch) {
			this.data = paginatedResponse.edges.map(e => e.node);
		}
		else {
			this.data = this.data.concat(paginatedResponse.edges.map(e => e.node));
		}
	}

	search(newSearch = true) {
		if (this.timerId) {
			clearTimeout(this.timerId);
		}
		this.timerId = setTimeout(async () => {
			await this.doSearch(newSearch);
		}, 300);
	}

	// evento del CrudSearchComponent
	itemDeleted(item: Identity) {

		if (this.data) {
			const found = this.data.findIndex(d => d.id === item.id);
			if (found >= 0) {
				this.data.splice(found, 1);
				this.cdr.detectChanges();
			}
		}
	}

	createItem() {
		this.appNav.navigateForward(this.crudSearch.itemDetailsUrl);
	}
}
