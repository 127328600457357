import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Host, Input, OnInit, ViewChild, TemplateRef, ViewContainerRef, Output, EventEmitter } from "@angular/core";
import { CrudListItemComponentInterface } from "./crud-list-item.component.interface";
import { CORE_PERMISSION, Identity } from "../../../backend-api";
import { safeAwaitArray } from "../../utils";
import { ICrudSearch } from "./crud-search.component";

@Component({
	selector: "crud-list-item",
	templateUrl: "./crud-list-item.component.html",
	styleUrls: ["./crud-list-item.component.scss"]
})
export class CrudListItemComponent implements OnInit {
	@Input() item: Identity;
	@Input() crudSearch: ICrudSearch;
	@Output() itemDeleted = new EventEmitter();

	searchTitle: string;
	itemDetailsUrl: string;

	/** The view container reference for the internal template. */
	@ViewChild(TemplateRef, { read: ViewContainerRef })
	private crudViewContainerRef: ViewContainerRef;

	private realInstanceRef: ComponentRef<CrudListItemComponentInterface>;
	public corePermissionEnum = CORE_PERMISSION;

	constructor(
		private componentFactoryResolver: ComponentFactoryResolver) { }

	ngOnInit() {
		this._loadComponentAsync();
	}

	private async _loadComponentAsync(): Promise<void> {

		// mettere in questo array tutti i componenti possibili che posso istanziare
		await Promise.all([
			import("./simple-crud-list-item.component")
		]);

		const componentFactory: ComponentFactory<CrudListItemComponentInterface> = this.componentFactoryResolver.resolveComponentFactory(this.crudSearch.listItemComponent);

		this.crudViewContainerRef.clear();

		this.realInstanceRef = this.crudViewContainerRef.createComponent(componentFactory);
		this.realInstanceRef.instance.item = this.item;
		this.realInstanceRef.instance.itemDetailsUrl = this.crudSearch.itemDetailsUrl;
		this.realInstanceRef.instance.additionalData = this.crudSearch.additionalData;
	}

	// chiama il metodo dell'implementor
	itemClicked() {
		this.realInstanceRef.instance.itemSelected();
	}

	// chiama il metodo dell'implementor
	async deleteClicked() {
		const [error] = await safeAwaitArray(this.realInstanceRef.instance.deleteItem());
		if (!error) {
			this.itemDeleted.emit(this.item);
		}
	}

}
