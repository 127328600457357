import { Component, Host, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { CrudListItemComponentInterface } from "./crud-list-item.component.interface";
import { TranslateService } from "@ngx-translate/core";
import { AlertController } from "@ionic/angular";
import { Identity } from "../../../backend-api";
import { safeAwaitOrDefault } from "../../utils";
import { AppNavigatorService } from "../../services/app-navigator.service";

export interface ISimpleCrudListItemData {
	titleInfo: (item: Identity) => {
		title: string;
		subtitle: string;
		other?: string; // anche html
	};
	execDeleteFunction: (item: Identity) => Promise<void>;
}

@Component({
	selector: "simple-crud-list-item",
	templateUrl: "./simple-crud-list-item.component.html",
})
export class SimpleCrudListItemComponent implements OnInit, CrudListItemComponentInterface {
	@Input() item: Identity;
	@Input() itemDetailsUrl: string;
	@Input() additionalData: ISimpleCrudListItemData;

	constructor(
		public translate: TranslateService,
		private appNav: AppNavigatorService,
		private alertCtrl: AlertController,
	) {
	}

	ngOnInit(): void {
	}

	// viene chiamata da fuori
	itemSelected() {
		this.appNav.navigateForward(this.itemDetailsUrl + "/" + this.item.id);
	}

	private async showYesNo(): Promise<boolean> {

		return new Promise(async (resolve, reject) => {

			const NO: string = this.translate.instant("NO");
			const SI: string = this.translate.instant("SI");
			const attention: string = this.translate.instant("ATTENTION");
			const deleteWarning: string = this.translate.instant("ITEM_DELETE_WARNING", { item: this.additionalData.titleInfo(this.item).title });
			const w = await this.alertCtrl.create({
				header: attention,
				message: deleteWarning,
				backdropDismiss: false,
				buttons: [
					{
						text: NO,
						role: "cancel",
						handler: () => {
							reject();
						}
					},
					{
						text: SI,
						handler: () => {
							resolve(true);
						}
					}]
			});
			await w.present();
		});
	}

	// viene chiamata da fuori e ritorna l'identity eliminata in una Promise
	async deleteItem(): Promise<Identity> {

		const ret = await safeAwaitOrDefault(this.showYesNo());
		if (ret) {
			try {
				await this.additionalData.execDeleteFunction(this.item);
				return this.item;
			}
			catch (e) {
				console.error("Error deleting item", e);
			}
		}
	}
}

