import {firebaseCustomApp} from "./firebase-custom-app";

export const environment = {
	production: true,
	firebase: {
		apiKey: "AIzaSyAYwEYNhjl0LwD_-G6m_pUqEV5vh6qm2ZQ",
		authDomain: "plazar.app",
		databaseURL: "https://kaleidos-cloud.firebaseio.com",
		projectId: "kaleidos-cloud",
		storageBucket: "kaleidos-cloud.appspot.com",
		messagingSenderId: "780837516549",
		vapidKey: "BAXT1c2lRcwsXz5HQG74AT4Qkn8YjG1w-XuMAJPk5adIGNmtjiqXkwqD0YZUvul_kJU1iY9LTZaCE304sQuqMXM",
		...firebaseCustomApp
	},
	backendUrl: "https://backend.plazar.app",
	graphqlUrl: "https://backend.plazar.app/graphql",
	picturesRemoteStaticBaseUrl: "https://backend.plazar.app/static",
	loggingBaseUrlPattern: "https://backend.plazar.app/log/{level}",
	storageFileUrlDownloadPattern: "https://backend.plazar.app/storage/{storageId}",
	storageThumbnailUrlDownloadPattern: "https://backend.plazar.app/storage/thumbnail/{storageId}",
	alwaysStartWithHome: true, // partenza sempre dalla bootstrap
	GMaps_api_debug: "AIzaSyBMxz88LhSuZskgXZhntONHH9sl6BsDABc",
	GMaps_api_release: "AIzaSyBMxz88LhSuZskgXZhntONHH9sl6BsDABc",
	Stripe_public_key: "pk_live_GMmiQJRxVDnWTqwIFUp6hi5L001XOb4lPz",
	Stripe_accountId: "ca_HD9hfDRLm8hLwngH6O7uZ1S86L5p46nz"
};
