import { Pipe, PipeTransform } from "@angular/core";
import { parse, parseJSON } from "date-fns";

@Pipe({ name: "dateStringToDate" })
export class DateStringToDatePipe implements PipeTransform {

	constructor() {
	}

	transform(value): Date {
		/////return parseJSON(value); /// DAV, non funziona

		return parse(value, "yyyy-M-d", new Date());
	}
}
