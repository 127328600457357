import { Component, OnInit, Input } from "@angular/core";
import { AppConfigurationService } from "../../services/app-configuration.service";
import { Tag } from "../../../backend-api";
import { DataTranslateService } from "../../services/data-translate.service";

@Component({
	selector: "app-tags",
	templateUrl: "./tags.component.html",
	styleUrls: ["./tags.component.scss"],
})
export class TagsComponent implements OnInit {

	@Input() tags: Tag[];
	@Input() canManage: boolean;

	constructor(
		private dts: DataTranslateService,
		public appConfigService: AppConfigurationService
	) { }

	ngOnInit() {

		this.tags = this.tags || [];
	}

	doAdd() {
		// TODO
	}
}
