import { Component, Input, OnInit } from "@angular/core";
import { IKPrinter } from "../../printer.interface";

@Component({
	selector: "app-printer",
	templateUrl: "./printer.component.html",
	styleUrls: ["./printer.component.scss"],
})
export class PrinterComponent implements OnInit {

	@Input() printer: IKPrinter;
	@Input() editable: boolean;

	constructor() { }

	ngOnInit() { }

}
