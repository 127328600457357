import {Injectable} from "@angular/core";
import {
	ClaimPaymentMethodResult,
	Pagination,
	PAYMENT_REFUND_REASON,
	PAYMENT_STATUS,
	PaymentClaim,
	PaymentClaimPaginatedResponse,
	PaymentMethod,
	PaymentRefundableAmount,
	PaymentRequestCode,
	SavePaymentMethodResult, SetupPaymentMethodResult,
} from "../../backend-api";
import {AppConfigurationService} from "../../core/services/app-configuration.service";
import {
	CANCEL_PAYMENT_REQUEST,
	CLAIM_PAYMENT,
	GET_FAVOURITE_PAYMENT_METHOD,
	GET_PAYMENT_METHODS,
	GET_PAYMENT_METHODS_FOR_COMPANY,
	GET_PAYMENT_METHODS_FOR_STORE,
	GET_PAYMENT_REFUNDABLE_AMOUNT,
	GET_PAYMENTS_FOR_STORE,
	GET_PAYMENTS_FOR_USER,
	ISSUE_PAYMENT_REQUEST,
	READ_PAYMENT_REQUEST,
	REFUND_PAYMENT,
	REMOVE_PAYMENT_METHOD,
	REMOVE_PAYMENT_METHOD_FOR_COMPANY,
	REMOVE_PAYMENT_METHOD_FOR_STORE,
	RETRIEVE_REQUEST_CODE,
	RETRIEVE_VALID_PAYMENT_CLAIM_BY_ORDER,
	SAVE_PAYMENT_METHOD,
	SAVE_PAYMENT_METHOD_FOR_COMPANY,
	SAVE_PAYMENT_METHOD_FOR_STORE,
	SET_DEFAULT_PAYMENT_METHOD,
	SET_DEFAULT_PAYMENT_METHOD_FOR_COMPANY,
	SET_DEFAULT_PAYMENT_METHOD_FOR_STORE,
	SETUP_PAYMENT_METHOD,
	SETUP_PAYMENT_METHOD_FOR_COMPANY
} from "./payment-queries";
import {IQueryManagerMutationOptions} from "../../core/services/query-manager.service";

@Injectable({
	providedIn: "root"
})
export class PaymentService {

	constructor(
		private appConfigService: AppConfigurationService
	) {
	}

	static getAmount(paymentClaim: PaymentClaim): number {
		if (paymentClaim.status === PAYMENT_STATUS.paid || paymentClaim.status === PAYMENT_STATUS.partially_refunded || paymentClaim.status === PAYMENT_STATUS.refunded) {
			return paymentClaim.amount;
		}
		else if (paymentClaim.authorizedAmount) {
			return paymentClaim.authorizedAmount;
		}
		else {
			return paymentClaim.amount;
		}
	}

	readPaymentClaim(appId: string, storeId: string, paymentClaimId: string): Promise<PaymentClaim> {
		const params = {
			appId,
			storeId,
			paymentClaimId
		};
		return this.appConfigService.queryManager.execute(READ_PAYMENT_REQUEST, params);
	}

	issuePaymentRequest(appId: string, storeId: string, amount: number, description: string, options?: IQueryManagerMutationOptions): Promise<PaymentClaim> {
		const params = {
			appId,
			storeId,
			amount,
			description
		};
		return this.appConfigService.queryManager.mutate(ISSUE_PAYMENT_REQUEST, params, options);
	}

	setupPaymentMethod(providerCustomData?: string, options?: IQueryManagerMutationOptions): Promise<SetupPaymentMethodResult> {
		const params = {
			providerCustomData,
		};
		return this.appConfigService.queryManager.mutate(SETUP_PAYMENT_METHOD, params, options);
	}

	setupPaymentMethodForCompany(appId: string, companyId: string, providerCustomData?: string, options?: IQueryManagerMutationOptions): Promise<SetupPaymentMethodResult> {
		const params = {
			appId,
			companyId,
			providerCustomData,
		};
		return this.appConfigService.queryManager.mutate(SETUP_PAYMENT_METHOD_FOR_COMPANY, params, options);
	}

	setupPaymentMethodForStore(appId: string, storeId: string, providerCustomData?: string, options?: IQueryManagerMutationOptions): Promise<SetupPaymentMethodResult> {
		const params = {
			appId,
			storeId,
			providerCustomData,
		};
		return this.appConfigService.queryManager.mutate(SAVE_PAYMENT_METHOD_FOR_STORE, params, options);
	}

	savePaymentMethod(providerCustomData: string, setAsDefault: boolean = false, options?: IQueryManagerMutationOptions): Promise<SavePaymentMethodResult> {
		const params = {
			setAsDefault,
			providerCustomData,
		};
		return this.appConfigService.queryManager.mutate(SAVE_PAYMENT_METHOD, params, options);
	}

	savePaymentMethodForCompany(appId: string, companyId: string, providerCustomData: string, setAsDefault: boolean = false): Promise<SavePaymentMethodResult> {
		const params = {
			appId,
			companyId,
			setAsDefault,
			providerCustomData,
		};
		return this.appConfigService.queryManager.mutate(SAVE_PAYMENT_METHOD_FOR_COMPANY, params);
	}

	savePaymentMethodForStore(appId: string, storeId: string, providerCustomData: string, setAsDefault: boolean = false): Promise<SavePaymentMethodResult> {
		const params = {
			appId,
			storeId,
			setAsDefault,
			providerCustomData,
		};
		return this.appConfigService.queryManager.mutate(SAVE_PAYMENT_METHOD_FOR_STORE, params);
	}

	cancelPaymentRequest(appId?: string, storeId?: string, paymentClaimId?: string): Promise<PaymentClaim> {
		const params = {
			appId,
			storeId,
			paymentClaimId,
		};
		return this.appConfigService.queryManager.mutate(CANCEL_PAYMENT_REQUEST, params);
	}

	claimPayment(appId: string, paymentClaimId: string, providerCustomData: string, options?: IQueryManagerMutationOptions): Promise<ClaimPaymentMethodResult> {
		const params = {
			appId,
			paymentClaimId,
			providerCustomData,
		};
		return this.appConfigService.queryManager.mutate(CLAIM_PAYMENT, params, options);
	}

	getPaymentMethods(options?: IQueryManagerMutationOptions): Promise<PaymentMethod[]> {
		return this.appConfigService.queryManager.execute(GET_PAYMENT_METHODS, options);
	}

	getPaymentMethodsForCompany(appId: string, companyId: string, options?: IQueryManagerMutationOptions): Promise<PaymentMethod[]> {
		const params = {
			appId,
			companyId
		};
		return this.appConfigService.queryManager.execute(GET_PAYMENT_METHODS_FOR_COMPANY, params, options);
	}

	getPaymentMethodsForStore(appId: string, storeId: string, options?: IQueryManagerMutationOptions): Promise<PaymentMethod[]> {
		const params = {
			appId,
			storeId
		};
		return this.appConfigService.queryManager.execute(GET_PAYMENT_METHODS_FOR_STORE, params, options);
	}

	getFavouritePaymentMethod(): Promise<PaymentMethod> {
		return this.appConfigService.queryManager.execute(GET_FAVOURITE_PAYMENT_METHOD);
	}

	removePaymentMethod(providerCustomData: string, options?: IQueryManagerMutationOptions): Promise<PaymentMethod> {
		const params = {
			providerCustomData
		};
		return this.appConfigService.queryManager.mutate(REMOVE_PAYMENT_METHOD, params, options);
	}

	removePaymentMethodForCompany(appId: string, companyId: string, providerCustomData: string, options?: IQueryManagerMutationOptions): Promise<PaymentMethod> {
		const params = {
			appId,
			companyId,
			providerCustomData
		};
		return this.appConfigService.queryManager.mutate(REMOVE_PAYMENT_METHOD_FOR_COMPANY, params, options);
	}

	removePaymentMethodForStore(appId: string, storeId: string, providerCustomData: string, options?: IQueryManagerMutationOptions): Promise<PaymentMethod> {
		const params = {
			appId,
			storeId,
			providerCustomData
		};
		return this.appConfigService.queryManager.mutate(REMOVE_PAYMENT_METHOD_FOR_STORE, params, options);
	}

	setDefaultPaymentMethod(providerCustomData: string, options?: IQueryManagerMutationOptions): Promise<PaymentMethod> {
		const params = {
			providerCustomData
		};
		return this.appConfigService.queryManager.mutate(SET_DEFAULT_PAYMENT_METHOD, params, options);
	}

	setDefaultPaymentMethodForCompany(appId: string, companyId: string, providerCustomData: string, options?: IQueryManagerMutationOptions): Promise<PaymentMethod> {
		const params = {
			appId,
			companyId,
			providerCustomData
		};
		return this.appConfigService.queryManager.mutate(SET_DEFAULT_PAYMENT_METHOD_FOR_COMPANY, params, options);
	}

	setDefaultPaymentMethodForStore(appId: string, storeId: string, providerCustomData: string, options?: IQueryManagerMutationOptions): Promise<PaymentMethod> {
		const params = {
			appId,
			storeId,
			providerCustomData
		};
		return this.appConfigService.queryManager.mutate(SET_DEFAULT_PAYMENT_METHOD_FOR_STORE, params, options);
	}

	retrieveRequestCode(requestCode: string): Promise<PaymentRequestCode> {
		const params = {
			requestCode,
		};
		return this.appConfigService.queryManager.execute(RETRIEVE_REQUEST_CODE, params);
	}

	retrieveValidPaymentClaimByOrder(appId: string, orderId: string): Promise<PaymentClaim> {
		const params = {
			appId,
			orderId,
		};
		return this.appConfigService.queryManager.execute(RETRIEVE_VALID_PAYMENT_CLAIM_BY_ORDER, params);
	}


	getPaymentsForUser(appId: string, pagination: Pagination, storeId?: string): Promise<PaymentClaimPaginatedResponse> {
		const params = {
			appId,
			storeId
		};
		return this.appConfigService.queryManager.execute(GET_PAYMENTS_FOR_USER, params, {pagination});
	}

	getPaymentsForStore(appId: string, storeId: string, pagination: Pagination): Promise<PaymentClaimPaginatedResponse> {
		const params = {
			appId,
			storeId
		};
		return this.appConfigService.queryManager.execute(GET_PAYMENTS_FOR_STORE, params, { pagination });
	}

	async getPaymentRefundableAmount(appId: string, storeId: string, paymentClaimId: string): Promise<PaymentRefundableAmount> {
		const params = {
			appId,
			storeId,
			paymentClaimId
		};
		return this.appConfigService.queryManager.execute(GET_PAYMENT_REFUNDABLE_AMOUNT, params);
	}

	async refundPayment(appId: string, storeId: string, paymentClaimId: string, refundReason?: PAYMENT_REFUND_REASON, amount?: number,
	                    options?: IQueryManagerMutationOptions): Promise<PaymentRefundableAmount> {
		const params = {
			appId,
			storeId,
			paymentClaimId,
			refundReason,
			amount
		};
		return this.appConfigService.queryManager.mutate(REFUND_PAYMENT, params, options);
	}
}
