import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { OrderingService } from "src/app/ordering/services/ordering.service";
import { OrderItem, Product } from "../../../backend-api";
import { DataTranslateService } from "../../services/data-translate.service";
import { formatNumber } from "@angular/common";
import { round3 } from "../../utils";

@Component({
	selector: "app-product-cart-buttons",
	templateUrl: "./product-cart-buttons.component.html",
	styleUrls: ["./product-cart-buttons.component.scss"],
})
export class ProductCartButtonsComponent implements OnInit {

	private _timerId = null;
	private readonly QuantityChangedDelayedTimeout = 1000;

	@Input() product: Product;
	@Input() showAsLabel: boolean;
	@Input() disabled: boolean;
	@Output() quantityHasChanged = new EventEmitter();


	// permette di disattivare i pulsanti + e - durante l'aggiornamento del carrello per un'azione precedente
	updatingOrderItem = false;

	private _diff = 0;

	constructor(
		readonly orderingService: OrderingService,
		private dts: DataTranslateService
	) {

	}

	ngOnInit() { }

	/**
	 * Ritorna una stringa esplicativa della quantità
	 */
	quantityStringByProduct(p: Product, minimum = 0): string {
		let ret = "";
		let quantity = 0;

		const oItems = this.orderingService.queryOrderItemsByProduct(p);
		if (oItems.length > 1) {
			quantity = this.orderingService.getTotalRequestedQuantityByProduct(p);

		}
		else {
			const oi = oItems[0]; // prende il primo se ne esiste 1 solo o nessuno
			if (oi) {
				quantity = oi.requestedQuantity;
			}
		}

		const q = Math.max(minimum, quantity);
		if (!Number.isInteger(p.minimumLot)) {
			ret += formatNumber(q, this.dts.currentLang, "1.2-2") + " " + this.dts.translateService.instant("measure_units.short." + p.measureUnit);
		} else {
			ret += formatNumber(q, this.dts.currentLang, "1.0-0") + " " + this.dts.translateService.instant("measure_units.short." + p.measureUnit);
		}
		return ret;
	}

	removeClicked(p: Product) {

		const oItems = this.orderingService.queryOrderItemsByProduct(p);
		if (oItems.length > 1) {
			// perchè ci sono più orderItem ed il prodotto è in tutti
			// bisogna usare l'altro componente
			throw Error("removeClicked: unmanageable case");
		}
		else if (oItems.length === 0) {
			throw Error("removeClicked: nothing to remove");
		}
		else {
			const oi = oItems[0];
			if (oi.requestedQuantity > 0) {

				// dav 030620, gestione quantityStep
				if (oi.requestedQuantity > p.minimumLot) {
					oi.requestedQuantity = round3(oi.requestedQuantity - (p.quantityStep || p.minimumLot));
					this._diff -= p.quantityStep || p.minimumLot;
				}
				else {
					oi.requestedQuantity = 0; // sicurezza
					this._diff -= p.minimumLot;
				}
				oi.amount = null; // metto null per mostrare lo spinner finche' non si carica il prezzo reale

				// timer delayed
				if (this._timerId) {
					clearTimeout(this._timerId);
				}
				this._timerId = setTimeout(() => {
					this.quantityChangedDelayed(p, oi);
				}, this.QuantityChangedDelayedTimeout, p, oi);
			}
		}

	}

	addClicked(p: Product) {

		if (this.orderingService.canAddMore(p)) {

			const oItems = this.orderingService.queryOrderItemsByProduct(p);
			if (oItems.length > 1) {
				// perchè ci sono più orderItem ed il prodotto è in tutti
				// bisogna usare l'altro componente
				throw Error("removeClicked: unmanageable case");
			}
			else {

				// prende l'unico esistente
				const oi = this.orderingService.queryOrderItemsByProduct(p, p)[0];
				if (!round3(oi.requestedQuantity)) {
					oi.requestedQuantity = round3(oi.requestedQuantity + p.minimumLot);
					this._diff += p.minimumLot;
				}
				else {
					oi.requestedQuantity = round3(oi.requestedQuantity + (p.quantityStep || p.minimumLot));
					this._diff += p.quantityStep || p.minimumLot;
				}

				oi.amount = null; // metto null per mostrare lo spinner finche' non si carica il prezzo reale

				// timer delayed
				if (this._timerId) {
					clearTimeout(this._timerId);
				}
				this._timerId = setTimeout(() => {
					this.quantityChangedDelayed(p, oi);
				}, this.QuantityChangedDelayedTimeout, p, oi);
			}
		}

	}

	// chiamata in modo ritardato
	async quantityChangedDelayed(p: Product, oi: OrderItem): Promise<void> {

		this._timerId = null; // azzera il timer

		this.quantityHasChanged.emit({
			product: p,
			orderItem: oi
		});

		// per semplicità chiamo l'aggiornamento dell'item nel carrello già da qui altrimenti
		// in ogni punto in cui l'ho usato è necessario metterci questa chiamata
		const d = this._diff;
		this._diff = 0; // reset di diff

		this.updatingOrderItem = true;
		await this.orderingService.execUpdateOrderItem(p, oi, d);
		this.updatingOrderItem = false;
	}

}
