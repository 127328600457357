import { Injectable } from "@angular/core";
import { statics } from "../statics";
import { IPageConfig } from "src/app/routes";
import { environment } from "../../../environments/environment";
import * as firebase from "firebase";
import {
	App,
	AppEnrollment,
	AppNotification,
	CONTACT_SUBTYPE,
	CONTACT_TYPE,
	FEATURE,
	GalleryItem,
	ORDERING_NOTIFICATION_EVENT,
	PLATFORM_APP_ID,
	Product,
	PurchasedFeature,
	SOCIAL,
	StorageFile,
	Store,
	StorePermissions,
	SYSTEM_PERMISSION,
	User,
	PAYMENT_NOTIFICATION_EVENT
} from "../../backend-api";
import { DataTranslateService } from "./data-translate.service";
import { KeyPropertyMap } from "../key-property-map";
import { QueryAppService } from "../queries/query-app.service";
import { QueryManagerService } from "./query-manager.service";
import { Subject, Subscription } from "rxjs";
import { Platform2Service } from "./platform2.service";

// tipologie di cambio stato app
export type STATE_CHANGE_REASON = "account" | "notification" | "refreshCurrentPage" | "refreshAppMenuPages";

export interface ICustomStoreInfo {
	tname: string; // nome tradotto
	address: string;
	city: string;
	email?: string;
	telephone?: string;
	cellphone?: string;
	favoritephone?: string;
	website?: string;
	facebook?: string;
	instagram?: string;
	twitter?: string;
	whatsapp?: string;
	longitude?: number;
	latitude?: number;
}

export type tAppMapPropertyName = "currentAppName" | "currentAppLandingPageGalleryItems" |
	"currentAppIconStorageFileId" | "currentApp" | "unloggedUserCurrentStoreId" | "appAlone" |
	"inlineChangesEnabled";

export interface IAppMapInfo {
	currentAppName?: string;
	currentAppIconStorageFileId?: string;
	currentApp?: App;
	unloggedUserCurrentStoreId?: string; // dav 131119
	currentAppLandingPageGalleryItems?: GalleryItem[];
	appAlone?: boolean;
}

export interface INavigationAfterLink {
	requestCode: string;
	onboardingReturnAppId: string;
	appId: string;
}

// info che arriva ai sottoscrittori del cambio stato
export interface IStateChangeInfo {
	reason: STATE_CHANGE_REASON,
	appIdForAccountAndForNotifications?: string
}

@Injectable({
	providedIn: "root"
})
export class AppConfigurationService {

	// info di app divise per appId
	appMap: KeyPropertyMap<IAppMapInfo, tAppMapPropertyName> = new KeyPropertyMap();

	private _loggedUser: User = null; // Utente collegato, non accessibile direttamente, sempre e solo 1

	// per le sottoscrizioni a messaggi globali
	private _stateChangedSubject = new Subject<IStateChangeInfo>();

	currentAppTheme?: string; // tema dell'app
	mainAppLoaded = false; // mi dice se l'app principale è stata caricata

	// istanza dell'app di default di firebase
	firebaseDefaultAppInitialized = false;

	bUserCanIssuePayment: boolean;

	// dav 071220, app gestite dall'utente corrente, visualizzate nel menu di sx
	appsManagedByUser: App[];

	public navigationAfterLink: INavigationAfterLink = {
		requestCode: null,
		onboardingReturnAppId: null,
		appId: null
	};

	// non referenziare appNav da qui!!! perchè appNav referenzia questo!!!
	constructor(
		private dts: DataTranslateService,
		private platform2: Platform2Service,
		private appQueriesService: QueryAppService,
		public queryManager: QueryManagerService // viene esposto agli altri
	) {

	}

	// ritorna la route corretta per la home leggendola dall'app corrente ed in base all'utente
	getHomeRoute(appId: string): string {
		const currentApp: App = this.appMap.get("currentApp", appId);
		const cu = this.loggedUser;

		// trovo la feature di atterraggio se specificata
		const landingFeature = currentApp?.landingPage?.feature;

		// e la cerco
		if (landingFeature) {
			const purchasedFeature = this.storeOrAppOwnFeature(currentApp.id, undefined, FEATURE.landing_page);
			if (purchasedFeature?.feature) {
				const privateRoute = purchasedFeature.feature.privateLandingRoute;
				const publicRoute = purchasedFeature.feature.publicLandingRoute;

				if (cu) {
					return privateRoute;
				}
				return publicRoute;
			}
		}

		// sono sulla piattaforma
		if (appId === PLATFORM_APP_ID) {
			return "/platform";
		}
		return "/home";
	}

	//
	// Ritorna le info di store corrente/preferito per app
	//

	getCurrentStoreId(appId: string): string | null {

		const lu = this.loggedUser;
		if (!lu) {
			return this.appMap.get("unloggedUserCurrentStoreId", appId);
		}

		const ae = this.getUserAppEnrollment(lu, appId);
		if (ae) {
			if (!ae.favouriteStoreId) {
				if (this.getAppStoresOrEmptyArray(appId).length === 1) {
					ae.favouriteStoreId = this.getAppStoresOrEmptyArray(appId)[0].id;
				}
			}

			return ae.favouriteStoreId;
		}

		return null;
	}

	// ritorna le info dello store corrente dato l'appId
	getCurrentStore(appId: string): Store | undefined {

		const currentStoreId = this.getCurrentStoreId(appId);
		if (currentStoreId) {
			const store = this.getAppStoresOrEmptyArray(appId).find((s) => {
				return s.id === currentStoreId;
			});

			if (store) {
				return store;
			}
		}
		return undefined;
	}

	// trova la lista degli store per l'app data
	getAppStoresOrEmptyArray(appId: string): Store[] {
		return this.appMap.get("currentApp", appId)?.stores || [];
	}

	// da chiamare in ogni servizio in cui si inizializza firebase
	async ensurePlatformReadyAndFirebaseDefaultAppInitialized(): Promise<void> {

		await this.platform2.ready();

		if (!this.firebaseDefaultAppInitialized) {
			firebase.default.initializeApp(environment.firebase);
			this.firebaseDefaultAppInitialized = true;
		}
	}

	// Ritorna l'utente collegato o null
	get loggedUser(): User | null {
		return this._loggedUser;
	}

	// per il settaggio dell'utente corrente
	async setLoggedUser(jwt: string, user: User,
		options?: {
			raiseStateChange: boolean;
			stateChangeAppId: string; // valido solo se rilascio un cambio stato
		}): Promise<any> {

		statics.jwt = jwt;
		if (user) {
			statics.userId = user.id;
		}
		else {
			statics.userId = null;
		}

		this._loggedUser = user;

		// setto la lingua dell'utente
		if (user) {
			await this.dts.translateService.use(this.dts.getLanguageFromLocaleOrUndef(user.locale)).toPromise();
		}
		else { // dav 200120
			await this.dts.translateService.use(this.dts.getNavigatorLanguage()).toPromise();
		}

		// se specificato lancia l'evento di cambio stato
		if (options?.raiseStateChange) {
			this.dispatchStateChanged({
				reason: "account",
				appIdForAccountAndForNotifications: options?.stateChangeAppId
			});
		}
	}

	/**
	 * Download file url. If used for images, it will download the full resolution image.
	 */
	getStorageFileDownloadUrlForApp(storageId: string): string {
		let url = environment.storageFileUrlDownloadPattern;
		url = url.replace("{storageId}", storageId);
		return url;
	}

	/**
	 * Same as getStorageFileDownloadUrlForApp() but downloads the thumbnail of the image, if available,
	 * or the full image if it does not provide a thumbnail
	 */
	getStorageThumbnailDownloadUrlForApp(storageId: string): string {
		let url = environment.storageThumbnailUrlDownloadPattern;
		url = url.replace("{storageId}", storageId);
		return url;
	}

	/**
	 * Ritorna l'URL della picture grande, dato lo storage file di riferimento
	 */
	getPictureUrlFromStorageFile(sf: StorageFile, canReturnNull?: boolean): string {
		if (sf && sf.id) {
			return this.getStorageFileDownloadUrlForApp(sf.id);
		}
		if (canReturnNull) {
			return null;
		}
		return "assets/images/no-image.jpg";
	}

	/**
	 * Ritorna l'URL della picture piccola, dato lo storage file di riferimento
	 */
	getThumbUrlFromStorageFile(sf: StorageFile, canReturnNull?: boolean): string {
		if (sf && sf.id) {
			return this.getStorageThumbnailDownloadUrlForApp(sf.id);
		}
		if (canReturnNull) {
			return null;
		}
		return "assets/images/no-image.jpg";
	}

	/**
	 * Ritorna info sullo store
	 */
	public getStoreInfo(store: Store, appId?: string): ICustomStoreInfo {

		const tname = this.dts.dataTranslate(store.name) || this.appMap.get("currentAppName", appId || statics.appId) || "*";
		let city: string;
		let address;
		if (store.address) {
			if (store.address.street && store.address.number && store.address.zip) {
				address = store.address.street + " " + store.address.number + ", " + store.address.zip;
			}
			if (store.address.city) {
				city = this.dts.dataTranslate(store.address.city.name, "");
				if (store.address.city.province && store.address.city.province.abbreviation) {
					city += " (" + store.address.city.province.abbreviation + ")"
				}
			}
		}

		// prende le info base
		const si: ICustomStoreInfo = {
			tname, // dav 301219
			address,
			city
		};

		// arricchisce
		if (store.contactList) {
			for (const contact of store.contactList.items) {
				switch (contact.type) {
					case CONTACT_TYPE.mail:
						si.email = contact.value;
						break;

					case CONTACT_TYPE.phone:
						if (contact.subtype === CONTACT_SUBTYPE.phone) {
							si.telephone = contact.value;

							if (contact.favourite) {
								si.favoritephone = "telephone";
							}
						}
						else if (contact.subtype === CONTACT_SUBTYPE.cellphone) {
							si.cellphone = contact.value;

							if (contact.favourite) {
								si.favoritephone = "cellphone";
							}
						}
						break;

					case CONTACT_TYPE.web:
						si.website = contact.value;
						break;

					case CONTACT_TYPE.social:
						if (contact.social === SOCIAL.facebook) {
							si.facebook = contact.value;
						}
						else if (contact.social === SOCIAL.instagram) {
							si.instagram = contact.value;
						}
						else if (contact.social === SOCIAL.twitter) {
							si.twitter = contact.value;
						}
						else if (contact.social === SOCIAL.whatsapp) {
							si.whatsapp = contact.value;
						}
						break;
				}
			}
		}

		if (store?.address?.geolocation?.coordinates?.length) {
			si.longitude = store.address.geolocation.coordinates[0].longitude;
			si.latitude = store.address.geolocation.coordinates[0].latitude;
		}

		return si;
	}

	//
	// Gestione stato app
	//
	// quando si verifica un cambio di stato chiamare questa per notificare globalmente
	// un cambio di stato e il servizio di bootstrap effettuerà cose
	addStateChangedSubscription(cb: (sci: IStateChangeInfo) => any): Subscription {
		return this._stateChangedSubject.subscribe(cb);
	}

	// effettua il dispatch del cambio stato (gestito da bootstrap service)
	dispatchStateChanged(sci: IStateChangeInfo) {
		this._stateChangedSubject.next(sci);
	}

	// Caricamento stato app
	async getOrLoadAndSaveGloballyAppConfiguration(appId: string, forceReloadAndSave: boolean): Promise<App> {

		// se esiste già lo ritorna al volo
		let currentApp: App = this.appMap.get("currentApp", appId);
		if (currentApp && !forceReloadAndSave) {
			return currentApp;
		}

		const mainApp = (appId === statics.appId);

		// legge le info e salva le variabili globali
		currentApp = await this.appQueriesService.loadApp(appId);

		// imposta l'info per id
		this.appMap.set("currentApp", currentApp, appId);

		if (mainApp) {

			// imposta la lingua dell'app
			this.dts.updateDataLanguages({
				appDefaultLang: currentApp.defaultLanguage || currentApp?.supportedLanguages?.[0],
				storeDefaultLang: undefined
			});

			// questa variabile è usata nel app.component.html per il cambio del tema
			this.currentAppTheme = currentApp.theme || "default";
		}

		// imposta il nome app tradotto per id
		this.appMap.set("currentAppName", this.dts.dataTranslate(currentApp.name), appId);

		// elementi dello slider della home
		this.appMap.set("currentAppLandingPageGalleryItems", currentApp?.landingPage?.gallery?.items, appId);

		if (currentApp.icon) {
			this.appMap.set("currentAppIconStorageFileId", currentApp?.icon?.id, appId);
		}
		//se l'app non ha l'icona impostata, prova con il logo della company
		else if (currentApp.company?.logoStorageFile?.id) {
			this.appMap.set("currentAppIconStorageFileId", currentApp.company.logoStorageFile.id, appId);
		}

		return currentApp;
	}

	// ritorna tutte le notifiche dell'utente per l'app corrente
	getUserNotifications(): AppNotification[] {

		const appId = statics.appId; // app singola o piattaforma
		const cu = this.loggedUser;
		if (cu) {
			const ea = this.getUserAppEnrollment(cu, appId);
			if (ea?.notifications?.length) {
				return ea.notifications;
			}
		}

		return [];
	}

	// ritorna le notifiche utente per tipo (feature/evento)
	getUserNotificationsByType(feature: FEATURE, event: ORDERING_NOTIFICATION_EVENT | PAYMENT_NOTIFICATION_EVENT | null): AppNotification[] {
		const notifications = this.getUserNotifications();
		return notifications.filter((nf) => {
			if (event) {
				return (nf.feature === feature && nf.event === event);
			}
			else {
				return (nf.feature === feature);
			}
		});
	}

	// definisce se il link è visibile o nascosto per quell'utente
	userCanAccessPage(p: IPageConfig, appId: string): boolean {
		if (p.visibleForAuthenticatedUsers && this._loggedUser) {
			return true;
		}
		else if (p.visibleForGuests && !this._loggedUser) {
			return true;
		}
		else {
			if (!p.visibleForAuthenticatedUsers && !p.visibleForGuests && !p.permissions) {
				//page has no permissions and is not reserved for authenticated/guest users, so it is completely open
				return true;
			}
			else if (p.permissions) {
				return this.isUserAllowed(appId, undefined, p.permissions);
			}
		}
	}

	// verifica se app o store dispongono della feature passata in input
	storeOrAppOwnFeature(
		appId: string,
		optStoreId: string,
		feature: FEATURE | "any"): PurchasedFeature {

		// store corrente
		const currentStoreId = optStoreId || this.getCurrentStoreId(appId);
		const currentApp: App = this.appMap.get("currentApp", appId);
		if (currentApp?.subscriptions?.length) {
			for (const s of currentApp.subscriptions) {
				if ((!currentStoreId || s?.stores?.find(store => store.id === currentStoreId)) && s?.purchasedFeatures?.length) {
					for (const pf of s.purchasedFeatures) {
						if (pf.validity > 0) { // dav 060520, controllo validità feature
							const fv = FEATURE[pf?.feature?.type];
							if (fv === feature) {
								return pf;
							}
						}
					}
				}
			}
		}
	}

	isPageVisibleForAppOrStore(p: IPageConfig, appId: string): boolean {

		const stores = this.getAppStoresOrEmptyArray(appId);

		if (p.itemKey === "stores" && (!stores || stores.length < 2)) {
			return false;
		}

		if (p.itemKey === "storeDetails" && (!stores || stores.length >= 2)) {
			return false;
		}

		// imposto l'url corretto sul menu
		if (p.itemKey === "storeDetails" && stores?.length) {
			p.url = "/store-details/" + stores[0].id;
		}

		// se è stata specificata la feature devo controllare se questa è presente nell'app corrente
		if (p.ownerFeature && p.ownerFeature !== "any") {
			return !!this.storeOrAppOwnFeature(appId, undefined, p.ownerFeature);
		}

		return true;
	}

	// verifica se l'utente corrente sia enrollato per questa app
	isUserOptEnrolledForThisApp(user: User, appId: string): boolean {
		if (user) {
			const ae = this.getUserAppEnrollment(user, appId);
			return !!ae;
		}
		return true;
	}

	// calcola la lista delle app utente, se non è loggato ritorna array vuoto
	getUserAppEnrollment(cu: User, appId: string): AppEnrollment {

		if (cu && cu.enrolledApps) {
			for (const ea of cu.enrolledApps) {
				if (ea.appId === appId) {
					return ea;
				}
			}
		}
		return undefined;
	}

	public isUserAllowed(appId: string, storeId: string | undefined | null, permission: string[]): boolean {
		if (this._loggedUser) {
			const currentStoreId = storeId || this.getCurrentStoreId(appId);
			return this.isAuthorized(this._loggedUser, appId, currentStoreId, permission);
		}
		return false;
	}

	/**
	 * DAV: changed a bit from backend, now better management of parameters
	 */
	private isAuthorized(user: User, appId: string, storeId?: string, permissions?: string[]): boolean {
		if (user) {
			let enrolledApp: AppEnrollment;
			let storePermissions: StorePermissions;
			if (user.enrolledApps && appId) {
				enrolledApp = this.getUserAppEnrollment(user, appId);
				if (storeId && enrolledApp.storePermissions) {
					storePermissions = enrolledApp.storePermissions.find(sp => sp.storeId === storeId);
				}
			}
			if (permissions) {
				for (const p of permissions) {
					//if the requested permission p is not present either in store permissions, app permissions or system permissions
					//then user is not authorized
					if (!(
						(storePermissions && storePermissions.permissions.includes(p)) ||
						(enrolledApp && enrolledApp.appPermissions && enrolledApp.appPermissions.includes(p)) ||
						(user.systemPermissions && user.systemPermissions.includes(p)))
					) {
						//requested permission not found, check if it is an admin, otherwise the result will be false (user not authorized)
						if (!this.isAdmin(user)) {
							return false;
						}
					}
				}
			}
			//else: if no permissions required, consider user authorized
			return true;
		}
		return false;
	}

	isAdmin(user: User) {
		return user && user.systemPermissions && user.systemPermissions.includes(SYSTEM_PERMISSION.admin);
	}

	// verifica se un prodotto è disponibile nel catalogo
	productIsAvailable(p: Product): boolean {
		if (p.available) {
			if (p.availabilityCheckActive) {
				return p.availableQuantity > 0;
			}
			return true;
		}
		return false;
	}

	// ritorna vero se il prodotto passato è disabilitato
	productIsDisabled(p: Product): boolean {
		return !this.productIsAvailable(p);
	}

	// ritorna vero se il prodotto passato è nascosto
	productIsHidden(p: Product, catalogHideUnavailableProducts = false): boolean {
		return !this.productIsAvailable(p) && (p.hideUnavailableProducts || catalogHideUnavailableProducts);
	}

	generateDynamicLink(appId: string, parameters: { [key: string]: string }): string {
		const currentApp = this.appMap.get("currentApp", appId);
		let link = "https://plazar.page.link/?link=https://web.plazar.app?";

		if (parameters) {
			for (const k in parameters) {
				if (parameters.hasOwnProperty(k) && parameters[k] !== null && parameters[k] !== undefined) {
					link += k + "=" + encodeURIComponent(parameters[k]) + "&";
				}
			}
		}
		link += "apn=" + encodeURIComponent(currentApp.androidAppId) + "&ibi=" + encodeURIComponent(currentApp.iosAppId);
		return link;
	}

	getPageToNavigateAfterLink(): string {

		if (this.navigationAfterLink.requestCode) {
			const rc = this.navigationAfterLink.requestCode;
			this.navigationAfterLink.requestCode = null;
			return "/recap-and-pay/" + encodeURIComponent(rc);
		}
		else if (this.navigationAfterLink.onboardingReturnAppId) {
			const appId = this.navigationAfterLink.onboardingReturnAppId;
			this.navigationAfterLink.onboardingReturnAppId = null;
			return "/settings?appId=" + encodeURIComponent(appId);
		}
		else if (this.navigationAfterLink.appId) {
			const appId = this.navigationAfterLink.appId;
			this.navigationAfterLink.appId = null;
			return "/home?appId=" + encodeURIComponent(appId);
		}

		return null;
	}
}
