import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from "@angular/core";
import { DataTranslateService } from "../../services/data-translate.service";
import { NativeGeocoder, NativeGeocoderOptions } from "@ionic-native/native-geocoder/ngx";
import { GoogleMap, Marker, Environment, GoogleMaps, GoogleMapsEvent, GeocoderResult, BaseArrayClass, ILatLng, Geocoder } from "@ionic-native/google-maps";
import { environment } from "src/environments/environment";
import { Coordinates, Address } from "src/app/backend-api";
import { safeAwaitOrDefault } from "../../utils";
import { Platform2Service } from "../../services/platform2.service";

@Component({
	selector: "app-google-geocoder",
	templateUrl: "./google-geocoder.component.html",
	styleUrls: ["./google-geocoder.component.scss"],
})
export class GoogleGeocoderComponent implements OnInit, OnDestroy {

	readonly mapContainerId = "address_map";

	@Input() height = "300px";

	private map: GoogleMap;
	private marker: Marker;
	public bGeocoding: boolean;
	private MAP_ANIMATION_DURATION = 1000;
	private MAP_ZOOM = 17;

	constructor(
		public dts: DataTranslateService,
		private platform2: Platform2Service,
		private nativeGeocoder: NativeGeocoder
	) { }

	async ngOnInit() {

		await this.platform2.ready();

		if (this.platform2.is("cordova")) {
			// This code is necessary for browser
			Environment.setEnv({
				API_KEY_FOR_BROWSER_RELEASE: environment.GMaps_api_release,
				API_KEY_FOR_BROWSER_DEBUG: environment.GMaps_api_debug
			});

			Environment.setBackgroundColor("#efefef");

			this.map = GoogleMaps.create(this.mapContainerId);

			// update coordinate per creazione posticipata di GoogleMaps
			const currentCoords = this.getCurrentPosition();
			if (currentCoords) {
				this._setMarkerPosition(currentCoords);
			}
		}
	}

	// legge le coordinate dal marker
	getCurrentPosition(): Coordinates | undefined {
		if (this.marker) {
			const pos = this.marker.getPosition();
			return {
				latitude: pos.lat,
				longitude: pos.lng
			}
		}
		return undefined;
	}

	setCurrentPosition(c: Coordinates) {
		// se ho specificato le coordinate
		if (c?.latitude && c?.longitude) {

			const currentCoords = this.getCurrentPosition();

			// e se queste sono diverse da quelle correnti
			if (c.latitude !== currentCoords?.latitude ||
				c.longitude !== currentCoords?.longitude) {

				// solo se la mappa è pronta
				if (this.map) {
					this._setMarkerPosition(c);
				}
			}
		}
	};

	// imposta le coordinate del marker e anima verso quel punto
	private async _setMarkerPosition(coordinates: Coordinates): Promise<void> {

		if (this.marker) {
			this.marker.setPosition({
				lat: coordinates.latitude,
				lng: coordinates.longitude
			});
		}
		else {
			const marker: Marker = this.map.addMarkerSync({
				//title: address,
				draggable: true,
				position: {
					lat: coordinates.latitude,
					lng: coordinates.longitude
				}
			});

			this.marker = marker;

			this.marker.on(GoogleMapsEvent.MARKER_DRAG_END).subscribe(() => {

				const newPos = this.marker.getPosition();

				this.map.animateCamera({
					target: newPos,
					zoom: this.MAP_ZOOM,
					tilt: 0,
					duration: this.MAP_ANIMATION_DURATION
				});

			});
		}

		await this.map.animateCamera({
			target: {
				lat: coordinates.latitude,
				lng: coordinates.longitude
			},
			zoom: this.MAP_ZOOM,
			tilt: 0,
			duration: this.MAP_ANIMATION_DURATION
		});
	}

	async performGeocoding(address: string): Promise<void> {

		if (this.platform2.isIntoCompiledApp()) {

			const options: NativeGeocoderOptions = {
				useLocale: true,
				defaultLocale: this.dts.currentLang,
				maxResults: 5
			};

			try {
				this.bGeocoding = true;
				const results = await this.nativeGeocoder.forwardGeocode(address, options);
				this.bGeocoding = false;

				if (results?.length > 0) {
					await this._setMarkerPosition({
						latitude: parseFloat(results[0].latitude),
						longitude: parseFloat(results[0].longitude)
					});
				}
				else {
					// fallback alle API di Google Maps
					await this._geocodeWithGoogleMaps(address);
				}
			}
			catch (e) {
				this.bGeocoding = false;
				// fallback alle API di Google Maps
				await this._geocodeWithGoogleMaps(address);
			}
		}
		else if (this.platform2.is("cordova")) {
			// usare API di Google Maps
			await this._geocodeWithGoogleMaps(address);
		}
	}

	private async _geocodeWithGoogleMaps(address: string): Promise<void> {

		let results: GeocoderResult[] | BaseArrayClass<GeocoderResult[]>;
		let myPosition: ILatLng;

		this.bGeocoding = true;
		try {
			results = await Geocoder.geocode({
				address
			});
		}
		catch (e) { }
		this.bGeocoding = false;

		if (results?.[0]?.position) {
			myPosition = results[0].position;
		}
		else {
			// coordinate di default: Roma
			myPosition = {
				lat: 41.9027835,
				lng: 12.4963655
			};
		}

		// sposta il cursore
		if (myPosition) {
			await this._setMarkerPosition({
				latitude: myPosition.lat,
				longitude: myPosition.lng
			});
		}
	}

	ngOnDestroy(): void {
		if (this.marker) {
			this.marker.destroy();
			this.marker = null;
		}
	}

}
