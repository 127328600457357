import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({ name: "smartDate" })
export class SmartDateDisplayPipe implements PipeTransform {

	/**
	 * 		REFS: code recyced and adapted from https://www.npmjs.com/package/smartdate
	 *
	 *		smartdate.js is a lightweight (~4KB minified) dependency-free library for displaying date and time in users' timezones,
	 *		in human-friendly format. Datetime string is updated dynamically without page reload. This is fast, tested with hundreds
	 *		of date objects on a single page.
	 *
	 *		locale - 'en' or 'ru'. Default is 'en';
	 *		mode - 'auto', 'date', 'past' or 'future'. Default is 'auto'. Defines how smartdate renders datetime text.
	 *		auto - use human-friendly time string for nearest date and time, from yesterday to tomorrow, like shown on examples above. Use date, if date is outside of yesterday <-> tomorrow interval;
	 *		date - always show dates, even for nearest date and time;
	 *		datetime - show date and time up to minutes;
	 *		past - prevent future dates from being shown. If the date is in the future, 'less than a minute ago' will be shown (or its equivalent in Russian). This could be helpful to deal with inaccurate clock on user machines. For example, if your app shows timestamps for article comments, and user clock is behind server clock by 5 minutes, the most recent comments could be shown for her as coming from future - 'in 5 min', and this could be confusing. Set mode to 'past' to fix this.
	 *		future - opposite to past, prevents past dates from being shown. If the date is in the past, 'in less than a minute' will be shown (or its equivalent in Russian).
	 *		fullMonthNames - true or false, default is false. Use full or short month names;
	 *		capitalize - true or false, default is false. Tells smartdate to capitalize produced date strings;
	 *		tagName - tag type to look for. Default is 'span';
	 *		className - tag class to look for. Default is 'smartdate';
	 *		addTitle - true or false, default is true. Tells smartdate to add title attribute with full datetime string in users' locale;
	 *		updateInterval - interval in milliseconds, how often should smartdate update datetime tags. Default is 5000 (5 seconds). Set it to null if you'd like to disable auto-update.
	 *
	 */

	//the default configuration
	private config = {
		locale: "it",
		tagName: "span",
		className: "smartdate",
		addTitle: true,
		updateInterval: 5000,
		fullMonthNames: false,
		capitalize: false,
		mode: "auto"  // also available: 'past', 'future' or 'date'
	};

	private locale = {
		en: {
			parent: this as SmartDateDisplayPipe,
			months: [
				"January", "February", "March", "April", "May", "June",
				"July", "August", "September", "October", "November", "December"
			],

			monthsShort: [
				"Jan", "Feb", "Mar", "Apr", "May", "Jun",
				"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
			],

			date(date, fullMonthNames) {
				const months = fullMonthNames ? this.months : this.monthsShort;
				return months[date.getMonth()] + " " +
					date.getDate() + ", " +
					date.getFullYear();
			},

			time(date) {
				let hours = date.getHours();
				let minutes = date.getMinutes();
				const ampm = hours >= 12 ? "pm" : "am";
				hours = hours % 12;
				hours = hours ? hours : 12; // the hour '0' should be '12'
				minutes = this.parent.pad(minutes);
				return hours + ":" + minutes + " " + ampm;
			},

			datetime(date, fullMonthNames) {
				return this.parent.date(date, fullMonthNames) + " at " + this.parent.time(date);
			},

			/*
						now(): Date {
							return new Date();
						},
			*/

			past(date, fullMonthNames) {
				const now = this.parent.now();
				const sec = (now.getTime() - date.getTime()) / 1000;

				if (sec < 60) {
					return "just now";
				}
				else if (sec < 60 * 60) {
					return Math.floor(sec / 60) + " min ago";
				}
				else if (this.parent.daysBetween(now, date) === 0) {
					return "today at " + this.time(date);
				}
				else if (this.parent.daysBetween(now, date) === -1) {
					return "yesterday at " + this.time(date);
				}
				return this.date(date, fullMonthNames);
			},

			future(date, fullMonthNames) {
				const now = this.parent.now();
				const sec = (date.getTime() - now.getTime()) / 1000;

				if (sec < 60) {
					return "now";
				}
				else if (sec < 60 * 60) {
					return "in " + Math.floor(sec / 60) + " min";
				}
				else if (this.parent.daysBetween(now, date) === 0) {
					return "today at " + this.time(date);
				}
				else if (this.parent.daysBetween(now, date) === 1) {
					return "tomorrow at " + this.time(date);
				}
				return this.date(date, fullMonthNames);
			}
		},

		it: {
			parent: this,
			months: [
				"Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno",
				"Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"
			],

			monthsShort: [
				"Gen", "Feb", "Mar", "Apr", "Mag", "Giu",
				"Lug", "Ago", "Set", "Ott", "Nov", "Dic"
			],

			date(date, fullMonthNames) {
				const months = fullMonthNames ? this.months : this.monthsShort;
				return date.getDate() + " " +
					months[date.getMonth()] + " " +
					date.getFullYear();
			},

			time(date) {
				return this.parent.pad(date.getHours()) + ":" + this.parent.pad(date.getMinutes());
			},

			datetime(date, fullMonthNames) {
				return this.date(date, fullMonthNames) + " alle " + this.time(date);
			},

			/*now(): Date {
				return new Date();
			},*/

			past(date, fullMonthNames) {
				const now = this.parent.now();
				const sec = (now.getTime() - date.getTime()) / 1000;

				if (sec < 60) {
					return "adesso";
				}
				else if (sec < 60 * 60) {
					return Math.floor(sec / 60) + " min fa";
				}
				else if (this.parent.daysBetween(now, date) === 0) {
					return "oggi alle " + this.time(date);
				}
				else if (this.parent.daysBetween(now, date) === -1) {
					return "ieri alle " + this.time(date);
				}
				return this.date(date, fullMonthNames);
			},

			future(date, fullMonthNames) {
				const now = this.parent.now();
				const sec = (date.getTime() - now.getTime()) / 1000;

				if (sec < 60) {
					return "adesso";
				}
				else if (sec < 60 * 60) {
					return "tra " + Math.floor(sec / 60) + " min";
				}
				else if (this.parent.daysBetween(now, date) === 0) {
					return "oggi alle " + this.time(date);
				}
				else if (this.parent.daysBetween(now, date) === 1) {
					return "domani alle " + this.time(date);
				}
				return this.date(date, fullMonthNames);
			}
		}
	};

	constructor(
		public translate: TranslateService
	) {
	}

	transform(date: Date | number, options: any = {}): string {
		const formattedDate = this.format(date, options);
		if (!formattedDate) {
			return this.translate.instant("INVALID_DATE");
		}
		else {
			return formattedDate;
		}
	}

	private daysBetween(date1, date2): number {
		// Copy date parts of the timestamps, discarding the time parts.
		const one = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate()),
			two = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());

		// Do the math.
		const millisecondsPerDay = 1000 * 60 * 60 * 24,
			millisBetween = two.getTime() - one.getTime(),
			days = millisBetween / millisecondsPerDay;
		// Round down.
		return Math.floor(days);
	}

	private pad(n): string {
		return n < 10 ? "0" + n : n.toString();
	}

	private now(): Date {
		return new Date();
	}


	/**
	 * String format of Date object or unix timestamp using current settings
	 *
	 * @param {Date|number} date - Date object or unix timestamp (in seconds)
	 * @param {object} options - optional, object with configuration options
	 * @return {string|null} - string representation of date made with current
	 *                         format and locale settings,
	 *                         or null if input is incorrect
	 */
	private format(date, options): string {
		//const config = this.getOptions(options);
		if (!(date instanceof Date) || isNaN(date.getTime())) {
			if (typeof date === "string") {
				date = Number(date);
			}
			if (typeof date !== "number" || isNaN(date)) {
				return null;
			}
			date = new Date(date * 1000);
		}
		options = this.getOptions(options);
		let dateText;
		const locale = this.getLocale(options.locale);
		if (!(options.mode && typeof locale[options.mode] === "function")) {
			// default is auto mode
			dateText = this.auto(locale, date, options.fullMonthNames);
		}
		else {
			dateText = locale[options.mode](date, options.fullMonthNames);
		}
		if (options.capitalize) {
			dateText = dateText.charAt(0).toUpperCase() + dateText.slice(1);
		}
		return dateText;
	}

	/*
		//suspended
		private render() {
			const config = this.config,
				date,
				selector = config.tagName + "." + config.className,
				elements = document.querySelectorAll(selector),
				el,
				options,
				optionValue,
				timestamp;
			for (let i = 0, l = elements.length; i < l; i++) {
				el = elements[i];
				timestamp = +el.getAttribute("data-timestamp");

				// extract custom options from data-attributes
				options = {};
				for (let o in config) {
					if (config.hasOwnProperty(o) && el.hasAttribute("data-" + o)) {
						optionValue = el.getAttribute("data-" + o).toLowerCase();
						if (typeof config[o] === "boolean") {
							optionValue = (optionValue === "true");
						}
						options[o] = optionValue;
					}
				}
				options = this.getOptions(options);

				date = new Date(timestamp * 1000);
				el.innerHTML = this.format(date, options);
				if (options.addTitle) {
					el.setAttribute("title", date.toLocaleString());
				}
			}
		}
	*/

	/*
		private init(options) {
			this.setup(options);
			this.render();
			if (this.config.updateInterval) {
				window.setInterval(this.render, this.config.updateInterval);
			}
		}
	*/

	/*
		private setup(options) {
			this.config = this.getOptions(options);
		}
	*/

	private tag(date, options) {
		let tag,
			timestamp;
		if (date instanceof Date) {
			timestamp = Math.round(date.getTime() / 1000);
		}
		else {
			// assuming unix timestamp in seconds
			timestamp = date;
			date = new Date(timestamp * 1000);
		}
		tag = document.createElement(this.config.tagName);
		tag.className = this.config.className;
		tag.setAttribute("data-timestamp", timestamp);
		options = this.getOptions(options);
		tag.innerHTML = this.format(date, options);
		if (options.addTitle) {
			tag.setAttribute("title", date.toLocaleString());
		}
		return tag;
	}


	private getLocale(locale) {
		locale = locale || this.config.locale;
		if (typeof locale === "string") {
			locale = locale.toLowerCase();
			if (this.locale.hasOwnProperty(locale)) {
				return this.locale[locale];
			}
		}
		return this.locale.it;
	}

	private getOptions(options) {
		options = options || {};
		for (const o in this.config) {
			if (this.config.hasOwnProperty(o) && !options.hasOwnProperty(o)) {
				options[o] = this.config[o];
			}
		}
		return options;
	}

	private auto(locale, date, fullMonthNames) {
		if (date.getTime() > this.now().getTime()) {
			return locale.future(date, fullMonthNames);
		}
		else {
			return locale.past(date, fullMonthNames);
		}
	}


}
