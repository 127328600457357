import { Routes } from "@angular/router";
import { AppLoadedGuard } from "./core/guards/app-loaded.guard";
import { FEATURE, ORDERING_NOTIFICATION_EVENT, ORDERING_PERMISSION, PAYMENT_PERMISSION, SYSTEM_PERMISSION, PAYMENT_NOTIFICATION_EVENT, CORE_PERMISSION } from "./backend-api";

export type TPageKey = "stores" | "storeDetails" | "settingsPage" | "cart";

export interface IPageConfig {
	title: string;
	url: string;
	icon: string;
	iconEnd?: string; // dav 110121, seconda icona
	permissions?: string[];
	// status?: "expiring" | "disabled";
	onTabBar?: boolean;
	// order?: number;
	visibleForGuests?: boolean;
	visibleForAuthenticatedUsers?: boolean;
	// per le notifiche sulle varie voci di menu
	ownerFeature: FEATURE | "any";
	isMultiStore?: boolean;  // true if the page supports multistore functionality (and you can choose the store on which operate)
	propertyToCheck?: string; // name of the property to check if the page supports multistore functionality
	notification_feature?: FEATURE;
	notification_event?: ORDERING_NOTIFICATION_EVENT | PAYMENT_NOTIFICATION_EVENT;
	plazarItem?: boolean; // per identificare la voce di menu di plazar
	itemKey?: TPageKey;
}

// sezioni del menu, questo serve per la ricerca di un certo gruppo
export type TPageSectionGroup = "global" | "shop" | "payments" | "admin" | "account" | "myApps";

// sezioni del menu
export interface IPageSectionConfig {
	group: TPageSectionGroup;
	title: string;
	pages: IPageConfig[];
}

const ANY_FEATURE = "any";

export const appPages: IPageSectionConfig[] = [
	{
		group: "global",
		title: "",
		pages: [
			{
				title: "BACK_TO_PLAZAR",
				url: "/platform?appId=",
				icon: "arrow-back",
				ownerFeature: ANY_FEATURE,
				plazarItem: true
			},
			{
				title: "HOME",
				url: "/home",
				icon: "home",
				onTabBar: true,
				ownerFeature: ANY_FEATURE, // dav: la home è dinamica  //FEATURE.landingPage,
				notification_feature: FEATURE.newsletter,
				notification_event: undefined
			},
			{
				title: "SETTINGS",
				url: "/settings",
				icon: "settings",
				ownerFeature: ANY_FEATURE,
				permissions: [CORE_PERMISSION.manage_app],
				itemKey: "settingsPage" // mi permette di identificarla in modo sicuro
			},
			{
				title: "STORES",
				url: "/stores",
				icon: "bag-handle",
				ownerFeature: FEATURE.product_catalog,
				itemKey: "stores"
			},
			{
				title: "STORE_DETAILS",
				url: "/store-details/???", // lo imposto in modo corretto su appConfig.isPageVisibleForApp()
				icon: "information-circle",
				ownerFeature: FEATURE.landing_page,
				itemKey: "storeDetails"
			}
		]
	},
	{
		group: "shop",
		title: "SHOPPING",
		pages: [
			{
				title: "SHOP",
				url: "/shop",
				icon: "basket",
				onTabBar: true,
				ownerFeature: FEATURE.product_catalog,
			},
			{
				title: "CART",
				url: "/cart",
				icon: "cart",
				onTabBar: true,
				permissions: [ORDERING_PERMISSION.cart],
				visibleForGuests: true,
				ownerFeature: FEATURE.ordering,
				itemKey: "cart"
			},
			{
				title: "ORDERS",
				url: "/orders",
				icon: "list",
				permissions: [ORDERING_PERMISSION.cart],
				notification_feature: FEATURE.ordering,
				notification_event: ORDERING_NOTIFICATION_EVENT.order_user,
				ownerFeature: FEATURE.ordering,
			},
			{
				title: "ORDER_MANAGEMENT",
				url: "/order-management",
				icon: "list",
				permissions: [ORDERING_PERMISSION.order_management],
				onTabBar: true,
				notification_feature: FEATURE.ordering,
				notification_event: ORDERING_NOTIFICATION_EVENT.order_manager,
				ownerFeature: FEATURE.ordering,
			}
		]
	},
	{
		group: "payments",
		title: "PAYMENTS",
		pages: [
			{
				title: "payment.CLAIM_PAYMENT",
				url: "/require-payment",
				icon: "card-outline",
				permissions: [PAYMENT_PERMISSION.issue_payments],
				isMultiStore: true,
				propertyToCheck: "bUserCanIssuePayment",
				//visibleForAuthenticatedUsers: true,
				ownerFeature: FEATURE.payment,
			},
			{
				title: "payment.SEND_PAYMENT",
				url: "/send-payment",
				icon: "wallet-outline",
				ownerFeature: FEATURE.payment,
			},
			{
				title: "payment.PAYMENT_HISTORY",
				url: "/payment-history",
				icon: "cash-outline",
				visibleForAuthenticatedUsers: true,
				ownerFeature: FEATURE.payment,
				notification_feature: FEATURE.payment,
				notification_event: PAYMENT_NOTIFICATION_EVENT.payment_status,
			}
		]
	},
	{
		group: "admin",
		title: "ADMINISTRATION",
		pages: [
			{
				title: "administration.ADMINISTRATION",
				url: "/administration",
				icon: "hammer",
				permissions: [SYSTEM_PERMISSION.admin],
				ownerFeature: FEATURE.administration,
			},
			{
				title: "Typography",
				url: "/typography",
				icon: "eye",
				permissions: [SYSTEM_PERMISSION.admin],
				ownerFeature: ANY_FEATURE
			},
			{
				title: "Import Catalog",
				url: "/catalog-impexp",
				icon: "cloud-upload-outline",
				permissions: [SYSTEM_PERMISSION.admin],
				ownerFeature: ANY_FEATURE
			}
		]
	},
	{
		group: "account",
		title: "ACCOUNT",
		pages: [
			{
				title: "account.MY_ACCOUNT",
				url: "/my-account",
				icon: "person",
				visibleForAuthenticatedUsers: true,
				ownerFeature: ANY_FEATURE,
			},
			{
				title: "account.LOGIN",
				url: "/login",
				icon: "log-in",
				visibleForGuests: true,
				ownerFeature: ANY_FEATURE
			},
			{
				title: "account.LOGOUT",
				url: "/logout",
				icon: "log-out",
				visibleForAuthenticatedUsers: true,
				ownerFeature: ANY_FEATURE
			}
		]
	},
	{
		group: "myApps",
		title: "account.MY_APPS",
		pages: []
	}
];

export const routes: Routes = [
	{
		path: "",
		redirectTo: "bootstrap",
		pathMatch: "full",
		canActivate: [AppLoadedGuard],
	},
	{
		path: "bootstrap",
		loadChildren: () => import("./bootstrap/bootstrap.module").then(m => m.BootstrapPageModule),
		canActivate: [AppLoadedGuard]
	},
	{
		path: "",
		loadChildren: () => import("./core/core.module").then(m => m.CoreModule)
	},
	{
		path: "",
		loadChildren: () => import("./home/home.module").then(m => m.HomePageModule)
	},
	{
		path: "",
		loadChildren: () => import("./account/account.module").then(m => m.AccountModule)
	},
	{
		path: "",
		loadChildren: () => import("./shop/shop.module").then(m => m.ShopModule)
	},
	{
		path: "",
		loadChildren: () => import("./ordering/ordering.module").then(m => m.OrderingModule)
	},
	{
		path: "",
		loadChildren: () => import("./administration/administration.module").then(m => m.AdministrationModule)
	},
	{
		path: "typography",
		loadChildren: () => import("./typography/typography.module").then(m => m.TypographyPageModule)
	},
	{
		path: "",
		loadChildren: () => import("./platform/platform.module").then(m => m.PlatformModule)
	},
	{
		path: "",
		loadChildren: () => import("./payment/payment.module").then(m => m.PaymentModule)
	},
	{
		path: "",
		loadChildren: () => import("./printer/printer.module").then(m => m.PrinterModule)
	}
];
