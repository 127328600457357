import { Component, OnInit, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
	selector: "app-show-image",
	templateUrl: "./show-image.page.html",
	styleUrls: ["./show-image.page.scss"],
})
export class ShowImagePage implements OnInit {

	@Input() url: string;

	constructor(
		private modalController: ModalController
	) { }

	ngOnInit() {
	}

	close() {
		this.modalController.dismiss();
	}
}
