import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { PrinterService } from "../../printer.service";

@Component({
	selector: "app-add-bt-printer",
	templateUrl: "./add-bt-printer.page.html",
	styleUrls: ["./add-bt-printer.page.scss"],
})
export class AddBtPrinterPage implements OnInit {

	constructor(
		private printerService: PrinterService,
		private modalController: ModalController
	) { }

	ngOnInit() {
	}

}
