<ion-item lines="none" class="main-item">
	<ion-input style="max-width: 50px; text-align: right;border-bottom: none;" size="3" type="number" [(ngModel)]="amount.value" (ionChange)="updateAmount()"></ion-input>
	<ion-select style="margin-left: 5px" [(ngModel)]="amount.measureUnit" (ionChange)="updateAmount()" okText="{{'FATTO' | translate}}"
	cancelText="{{'ANNULLA' | translate}}">
		<ion-select-option value="{{CALENDAR_UNIT.milliseconds}}" *ngIf="!hideMilliseconds || amount.measureUnit === CALENDAR_UNIT.milliseconds">{{ 'calendar_unit.milliseconds' | translate}}</ion-select-option>
		<ion-select-option value="{{CALENDAR_UNIT.seconds}}" *ngIf="!hideSeconds || amount.measureUnit === CALENDAR_UNIT.seconds">{{ 'calendar_unit.seconds' | translate}}</ion-select-option>
		<ion-select-option value="{{CALENDAR_UNIT.minutes}}" *ngIf="!hideMinutes || amount.measureUnit === CALENDAR_UNIT.minutes">{{ 'calendar_unit.minutes' | translate}}</ion-select-option>
		<ion-select-option value="{{CALENDAR_UNIT.hours}}" *ngIf="!hideHours || amount.measureUnit === CALENDAR_UNIT.hours">{{ 'calendar_unit.hours' | translate}}</ion-select-option>
		<ion-select-option value="{{CALENDAR_UNIT.scheduled_days}}" *ngIf="!hideDays || amount.measureUnit === CALENDAR_UNIT.scheduled_days">{{ 'calendar_unit.scheduled_days' | translate}}</ion-select-option>
	</ion-select>
</ion-item>
