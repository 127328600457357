import { Component, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";
import { Camera, CameraOptions, PictureSourceType } from "@ionic-native/camera/ngx";
import { ActionSheetController, AlertController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { StorageFile } from "../../../backend-api";
import { UploadService } from "../../queries/upload.service";
import { Platform2Service } from "../../services/platform2.service";

@Component({
	selector: "app-camera-upload",
	templateUrl: "./camera-upload.component.html",
	styleUrls: ["./camera-upload.component.scss"],
})
export class CameraUploadComponent implements OnInit {

	@Input() appId: string;
	@Input() category: string;
	@Output() uploadDone = new EventEmitter();
	@Output() uploadFail = new EventEmitter();

	public plt: "app-ios" | "app-android" | "browser";

	constructor(
		private camera: Camera,
		private platform2: Platform2Service,
		private uploadService: UploadService,
		private actionSheetController: ActionSheetController,
		private alertCtrl: AlertController,
		public translate: TranslateService,
	) {
	}

	ngOnInit() {

		if (this.platform2.is("ios") && this.platform2.isIntoCompiledApp()) {
			this.plt = "app-ios";
		}
		else if (this.platform2.is("android") && this.platform2.isIntoCompiledApp()) {
			this.plt = "app-android";
		}
		else {
			this.plt = "browser";
		}
	}

	// click sul componente
	@HostListener("click", ["$event.target"])
	componentClicked() {
		this.buttonClicked();
	}

	// chiamata dal browser
	async inputFileSelected(files: File[]): Promise<void> {
		const file: File = files[0];
		if (!file) {
			return;
		}

		const ext: string = file.name.toLowerCase();
		const regex: RegExp = new RegExp("(.*?)\.(jpg|jpeg|png|webp)$");
		const regexTest: boolean = regex.test(ext);

		if (!regexTest) {
			this.alertCtrl.create({
				header: this.translate.instant("ERROR"),
				message: this.translate.instant("UNSUPPORTED_IMAGE_TYPE") + ": " + ext,
				buttons: [this.translate.instant("OK")]
			}).then(errMsg => {
				errMsg.present();
			});

			return;
		}

		try {
			const uploadedFile = await this.uploadService.uploadFile(this.appId, file, "image/jpeg", this.category, true);
			// upload eseguito
			this.uploadDone.emit(uploadedFile);
			return;
		}
		catch (e) {
		}

		// upload fallito
		this.uploadFail.emit();
	}

	// si verifica alla pressione del pulsante
	buttonClicked() {
		if (this.plt !== "browser") {
			this._presentActionSheet();
		}
	}

	// risolve con l'id del file caricato nello storage
	private async _doUpload(sourceType: PictureSourceType): Promise<StorageFile | null> {

		const options: CameraOptions = {
			targetWidth: 1000,
			targetHeight: 1000,
			quality: 60,
			destinationType: this.camera.DestinationType.DATA_URL,
			encodingType: this.camera.EncodingType.JPEG,
			mediaType: this.camera.MediaType.PICTURE,
			sourceType,
			correctOrientation: true
		};

		try {
			const b64Data = await this.camera.getPicture(options);
			if (b64Data) {
				const uploadedFile = await this.uploadService.uploadFile(this.appId, b64Data, "image/jpeg", this.category);

				// upload eseguito
				this.uploadDone.emit(uploadedFile);

				return uploadedFile;
			}
		}
		catch (e) {
		}

		// upload fallito
		this.uploadFail.emit();

		return null;
	}

	// da smartphone
	private async _presentActionSheet() {

		const title = this.translate.instant("camera_upload.title");
		const camera = this.translate.instant("camera_upload.camera");
		const library = this.translate.instant("camera_upload.library");
		const cancel = this.translate.instant("camera_upload.cancel");

		const actionSheet = await this.actionSheetController.create({
			header: title,
			buttons: [
				{
					text: camera,
					icon: "camera",
					handler: () => {
						this._doUpload(PictureSourceType.CAMERA);
					}
				}, {
					text: library,
					icon: "image",
					handler: () => {
						this._doUpload(PictureSourceType.PHOTOLIBRARY);
					}
				}, {
					text: cancel,
					icon: "close",
					role: "cancel",
					handler: () => {
						//console.log("Cancel clicked");
					}
				}
			]
		});
		await actionSheet.present();
	}

}
