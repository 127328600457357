<ion-toolbar>
    <ion-searchbar [(ngModel)]="searchString" animated placeholder="{{ crudSearch.searchTitle | translate}}" (ionChange)="search()" ></ion-searchbar>
</ion-toolbar>

<div style="text-align: center">
    <ion-button color="success" shape="round" (click)="createItem()">
        <ion-icon name="add"></ion-icon>
        {{ "CREATE" | translate}}
    </ion-button>
</div>

<ion-list mode="">
    <ion-item *ngFor="let item of data">
        <crud-list-item [crudSearch]="crudSearch" [item]="item" (itemDeleted)="itemDeleted($event)"></crud-list-item>
    </ion-item>
</ion-list>

<ion-infinite-scroll threshold="100px" (ionInfinite)="loadNextData($event)">
    <ion-infinite-scroll-content
            loadingSpinner="bubbles"
            loadingText="{{ 'LOADING' | translate }}">
    </ion-infinite-scroll-content>
</ion-infinite-scroll>
