import {Query} from "src/app/core/services/query";

export const paymentClaim =
	`
	id
	appId
	requestCode
	providerCustomDataJson
	issueDate
	store {
		id
		name{
			invariant
		}
		businessInfo {
			stripeConnectInfo {
			    connectedAccountId
				accountDetailsSubmitted
				chargesEnabled
				payoutsEnabled
				stripeConnectAccountLink
			}
		}
	}
	issuerUser {
		username
		firstName
		lastName
		email
		telephoneNumber
	}
	payingUser {
		username
		firstName
		lastName
		email
		telephoneNumber
	}
	refundedAmount
	virtualRefundedAmount
	amount
	authorizedAmount
	currency
	description
	status
	feeAmount
	transactionId
	transactionMessage
`;

const requestCode =
	`
    paymentClaim {
      ${paymentClaim}
    }
`;

export const READ_PAYMENT_REQUEST = new Query(
	`
	query _($appId: String, $storeId: String, $paymentClaimId: String) {
		paymentClaim(appId: $appId, storeId: $storeId, paymentClaimId: $paymentClaimId) {
			${paymentClaim}
		}
	}
	`
);

export const ISSUE_PAYMENT_REQUEST = new Query(
	`
	mutation _($appId: String, $storeId: String, $amount: Float, $description: String,) {
		issuePaymentRequest(appId: $appId, storeId: $storeId, amount: $amount, description: $description) {
			${paymentClaim}
		}
	}
	`
);

export const SETUP_PAYMENT_METHOD = new Query(
	`
	mutation _($providerCustomData: String) {
		setupPaymentMethod(providerCustomData: $providerCustomData) {
			providerCustomDataJson
		}
	}
	`
);

export const SETUP_PAYMENT_METHOD_FOR_COMPANY = new Query(
	`
	mutation _($appId: String, $companyId: String, $providerCustomData: String) {
		setupPaymentMethodForCompany(appId: $appId, companyId: $companyId, providerCustomData: $providerCustomData) {
			providerCustomDataJson
		}
	}
	`
);

export const SETUP_PAYMENT_METHOD_FOR_STORE = new Query(
	`
	mutation _($appId: String, $storeId: String, $providerCustomData: String) {
		setupPaymentMethodForStore(appId: $appId, storeId: $storeId, providerCustomData: $providerCustomData) {
			providerCustomDataJson
		}
	}
	`
);

export const SAVE_PAYMENT_METHOD = new Query(
	`
	mutation _($providerCustomData: String, $setAsDefault: Boolean) {
		savePaymentMethod(providerCustomData: $providerCustomData, setAsDefault: $setAsDefault) {
			providerCustomDataJson
		}
	}
	`
);

export const SAVE_PAYMENT_METHOD_FOR_COMPANY = new Query(
	`
	mutation _($appId: String, $companyId: String, $providerCustomData: String, $setAsDefault: Boolean) {
		savePaymentMethodForCompany(appId: $appId, companyId: $companyId, providerCustomData: $providerCustomData, setAsDefault: $setAsDefault) {
			providerCustomDataJson
		}
	}
	`
);

export const SAVE_PAYMENT_METHOD_FOR_STORE = new Query(
	`
	mutation _($appId: String, $storeId: String, $providerCustomData: String, $setAsDefault: Boolean) {
		savePaymentMethodForStore(appId: $appId, storeId: $storeId, providerCustomData: $providerCustomData, setAsDefault: $setAsDefault) {
			providerCustomDataJson
		}
	}
	`
);

export const CANCEL_PAYMENT_REQUEST = new Query(
	`
	mutation _($appId: String, $storeId: String, $paymentClaimId: String) {
		cancelPaymentRequest(appId: $appId, storeId: $storeId, paymentClaimId: $paymentClaimId) {
			${paymentClaim}
		}
	}
	`
);

export const CLAIM_PAYMENT = new Query(
	`
	mutation _($appId: String, $paymentClaimId: String, $providerCustomData: String) {
		claimPayment(appId: $appId, paymentClaimId: $paymentClaimId, providerCustomData: $providerCustomData) {
			providerCustomDataJson
		}
	}
	`
);

export const PAYMENT_METHOD =
	`
    stripePaymentMethodId
	stripeFingerprint
	type
	lastDigits
	defaultPaymentMethod
`;

export const GET_PAYMENT_METHODS = new Query(
	`
	query _ {
		getPaymentMethods {
			${PAYMENT_METHOD}
		}
	}
	`
);

export const GET_PAYMENT_METHODS_FOR_COMPANY = new Query(
	`
	query _($appId: String, $companyId: String) {
		getPaymentMethodsForCompany(appId: $appId, companyId: $companyId) {
			${PAYMENT_METHOD}
		}
	}
	`
);

export const GET_PAYMENT_METHODS_FOR_STORE = new Query(
	`
	query _($appId: String, $storeId: String) {
		getPaymentMethodsForStore(appId: $appId, storeId: $storeId) {
			${PAYMENT_METHOD}
		}
	}
	`
);

export const GET_FAVOURITE_PAYMENT_METHOD = new Query(
	`
	query _ {
		getFavouritePaymentMethod {
			${PAYMENT_METHOD}
		}
	}
	`
);

export const REMOVE_PAYMENT_METHOD = new Query(
	`
	mutation _($providerCustomData: String) {
		removePaymentMethod(providerCustomData: $providerCustomData)
	}
	`
);

export const REMOVE_PAYMENT_METHOD_FOR_COMPANY = new Query(
	`
	mutation _($appId: String, $companyId: String, $providerCustomData: String) {
		removePaymentMethodForCompany(appId: $appId, companyId: $companyId, providerCustomData: $providerCustomData)
	}
	`
);

export const REMOVE_PAYMENT_METHOD_FOR_STORE = new Query(
	`
	mutation _($appId: String, $companyId: String, $providerCustomData: String) {
		removePaymentMethodForStore(appId: $appId, companyId: $companyId, providerCustomData: $providerCustomData)
	}
	`
);

export const SET_DEFAULT_PAYMENT_METHOD = new Query(
	`
	mutation _($providerCustomData: String) {
		setDefaultPaymentMethod(providerCustomData: $providerCustomData)
	}
	`
);

export const SET_DEFAULT_PAYMENT_METHOD_FOR_STORE = new Query(
	`
	mutation _($appId: String, $storeId: String, $providerCustomData: String) {
		setDefaultPaymentMethodForStore(appId: $appId, storeId: $storeId, providerCustomData: $providerCustomData)
	}
	`
);

export const SET_DEFAULT_PAYMENT_METHOD_FOR_COMPANY = new Query(
	`
	mutation _($appId: String, $companyId: String, $providerCustomData: String) {
		setDefaultPaymentMethodForCompany(appId: $appId, companyId: $companyId, providerCustomData: $providerCustomData)
	}
	`
);

export const GET_PAYMENTS_FOR_USER = new Query(
	`
	query _($appId: String, $storeId: String) {
		getPaymentClaimsForUser(appId: $appId, storeId: $storeId) {
			id
			appId
			store {
				id
				name {
					invariant
				}
			}
			payingUser {
				id
				firstName
				lastName
			}
			refundedAmount
			virtualRefundedAmount
			issueDate
			paymentDate
			status
			amount
			authorizedAmount
			currency
			description
			requestCode
			order {
				id
				status
			}
		}
	}
	`
);

export const GET_PAYMENTS_FOR_STORE = new Query(
	`
	query _($appId: String, $storeId: String) {
		getPaymentClaimsForStore(appId: $appId, storeId: $storeId) {
			id
			appId
			store {
				id
				name {
					invariant
				}
			}
			payingUser {
				id
				firstName
				lastName
			}
			refundedAmount
			virtualRefundedAmount
			issueDate
			paymentDate
			status
			amount
			authorizedAmount
			currency
			description
			requestCode
			order {
				id
				status
			}
		}
	}
	`
);

export const RETRIEVE_REQUEST_CODE = new Query(
	`
	query _($requestCode: String) {
		paymentRequestCode(requestCode: $requestCode) {
			${requestCode}
		}
	}
	`
);


export const RETRIEVE_VALID_PAYMENT_CLAIM_BY_ORDER = new Query(
	`
	query _($appId: String, $orderId: String) {
		findValidPaymentClaimByOrder(appId: $appId, orderId: $orderId) {
			${paymentClaim}
		}
	}
	`
);

export const GET_PAYMENT_REFUNDABLE_AMOUNT = new Query(
	`
	query _($appId: String, $storeId: String, $paymentClaimId: String) {
		getPaymentRefundableAmount(appId: $appId, storeId: $storeId, paymentClaimId: $paymentClaimId) {
			amount
			currency
		}
	}
	`
);

export const REFUND_PAYMENT = new Query(
	`
	mutation _($appId: String, $storeId: String, $paymentClaimId: String, $amount: Float, $refundReason: PAYMENT_REFUND_REASON) {
		refundPayment(appId: $appId, storeId: $storeId, paymentClaimId: $paymentClaimId, amount: $amount, refundReason: $refundReason)
	}
	`
);
