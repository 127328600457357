import { Query } from "../core/services/query";
import gql from "graphql-tag";

export const REGISTER_USER_FOR_PUSH = new Query(
	`
	mutation _($appId: String, $registrationId: String) {
		registerUserForPush(appId: $appId, registrationId: $registrationId)
	}
	`
);

export const UNREGISTER_USER_FROM_PUSH = new Query(
	`
	mutation _($appId: String, $registrationId: String) {
		unregisterUserFromPush(appId: $appId, registrationId: $registrationId)
	}
	`
);

export const CLEAR_NOTIFICATIONS_BY_FEATURE = new Query(
	`
	mutation _($appId: String, $feature: String, $event: String) {
		clearNotificationsByFeature(appId: $appId, feature: $feature, event:$event)
	}
	`
);
