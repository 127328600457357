import { AppConfigurationService } from "../../core/services/app-configuration.service";
import { TranslateService } from "@ngx-translate/core";
import { LocalStorageService } from "../../core/services/local-storage.service";
import {
	ACCEPT_PROPOSAL,
	ACQUIRE_ORDER,
	CONFIRM_ORDER,
	CONFIRM_ORDER_ITEM,
	DELETE_ORDER,
	FORCE_ORDER_ITEM,
	GET_CLOSED_ORDERS_BY_STORE,
	GET_CLOSED_ORDERS_BY_USER,
	GET_DELIVERY_SHIPMENT_CALENDAR,
	GET_LATEST_CART,
	GET_OPEN_ORDERS_BY_STORE,
	GET_OPEN_ORDERS_BY_USER,
	GET_ORDER_DETAILS,
	GET_DELIVERY_AVAILABILITY,
	IS_DELIVERY_SHIPMENT_ZIP_AVAILABLE,
	PROPOSE_ORDER,
	REJECT_ORDER_ITEM,
	SEND_ORDER,
	SET_AMOUNT_ADJUSTMENT,
	UPDATE_ORDER_ITEM,
	UPDATE_ORDER_DELIVERY,
	REJECT_ORDER,
	ORDER_READY,
	SHIP_ORDER,
	ORDER_DELIVERED,
	PAYMENT_REQUEST_FOR_ORDER,
	SET_ORDER_PAYMENT_METHOD,
	FIND_DELIVERY_SERVICE,
	COUNT_CART_ORDERS_WITH_PRODUCT,
	PREVIEW_CATALOG_SECTION_DEACTIVATION,
	PREPARE_SEND_ORDER, ROLLBACK_PREPARE_SEND_ORDER
} from "./ordering-queries";
import { Injectable } from "@angular/core";
import { cloneObject, round3, safeAwaitArray, safeAwaitOrDefault } from "src/app/core/utils";
import { AlertController } from "@ionic/angular";
// per la creazione degli Id simil mongodb per l'aggiornamento furbo del carrello
import ObjectID from "bson-objectid";
import {
	AmountAdjustment,
	DateRange,
	DELIVERY_TYPE,
	Order,
	ORDER_ITEM_STATUS,
	ORDER_STATUS,
	OrderItem,
	OrderPaginatedResponse,
	Pagination,
	Product,
	ServiceAvailability,
	User,
	ZipCodeShipmentSchedule,
	PaymentClaim, PAYMENT_METHOD, FEATURE, Service, ProductOrderStatistics
} from "../../backend-api";
import { statics } from "src/app/core/statics";
import { AppNavigatorService } from "src/app/core/services/app-navigator.service";
import { formatNumber } from "@angular/common";
import { CartMap } from "src/app/core/cart-map";

@Injectable({
	providedIn: "root"
})
export class OrderingService {

	// its a way to "export" the enum for using it in the html template
	orderStatus = ORDER_STATUS;
	orderItemStatus = ORDER_ITEM_STATUS;

	// cart: Partial<Order> = {
	// 	orderItems: []
	// };

	// carrello, diviso per app
	cartMap = new CartMap({
		// inizializzazione di nuovo carrelli
		configFnc: (key: string) => {
			return {
				orderItems: []
			};
		}
	});

	private statusNavigationMap = {
		accepted: [ORDER_STATUS.confirmed, ORDER_STATUS.ready, ORDER_STATUS.shipped, ORDER_STATUS.delivered],
		confirmed: [ORDER_STATUS.ready, ORDER_STATUS.shipped, ORDER_STATUS.delivered],
		ready: [ORDER_STATUS.shipped, ORDER_STATUS.delivered],
		shipped: [ORDER_STATUS.delivered]
	};

	constructor(
		private appConfigService: AppConfigurationService,
		private translate: TranslateService,
		private localStorageService: LocalStorageService,
		private alertCtrl: AlertController) {
	}

	async getOpenOrdersByStore(appId: string, storeId: string): Promise<Order[]> {
		const params = {
			appId,
			storeId
		};

		const result = await this.appConfigService.queryManager.execute(GET_OPEN_ORDERS_BY_STORE, params, {
			pagination: {}
		});
		return result.edges.map(e => e.node);
	}

	async getClosedOrdersByStore(appId: string, storeId: string, pagination: Pagination): Promise<OrderPaginatedResponse> {
		const params = {
			appId,
			storeId
		};

		const result = await this.appConfigService.queryManager.execute(GET_CLOSED_ORDERS_BY_STORE, params, {
			pagination
		});
		return result;
	}

	async getOpenOrdersByUser(appId: string): Promise<Order[]> {
		const params = {
			appId
		};
		const result = await this.appConfigService.queryManager.execute(GET_OPEN_ORDERS_BY_USER, params, {
			pagination: {}
		});
		return result.edges.map(e => e.node);
	}

	async getClosedOrdersByUser(appId: string, pagination: Pagination): Promise<OrderPaginatedResponse> {
		const params = {
			appId
		};
		const result = await this.appConfigService.queryManager.execute(GET_CLOSED_ORDERS_BY_USER, params, {
			pagination
		});
		return result;
	}

	// TODO: da arricchire
	async getOrderDetails(appId: string, orderId: string): Promise<Order> {

		const params = {
			appId,
			orderId
		};
		const od: Order = await this.appConfigService.queryManager.execute(GET_ORDER_DETAILS, params);

		// dav 240520, fix per carrello contenente elementi eliminati dall'admin
		// scorre gli elementi e se ne trova qualcuno di vuoto lo elimina dalla lista e scrive sul log
		if (od?.orderItems?.length) {
			for (let i = od.orderItems.length - 1; i >= 0; i--) {
				const oi = od.orderItems[i];
				if (!oi?.product && !oi?.orderedProduct) {
					od.orderItems.splice(i, 1);
					console.warn("Trovato elemento mancante in ordine/carrello utente");
				}
			}
		}

		return od;
	}

	confirmOrderItem(
		appId: string,
		orderId: string,
		orderItem: OrderItem,
		storeId: string,
		forcedPricePerMeasureUnit?: number,
		modifyProduct?: boolean,
		forcedQuantity?: number): Promise<Order> {

		const params = {
			appId,
			orderId,
			orderItemId: orderItem.id,
			storeId,
			forcedPricePerMeasureUnit,
			modifyProduct,
			forcedQuantity
		};
		return this.appConfigService.queryManager.mutate(CONFIRM_ORDER_ITEM, params);
	}

	forceOrderItemPrice(
		appId: string,
		orderId: string,
		orderItem: OrderItem,
		storeId: string,
		forcedPricePerMeasureUnit: number,
		modifyProduct?: boolean): Promise<Order> {

		const params = {
			appId,
			orderId,
			orderItemId: orderItem.id,
			storeId,
			forcedPricePerMeasureUnit,
			modifyProduct
		};
		return this.appConfigService.queryManager.mutate(FORCE_ORDER_ITEM, params);
	}

	forceOrderItemQuantity(
		appId: string,
		orderId: string,
		orderItem: OrderItem,
		storeId: string,
		forcedQuantity: number): Promise<Order> {

		const params = {
			appId,
			orderId,
			orderItemId: orderItem.id,
			storeId,
			forcedQuantity
		};
		return this.appConfigService.queryManager.mutate(FORCE_ORDER_ITEM, params);
	}

	rejectOrderItem(
		appId: string,
		orderId: string,
		orderItem: OrderItem,
		storeId: string): Promise<Order> {

		const params = {
			appId,
			orderId,
			orderItemId: orderItem.id,
			storeId
		};
		return this.appConfigService.queryManager.mutate(REJECT_ORDER_ITEM, params);
	}

	issuePaymentRequestForOrder(
		appId: string,
		storeId: string,
		orderId: string): Promise<PaymentClaim> {

		const params = {
			appId,
			orderId,
			storeId
		};
		return this.appConfigService.queryManager.mutate(PAYMENT_REQUEST_FOR_ORDER, params);
	}

	// svuota il carrello
	async deleteCart(appId: string, callKey?: string) {

		// se l'utente è connesso esegue la chiamata remota
		if (this.appConfigService.loggedUser) {

			// Prende il carrello dall'app corrente
			const cart = this.cartMap.get(appId);

			// se il carrello è valido
			if (cart.id) {

				// carrello svuotato
				await this.deleteOrder(cart.id, appId, cart.store.id, callKey);

				// qui azzera anche il cartId
				this.cartMap.reset(appId);

				// rimuove i dati da locale
				await this.localStorageService.clearCartId(appId);
			}
		}
		else {
			// qui azzera anche il cartId
			this.cartMap.reset(appId);

			// rimuove i dati da locale
			await this.localStorageService.clearCartId(appId);
		}
	}

	async loadLatestCartIfSupportedByStoreOrApp(
		appId: string,
		optStoreId: string,
		noEvent = false,
		resetFirst = true): Promise<void> {

		// dav 210520, se l'app corrente non gestisce il carrello esce
		if (!this.appConfigService.storeOrAppOwnFeature(appId, optStoreId, FEATURE.ordering)) {
			this.cartMap.reset(appId, noEvent);
			return;
		}

		// importante se ricarico in seguito ad un login/logout
		if (resetFirst) {
			this.cartMap.reset(appId, noEvent);
		}

		// prende l'id del carrello locale (usato solo dall'user anonimo)
		const localCartId = await this.localStorageService.loadCartId(appId);

		if (this.appConfigService.loggedUser) {

			// reset cart info and local id if user is connected
			await this._forgetLocalCart(appId);

			// legge le info dell'ultimo carrello utente (solo Id)
			const latestUserCartId: string = await safeAwaitOrDefault(this.callGetLatestCartId(appId)); // app singola o piattaforma
			if (latestUserCartId) {

				// legge i dettagli del carrello utente
				const [cartError, tempCart] = await safeAwaitArray(this._getCartDetailsOrReject(latestUserCartId, appId));
				if (tempCart) {
					// aggiorna il carrello globale
					this.cartMap.set(tempCart, appId, noEvent);
				}
				else {
					// se per qualsiasi motivo non riesco a caricare il carrello lo ricreo perchè potrebbe tornare a null
					// reset cart info and local id if user is connected
					await this._forgetLocalCart(appId);
				}
			}
			else {

				// l'utente connesso non ha un carrello, vedo se l'utente anonimo ne aveva uno
				if (localCartId) {

					// prende il carrello locale e lo assegna all'utente corrente
					const [localCartError, localCart] = await safeAwaitArray(this.acquireOrder(appId, localCartId));
					if (localCart) {
						// aggiorna il carrello globale
						this.cartMap.set(localCart, appId);
					}
				}
			}
		}
		else {

			const [tCartErr, tCart] = await safeAwaitArray(this._getCartDetailsOrReject(localCartId, appId));
			// aggiorna il globale
			if (tCart) {
				this.cartMap.set(tCart, appId, noEvent);
			}
		}
	}

	// legge i dettagli di un certo ordine di tipo cart oppure ritorna null se qualcosa non va per il verso giusto
	private _getCartDetailsOrReject(cartId: string, appId: string): Promise<Order> {

		return new Promise(async (resolve, reject) => {

			// se esiste un ID carrello ne carica al volo le info
			if (cartId) {

				try {
					const orderDetails = await this.getOrderDetails(appId, cartId);
					if (orderDetails) {

						// per sicurezza controlla che l'ordine che ho in canna sia in stato cart; magari l'avevo
						// confermato ma non sono riuscito a salvare l'id vuoto del carrello
						if (orderDetails.status !== ORDER_STATUS.cart) {

							// this order is no more in cart status, so detach local cart
							await this._forgetLocalCart(appId);
						}
						else {

							resolve(orderDetails);
							return; // qui deve uscire altrimenti fa anche reject
						}
					}
					else {
						// cart is wrong
						await this._forgetLocalCart(appId);
					}
				} catch (e) {
					console.error("Error calling 'orderDetails': " + e);
					await this._forgetLocalCart(appId);
				}
			}

			reject();
		});
	}

	private async _forgetLocalCart(appId: string) {

		// resetto cartId
		this.cartMap.get(appId).id = null;

		// salvo cartId vuoto per la prox volta
		await this.localStorageService.clearCartId(appId);
	}

	// ritorna tutti gli orderItems nel carrello contenenti quel prodotto
	queryOrderItemsByProduct(
		prd: Product,
		fillWithThisIfNotExist?: Product): OrderItem[] {

		// ottiene il cart dell'app passata
		const appCart = this.cartMap.get(prd.appId);

		const ret: OrderItem[] = [];
		for (const oi of appCart?.orderItems) {
			if (oi?.product?.id === prd.id) {
				ret.push(oi);
			}
		}

		// se non ne ha trovati e si vuole aggiungerne 1 lo aggiunge alla lista
		if (ret.length === 0 && fillWithThisIfNotExist) {
			const oi = {
				id: null, // treppo
				requestedQuantity: 0,
				product: fillWithThisIfNotExist,
				user: (this.appConfigService.loggedUser as User)
			};

			if (!appCart.orderItems) {
				appCart.orderItems = [];
			}
			appCart.orderItems.push(oi);

			ret.push(oi)
		}

		return ret;
	}

	// ritorna vero se può aggiungere al carrello "quantity" elementi
	// leggendo da tutti gli orderItem che contengono quel prodotto
	canAddMore(p: Product): boolean {

		// vede se è già nel carrello
		const aoi = this.queryOrderItemsByProduct(p);

		if (aoi.length > 0) {
			let ret = true;
			for (const oi of aoi) {
				ret = ret && this._canAddMoreOrderItem(oi.product, oi);
			}
			return ret;
		}
		else {
			return this._canAddMoreOrderItem(p, undefined);
		}

	}

	// vede se posso aggiungere più quantità al prodotto nell'orderItem corrente
	private _canAddMoreOrderItem(p: Product, oi?: OrderItem): boolean {

		if (p.availabilityCheckActive) {

			let requestedQuantity = 0;
			if (oi) {
				requestedQuantity = round3(oi.requestedQuantity); // per sicurezza arrotondo
			}

			// nuovo incremento
			let quantityToRequire = p.minimumLot;
			if (requestedQuantity) {
				quantityToRequire = p.quantityStep || p.minimumLot;
			}

			return requestedQuantity + quantityToRequire <= p.availableQuantity;
		}

		return true;
	}

	// viene chiamata in modo delayed quando si modificano i prodotti del carrello
	async execUpdateOrderItem(
		p: Product,
		oi: OrderItem,
		diff: number
	): Promise<void> {

		// app corrente
		const appId = p.appId;

		// carrello corrente
		let appCart = this.cartMap.get(appId);

		try {

			// dav 301219, quando oi viene inserito, genero un nuovo ID cosi lo posso monitorare e aggiornare solo quello a video
			if (!oi.id) {
				oi.id = new ObjectID().toHexString();
			}

			// assegna il carrello in uscita
			const updatedCart = await this.updateOrderItem(appId, this.appConfigService.getCurrentStoreId(appId), oi, appCart.id);

			// aggiorna la vista
			appCart = this.updateOrderItemInCartAndGetNewCart(oi, appCart, updatedCart);

			// aggiorna sul globale il nuovo riferimento
			this.cartMap.set(appCart, appId);

			// dav fix 270919, scambio carrello tra utenti
			if (this.appConfigService.loggedUser) {
				// elimino i dati di localstorage
				await this.localStorageService.clearCartId(appId);
			}
			else {
				// salvo il cartId nel localStorage
				await this.localStorageService.saveCartId(appCart.id, appId);
			}

		}
		catch (e) {
			const errorDescription = this.appConfigService.queryManager.getErrorDescription(e, "ordering.ERROR_UPDATING_CART");
			this.alertCtrl.create({
				header: this.translate.instant("ERROR"),
				message: errorDescription,
				buttons: [this.translate.instant("OK")]
			}).then(errMsg => {
				errMsg.present();
			});

			console.error("Error updating items in remote cart", e);

			//if update of cart went in error, rollback the change on this order item
			oi.requestedQuantity = round3(oi.requestedQuantity - diff);
			if (oi.requestedQuantity <= 0) {
				const i = this._searchOrderItemInArray(oi.id, appCart.orderItems);
				if (i >= 0) {
					appCart.orderItems.splice(i, 1);
				}
			}
		}

	}

	// cerca nell'array degli order item quell'elemento
	private _searchOrderItemInArray(orderItemId: string, orderItems: OrderItem[]): number {
		if (orderItems) {
			for (let i = 0; i < orderItems.length; i++) {
				const oi = orderItems[i];
				if (oi.id === orderItemId) {
					return i;
				}
			}
		}
		return -1;
	}

	// ritorna l'ultimo carrello utilizzato dall'utente corrente
	async callGetLatestCartId(appId: string): Promise<string> {
		const vars = {
			appId
		};

		const order: Order = await safeAwaitOrDefault(this.appConfigService.queryManager.execute(GET_LATEST_CART, vars));
		return order?.id;
	}

	isDeliveryShipmentZipAvailable(
		appId: string,
		storeId: string,
		range: DateRange,
		bookingCustomData: string): Promise<boolean> {

		const vars = {
			appId,
			storeId,
			range,
			bookingCustomData
		};

		return this.appConfigService.queryManager.execute(IS_DELIVERY_SHIPMENT_ZIP_AVAILABLE, vars);
	}

	getDeliveryShipmentCalendar(
		appId: string,
		storeId: string,
		bookingCustomData: string): Promise<ZipCodeShipmentSchedule[]> {

		const vars = {
			appId,
			storeId,
			bookingCustomData
		};

		return this.appConfigService.queryManager.execute(GET_DELIVERY_SHIPMENT_CALENDAR, vars);
	}

	getDeliveryAvailability(
		appId: string,
		storeId: string,
		deliveryType: DELIVERY_TYPE,
		bookingCustomData?: string,
		range?: DateRange): Promise<ServiceAvailability[]> {

		const vars = {
			appId,
			storeId,
			range,
			deliveryType,
			bookingCustomData
		};

		return this.appConfigService.queryManager.execute(GET_DELIVERY_AVAILABILITY, vars);
	}

	findDeliveryService(
		appId: string,
		storeId: string,
		deliveryType: DELIVERY_TYPE): Promise<Service> {

		const vars = {
			appId,
			storeId,
			deliveryType
		};

		return this.appConfigService.queryManager.execute(FIND_DELIVERY_SERVICE, vars) as Promise<Service>;
	}

	findDeliveryService_idOnly(
		appId: string,
		storeId: string,
		deliveryType: DELIVERY_TYPE): Promise<Service> {

		const vars = {
			appId,
			storeId,
			deliveryType
		};

		return this.appConfigService.queryManager.execute(FIND_DELIVERY_SERVICE, vars);
	}

	// cancellazione del carrello, da chiamare solo se l'utente è connesso
	deleteOrder(orderId: string, appId: string, storeId: string, callKey?: string): Promise<any> {

		const loggedUser = this.appConfigService.loggedUser;
		const vars = {
			appId,
			orderId,
			storeId
		};

		// controllo congruenza
		if (!loggedUser || !vars.orderId) {
			throw Error("You must be logged in and have an order to delete it");
		}

		return this.appConfigService.queryManager.mutate(DELETE_ORDER, vars, {callKey});
	}

	// Permette da parte dell'utente appena connesso di acquisire il carrello dell'utente anonimo
	acquireOrder(
		appId: string,
		orderId: string): Promise<Order> {

		const loggedUser = this.appConfigService.loggedUser;
		const vars = {
			appId,
			orderId
		};

		// controllo congruenza
		if (!loggedUser || !vars.orderId) {
			throw Error("You must be logged in to acquire an order");
		}

		return this.appConfigService.queryManager.mutate(ACQUIRE_ORDER, vars);
	}

	/**
	 * Ritorna vero se il carrello contiene del prodotti
	 */
	cartHasProducts(appId: string): boolean {
		return (this.countCartItems(appId) > 0);
	}

	cartHasProductWithVariablePrice(appId: string): boolean {

		const cart = this.cartMap.get(appId);
		if (cart.orderItems) {
			for (const oi of cart.orderItems) {
				const prod = oi.product;
				if (!prod?.priceBlocked) { // dav 240520 - se l'admin elimina un prodotto dal catalogo ritorna null
					return true;
				}
			}
		}
		return false;
	}

	cartHasProductWithVariableQuantity(appId: string): boolean {

		const cart = this.cartMap.get(appId);
		if (cart.orderItems) {
			for (const oi of cart.orderItems) {
				const prod = oi.product;
				if (!prod?.exactQuantity) { // dav 240520 - se l'admin elimina un prodotto dal catalogo ritorna null
					return true;
				}
			}
		}
		return false;
	}

	/**
	 * Ritorna il conteggio dei prodotti di tipo diverso trovati nel carrello
	 */
	countCartItems(appId: string): number {
		const cart = this.cartMap.get(appId);
		return cart?.orderItems?.length || 0;
	}

	// ritorna vero se il carrello contiene una quantità > 0 tra tutti gli orderItems che contengono quel prodotto
	getTotalRequestedQuantityByProduct(p: Product): number {
		const aoi = this.queryOrderItemsByProduct(p);
		let ret = 0;

		for (const oi of aoi) {
			ret += (oi.requestedQuantity || 0);
		}

		return ret;
	}

	/**
	 * Ritorna il totale del carrello
	 */
	totalCartPrice(appId: string): number {
		const appCart = this.cartMap.get(appId);
		return appCart.amount || 0;
	}

	/**
	 * Ritorna il prezzo totale per prodotto
	 */
	// totalProductPriceById(pid: string): number {

	// 	const oi = this.queryOrderItemById(pid);
	// 	const prod = oi.product;

	// 	return oi.requestedQuantity * prod.pricePerMeasureUnit;
	// }

	/**
	 * Aggiorna il tipo di delivery dell'ordine
	 */
	updateOrderDelivery(
		deliveryDate: Date | DateRange,
		deliveryType: DELIVERY_TYPE,
		bookingCustomData: string,
		appId: string): Promise<Order> {

		// carrello corrente
		const appCart = this.cartMap.get(appId);

		// controlli di validità fatti dentro alla chiamata
		return this._callUpdateOrderDelivery(
			this.appConfigService.getCurrentStoreId(appId),
			appCart.id,
			deliveryDate,
			deliveryType,
			bookingCustomData,
			appId);
	}

	prepareSendOrder(appId: string, message: string): Promise<Order> {
		// carrello corrente
		const appCart = this.cartMap.get(appId);

		const params = {
			appId,
			orderId: appCart.id,
			storeId: this.appConfigService.getCurrentStoreId(appId),
			message
		};
		return this.appConfigService.queryManager.mutate(PREPARE_SEND_ORDER, params);
	}

	rollbackPrepareSendOrder(appId: string): Promise<Order> {
		// carrello corrente
		const appCart = this.cartMap.get(appId);

		const params = {
			appId,
			orderId: appCart.id,
			storeId: this.appConfigService.getCurrentStoreId(appId)
		};
		return this.appConfigService.queryManager.mutate(ROLLBACK_PREPARE_SEND_ORDER, params);
	}

	/**
	 * Invia l'ordine, se va a buon fine svuota il carrello
	 */
	sendOrder(
		paymentMethod: PAYMENT_METHOD,
		message: string,
		appId: string): Promise<Order> {

		// carrello corrente
		const appCart = this.cartMap.get(appId);

		// controlli di validità fatti dentro alla chiamata
		return this._callSendOrder(
			this.appConfigService.getCurrentStoreId(appId),
			appCart.id,
			paymentMethod,
			message,
			appId);

		// simulo una chiamata andata a buon fine
		// this.emptyCart();
	}

	/**
	 * Imposta la modalita' di pagamento per l'ordine
	 */
	setOrderPaymentMethod(
		paymentMethod: PAYMENT_METHOD,
		appId: string): Promise<Order> {

		// carrello corrente
		const appCart = this.cartMap.get(appId);
		const loggedUser = this.appConfigService.loggedUser;

		const vars = {
			appId,
			orderId: appCart.id,
			storeId: this.appConfigService.getCurrentStoreId(appId),
			paymentMethod,
		};

		return this.appConfigService.queryManager.mutate(SET_ORDER_PAYMENT_METHOD, vars);
	}

	updateOrderItem(appId: string, storeId: string, oi: OrderItem, orderId?: string): Promise<Order> {

		const lu = this.appConfigService.loggedUser;
		const vars = {
			orderItem: oi,
			orderId,
			appId,
			storeId,
			userId: lu ? lu.id : null
		};

		return this.appConfigService.queryManager.mutate(
			UPDATE_ORDER_ITEM,
			vars
		);
	}

	setAmountAdjustment(appId: string, orderId: string, storeId: string, amountAdjustment: AmountAdjustment): Promise<Order> {
		const lu = this.appConfigService.loggedUser;
		const vars = {
			amountAdjustment,
			orderId,
			appId,
			storeId,
			userId: lu ? lu.id : null
		};

		return this.appConfigService.queryManager.mutate(
			SET_AMOUNT_ADJUSTMENT,
			vars
		);
	}


	confirmOrder(orderId: string, appId: string, storeId: string): Promise<Order> {
		const loggedUser = this.appConfigService.loggedUser;
		const vars = {
			appId,
			orderId,
			storeId
		};

		// controllo congruenza
		if (!loggedUser || !vars.orderId) {
			throw Error("You must be logged in and have an order to delete it");
		}

		return this.appConfigService.queryManager.mutate(CONFIRM_ORDER, vars);
	}

	proposeOrder(orderId: string, appId: string, storeId: string): Promise<Order> {

		const loggedUser = this.appConfigService.loggedUser;
		const vars = {
			appId,
			orderId,
			storeId
		};

		// controllo congruenza
		if (!loggedUser || !vars.orderId) {
			throw Error("You must be logged in and have a order to delete it");
		}

		return this.appConfigService.queryManager.mutate(PROPOSE_ORDER, vars);
	}

	acceptProposal(orderId: string, appId: string, storeId: string): Promise<Order> {

		const loggedUser = this.appConfigService.loggedUser;
		const vars = {
			appId,
			orderId,
			storeId
		};

		// controllo congruenza
		if (!loggedUser || !vars.orderId) {
			throw Error("You must be logged in and have a order to delete it");
		}

		return this.appConfigService.queryManager.mutate(ACCEPT_PROPOSAL, vars);
	}

	orderReady(orderId: string, appId: string, storeId: string): Promise<Order> {

		const loggedUser = this.appConfigService.loggedUser;
		const vars = {
			appId,
			orderId,
			storeId
		};
		// controllo congruenza
		if (!loggedUser || !vars.orderId) {
			throw Error("You must be logged in and have a order to delete it");
		}
		return this.appConfigService.queryManager.mutate(ORDER_READY, vars);
	}

	shipOrder(orderId: string, appId: string, storeId: string): Promise<Order> {

		const loggedUser = this.appConfigService.loggedUser;
		const vars = {
			appId,
			orderId,
			storeId
		};
		// controllo congruenza
		if (!loggedUser || !vars.orderId) {
			throw Error("You must be logged in and have a order to delete it");
		}
		return this.appConfigService.queryManager.mutate(SHIP_ORDER, vars);
	}

	orderDelivered(orderId: string, appId: string, storeId: string): Promise<Order> {

		const loggedUser = this.appConfigService.loggedUser;
		const vars = {
			appId,
			orderId,
			storeId
		};
		// controllo congruenza
		if (!loggedUser || !vars.orderId) {
			throw Error("You must be logged in and have a order to delete it");
		}
		return this.appConfigService.queryManager.mutate(ORDER_DELIVERED, vars);
	}

	rejectOrder(orderId: string, appId: string, storeId: string): Promise<Order> {

		const loggedUser = this.appConfigService.loggedUser;
		const vars = {
			appId,
			orderId,
			storeId
		};
		// controllo congruenza
		if (!loggedUser || !vars.orderId) {
			throw Error("You must be logged in and have a order to delete it");
		}
		return this.appConfigService.queryManager.mutate(REJECT_ORDER, vars);
	}

	validStatus(currentOrder: Order): ORDER_STATUS[] {
		// no logic att the moment
		const status: string = currentOrder.status.toString();
		return this.statusNavigationMap[status];
	}

	//??????????????????????
	compareWith = (status1, status2) => {
		return status1 === status2;
	}

	public isOpen(order: Order): boolean {
		return (order.status === ORDER_STATUS.sending ||
			order.status === ORDER_STATUS.sent ||
			order.status === ORDER_STATUS.proposed ||
			order.status === ORDER_STATUS.new_proposal ||
			order.status === ORDER_STATUS.confirmed ||
			order.status === ORDER_STATUS.shipped ||
			order.status === ORDER_STATUS.ready ||
			order.status === ORDER_STATUS.accepted);
	}

	public isOrderClosed(order: Order): boolean {
		if (!order) {
			return true;
		}
		const orderStatus = order.status;
		return orderStatus === ORDER_STATUS.cancelled ||
			orderStatus === ORDER_STATUS.delivered ||
			orderStatus === ORDER_STATUS.ready ||
			orderStatus === ORDER_STATUS.shipped;
	}

	/**
	 * Inserts the provided order item in the actual cart, replacing the existing order item or adding as a new item,
	 * trying to avoid updating the whole cart, in this way only the specific item part is updated in UI.
	 */
	private updateOrderItemInCartAndGetNewCart(
		oi: OrderItem,
		cart: Partial<Order>,
		newCart: Partial<Order>): Partial<Order> {

		if (!oi.id) {
			throw Error("Unexpected condition");
		}

		//
		// qui oi.id è sempre valorizzato, anche per gli elementi appena inseriti
		//

		// salva gli items attuali
		const thisCart: Order = cloneObject(cart) as Order;

		// carrello nuovo da cui prendere i dati
		newCart.orderItems = newCart.orderItems || [];
		const indexInNewCart = newCart.orderItems.findIndex(current => current.id === oi.id);

		// carrello corrente, da aggiornare
		thisCart.orderItems = thisCart.orderItems || [];
		const indexInThisCart = thisCart.orderItems.findIndex(current => current.id === oi.id);

		if (indexInNewCart >= 0) { // trovato nel nuovo carrello

			if (indexInThisCart >= 0) {
				// vedo se esiste nel vecchio e nel caso lo sostituisce
				thisCart.orderItems[indexInThisCart] = newCart.orderItems[indexInNewCart];
			}
			else {
				// lo aggiunge
				thisCart.orderItems.push(newCart.orderItems[indexInNewCart]);
			}

		}
		else { // non presente nel nuovo carrello

			if (indexInThisCart >= 0) {
				// se esiste lo toglie visto che nel nuovo non c'è
				thisCart.orderItems.splice(indexInThisCart, 1);
			}

		}

		// adesso assegna a newCart gli items clonati dal vecchio che ho aggiornato
		newCart.orderItems = thisCart.orderItems;

		// assegna il nuovo
		return newCart;
	}

	// spedizione dell'ordine
	private _callUpdateOrderDelivery(
		storeId: string,
		orderId: string,
		deliveryDate: Date | DateRange,
		deliveryType: DELIVERY_TYPE,
		bookingCustomData: string,
		appId: string): Promise<Order> {

		if (!storeId) {
			throw Error("callSendOrder: storeId is missing");
		}

		const loggedUser = this.appConfigService.loggedUser;

		if (deliveryDate instanceof Date) {
			deliveryDate = { startDate: deliveryDate };
		}

		const vars = {
			appId,
			orderId,
			storeId,
			deliveryDate,
			deliveryType,
			bookingCustomData
		};

		// controllo congruenza
		if (!loggedUser || !vars.orderId) {
			throw Error("You must be logged in and have a cart to update order delivery");
		}

		return this.appConfigService.queryManager.mutate(UPDATE_ORDER_DELIVERY, vars);
	}

	// spedizione dell'ordine
	private _callSendOrder(
		storeId: string,
		orderId: string,
		paymentMethod: PAYMENT_METHOD,
		message: string,
		appId: string): Promise<Order> {

		// dav 131119
		if (!storeId) {
			throw Error("callSendOrder: storeId is missing");
		}

		const loggedUser = this.appConfigService.loggedUser;

		const vars = {
			appId,
			orderId,
			storeId,
			paymentMethod,
			message,
		};

		// controllo congruenza
		if (!loggedUser || !vars.orderId) {
			throw Error("You must be logged in and have a cart to execute the order");
		}

		return this.appConfigService.queryManager.mutate(SEND_ORDER, vars);
	}

	// ritorna il numero di carrelli o ordini non chiusi che contengono quel prodotto
	// serve in caso si disabiliti un prodotto da catalogo
	countCartOrdersWithProduct(appId: string, productId: string): Promise<number> {
		const vars = {
			appId,
			productId
		};

		return this.appConfigService.queryManager.execute(COUNT_CART_ORDERS_WITH_PRODUCT, vars);
	}

	previewCatalogSectionDeactivation(appId: string, catalogId: string, catalogSectionId?: string): Promise<ProductOrderStatistics> {
		const vars = {
			appId,
			catalogId,
			catalogSectionId
		};

		return this.appConfigService.queryManager.execute(PREVIEW_CATALOG_SECTION_DEACTIVATION, vars);
	}

}
