import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root"
})
export class ShowOverlayService {

	static calls = 0;
	private readonly overlayId = "__kaleidos_overlay_element__";
	private readonly kaleidosOverlayClass = "kaleidos_overlay";

	constructor() { }

	showOverlay() {
		ShowOverlayService.calls++;

		/////console.log("ShowOverlayService show " + ShowOverlayService.calls);

		if (ShowOverlayService.calls > 0) {
			let el = document.getElementById(this.overlayId);
			if (el) {
				el.style.display = "block";
			}
			else {
				el = document?.body?.appendChild(document.createElement("div"));
				el.id = this.overlayId;
				el.className = this.kaleidosOverlayClass;
			}
		}
	}

	hideOverlay() {
		ShowOverlayService.calls--;
		if (ShowOverlayService.calls < 0) {
			ShowOverlayService.calls = 0;
		}

		/////console.log("ShowOverlayService hide " + ShowOverlayService.calls);

		if (ShowOverlayService.calls === 0) {
			const el = document.getElementById(this.overlayId);
			if (el) {
				el.style.display = "none";
			}
		}
	}
}
