<ion-footer>
    <ion-grid>
        <ion-row class="ion-align-items-center ion-justify-content-center ion-nowrap">
            <ion-col *ngFor="let footerButton of footerButtons()" size="{{12 / footerButtons().length}}" class="footerCol ion-align-self-center">
                <div style="text-align: center;" class="div-footer-col-container ion-activatable" (click)="routeTo(footerButton)">
                    <ion-ripple-effect></ion-ripple-effect>
                    <ion-icon [name]="footerButton.icon"></ion-icon><br>
                    <ion-label>{{ footerButton.title | translate }}</ion-label><br>
                    <div class="badge" *ngIf="footerButton.itemKey==='cart' && orderingService.cartHasProducts(appNav.appId())">{{orderingService.countCartItems(appNav.appId())}}</div>
                    <div class="badge" *ngIf="footerButton.itemKey!=='cart' && countNotificationsByPageConfig(footerButton)>0">{{countNotificationsByPageConfig(footerButton)}}</div>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-footer>