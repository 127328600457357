import { Order } from "../backend-api";
import { Subject } from "rxjs";

// per la gestione di carrelli multipli con inizializzazione al primo accesso
export class CartMap {

	cart: {
		[appId: string]: Partial<Order>;
	} = {};

	// sottoscrizione per cambio istanza carrello
	instanceSet = new Subject();
	instanceReset = new Subject();

	constructor(
		public options: {
			// questa funzione permette di impostare un default al primo accesso al carrello di una certa app
			configFnc: (key: string) => Partial<Order>;
		}) {
	}

	// imposta una proprietà diversa da chiave a chiave
	set(value: any, appId: string, noEvent = false): void {
		this.cart[appId] = value;

		// notifica che ha impostato un nuovo carrello
		if (!noEvent) {
			this.instanceSet.next(appId);
		}
	}

	// inizializza il carrello xxx e ne ritorna il riferimento
	reset(appId: string, noEvent = false): Partial<Order> {
		this.cart[appId] = this.options.configFnc(appId);

		// notifica che ha impostato un nuovo carrello
		if (!noEvent) {
			this.instanceReset.next(appId);
		}

		return this.cart[appId];
	}

	// legge il carrello per app
	get(appId: string): Partial<Order> {

		// se non c'è valore
		if (!this.cart[appId]) {
			// esegue l'inizializzazione
			return this.reset(appId);
		}

		// ritorna il riferimento all'elemento interno
		return this.cart[appId];
	}
}
