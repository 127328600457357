<div class="cont">
    <table class="contTable">
        <tr style="height: 100%;">
            <!--FF-->
            <td class="contTd" *ngFor="let item of firstItems" [style.width]="tdWidth" [style.cursor]="tdCursor">
                <app-cached-image-div borderRadius="7px" class="galleryItem" [src]="getImage(item)" (click)="clickPressed()">

                    <ion-button *ngIf="canDelete" slot="end" color="danger" size="small" (click)="internalDeleteItemClicked(item, $event)">
                        <ion-icon name="trash"></ion-icon>
                    </ion-button>

                </app-cached-image-div>

            </td>
            <td class="contTd" *ngIf="items.length === maxItems" [style.width]="tdWidth" [style.cursor]="tdCursor">
                <app-cached-image-div borderRadius="7px" class="galleryItem" [src]="getImage(items[maxItems-1])" (click)="clickPressed()">

                    <ion-button *ngIf="canDelete" slot="end" color="danger" size="small" (click)="internalDeleteItemClicked(items[maxItems-1], $event)">
                        <ion-icon name="trash"></ion-icon>
                    </ion-button>

                </app-cached-image-div>
            </td>
            <td class="contTdMore" *ngIf="items.length > maxItems" [style.width]="tdWidth" [style.cursor]="tdCursor">
                <app-cached-image-div borderRadius="7px" class="galleryItem" [src]="getImage(items[maxItems-1])" (click)="clickPressed()">
                    <span>+{{ items.length - maxItems }}</span>
                </app-cached-image-div>
            </td>
        </tr>
    </table>
</div>