import { Injectable } from "@angular/core";
import { AppConfigurationService, IStateChangeInfo, STATE_CHANGE_REASON, tAppMapPropertyName } from "./app-configuration.service";
import { LocalStorageService } from "./local-storage.service";
import { statics } from "../statics";
import { LoginService } from "src/app/account/services/login.service";
import { OrderingService } from "src/app/ordering/services/ordering.service";
import { NotificationService } from "../../notification/notification.service";
import { HttpClient } from "@angular/common/http";
import { Order, ORDER_STATUS, FEATURE, PAYMENT_PERMISSION } from "../../backend-api";
import { DataTranslateService } from "./data-translate.service";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root"
})
export class BootstrapService {

	// dav 221119, usata per salvare il path di redirect in avvio
	startupRedirectRoute = null;

	public readonly INVALID_APP_VERSION: string = "INVALID_APP_VERSION";

	constructor(
		private appConfigService: AppConfigurationService,
		private localStorageService: LocalStorageService,
		private loginService: LoginService,
		private orderingService: OrderingService,
		private notificationService: NotificationService,
		private http: HttpClient,
		private dts: DataTranslateService
	) {

		// imposta a livello della appConfig la callback per la gestione del cambio stato
		appConfigService.addStateChangedSubscription(
			async (sci: IStateChangeInfo) => {
				await this._stateCallback(sci);
			}
		);
	}

	// rimappa all'altra per la bootstrap page altrimenti va in circolo
	loadLatestCartIfSupportedByStoreOrApp(appId: string, optStoreId: string): Promise<void> {
		return this.orderingService.loadLatestCartIfSupportedByStoreOrApp(appId, optStoreId);
	}

	// funzione richiamata quando avviene un cambio di stato dell'app ed è necessario un rinfresco di alcune parti
	private async _stateCallback(sci: IStateChangeInfo): Promise<void> {
		switch (sci.reason) {
			case "account": // qui arriva già con le info utente aggiornate
				await this.loadLatestCartIfSupportedByStoreOrApp(sci.appIdForAccountAndForNotifications, undefined);

				// se mi sono collegato su di un'app figlia aggiorno anche il carrello di plazar
				if (sci.appIdForAccountAndForNotifications !== statics.appId) {
					await this.loadLatestCartIfSupportedByStoreOrApp(statics.appId, undefined);
				}

				await this.notificationService.connectCurrentUserWithNotifications(this.appConfigService.loggedUser);
				await this.reloadCurrentUserDataAndDispatchNotifications(); // dav 241019, controlla anche le notifiche dell'utente
				// sempre app corrente
				await this.initFavouriteStore(false, sci.appIdForAccountAndForNotifications); // dav 181219, aggiusta alcune info dello store per l'utente corrente
				// dav 040620, svuota la cache del querymanager, cosi evita di vedere l'unico catalogo passando da admin a utente sconnesso
				this.appConfigService.queryManager.clearCache();

				// questo pezzo di codice e' duplicato anche in bootstrap.page
				if (this.appConfigService.loggedUser) {
					const aStores = await this.loginService.getUserStores(statics.appId, [FEATURE.payment], false, [PAYMENT_PERMISSION.issue_payments], false);
					this.appConfigService.bUserCanIssuePayment = aStores && aStores.length > 0;
				}
				else {
					this.appConfigService.bUserCanIssuePayment = false;
				}

				// dav 071220, legge le app gestite dall'utente per il menu
				await this.loginService.saveAndDispatchMessage_AppsManagedByCurrentUserWithConfigHints();
				break;

			case "notification":
				await this.reloadCurrentUserDataAndDispatchNotifications();
				break;

			case "refreshCurrentPage":
				this.notificationService.invokeRefreshOnSubscribers();
				break;

			case "refreshAppMenuPages":
				//
				// qui viene ignorato per un problema di dipendenze circolari
				//
				break;

			default:
				throw Error("State unknown in bootstrap service!");
		}

		console.log("State Changed called with verb: " + sci.reason);
	}

	// verifica ed eventualmente sistema le info dello store corrente
	async initFavouriteStore(fullInit: boolean, appId: string): Promise<any> {

		const appStores = this.appConfigService.getAppStoresOrEmptyArray(appId);
		if (appStores.length === 0) {
			console.log("Bootstrap: no stores for appId: " + appId);
		}

		let userStoreLangUpdated = false;

		// legge se c'è un utente collegato
		const lu = this.appConfigService.loggedUser;
		if (lu) {

			// appEnrollment corrente
			const ae = this.appConfigService.getUserAppEnrollment(lu, appId);
			if (ae) {

				// dav 111219, se cè un solo store imposta quello
				if (appStores.length === 1) {
					ae.favouriteStoreId = appStores[0].id;
				}

				// legge quello salvato in precedenza
				const loggedFavStoreId = ae.favouriteStoreId;

				// se esiste controlla che esista nella lista degli store disponibili per app
				if (loggedFavStoreId) {
					const foundStore = appStores.find((s) => {
						return s.id === loggedFavStoreId;
					});

					// se è un fake lo resetta, verrà salvato alla prima occasione
					if (!foundStore) {
						ae.favouriteStoreId = null;
					}
					else {
						// aggiorna la lingua di default dello store
						this.dts.updateDataLanguages({
							storeDefaultLang: foundStore.defaultLanguage
						});
						userStoreLangUpdated = true;
					}
				}
			}

		}

		// prende la info dal localStorage per l'utente anonimo
		if (fullInit) {
			try {

				let unloggedFavStoreId = null;

				// dav 111219, se cè un solo store imposta quello
				if (appStores.length === 1) {
					unloggedFavStoreId = appStores[0].id;
				}
				else {
					unloggedFavStoreId = await this.localStorageService.getUnloggedCurrentStoreId(appId);
				}
				this.appConfigService.appMap.set("unloggedUserCurrentStoreId", unloggedFavStoreId, appId);

				if (unloggedFavStoreId) {
					const foundStore = appStores.find((s) => {
						return s.id === unloggedFavStoreId;
					});

					// se è un fake lo resetta, verrà salvato alla prima occasione
					if (!foundStore) {
						this.appConfigService.appMap.set("unloggedUserCurrentStoreId", null, appId);
					}
					else {
						if (!userStoreLangUpdated) {
							// aggiorna la lingua di default dello store
							this.dts.updateDataLanguages({
								storeDefaultLang: foundStore.defaultLanguage
							});
						}
					}
				}

			} catch (e) {
				// nothing to do
			}
		}

	}

	// ritorna i dati dell'utente corrente, assieme alle sue notifiche
	async reloadCurrentUserDataAndDispatchNotifications() {

		try {
			const jwt: string = await this.localStorageService.getJwtToken();
			if (!jwt) {
				throw new Error("Go to catch");
			}

			const ud = await this.loginService.getUserData(); // legge dati e notifiche

			// dav 020520, controllo se l'utente collegato è enrollato per l'app corrente
			if (!this.appConfigService.isUserOptEnrolledForThisApp(ud, statics.appId)) {
				throw new Error("Go to catch");
			}

			// salva le info utente per la sessione
			await this.appConfigService.setLoggedUser(statics.jwt, ud);

			// dispaccia le notifiche
			if (ud.enrolledApps) {
				const appEnrollment = this.appConfigService.getUserAppEnrollment(ud, statics.appId); // app singola o piattaforma
				if (appEnrollment?.notifications) { //da lanciare anche se non ci sono notifiche
					this.notificationService.dispatchNotifications(appEnrollment.notifications);
				}
			}

		} catch (e) {

			await this.appConfigService.setLoggedUser(null, null);

			// salvo il token resettato
			await this.localStorageService.clearJwtToken();
		}
	}

	async checkVersion(): Promise<boolean> {

		// se sono nel browser la versione e' null
		if (!statics.appVersion) {
			return Promise.resolve(true);
		}

		const bOK: boolean = await this.http.get(environment.backendUrl +
			"/system-info/isVersionCompatible?version=" + encodeURIComponent(statics.appVersion)).toPromise() as boolean;

		return bOK;
	}

}
