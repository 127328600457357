import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { DeliveryAddress, City, CityPaginatedResponse, Pagination, Address, Coordinates, Geolocation, COORDINATES_TYPE } from "src/app/backend-api";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertController, Platform } from "@ionic/angular";
import { DataTranslateService } from "src/app/core/services/data-translate.service";
import { cloneObject, safeAwaitOrDefault } from "src/app/core/utils";
import { SelectSearchComponent, ISelectSearchOptions } from "src/app/core/components/select-search/select-search.component";
import { QueryLocalizationService } from "src/app/core/queries/query-localization.service";
import { GoogleGeocoderComponent } from "src/app/core/components/google-geocoder/google-geocoder.component";
import { AppNavigatorService } from "src/app/core/services/app-navigator.service";

export interface IAddressEditInput {
	address: Address | DeliveryAddress;
	addressOnly?: boolean;
}
export interface IAddressEditOutput extends IAddressEditInput {
	confirmed?: boolean;
	deleted?: boolean;
}

@Component({
	selector: "app-address-edit",
	templateUrl: "./address-edit.page.html",
	styleUrls: ["./address-edit.page.scss"]
})
export class AddressEditPage implements OnInit {

	_orgAddress: DeliveryAddress = null;
	currentAddress: DeliveryAddress = null;
	fm: FormGroup;

	public formOKClicked: boolean;
	private iTimeout: number;
	private _lastAddress: string; // ultimo indirizzo risolto

	// opzioni per il selectSearch
	cityOptions: ISelectSearchOptions;

	// posso invocare questa pagina anche da navigazione normale
	public navData: {
		address: Address;
		addressOnly: boolean;
	};

	@ViewChild("selectCity") selectCity: SelectSearchComponent;
	@ViewChild("geocoder") geocoder: GoogleGeocoderComponent;

	set address(address: DeliveryAddress) {
		this._orgAddress = address;
		this.currentAddress = cloneObject(address);
	}

	addressOnly = false;

	constructor(
		public dts: DataTranslateService,
		private formBuilder: FormBuilder,
		private alertCtrl: AlertController,
		private localizationService: QueryLocalizationService,
		private appNav: AppNavigatorService
	) {

		this.formOKClicked = false;
	}

	async ngOnInit() {

		// prende i dati di navigazione passati in avanti
		const navPageInfo = this.appNav.getPageInfo();

		// vede se sono arrivato in navigazione
		this.navData = navPageInfo?.navigationCustomData;
		if (this.navData) {
			this.addressOnly = this.navData.addressOnly;
			this.address = this.navData.address;
		}

		const groupConfig = {};

		if (!this.addressOnly) {
			// tslint:disable-next-line: no-string-literal
			groupConfig["name"] = [
				"",
				Validators.compose([Validators.required, Validators.minLength(3)])
			];
		}

		// tslint:disable-next-line: no-string-literal
		groupConfig["street"] = [
			"",
			Validators.compose([Validators.required, Validators.minLength(3)])
		];

		// tslint:disable-next-line: no-string-literal
		groupConfig["streetn"] = [
			"",
			Validators.compose([])
		];

		if (!this.addressOnly) {
			// tslint:disable-next-line: no-string-literal
			groupConfig["intercom"] = [
				"",
				Validators.compose([])
			];

			// tslint:disable-next-line: no-string-literal
			groupConfig["interior"] = [
				"",
				Validators.compose([])
			];
		}

		// tslint:disable-next-line: no-string-literal
		groupConfig["city"] = [
			"",
			Validators.compose([Validators.required]) // basta l'attributo richiesto
		];

		// tslint:disable-next-line: no-string-literal
		groupConfig["zip"] = [
			"",
			Validators.compose([Validators.required, Validators.minLength(3)])
		];

		this.fm = this.formBuilder.group(groupConfig);

		// impostazioni per la ricerca città
		this.cityOptions = {
			popupTitle: this.dts.translateService.instant("account.ADDRESS_CITY_SEARCH"),
			popupInfo: this.dts.translateService.instant("account.ADDRESS_CITY_SEARCH_INFO"),
			searchPlaceholder: this.dts.translateService.instant("account.ADDRESS_CITY_NAME"),
			itemRenderer: (item: City): string => {
				return this.dts.dataTranslate(item.name);
			},
			execPaginatedCall: (filter: string, pagination: Pagination): Promise<CityPaginatedResponse> => {
				return this.localizationService.searchCities(pagination, filter);
			},
			itemSelected: (city: City) => {

				// imposta la città corrente per poi leggere i cap nel combo successivo
				this.currentAddress.city = city;

				// svuota il cap
				// tslint:disable-next-line: no-string-literal
				this.fm.controls["zip"].setValue("");
			}
		};
	}

	ionViewDidEnter() {
		if (!this.currentAddress) {
			return;
		}

		this.currentAddress.city = this.currentAddress.city || {
			id: undefined
		};

		// calcola l'indirizzo iniziale per evitare eventi doppi
		this._lastAddress = this._getGeoAddressFromAddress(this.currentAddress);

		this.fm.controls.street.setValue(this.currentAddress.street);
		this.fm.controls.streetn.setValue(this.currentAddress.number);

		if (!this.addressOnly) {
			this.fm.controls.name.setValue(this.currentAddress.name); // sanza traduzione
			this.fm.controls.intercom.setValue(this.currentAddress.intercomName);
			this.fm.controls.interior.setValue(this.currentAddress.interior);
		}

		// da fare qui nel viewEnter altrimenti il viewChild non funziona
		// imposta direttamente l'item nel search
		this.selectCity.item = this.currentAddress.city;

		this.fm.controls.zip.setValue(this.currentAddress.zip);

		// se trova le coordinate le imposta
		if (this.currentAddress?.geolocation?.coordinates?.length) {
			this.geocoder.setCurrentPosition(this.currentAddress.geolocation.coordinates[0]);
		}
		// altrimenti risolve l'indirizzo e trova le coordinate
		else if (this._lastAddress) {
			this.geocoder.performGeocoding(this._lastAddress);
		}
	}

	private _getGeoAddressFromAddress(addr: Address): string {
		if (addr.street && addr.number && addr.city.name.invariant) {
			return addr.street + " " + addr.number + ", " + addr.city.name.invariant;
		}
		else {
			return undefined;
		}
	}
	private _getGeoAddressFromFieldsOnPage(): string {
		if (this.fm.controls.street.value && this.fm.controls.streetn.value && this.fm.controls.city.value) {
			return this.fm.controls.street.value + " " + this.fm.controls.streetn.value + ", " + this.fm.controls.city.value;
		}
		else {
			return undefined;
		}
	}

	onAddressChange() {
		// calcola l'indirizzo
		const newAddress = this._getGeoAddressFromFieldsOnPage();
		if (newAddress && newAddress !== this._lastAddress) {

			// salva
			this._lastAddress = newAddress;
			if (this._lastAddress) {
				if (this.iTimeout) {
					clearTimeout(this.iTimeout);
				}
				this.iTimeout = window.setTimeout(async () => {
					await this.geocoder.performGeocoding(this._lastAddress);
				}, 1000);
			}
		}

	}

	// ritorna la lista dei cap dalla città dell'indirizzo corrente
	getCapsFromCurrentAddress(): string[] {
		const zips = this.currentAddress?.city?.zip;
		if (zips) {
			return zips;
		}
		return [];
	}

	private async showYesNo(): Promise<boolean> {

		return new Promise(async (resolve, reject) => {

			const NO: string = this.dts.translateService.instant("NO");
			const SI: string = this.dts.translateService.instant("SI");
			const attention: string = this.dts.translateService.instant("ATTENTION");
			const deleteWarning: string = this.dts.translateService.instant("ITEM_DELETE_WARNING", {
				item: this.dts.translateService.instant("account.THIS_ADDRESS").toLowerCase()
			});
			const w = await this.alertCtrl.create({
				header: attention,
				message: deleteWarning,
				backdropDismiss: false,
				buttons: [
					{
						text: NO,
						role: "cancel",
						handler: () => {
							reject();
						}
					},
					{
						text: SI,
						handler: () => {
							resolve(true);
						}
					}]
			});
			await w.present();
		});
	}

	// cancellazione indirizzo
	async doDeleteAddress() {
		const ret = await safeAwaitOrDefault(this.showYesNo());
		if (ret) {
			this.appNav.pop({
				deleted: true
			} as IAddressEditOutput);
		}
	}

	doSave() {

		this.formOKClicked = true;

		// sicurezza
		if (!this.fm.valid) {

			this.alertCtrl.create({
				header: this.dts.translateService.instant("FORM_WRONG_DATA"),
				message: this.dts.translateService.instant("FORM_FIX_DATA"),
				buttons: [this.dts.translateService.instant("OK")]
			}).then(errMsg => {
				errMsg.present();
			});

			return;
		}

		this.currentAddress.street = this.fm.controls.street.value;
		this.currentAddress.number = this.fm.controls.streetn.value; // di tipo stringa

		// converte in stringa altrimenti scoppia a server
		if (this.currentAddress.number) {
			this.currentAddress.number = this.currentAddress.number + "";
		}

		if (!this.addressOnly) {
			this.currentAddress.name = this.fm.controls.name.value; // senza traduzione
			this.currentAddress.intercomName = this.fm.controls.intercom.value;
			this.currentAddress.interior = this.fm.controls.interior.value;
		}

		//
		// qui se ci arrivo allora currentAddress.city è già valorizzato
		// si può anche leggere come "this.selectCity.item"
		//

		this.currentAddress.zip = this.fm.controls.zip.value;

		// inizializza la geolocation se esiste
		this.currentAddress.geolocation = this.currentAddress.geolocation || {
			id: undefined
		} as Geolocation;

		// aggiorna le info
		const cp = this.geocoder.getCurrentPosition();
		Object.assign(this.currentAddress.geolocation, {
			coordinates: [Object.assign({}, this.geocoder.getCurrentPosition())],
			type: COORDINATES_TYPE.point
		} as Geolocation);

		// riporta le info dell'oggetto temporaneo sull'originale
		Object.assign(this._orgAddress, this.currentAddress);

		this.appNav.pop({
			confirmed: true
		} as IAddressEditOutput);
	}

}
