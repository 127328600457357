import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AppConfigurationService } from "src/app/core/services/app-configuration.service";
import { OrderingService } from "src/app/ordering/services/ordering.service";
import { appPages, IPageConfig } from "src/app/routes";
import { AppNavigatorService } from "../../services/app-navigator.service";

@Component({
	selector: "app-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {

	constructor(
		public translate: TranslateService,
		public orderingService: OrderingService,
		private appConfigService: AppConfigurationService,
		public appNav: AppNavigatorService
	) { }

	ngOnInit() { }

	routeTo(p: IPageConfig) {
		this.appNav.navigateRoot(p.url);
	}

	// ritorna i pulsanti abilitati ad essere mostrati nella toolbar sotto
	footerButtons(): IPageConfig[] {

		const appId = this.appNav.appId();

		const pages: IPageConfig[] = [];
		appPages.forEach((sec) => {
			for (const p of sec.pages) {
				pages.push(p);
			}
		});

		let aButtons: IPageConfig[] = pages;
		aButtons = aButtons.filter((p) => {
			if (p.onTabBar) {
				if (this.appConfigService.userCanAccessPage(p, appId) &&
					this.appConfigService.isPageVisibleForAppOrStore(p, appId)) {
					return true;
				}
			}
			return false;
		});

		return aButtons;
	}
	// conteggio delle notifiche per pagina
	countNotificationsByPageConfig(p: IPageConfig): number {

		return this.appConfigService.getUserNotificationsByType(p.notification_feature, p.notification_event).length;
	}
}
