import { Injectable } from "@angular/core";
import { Network, Connection } from "@ionic-native/network/ngx";

@Injectable({
	providedIn: "root"
})
export class NetworkConnectionService {

	isConnected = true;

	constructor(
		private network: Network
	) {

		// watch network for a disconnection
		const disconnectSubscription = this.network.onDisconnect().subscribe(() => {
			this.isConnected = false;

			console.log("network was disconnected :-(");
		});

		// stop disconnect watch
		//disconnectSubscription.unsubscribe();

		// watch network for a connection
		const connectSubscription = this.network.onConnect().subscribe(() => {
			this.isConnected = true;

			console.log("network connected!");

			// We just got a connection but we need to wait briefly
			// before we determine the connection type. Might need to wait.
			// prior to doing any api requests as well.
			// setTimeout(() => {
			// 	if (this.network.type === "wifi") {
			// 		console.log("we got a wifi connection, woohoo!");
			// 	}
			// }, 3000);
		});

		// stop connect watch
		//connectSubscription.unsubscribe();

		this.isConnected = (this.network.type !== Connection.NONE.toString());
	}
}
