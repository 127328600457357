<ion-item>
	<ion-label class="title medium">
		{{ 'schedule_calendar_management.timezone' | translate }}
	</ion-label>
	<ion-select [(ngModel)]="timezone" okText="{{'FATTO' | translate}}" cancelText="{{'ANNULLA' | translate}}"
		[interfaceOptions]="{'cssClass': 'largeAlert'}">
		<ion-select-option *ngFor="let tz of timezoneList" value="{{tz}}">{{tz}}</ion-select-option>
	</ion-select>
</ion-item>

<form novalidate [formGroup]="fm">
	<ion-card>
		<ion-card-header (click)="standardScheduleVisible=!standardScheduleVisible" style="cursor: pointer;">
			<ion-card-title>

				<ion-grid class="ion-no-padding">
					<ion-row class="ion-no-padding">
						<ion-col>{{ 'schedule_calendar_management.standard_schedule' | translate }}</ion-col>
						<ion-col size="auto">
							<ion-icon [name]="standardScheduleVisible?'chevron-down-outline':'chevron-forward-outline'">
							</ion-icon>
						</ion-col>
					</ion-row>
				</ion-grid>

			</ion-card-title>
		</ion-card-header>
		<ion-card-content [hidden]="!standardScheduleVisible">
			<!-- Ciclo giorni -->
			<ion-card *ngFor="let d of daysString" class="ion-no-padding">
				<ion-card-header>
					<ion-card-subtitle>{{ 'schedule_calendar_management.' + d | translate }}</ion-card-subtitle>
				</ion-card-header>
				<ion-card-content class="ion-no-padding">
					<ion-grid class="ion-no-padding">
						<ion-item *ngIf="baseCalendar">
							<ion-label>{{'schedule_calendar_management.useStoreCalendar' | translate}}</ion-label>
							<ion-checkbox slot="start" [checked]="!overrideDays[d]" (ionChange)="useStoreCalendar_standard(d)"></ion-checkbox>
						</ion-item>

						<ion-row class="ion-no-padding">
							<ion-col class="ion-no-padding">
								<ion-item>
									<ion-label position="floating">
										{{ 'schedule_calendar_management.start' | translate }}
									</ion-label>
									<ion-datetime *ngIf="!baseCalendar || overrideDays[d]" [formControlName]="d + 'Start1'" [displayFormat]="TIME_FORMAT"
										[pickerFormat]="TIME_FORMAT"
										[pickerOptions]="getTimePickerOptions(fm, d +'Start1')">
									</ion-datetime>
									<ion-datetime *ngIf="baseCalendar && !overrideDays[d]" disabled="true" [displayFormat]="TIME_FORMAT"
									              [pickerFormat]="TIME_FORMAT" [value]="baseCalendar?.weekSchedule[d] && baseCalendar?.weekSchedule[d].length > 0 ?
										baseCalendar.weekSchedule[d][0].from : ''">
									</ion-datetime>
								</ion-item>
							</ion-col>
							<ion-col class="ion-no-padding">
								<ion-item>
									<ion-label position="floating">
										{{ 'schedule_calendar_management.end' | translate }}
									</ion-label>
									<ion-datetime *ngIf="!baseCalendar || overrideDays[d]" [formControlName]="d + 'End1'" [displayFormat]="TIME_FORMAT"
										[pickerFormat]="TIME_FORMAT"
										[pickerOptions]="getTimePickerOptions(fm, d +'End1')">
									</ion-datetime>
									<ion-datetime *ngIf="baseCalendar && !overrideDays[d]" disabled="true" [displayFormat]="TIME_FORMAT"
									              [pickerFormat]="TIME_FORMAT" [value]="baseCalendar?.weekSchedule[d] && baseCalendar?.weekSchedule[d].length > 0 ?
										baseCalendar.weekSchedule[d][0].to : ''">
									</ion-datetime>
								</ion-item>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col class="ion-no-padding">
								<ion-item>
									<ion-label position="floating">
										{{ 'schedule_calendar_management.start' | translate }}
									</ion-label>
									<ion-datetime *ngIf="!baseCalendar || overrideDays[d]" [formControlName]="d + 'Start2'" [displayFormat]="TIME_FORMAT"
										[pickerFormat]="TIME_FORMAT"
										[pickerOptions]="getTimePickerOptions(fm, d +'Start2')">
									</ion-datetime>
									<ion-datetime *ngIf="baseCalendar && !overrideDays[d]" disabled="true" [displayFormat]="TIME_FORMAT"
									              [pickerFormat]="TIME_FORMAT" [value]="baseCalendar?.weekSchedule[d] && baseCalendar?.weekSchedule[d].length > 1 ?
										baseCalendar.weekSchedule[d][1].from : ''">
									</ion-datetime>
								</ion-item>
							</ion-col>
							<ion-col class="ion-no-padding">
								<ion-item>
									<ion-label position="floating">
										{{ 'schedule_calendar_management.end' | translate }}
									</ion-label>
									<ion-datetime *ngIf="!baseCalendar || overrideDays[d]" [formControlName]="d + 'End2'" [displayFormat]="TIME_FORMAT"
										[pickerFormat]="TIME_FORMAT"
										[pickerOptions]="getTimePickerOptions(fm, d +'End2')">
									</ion-datetime>
									<ion-datetime *ngIf="baseCalendar && !overrideDays[d]" disabled="true" [displayFormat]="TIME_FORMAT"
									              [pickerFormat]="TIME_FORMAT" [value]="baseCalendar?.weekSchedule[d] && baseCalendar?.weekSchedule[d].length > 1 ?
										baseCalendar.weekSchedule[d][1].to : ''">
									</ion-datetime>
								</ion-item>
							</ion-col>
						</ion-row>
					</ion-grid>
				</ion-card-content>
			</ion-card>

		</ion-card-content>
	</ion-card>
</form>

<form novalidate [formGroup]="es">
	<ion-card>
		<ion-card-header (click)="extraSchedulesVisible=!extraSchedulesVisible" style="cursor: pointer;">
			<ion-card-title>

				<ion-grid class="ion-no-padding">
					<ion-row class="ion-no-padding">
						<ion-col>{{ 'schedule_calendar_management.extra_schedule' | translate }}</ion-col>
						<ion-col size="auto">
							<ion-icon [name]="extraSchedulesVisible?'chevron-down-outline':'chevron-forward-outline'">
							</ion-icon>
						</ion-col>
					</ion-row>
				</ion-grid>

			</ion-card-title>
		</ion-card-header>
		<ion-card-content [hidden]="!extraSchedulesVisible">
			<ion-item *ngIf="baseCalendar">
				<ion-label>{{'schedule_calendar_management.useStoreCalendar' | translate}}</ion-label>
				<ion-checkbox slot="start" [checked]="!overrideExtraSchedule" (ionChange)="useStoreCalendar_extra()"></ion-checkbox>
			</ion-item>

			<ion-card *ngFor="let es of (baseCalendar && !overrideExtraSchedule ? baseCalendar.extraSchedules : sc?.extraSchedules); index as i" class="ion-no-padding">
				<ion-card-header>
					<ion-card-subtitle>
						<ion-grid class="ion-no-padding">
							<ion-row class="ion-no-padding">
								<ion-col>
									<ion-label *ngIf="isDaySmartInherited(es)" color="danger" style="display: block">
										{{ 'schedule_calendar_management.smart_inherited' | translate:{day: getLocalizedDayOfWeek(es) } }}
									</ion-label>
									{{es.day | dateStringToDate | date:"fullDate":"":dts.currentLang }}
								</ion-col>
								<ion-col [hidden]="baseCalendar && !overrideExtraSchedule" size="auto" (click)="deleteExtraSchedule(i)" style="cursor: pointer;">
									<ion-icon name="trash" color="danger"></ion-icon>
								</ion-col>
							</ion-row>
						</ion-grid>
					</ion-card-subtitle>
				</ion-card-header>
				<ion-card-content>
					<ng-container *ngIf="es?.timeRanges?.length">
						<span *ngFor="let tr of es.timeRanges; index as i">
							{{ i>0?", ":"" }} {{'schedule_calendar_management.from' | translate}}
							<strong>{{ tr.from }}</strong> {{'schedule_calendar_management.to' | translate}}
							<strong>{{ tr.to}}</strong>
						</span>
					</ng-container>
					<ng-container *ngIf="!es?.timeRanges?.length">
						<strong>{{ "schedule_calendar_management.opened_entire_day" | translate }}</strong>
					</ng-container>
				</ion-card-content>
			</ion-card>
			<ion-card class="ion-no-padding" *ngIf="!baseCalendar || overrideExtraSchedule">
				<ion-card-header>
					<ion-card-subtitle>
						<ion-grid class="ion-no-padding">
							<ion-row class="ion-no-padding">
								<ion-col size="6">

									<ion-item>
										<ion-label position="floating">
											{{ 'schedule_calendar_management.day' | translate }}
										</ion-label>
										<ion-datetime [disabled]="baseCalendar && !overrideExtraSchedule" [formControlName]="'esDay'" [displayFormat]="DATE_FORMAT"
											[pickerFormat]="DATE_FORMAT"
											[pickerOptions]="getDatePickerOptions(es,'esDay')" [max]="nextYear">
										</ion-datetime>
									</ion-item>

								</ion-col>
							</ion-row>
						</ion-grid>
					</ion-card-subtitle>
				</ion-card-header>
				<ion-card-content class="ion-no-padding">

					<ion-grid class="ion-no-padding">
						<ion-row class="ion-no-padding">
							<ion-col size="6">
								<ion-item>
									<ion-label position="floating">
										{{ 'schedule_calendar_management.start' | translate }}
									</ion-label>
									<ion-datetime [disabled]="baseCalendar && !overrideExtraSchedule" [formControlName]="'esStart1'" [displayFormat]="TIME_FORMAT"
										[pickerFormat]="TIME_FORMAT"
										[pickerOptions]="getTimePickerOptions(es, 'esStart1')">
									</ion-datetime>
								</ion-item>
							</ion-col>
							<ion-col size="6">
								<ion-item>
									<ion-label position="floating">
										{{ 'schedule_calendar_management.end' | translate }}
									</ion-label>
									<ion-datetime [disabled]="baseCalendar && !overrideExtraSchedule" [formControlName]="'esEnd1'" [displayFormat]="TIME_FORMAT"
										[pickerFormat]="TIME_FORMAT"
										[pickerOptions]="getTimePickerOptions(es, 'esEnd1')">
									</ion-datetime>
								</ion-item>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col size="6">
								<ion-item>
									<ion-label position="floating">
										{{ 'schedule_calendar_management.start' | translate }}
									</ion-label>
									<ion-datetime [disabled]="baseCalendar && !overrideExtraSchedule" [formControlName]="'esStart2'" [displayFormat]="TIME_FORMAT"
										[pickerFormat]="TIME_FORMAT"
										[pickerOptions]="getTimePickerOptions(es, 'esStart2')">
									</ion-datetime>
								</ion-item>
							</ion-col>
							<ion-col size="6">
								<ion-item>
									<ion-label position="floating">
										{{ 'schedule_calendar_management.end' | translate }}
									</ion-label>
									<ion-datetime [disabled]="baseCalendar && !overrideExtraSchedule" [formControlName]="'esEnd2'" [displayFormat]="TIME_FORMAT"
										[pickerFormat]="TIME_FORMAT"
										[pickerOptions]="getTimePickerOptions(es, 'esEnd2')">
									</ion-datetime>
								</ion-item>
							</ion-col>
						</ion-row>
					</ion-grid>

					<ion-grid>
						<ion-row>
							<ion-col class="ion-text-right">
								<ion-button color="success" (click)="doAddExtraSchedule()"
									[disabled]="(baseCalendar && !overrideExtraSchedule) || !canAddExtraSchedule()">
									{{ 'schedule_calendar_management.insert' | translate }}
								</ion-button>
							</ion-col>
						</ion-row>
					</ion-grid>
				</ion-card-content>
			</ion-card>

		</ion-card-content>
	</ion-card>
</form>

<form novalidate [formGroup]="cs">
	<ion-card>
		<ion-card-header (click)="cancelledSchedulesVisible=!cancelledSchedulesVisible" style="cursor: pointer;">
			<ion-card-title>

				<ion-grid class="ion-no-padding">
					<ion-row class="ion-no-padding">
						<ion-col>{{ 'schedule_calendar_management.cancelled_schedule' | translate }}</ion-col>
						<ion-col size="auto">
							<ion-icon
								[name]="cancelledSchedulesVisible?'chevron-down-outline':'chevron-forward-outline'">
							</ion-icon>
						</ion-col>
					</ion-row>
				</ion-grid>

			</ion-card-title>
		</ion-card-header>
		<ion-card-content [hidden]="!cancelledSchedulesVisible">
			<ion-item *ngIf="baseCalendar">
				<ion-label>{{'schedule_calendar_management.useStoreCalendar' | translate}}</ion-label>
				<ion-checkbox slot="start" [checked]="!overrideCancelledSchedule" (ionChange)="useStoreCalendar_cancelled()"></ion-checkbox>
			</ion-item>

			<ion-card *ngFor="let cs of (baseCalendar && !overrideCancelledSchedule ? baseCalendar.cancelledSchedules : sc?.cancelledSchedules); index as i" class="ion-no-padding">
				<ion-card-header>
					<ion-card-subtitle>
						<ion-grid class="ion-no-padding">
							<ion-row class="ion-no-padding">
								<ion-col>{{cs.day | dateStringToDate | date:"fullDate":"":dts.currentLang }}
								</ion-col>
								<ion-col [hidden]="baseCalendar && !overrideCancelledSchedule" size="auto" (click)="deleteCancelledSchedule(i)" style="cursor: pointer;">
									<ion-icon name="trash" color="danger"></ion-icon>
								</ion-col>
							</ion-row>
						</ion-grid>
					</ion-card-subtitle>
				</ion-card-header>
				<ion-card-content>
					<ng-container *ngIf="cs?.timeRanges?.length">
						<span *ngFor="let tr of cs.timeRanges; index as i">
							{{ i>0?", ":"" }} {{'schedule_calendar_management.from' | translate}}
							<strong>{{ tr.from }}</strong> {{'schedule_calendar_management.to' | translate}}
							<strong>{{ tr.to}}</strong>
						</span>
					</ng-container>
					<ng-container *ngIf="!cs?.timeRanges?.length">
						<strong>{{ "schedule_calendar_management.closed_entire_day" | translate }}</strong>
					</ng-container>
				</ion-card-content>
			</ion-card>
			<ion-card class="ion-no-padding" *ngIf="!baseCalendar || overrideCancelledSchedule">
				<ion-card-header>
					<ion-card-subtitle>
						<ion-grid class="ion-no-padding">
							<ion-row class="ion-no-padding">
								<ion-col size="6">

									<ion-item>
										<ion-label position="floating">
											{{ 'schedule_calendar_management.day' | translate }}
										</ion-label>
										<ion-datetime [disabled]="baseCalendar && !overrideCancelledSchedule" [formControlName]="'csDay'" [displayFormat]="DATE_FORMAT"
											[pickerFormat]="DATE_FORMAT"
											[pickerOptions]="getDatePickerOptions(cs,'csDay')" [max]="nextYear">
										</ion-datetime>
									</ion-item>

								</ion-col>
							</ion-row>
						</ion-grid>
					</ion-card-subtitle>
				</ion-card-header>
				<ion-card-content class="ion-no-padding">

					<ion-grid class="ion-no-padding">
						<ion-row class="ion-no-padding">
							<ion-col size="6">
								<ion-item>
									<ion-label position="floating">
										{{ 'schedule_calendar_management.start' | translate }}
									</ion-label>
									<ion-datetime [disabled]="baseCalendar && !overrideCancelledSchedule" [formControlName]="'csStart1'" [displayFormat]="TIME_FORMAT"
										[pickerFormat]="TIME_FORMAT"
										[pickerOptions]="getTimePickerOptions(cs, 'csStart1')">
									</ion-datetime>
								</ion-item>
							</ion-col>
							<ion-col size="6">
								<ion-item>
									<ion-label position="floating">
										{{ 'schedule_calendar_management.end' | translate }}
									</ion-label>
									<ion-datetime [disabled]="baseCalendar && !overrideCancelledSchedule" [formControlName]="'csEnd1'" [displayFormat]="TIME_FORMAT"
										[pickerFormat]="TIME_FORMAT"
										[pickerOptions]="getTimePickerOptions(cs, 'csEnd1')">
									</ion-datetime>
								</ion-item>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col size="6">
								<ion-item>
									<ion-label position="floating">
										{{ 'schedule_calendar_management.start' | translate }}
									</ion-label>
									<ion-datetime [disabled]="baseCalendar && !overrideCancelledSchedule" [formControlName]="'csStart2'" [displayFormat]="TIME_FORMAT"
										[pickerFormat]="TIME_FORMAT"
										[pickerOptions]="getTimePickerOptions(cs, 'csStart2')">
									</ion-datetime>
								</ion-item>
							</ion-col>
							<ion-col size="6">
								<ion-item>
									<ion-label position="floating">
										{{ 'schedule_calendar_management.end' | translate }}
									</ion-label>
									<ion-datetime [disabled]="baseCalendar && !overrideCancelledSchedule" [formControlName]="'csEnd2'" [displayFormat]="TIME_FORMAT"
										[pickerFormat]="TIME_FORMAT"
										[pickerOptions]="getTimePickerOptions(cs, 'csEnd2')">
									</ion-datetime>
								</ion-item>
							</ion-col>
						</ion-row>
					</ion-grid>

					<ion-grid>
						<ion-row>
							<ion-col class="ion-text-right">
								<ion-button color="success" (click)="doAddCancelledSchedule()"
									[disabled]="(baseCalendar && !overrideCancelledSchedule) || !canAddCancelledSchedule()">
									{{ 'schedule_calendar_management.insert' | translate }}
								</ion-button>
							</ion-col>
						</ion-row>
					</ion-grid>
				</ion-card-content>
			</ion-card>

		</ion-card-content>
	</ion-card>
</form>
<ion-grid>
	<ion-row>
		<ion-col class="ion-text-right">
			<ion-button color="success" (click)="doSaveClicked()">
				{{ 'SAVE' | translate }}
			</ion-button>
		</ion-col>
	</ion-row>
</ion-grid>
