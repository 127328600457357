<ion-app [attr.data-theme]="'app-theme-' + appConfig.currentAppTheme">
    <ion-split-pane contentId="menu-content" [disabled]="!appConfig.mainAppLoaded">
        <ion-menu contentId="menu-content" class="app-sidemenu">
            <ion-header>
                <ion-toolbar class="side-toolbar">
                    <ion-row class="user-details-wrapper ion-align-items-center">
                        <ion-col size="4">
                            <img *ngIf="appConfig.appMap.get('currentAppIconStorageFileId', appNav.appId())" [src]="appConfig.getStorageThumbnailDownloadUrlForApp(appConfig.appMap.get('currentAppIconStorageFileId', appNav.appId()))">
                            <app-camera-upload style="position: absolute;bottom: 0;right: 0;" *ngIf="appConfig.isUserAllowed(appNav.appId(), undefined, [CORE_PERMISSION.manage_app]) && appConfig.appMap.get('inlineChangesEnabled', appNav.appId())" [appId]="appNav.appId()" [category]="'AppIcon'"
                                (uploadDone)="uploadAppIconDone($event)">
                            </app-camera-upload>
                        </ion-col>
                        <ion-col class="user-info-wrapper" size="8">
                            <div class="app-name title medium">{{ getCurrentStoreName() }}</div>
                            <div class="store-info value small" *ngIf="appConfig.getCurrentStoreId(appNav.appId()) !== null">
                                {{ getCurrentStoreInfo() }}
                            </div>
                            <hr *ngIf="!!appConfig.loggedUser">
                            <div class="user-name value small" *ngIf="!!appConfig.loggedUser">
                                <strong>{{ 'USER' | translate }}:</strong> {{appConfig.loggedUser.firstName + ' ' + appConfig.loggedUser.lastName}}
                            </div>
                        </ion-col>
                    </ion-row>
                </ion-toolbar>
            </ion-header>
            <ion-content>
                <ng-container *ngFor="let sec of aPages; index as isec">
                    <ion-list class="sidemenu-list" *ngIf="isMenuSectionVisible(sec)" [style.margin-top]="isec===0?'10px':''">
                        <ion-list-header class="sidemenu-list-header" *ngIf="sec.title">
                            <ion-label>{{ sec.title | translate }}</ion-label>
                        </ion-list-header>
                        <ion-menu-toggle auto-hide="false">
                            <ion-item *ngFor="let p of sec.pages; let i = index" button="true" lines="none" [hidden]="!linkVisible(p)" (click)="goToPage(p)">
                                <ion-icon slot="start" [name]="p.icon"></ion-icon>
                                <ion-label>{{p.title | translate}}</ion-label>

                                <ion-icon *ngIf="p.itemKey !== 'settingsPage' && p.iconEnd" slot="end" [name]="p.iconEnd"></ion-icon>
                                <ion-icon *ngIf="p.itemKey === 'settingsPage' && settingsPageHasWarningIcon()" slot="end" name="warning"></ion-icon>

                                <ion-badge *ngIf="p.itemKey==='cart' && orderingService.cartHasProducts(appNav.appId())" slot="end" color="danger">
                                    {{ orderingService.countCartItems(appNav.appId()) }}</ion-badge>
                                <ion-badge *ngIf="p.itemKey!=='cart' && countNotificationsByPageConfig(p) > 0" slot="end" color="danger">
                                    {{ countNotificationsByPageConfig(p) }}</ion-badge>

                            </ion-item>
                        </ion-menu-toggle>
                    </ion-list>
                </ng-container>
            </ion-content>
        </ion-menu>
        <!-- Main app content get's rendered in this router-outlet -->
        <ion-router-outlet id="menu-content"></ion-router-outlet>
    </ion-split-pane>
</ion-app>