import { ADDRESS_INFO } from "./localization-queries";
import { Query } from "../services/query";
import { TAG_INFO_FULL } from "./tag-queries";
import { SCHEDULED_CALENDAR } from "./calendar-queries";

const CONTACT_LIST_ITEM =
	`
	value
	type
	subtype
	social
	position
	favourite
`;

export const STORE_INFO =
	`
	id
	appId
	name {
		...language
	}
	address {
		${ADDRESS_INFO}
	}
	contactList {
		items {
			${CONTACT_LIST_ITEM}
		}
	}
	published
	${SCHEDULED_CALENDAR}
	businessInfo {
		stripePlatformCustomer {
			stripeCustomerId
		}
		businessName {
			...language
		}
		vatCode
		fiscalCode
		address {
			${ADDRESS_INFO}
		}
		currency
	}
	timezone
`;

// STORE QUERIES
export const DELETE_STORE = new Query(
	`
	mutation _($appId: String, $storeId: String) {
		deleteStore(appId: $appId, storeId: $storeId) {
			${STORE_INFO}
		}
	}
	`
);

export const SEARCH_STORES = new Query(
	`
	query _($searchString: String) {
		searchStores(searchString: $searchString) {
			id
			name {...language}
			description {...language}
		}
	}
	`
);

const storeInfo = `
	id
	contactList {
		items {
			value
			type
			subtype
			social
		}
	}
`;


export const UPDATE_STORE = new Query(
	`mutation _($st: StoreInput) {
		updateStore(store: $st) {
			${storeInfo}
		}
	}
	`
);

export const CREATE_STORE = new Query(
	`mutation _($st: StoreInput, $requestedFeatures: [FeatureRequestInput], $targetSubscriptionId: String) {
		createStore(store: $st, requestedFeatures: $requestedFeatures, targetSubscriptionId: $targetSubscriptionId) {
			id
			appId
			app {
				name {
					...language
				}
				theme
				icon {
					id
				}
				androidAppId
				iosAppId
			}
			name {
				...language
			}
			businessInfo {
				stripePlatformCustomer {
					stripeCustomerId
				}
				businessName {
					...language
				}
				vatCode
				fiscalCode
				address {
					${ADDRESS_INFO}
				}
			}
			address {
				${ADDRESS_INFO}
			}
		}
	}
	`
);

export const GET_STORES_BY_DISTANCE_PAGINATED = new Query(
	`
	query _($appId: String, $tags: [String], $location: GeolocationInput, $searchString: String) {
		searchStoresByDistance(appId: $appId, tags: $tags, location: $location, searchString: $searchString) {
			id
			appId
			app {
				id
				name {
					...language
				}
				stores {
					name {
						...language
					}
					address {
						${ADDRESS_INFO}
					}
				}
				theme
				icon {
					id
				}
				androidAppId
				iosAppId
			}
			name {
				...language
			}
			description {
				...language
			}
			address {
				${ADDRESS_INFO}
			}
			tags {
				${TAG_INFO_FULL}
			}
		}
	}
	`
);

export const GET_STORE = new Query(
	`
	query _($id: String, $appId: String) {
		store(appId: $appId, id: $id) {
			${STORE_INFO}
		}
	}
	`
);

export const SAVE_CURRENT_USER_FAVOURITE_STORE = new Query(
	`
	mutation _($appId: String, $storeId: String) {
		saveUserFavouriteStore(appId:$appId, storeId:$storeId) {
			id
		}
	}
	`
);
