import { Query } from "../../core/services/query";

export const BASE_PRODUCT_INFO = `
	appId
	id
	externalCode
	pictureStorageFile {
		id
	}
	name {...language}
	description {...language}
	shortDescription {...language}
	specification {...language}
	shortSpecification {...language}
	available
	availableQuantity
	hideUnavailableProducts
	availabilityCheckActive
	measureUnit
	minimumLot
	quantityStep
	pricePerMeasureUnit
	exactQuantity
	priceBlocked
`;

const PRODUCT_INFO =
	`
	${BASE_PRODUCT_INFO}
	productComponents {
		id
		pictureStorageFile {
			id
		}
		name {...language}
		description {...language}
		decomposable
		decomposableMin
		decomposableMax
		measureUnit
	}
	tags {
		id
		internalCode
		name {...language}
	}
`;

const CATALOG_INFO =
	`
	appId
	id
	name {...language}
	description {...language}
	hideUnavailableProducts
	availabilityCheckActive
	active
	hideUnavailableProducts
	availabilityCheckActive
	exactQuantity
	priceBlocked
	pictureStorageFile {
		id
	}
	stores {
		id
		name {
			...language
		}
	}
	withoutImages
	showFlatStructure
	`;

const CATALOG_INFO_SECTIONS =
	`
	sections {
		...catalogSection
		sections {
			...catalogSection
			sections {
				...catalogSection
			}
		}
	}
`;

const CATALOG_INFO_DYN_SECTIONS =
	`
	dynamicSections {
		...catalogSection
		sections {
			...catalogSection
			sections {
				...catalogSection
			}
		}
	}
`;

const CATALOG_INFO_WITH_SECTIONS =
	`
	${CATALOG_INFO}
	${CATALOG_INFO_SECTIONS}
	${CATALOG_INFO_DYN_SECTIONS}
`;

const CATALOG_SECTION_INFO_BASE_NO_TAGS =
	`
	id
	name {...language}
	description {...language}
	pictureStorageFile {
		id
	}
	withoutImages
	active
`;

const TAG_INFO =
	`
	tags {
		name {...language}
		description {...language}
	}
`;

const FRAGMENT_CATALOG_SECTION_WITH_PRODUCTS_AND_TAGS =
	`
	fragment catalogSection on CatalogSection {
		${CATALOG_SECTION_INFO_BASE_NO_TAGS}
		products {
			${PRODUCT_INFO}
		}
		${TAG_INFO}
	}
`;

const FRAGMENT_CATALOG_SECTION_NO_PRODUCTS =
	`
	fragment catalogSection on CatalogSection {
		${CATALOG_SECTION_INFO_BASE_NO_TAGS}
	}
`;

// NB: questa query non funziona con le sezioni ed i prodotti
export const GET_CATALOGS_BASE_INFO_BY_STORES_NO_SECTIONS = new Query(
	`
	query _($appId: String, $storeIds: [String]) {
		catalogsByStores(appId: $appId, storeIds: $storeIds) {
			${CATALOG_INFO}
		}
	}
	`
);

export const GET_CATALOG_INFO_BY_ID_SECTIONS_NO_PRODUCTS = new Query(
	`
	query _($appId: String, $id: String) {
		catalog(appId: $appId, id: $id) {
			${CATALOG_INFO_WITH_SECTIONS}
		}
	}
	${FRAGMENT_CATALOG_SECTION_NO_PRODUCTS}
	`
);

// query specifica per l'edit, senza sezioni e tag
export const GET_CATALOG_INFO_BY_ID_FOR_EDIT = new Query(
	`
	query _($appId: String, $id: String) {
		catalog(appId: $appId, id: $id) {
			${CATALOG_INFO}
		}
	}
	`
);


export const SEARCH_PRODUCTS = new Query(
	`
	query _($appId: String, $searchString: String, $catalogIds: [String], $catalogSectionIds: [String], $searchOptions: SearchOptions, $recursive: Boolean, $includeDisabledCatalogsAndSections: Boolean) {
		searchProducts(appId: $appId, searchString: $searchString, catalogIds:$catalogIds, catalogSectionIds:$catalogSectionIds, searchOptions: $searchOptions, recursive:$recursive, includeDisabledCatalogsAndSections: $includeDisabledCatalogsAndSections) {
			${PRODUCT_INFO}
		}
	}
`
);

export const CREATE_CATALOG = new Query(
	`
	mutation _($catalog: CatalogInput!) {
		createCatalog (catalog: $catalog) {
			${CATALOG_INFO}
		}
	}
	`
);

export const UPDATE_CATALOG = new Query(
	`
	mutation _($catalog: CatalogInput!) {
		updateCatalog (catalog: $catalog) {
			${CATALOG_INFO}
		}
	}
	`
);

export const DELETE_CATALOG = new Query(
	`
	mutation _($appId: String, $catalogId: String) {
		deleteCatalog (appId: $appId, catalogId:$catalogId) {
			${CATALOG_INFO_WITH_SECTIONS}
		}
	}
	${FRAGMENT_CATALOG_SECTION_WITH_PRODUCTS_AND_TAGS}
	`
);

export const GET_CATALOG_SECTION_BY_ID = new Query(
	`
	query _($appId: String, $catalogId: String, $catalogSectionId: String) {
		catalogSection(appId: $appId, catalogId: $catalogId, id: $catalogSectionId) {
			${CATALOG_SECTION_INFO_BASE_NO_TAGS}
			products {
				${PRODUCT_INFO}
			}
		}
	}
`
);

export const GET_CATALOG_SECTION_AND_SUBSECTIONS_NO_PRODUCTS_BY_ID = new Query(
	`
	query _($appId: String, $catalogId: String, $catalogSectionId: String) {
		catalogSection(appId: $appId, catalogId: $catalogId, id: $catalogSectionId) {
			${CATALOG_SECTION_INFO_BASE_NO_TAGS}
			${CATALOG_INFO_SECTIONS}
		}
	}
	${FRAGMENT_CATALOG_SECTION_NO_PRODUCTS}
`
);

export const CREATE_CATALOG_SECTION = new Query(
	`
	mutation _($appId: String, $catalogId: String, $catalogSection: CatalogSectionInput!, $parentCatalogSectionId: String, $productIds: [String]) {
		createCatalogSection (appId: $appId, catalogId: $catalogId, catalogSection: $catalogSection, parentCatalogSectionId: $parentCatalogSectionId, productIds: $productIds) {
			${CATALOG_SECTION_INFO_BASE_NO_TAGS}
			products {
				${PRODUCT_INFO}
			}
		}
	}
	`
);

export const UPDATE_CATALOG_SECTION = new Query(
	`
	mutation _($appId: String, $catalogId: String, $catalogSection: CatalogSectionInput!, $newParentCatalogSectionId: String, $productIds: [String]) {
		updateCatalogSection (appId: $appId, catalogId: $catalogId, catalogSection: $catalogSection, newParentCatalogSectionId: $newParentCatalogSectionId, productIds: $productIds) {
			${CATALOG_SECTION_INFO_BASE_NO_TAGS}
			products {
				${PRODUCT_INFO}
			}
		}
	}
	`
);

export const DELETE_CATALOG_SECTION = new Query(
	`
	mutation _($appId: String, $catalogId: String, $catalogSectionId: String) {
		deleteCatalogSection (appId: $appId, catalogId: $catalogId, catalogSectionId: $catalogSectionId) {
			${CATALOG_SECTION_INFO_BASE_NO_TAGS}
			products {
				${PRODUCT_INFO}
			}
		}
	}
	`
);

// export const SET_CATALOG_SECTION_PRODUCTS = new Query(
// 	`
// 	mutation _($appId: String, $catalogId: String, $catalogSectionId: String, $productIds: [String]) {
// 		setCatalogSectionProducts (appId: $appId, catalogId: $catalogId, catalogSectionId: $catalogSectionId, productIds: $productIds) {
// 			${CATALOG_SECTION_INFO_BASE_NO_TAGS}
// 			products {
// 				${PRODUCT_INFO}
// 			}
// 		}
// 	}
// 	`
// );

export const GET_PRODUCT_BY_ID = new Query(
	`
	query _($appId: String, $id: String) {
		product(appId: $appId, id: $id) {
			${PRODUCT_INFO}
		}
	}
	`
);

export const CREATE_PRODUCT = new Query(
	`
	mutation _($product: ProductInput!) {
		createProduct(product: $product) {
			${PRODUCT_INFO}
		}
	}
	`
);

export const UPDATE_PRODUCT = new Query(
	`
	mutation _($product: ProductInput!) {
		updateProduct(product: $product) {
			${PRODUCT_INFO}
		}
	}
	`
);

export const DELETE_PRODUCT = new Query(
	`
	mutation _($appId: String, $productId: String) {
		deleteProduct(appId: $appId, productId: $productId) {
			${PRODUCT_INFO}
		}
	}
	`
);

// ritorna tutti i prodotti censiti per lo store
export const GET_PRODUCTS_BY_STORE = new Query(
	`
	query _($appId: String, $storeIds: [String]) {
		productsByStores(appId: $appId, storeIds: $storeIds) {
			${PRODUCT_INFO}
		}
	}
	`
);
