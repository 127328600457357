<ng-container *ngIf="orderItem">
	<ion-grid class="main-grid ion-no-padding ion-text-center" *ngIf="!showAsLabel">
		<ion-row class="ion-align-items-center ion-no-padding ion-nowrap">
			<ion-col class="ion-no-padding">
				<ion-button class="smallButtons" color="danger" size="small" fill="outline" (click)="removeClicked(orderItem)" [disabled]="disabled || updatingOrderItem">
					<ion-icon name="remove"></ion-icon>
				</ion-button>
			</ion-col>
			<ion-col class="quantityText ion-text-nowrap">
				<ion-text color="success">
					{{ quantityStringByOrderItem(orderItem)}}
				</ion-text>
			</ion-col>
			<ion-col class="ion-no-padding">
				<ion-button class="smallButtons"
					[disabled]="disabled || !orderingService.canAddMore(orderItem.product) || updatingOrderItem" color="success"
					size="small" fill="outline" (click)="addClicked(orderItem)">
					<ion-icon name="add"></ion-icon>
				</ion-button>
			</ion-col>
		</ion-row>
	</ion-grid>
	<span *ngIf="showAsLabel">
		{{ quantityStringByOrderItem(orderItem)}}
	</span>
</ng-container>
