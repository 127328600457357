//
// By Dav 281019, based on "ionic-image-loader" that is not working - https://github.com/zyra/ionic-image-loader
//
import { Component, OnInit, Input } from "@angular/core";
import { CachedImageService } from "./cached-image.service";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

@Component({
	selector: "app-cached-image",
	templateUrl: "./cached-image.component.html",
	styleUrls: ["./cached-image.component.scss"],
})
export class CachedImageComponent implements OnInit {

	private _src: string;

	@Input() alt: string;
	readonly noImage = "assets/images/no-image.jpg";
	readonly pleaseWaitImage = "assets/images/please_wait.jpg";
	imageSource: SafeUrl = null;

	@Input() noCache = false;
	@Input() border: string;
	@Input() borderRadius: string;

	constructor(
		private cachedImageService: CachedImageService,
		private sanitizer: DomSanitizer
	) {

		this._src = this.pleaseWaitImage;
		this.imageSource = this.sanitizer.bypassSecurityTrustUrl(this._src);
	}

	ngOnInit() {
	}

	@Input()
	set src(imageUrl: string) {

		if (this._src !== imageUrl) {

			// clona
			this._src = imageUrl;

			if (!this._src) {
				this.imageSource = this.sanitizer.bypassSecurityTrustUrl(this.noImage);
			}
			else {
				if (this.noCache) {
					this.imageSource = this.sanitizer.bypassSecurityTrustUrl(this._src);
				}
				else {
					this.cachedImageService.ready().then(() => {

						// cache e risolve l'immagine reale
						this.cachedImageService.getImageWithCacheManagement(this._src).then((source: string) => {
							this.imageSource = this.sanitizer.bypassSecurityTrustUrl(source);
						});
					});
				}
			}

		}
	}
	get src(): string {
		return this._src;
	}


}
