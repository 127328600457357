import { Injectable } from "@angular/core";
import { AppConfigurationService } from "../core/services/app-configuration.service";
import { REGISTER_USER_FOR_PUSH, CLEAR_NOTIFICATIONS_BY_FEATURE, UNREGISTER_USER_FROM_PUSH } from "./notification-queries";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { AppNotification, FEATURE, ORDERING_NOTIFICATION_EVENT, User, PAYMENT_NOTIFICATION_EVENT } from "../backend-api";
import { ILogService } from "../core/services/log.interface";
import { safeAwaitArray } from "../core/utils";
import { statics } from "../core/statics";
import { CrossPlatformToastService } from "../core/services/cross-platform-toast.service";
import { Platform2Service } from "../core/services/platform2.service";
import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";

import { firebase } from "@firebase/app";
import "@firebase/messaging";

export interface INotificationReceiverData {
	notifications?: AppNotification[];
	/////sleepTimeMs?: number; // TODO
}

export interface INotificationReceiver {
	onNotifications(data: INotificationReceiverData);
	onAskRefresh();
}

// export interface INotificationRawData {
// 	image: string;
// 	additionalData: {
// 		collapse_key: string; // "wOm2+gyQaY5DDhgEMDSDFjRB27Y="
// 		data: {
// 			appId: string;
// 			event: string; // "received_newsletter"
// 			feature: string; // "newsletter"
// 			reference: string;
// 		},
// 		from: string; // "780837516549"
// 		notification: {
// 			title: string;
// 		}
// 	}
// }

@Injectable({
	providedIn: "root"
})
export class NotificationService {

	// elenco dei sottoscrittori alle notifiche
	private readonly _notificationReceivers: INotificationReceiver[] = [];

	// id corrente per push sul dispositivo corrente
	private _pushRegistrationId: string = null;

	constructor(
		private fcm: FCM,
		private appConfigSettings: AppConfigurationService,
		private logService: ILogService,
		private platform2: Platform2Service,
		public translate: TranslateService,
		private toastService: CrossPlatformToastService
	) {
	}

	/**
	 * Ritorna il token di registrazione del token per il dispositivo corrente
	 */
	getPushRegistrationId(): string {
		return this._pushRegistrationId;
	}

	// aggiunge un receiver alla lista dei receiver per una certa feature
	// OKKIO: chiamare 'unregisterAsReceiver' su ngOnDestroy!!!
	registerAsReceiver(receiver: INotificationReceiver) {
		this._notificationReceivers.push(receiver);
	}

	// da chiamare a fine utilizzo, soprattutto nelle pagine
	unregisterAsReceiver(receiver: INotificationReceiver) {

		// scorre tutto ed elimina eventuali doppioni
		let i = 0;
		while (i < this._notificationReceivers.length) {
			const rec = this._notificationReceivers[i];
			if (rec === receiver || !rec) {
				this._notificationReceivers.splice(i, 1);
			}
			else {
				i++;
			}
		}
	}

	/**
	 * Forwarda le notifiche ai vari receiver, attualmente il filtro sulla notifica deve essere eseguito dal receiver
	 * viene invocato da bootstrap.service al termine della lettura delle notifiche per l'utente
	 */
	dispatchNotifications(notifications: AppNotification[]): void {
		let i = 0;
		while (i < this._notificationReceivers.length) {
			const rec = this._notificationReceivers[i];
			if (rec) {

				setTimeout((rec1) => {
					rec1.onNotifications(notifications);
				}, 50, rec);

				i++;
			}
			else {
				this._notificationReceivers.splice(i, 1);
			}
		}
	}

	/**
	 * Viene invocata dal gestore degli stati ed invoca il refresh della pagina corrente, se sottoscritta
	 */
	invokeRefreshOnSubscribers(): void {
		let i = 0;
		while (i < this._notificationReceivers.length) {
			const rec = this._notificationReceivers[i];
			if (rec) {
				rec.onAskRefresh();
				i++;
			}
			else {
				this._notificationReceivers.splice(i, 1);
			}
		}
	}

	async initNotifications(user: User): Promise<any> {

		const compiledApp = this.platform2.isIntoCompiledApp();
		if (compiledApp) {

			await this.platform2.ready();

			// const options: PushOptions = {
			// 	android: {
			// 		icon: "fcm_push_icon",
			// 		clearBadge: true,
			// 		sound: true,
			// 		//vibrate: true,
			// 		//forceShow:true // visualizza la notifica anche quando l'app è in primo piano
			// 	},
			// 	ios: {
			// 		alert: true,
			// 		badge: true,
			// 		sound: true,
			// 		clearBadge: true
			// 	}
			// };


			this.fcm.onNotification().subscribe(data => {
				if (data.wasTapped) {
					console.log("Received in background");
				} else {
					console.log("Received in foreground");
				};

				console.log("this.fcm.onNotification");
				console.log(data);

				this.onPushNotificationReceived(undefined);
			});

			this.fcm.onTokenRefresh().subscribe(token => {
				// Register your new token in your back-end if you want

				console.log("this.fcm.onTokenRefresh")

				if (token) {
					this.registerUserToken(token, user);
				}
			});

			const hasPermission = await this.fcm.requestPushPermission();

			if (hasPermission) {
				const token = await this.fcm.getToken();

				if (token) {
					this.registerUserToken(token, user);
				}
			}

			// const pushObject: PushObject = this.push.init(options);
			// pushObject.on("registration").subscribe(async (registration: any) => {

			// 	// data.registrationId

			// 	if (registration.registrationId) {
			// 		await this.registerUserToken(registration.registrationId, user);
			// 	}
			// }, (error) => {
			// 	this.logService.error("Error registering for notifications", error);
			// });

			// pushObject.on("notification").subscribe((/*INotificationRawData*/) => {

			// 	// data.message,
			// 	// data.title,
			// 	// data.count,
			// 	// data.sound,
			// 	// data.image,
			// 	// data.additionalData

			// 	// all'arrivo delle notifiche si va ad innescare il cambio stato "notification"
			// 	// che provvede nel servizio bootstrap ad aggiornare i dati delle notifiche
			// 	// ed a innescare il dispatch ai vari subscriber

			// 	//
			// 	// TODO recuperare appId
			// 	//

			// 	this.onPushNotificationReceived(undefined);

			// }, (error) => {

			// 	this.logService.error("Error attaching to push 'notification' event", error);
			// });

			// pushObject.on("error").subscribe((error) => {
			// 	this.logService.error("Error with push", error);
			// });
		}
		else {
			await this.appConfigSettings.ensurePlatformReadyAndFirebaseDefaultAppInitialized();
			await this._initFirebasePushNotifications(user);
			await this._requestFirebaseNotificationsPermission(user);

			// angularfireMessaging
			// await this.afWebPush(user);
		}
	}

	// async afWebPush(user: User): Promise<void> {
	// 	if (!navigator.serviceWorker) {
	// 		console.error("Service Worker not supported");
	// 		return;
	// 	}
	// 	// readonly requestPermission: Observable<void>;
	// 	// readonly getToken: Observable<string | null>;
	// 	// readonly tokenChanges: Observable<string | null>;
	// 	// readonly messages: Observable<{}>;
	// 	// readonly requestToken: Observable<string | null>;
	// 	// readonly deleteToken: (token: string) => Observable<boolean>;
	// 	this.afMessaging.requestToken.subscribe(
	// 		(token) => {
	// 			debugger
	// 			console.log(token);
	// 		},
	// 		(error) => {
	// 			debugger
	// 			console.error(error);
	// 		}
	// 	);
	// }

	private onPushNotificationReceived(appId: string) {

		// permette il ricarico dei dettagli della notifica e al suo dispatch ai vari gestori personalizzati
		this.appConfigSettings.dispatchStateChanged({
			reason: "notification",
			appIdForAccountAndForNotifications: appId
		}); // appId non serve per le notifiche

		setTimeout(() => {

			// toast di notifica
			this.toastService.showToast({
				message: this.translate.instant("NOTIFICATIONS"),
				duration: 1500
			});

		}, 500);
	}

	private _initFirebasePushNotifications(user: User): Promise<void> {

		return new Promise<void>((resolve, reject) => {

			if (!navigator.serviceWorker) {
				console.error("Service Worker not supported");
				resolve();
				return;
			}

			navigator.serviceWorker.ready.then((registration) => {
				// Don't crash an error if messaging not supported
				if (!firebase.messaging.isSupported()) {
					console.error("Firebase messaging not supported");
					resolve();
					return;
				}

				const messaging = firebase.messaging();

				// Register the Service Worker
				messaging.useServiceWorker(registration);

				// Initialize your VAPI key
				messaging.usePublicVapidKey(
					environment.firebase.vapidKey
				);

				// Listen to messages when your app is in the foreground
				messaging.onMessage((webpushMessage: {
					"data": {
						"reference": string;
						"body": string;
						"appId": string;
						"feature": string; // ordering
						"title": string;
						"event": string;  // "order_user"
					};
					"from": string; // "780837516549",
					"priority": "normal";
					"notification": {
						"title": string;
						"body": string;
						"renotify": boolean
					}
				}) => {

					this.onPushNotificationReceived(webpushMessage.data.appId);
				});

				//
				// questo va fatto in firebase-messaging-sw.js
				//
				// messaging.setBackgroundMessageHandler((payload: any) => {
				// 	// spedito quando il browser non è il primo piano
				// });

				// Handle token refresh
				messaging.onTokenRefresh(() => {
					messaging.getToken().then(
						async (refreshedToken: string) => {
							await this.registerUserToken(refreshedToken, user);
						}).catch((err) => {
							this.logService.error("Error refreshing token", err);
						});
				});

				resolve();
			}, (err) => {
				this.logService.error("Error starting Service Worker", err);
				reject(err);
			});
		});
	}

	private async _requestFirebaseNotificationsPermission(user: User): Promise<void> {

		if (!("Notification" in window)) {
			return;
		}

		if (!firebase.messaging.isSupported()) {
			return;
		}

		if (Notification.permission === "granted") {
			const messaging = firebase.messaging();
			const token: string = await messaging.getToken();
			await this.registerUserToken(token, user);

			return;
		}

		try {
			if (Notification.permission !== "denied") {
				await Notification.requestPermission().then(async (permission) => {

					if (permission === "granted") {
						const messaging = firebase.messaging();
						const token: string = await messaging.getToken();
						await this.registerUserToken(token, user);
					}

				}, () => {
					// No notifications granted
				});
			}

		} catch (err) {
			this.logService.error("Error requesting notifications permission", err);
		}

	}

	private async registerUserToken(token: string, user: User) {
		this._pushRegistrationId = token;
		await this.connectCurrentUserWithNotifications(user);
	}

	// elimina tutte le notifiche di quella feature, passa l'app corrente in input
	async clearNotificationsByFeatureAndDispatchStateChange(
		appId: string,
		feature: FEATURE,
		event?: ORDERING_NOTIFICATION_EVENT | PAYMENT_NOTIFICATION_EVENT): Promise<boolean> {

		const params = {
			appId,
			feature,
			event
		};
		const ret = await this.appConfigSettings.queryManager.mutate(CLEAR_NOTIFICATIONS_BY_FEATURE, params);

		// se la cancellazione va bene rinfresca le notifiche
		if (ret) {
			this.appConfigSettings.dispatchStateChanged({
				reason: "notification",
				appIdForAccountAndForNotifications: appId
			}); // appId non serve per le notifiche
		}

		return ret;
	}

	// Comunica a server l'id ottenuto dalla registrazione alle notifiche per l'utente corrente
	// Controlla anche se lo stesso ID è collegato all'utente precedente e provvede a comunicarlo a server il quale elimina i riferimenti all'utente precedente
	// in modo che questo non riceva più notifiche finchè questo utente è collegato
	async connectCurrentUserWithNotifications(user: User): Promise<void> {

		const appId = statics.appId;
		const registrationId = this._pushRegistrationId;
		if (user && registrationId) {
			try {
				const params = {
					appId,
					registrationId
				};
				await this.appConfigSettings.queryManager.mutate(REGISTER_USER_FOR_PUSH, params);

			}
			catch (e) {
				await safeAwaitArray(this.logService.error("Error registering user " + this.appConfigSettings.loggedUser.username +
					" in app " + appId +
					" for push with registrationId " + registrationId));
			}
		}
	}

	async disconnectUserFromNotifications(user: User): Promise<void> {

		const appId = statics.appId;
		const registrationId = this._pushRegistrationId;
		if (user && registrationId) {
			try {
				const params = {
					appId,
					registrationId
				};
				await this.appConfigSettings.queryManager.mutate(UNREGISTER_USER_FROM_PUSH, params);

			}
			catch (e) {

				await safeAwaitArray(this.logService.error("Error unregistering user " + this.appConfigSettings.loggedUser.username +
					" in app " + appId +
					" for push with registrationId " + registrationId));
			}
		}

	}

}
