import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from "@angular/core";
import { IonInput, ModalController } from "@ionic/angular";
import { ControlValueAccessor, ControlContainer, FormGroupDirective, AbstractControl } from "@angular/forms";
import { SearchPanelPage, ISelectSearchOptionsEx } from "./search-panel.page";
import { PaginatedResponse, Pagination } from "src/app/backend-api";

// interfaccia per l'esterno
export interface ISelectSearchOptions {
	// titolo della finestra di popup per la ricerca
	popupTitle: string;
	// Informazione di funzionamento, mostrata quando non ci sono elementi in lista
	popupInfo?: string;
	// numero dei risultati per pagina ritornata
	resultsInPage?: number;
	// placeholder per il pannello di ricerca
	searchPlaceholder?: string;
	// renderer per gli elementi
	itemRenderer?: (item: any) => string;
	// ricerca e ritorno elementi per il pannello
	execPaginatedCall: (filter: string, pagination: Pagination) => Promise<PaginatedResponse<any>>;
	// viene richiamata quando un elemento viene selezionato
	itemSelected: (item: any) => void;
}

@Component({
	selector: "app-select-search",
	templateUrl: "./select-search.component.html",
	styleUrls: ["./select-search.component.scss"],
	viewProviders: [
		{
			provide: ControlContainer,
			useExisting: FormGroupDirective
		}
	]
})
export class SelectSearchComponent implements OnInit, ControlValueAccessor {

	// variabile di appoggio utilizzabile dall'esterno
	currentItem: any;

	@ViewChild("txt", { static: true }) txt: IonInput;
	@Input() controlName: string;
	@Input() placeholder: string;
	@Input() options: ISelectSearchOptions;

	propagateChange = (_: any) => { };

	constructor(
		private modalController: ModalController
	) {
	}

	ngOnInit() {
		// defaults
		this.options = this.options || {} as ISelectSearchOptions;
		this.options.itemRenderer = this.options.itemRenderer || ((item: any) => {
			return item;
		});
	}

	// elemento corrente
	set item(o: any) {
		this.currentItem = o;
		if (o) {
			this.txt.value = this.options.itemRenderer(o);
		}
		this.propagateChange(this.txt.value);
	}
	get item(): any {
		return this.currentItem;
	}

	//////////////////////////////////////////////////////////
	writeValue(obj: any): void {
		if (obj !== undefined) {
			this.txt = obj;
		}
	}
	registerOnChange(fn: any): void {
		this.propagateChange = fn;
	}
	registerOnTouched(fn: any): void {
		/////throw new Error("Method not implemented.");
	}
	setDisabledState?(isDisabled: boolean): void {
		/////throw new Error("Method not implemented.");
	}
	//////////////////////////////////////////////////////////

	// ritorna il valore interno
	get value(): string {
		return this.txt.value + "";
	}

	async onClicked() {
		await this._showSearchBox();
	}

	private async _showSearchBox() {

		const modal = await this.modalController.create({
			component: SearchPanelPage,
			componentProps: {
				options: {
					filter: this.txt.value,
					searchPlaceholder: this.options.searchPlaceholder,
					itemRenderer: this.options.itemRenderer,
					execPaginatedCall: this.options.execPaginatedCall,
					resultsInPage: this.options.resultsInPage,
					popupTitle: this.options.popupTitle,
					popupInfo: this.options.popupInfo,
					itemSelected: (item: any) => {

						// imposta l'elemento corrente
						this.item = item;

						// chiama quello originale
						if (this.options.itemSelected) {
							this.options.itemSelected(item);
						}
					}
				} as ISelectSearchOptionsEx
			},
		});

		await modal.present();
		const ret = await modal.onDidDismiss() as any; // torna data.confirmed

		//
		// non mi preoccupo del risultato del popup
		// in quanto mi arriva già la callback sopra nelle opzioni passate a quel popup
		//

	}

}
