import { Query } from "src/app/core/services/query";
import { BASE_PRODUCT_INFO } from "src/app/shop/services/shop-queries";
import { paymentClaim } from "../../payment/services/payment-queries";

const productInfo = BASE_PRODUCT_INFO;

const orderItemInfo =
	`
	id
	amount
	requestedQuantity
	rejectedQuantity
	confirmedQuantity
	itemStatus
	product {
		${productInfo}
	}
	orderedProduct {
		${productInfo}
	}
`;

const orderInfo =
	`
	appId
	id
	timestamp
	orderProgressive
	amount
	amountAdjustment {
		user {
			username
			firstName
			lastName
			email
			telephoneNumber
		}
		timestamp
		amountAdjustment
		percentageAdjustment
		adjustedAmount
		reason
		rememberAdjustment
	}
	store {
		id
	}
	confirmedItems
    rejectedItems
    requestedItems
    modifiedItems
	status
	users {
		username
		firstName
		lastName
		email
		telephoneNumber
	}
	orderItems {
		${orderItemInfo}
	}
	bookings {
		bookedTimeSlot {
			startDate  
			endDate
		}
		service {
			name {...language}
			description {...language}
			serviceType
		}
		bookedService {
			name {...language}
			description {...language}
			serviceType
		}
		price 
		status
		customDataJson
	}
	chat{
		id
		timestamp
		messages{
			timestamp
			sender{
				username
				firstName
				lastName
			}
			message
		}
	}
	paymentInfo
	{
		paymentStatus
		paymentMethod
	}
`;

export const GET_OPEN_ORDERS_BY_STORE = new Query(
	`
	query _($appId: String, $storeId: String) {
		openOrdersByStore(appId: $appId, storeId: $storeId) {
			${orderInfo}
		}
	}
	`
);

export const GET_CLOSED_ORDERS_BY_STORE = new Query(
	`
	query _($appId: String, $storeId: String) {
		closedOrdersByStore(appId: $appId, storeId: $storeId) {
			${orderInfo}
		}
	}
	`
);

export const GET_OPEN_ORDERS_BY_USER = new Query(
	`
	query _($appId: String) {
		openOrdersByUser(appId: $appId) {
			${orderInfo}
		}
	}
	`
);

export const GET_CLOSED_ORDERS_BY_USER = new Query(
	`
	query _($appId: String) {
		closedOrdersByUser(appId: $appId) {
			${orderInfo}
		}
	}
	`
);

export const GET_ORDER_DETAILS = new Query(
	`
	query _($appId: String, $orderId: String) {
		order(appId: $appId, id: $orderId) {
	    	${orderInfo}
	  	}
	}
	`
);


export const CONFIRM_ORDER_ITEM = new Query(
	`
	mutation _($orderItemId: String, $orderId: String, $appId: String, $storeId: String, $forcedPricePerMeasureUnit: Float, $forcedQuantity: Float, $modifyProduct: Boolean) {
		confirmOrderItem(orderItemId: $orderItemId, orderId: $orderId, appId: $appId, storeId: $storeId, forcedPricePerMeasureUnit: $forcedPricePerMeasureUnit, forcedQuantity: $forcedQuantity, modifyProduct: $modifyProduct) {
	    	${orderInfo}
	  	}
	}
	`
);

export const FORCE_ORDER_ITEM = new Query(
	`
	mutation _($orderItemId: String, $orderId: String, $appId: String, $storeId: String, $forcedPricePerMeasureUnit: Float, $forcedQuantity: Float, $modifyProduct: Boolean) {
		forceOrderItem(orderItemId: $orderItemId, orderId: $orderId, appId: $appId, storeId: $storeId, forcedPricePerMeasureUnit: $forcedPricePerMeasureUnit, forcedQuantity: $forcedQuantity, modifyProduct: $modifyProduct) {
	    	${orderInfo}
	  	}
	}
	`
);

export const REJECT_ORDER_ITEM = new Query(
	`
	mutation _($orderItemId: String, $orderId: String, $appId: String, $storeId: String) {
		rejectOrderItem(orderItemId: $orderItemId, orderId: $orderId, appId: $appId, storeId: $storeId) {
	    	${orderInfo}
	  	}
	}
	`
);

export const UPDATE_ORDER_ITEM = new Query(
	`
	mutation _($orderItem: OrderItemInput!, $orderId: String, $userId: String, $appId: String, $storeId: String) {
		updateOrderItem(orderItem: $orderItem, orderId: $orderId, userId: $userId, appId: $appId, storeId: $storeId) {
			${orderInfo}
		}
	}
	`
);

export const SET_AMOUNT_ADJUSTMENT = new Query(
	`
	mutation _($amountAdjustment: AmountAdjustmentInput, $orderId: String, $appId: String, $storeId: String) {
		setAmountAdjustment(amountAdjustment: $amountAdjustment, orderId: $orderId, appId: $appId, storeId: $storeId) {
	    	${orderInfo}
	  	}
	}
	`
);

export const GET_LATEST_CART = new Query(
	`query _($appId: String) {
		cart(appId: $appId) {
			id
			store {
				id
			}
		}
	}`
);

export const IS_DELIVERY_SHIPMENT_ZIP_AVAILABLE = new Query(
	`query isDeliveryShipmentZipAvailable($appId: String, $storeId: String, $range: DateRangeInput, $bookingCustomData: String) {
		isDeliveryShipmentZipAvailable(appId: $appId, storeId: $storeId, range: $range, bookingCustomData: $bookingCustomData)
	}`
);

export const GET_DELIVERY_SHIPMENT_CALENDAR = new Query(
	`query getDeliveryShipmentCalendar($appId: String, $storeId: String, $bookingCustomData: String) {
		getDeliveryShipmentCalendar(appId: $appId, storeId: $storeId, bookingCustomData: $bookingCustomData) {
			schedule {
				weekSchedule {
					monday {
						from
						to
					}
					tuesday {
						from
						to
					}
					wednesday {
						from
						to
					}
					thursday {
						from
						to
					}
					friday {
						from
						to
					}
					saturday {
						from
						to
					}
					sunday {
						from
						to
					}
				}
				extraSchedules {
					day
					timeRanges {
						from
						to
					}
				}
				cancelledSchedules {
					day
					timeRanges {
						from
						to
					}
				}
				timezone
			}
			zipCodes
		}
	}`
);

export const GET_DELIVERY_AVAILABILITY = new Query(
	`query getDeliveryAvailability($appId: String, $storeId: String, $range: DateRangeInput, $deliveryType: DELIVERY_TYPE, $bookingCustomData: String) {
		getDeliveryAvailability(appId: $appId, storeId: $storeId, deliveryType: $deliveryType, range: $range, bookingCustomData: $bookingCustomData) {
			slot {
				startDate
				endDate
			}
			availabilityLevel
			price
		}
	}`
);

export const FIND_DELIVERY_SERVICE = new Query(
	`query findDeliveryService($appId: String, $storeId: String, $deliveryType: DELIVERY_TYPE) {
		findDeliveryService(appId: $appId, storeId: $storeId, deliveryType: $deliveryType) {
			id
			stores {
				id
			}
		}
	}`
);

// Conferma del carrello ed inoltro dell'ordine
export const UPDATE_ORDER_DELIVERY = new Query(
	`
	mutation _($appId: String, $orderId: String, $storeId: String, $deliveryDate: DateRangeInput, $deliveryType: DELIVERY_TYPE, $bookingCustomData: String) {
		updateOrderDelivery(appId: $appId, orderId: $orderId, storeId: $storeId, deliveryDate: $deliveryDate, deliveryType: $deliveryType, bookingCustomData: $bookingCustomData) {
			${orderInfo}
		}
	}
	`
);

// Conferma del carrello ed inoltro dell'ordine
export const SET_ORDER_PAYMENT_METHOD = new Query(
	`
	mutation _($appId: String, $orderId: String, $storeId: String, $paymentMethod: PAYMENT_METHOD) {
		setOrderPaymentMethod(appId: $appId, orderId: $orderId, storeId: $storeId, paymentMethod: $paymentMethod) {
			${orderInfo}
		}
	}
	`
);

// Preparazione per l'invio dell'ordine, che prima di poter essere inviato deve essere pagato
export const PREPARE_SEND_ORDER = new Query(
	`
	mutation _($appId: String, $orderId: String, $storeId: String, $message: String) {
		prepareSendOrder(appId: $appId, orderId: $orderId, storeId: $storeId, message: $message) {
			${orderInfo}
		}
	}
	`
);

export const ROLLBACK_PREPARE_SEND_ORDER = new Query(
	`
	mutation _($appId: String, $orderId: String, $storeId: String) {
		rollbackPrepareSendOrder(appId: $appId, orderId: $orderId, storeId: $storeId) {
			${orderInfo}
		}
	}
	`
);

// Conferma del carrello ed inoltro dell'ordine
export const SEND_ORDER = new Query(
	`
	mutation _($appId: String, $orderId: String, $storeId: String, $paymentMethod: PAYMENT_METHOD, $message: String) {
		sendOrder(appId: $appId, orderId: $orderId, storeId: $storeId, paymentMethod: $paymentMethod, message: $message) {
			${orderInfo}
		}
	}
	`
);

// Conferma tutto l'ordine
export const CONFIRM_ORDER = new Query(
	`
	mutation _($appId: String, $orderId: String, $storeId: String) {
		confirmOrder(appId: $appId, orderId: $orderId, storeId: $storeId) {
			${orderInfo}
		}
	}
	`
);

// Propose changes to order
export const PROPOSE_ORDER = new Query(
	`
	mutation _($appId: String, $orderId: String, $storeId: String) {
		proposeOrder(appId: $appId, orderId: $orderId, storeId: $storeId) {
			${orderInfo}
		}
	}
	`
);

// Propose changes to order
export const ACCEPT_PROPOSAL = new Query(
	`
	mutation _($appId: String, $orderId: String, $storeId: String) {
		acceptProposal(appId: $appId, orderId: $orderId, storeId: $storeId) {
			${orderInfo}
		}
	}
	`
);

export const ORDER_READY = new Query(
	`
	mutation _($appId: String, $orderId: String, $storeId: String) {
		orderReady(appId: $appId, orderId: $orderId, storeId: $storeId) {
			${orderInfo}
		}
	}
	`
);

export const SHIP_ORDER = new Query(
	`
	mutation _($appId: String, $orderId: String, $storeId: String) {
		shipOrder(appId: $appId, orderId: $orderId, storeId: $storeId) {
			${orderInfo}
		}
	}
	`
);

export const ORDER_DELIVERED = new Query(
	`
	mutation _($appId: String, $orderId: String, $storeId: String) {
		orderDelivered(appId: $appId, orderId: $orderId, storeId: $storeId) {
			${orderInfo}
		}
	}
	`
);

export const REJECT_ORDER = new Query(
	`
	mutation _($appId: String, $orderId: String, $storeId: String) {
		rejectOrder(appId: $appId, orderId: $orderId, storeId: $storeId) {
			${orderInfo}
		}
	}
	`
);

// Svuota il carrello
export const DELETE_ORDER = new Query(
	`
	mutation _($appId: String, $orderId: String, $storeId: String) {
		deleteOrder(appId: $appId, orderId: $orderId, storeId: $storeId) {
			${orderInfo}
		}
	}
	`
);

export const ACQUIRE_ORDER = new Query(
	`
	mutation _($appId: String, $orderId: String) {
		acquireOrder(appId: $appId, orderId: $orderId) {
			${orderInfo}
		}
	}
	`
);

export const PAYMENT_REQUEST_FOR_ORDER = new Query(
	`
	mutation _($orderId: String, $appId: String, $storeId: String) {
		issuePaymentRequestForOrder(orderId: $orderId, appId: $appId, paymentProvider: stripe, storeId: $storeId) {
			${paymentClaim}
		}
	}
	`
);

// conteggia quanti carrelli contengono quel prodotto
// usato quando si disabilita un prodotto che è inserito in qualche carrello
// qui torna un numerico quindi va scritta senza {}
export const COUNT_CART_ORDERS_WITH_PRODUCT = new Query(
	`
	query _($appId: String, $productId: String) {
		countCartOrdersWithProduct(appId: $appId, productId: $productId)
	}
	`
);

export const PREVIEW_CATALOG_SECTION_DEACTIVATION = new Query(
	`
	query _($appId: String, $catalogId: String, $catalogSectionId: String) {
		previewCatalogSectionDeactivation(appId: $appId, catalogId: $catalogId, catalogSectionId: $catalogSectionId) {
			products {
				id
			}
			cartCount
		}
	}
	`
);
