<ion-header class="ion-no-border">
    <ion-toolbar class="custom-color">
        <ion-buttons slot="start">
			<ion-button *ngIf="!hideNavButtons && (hasPreviousPages || isIntoModalPopup)" (click)="goBack()">
                <ion-icon name="arrow-back"></ion-icon>
            </ion-button>
			<ion-menu-button *ngIf="!hideNavButtons && !(isIntoModalPopup || hasPreviousPages)" [autoHide]="false"></ion-menu-button>
			<div class="badge" *ngIf="hasBadge()"></div>
			<ion-icon *ngIf="lock && userCanManageApp" (click)="lockClickedCB()" [name]="inlineChangesEnabled?'lock-open':'lock-closed'" style="cursor:pointer; zoom: 1.5;"></ion-icon>
        </ion-buttons>
        <ion-title>
            {{ title }}
		</ion-title>
		<ion-buttons slot="end">
			<!--<ion-button *ngIf="dismissButton" (click)="dismissModal()">{{ 'CANCEL' | translate }}</ion-button>-->
			<ion-icon name="cloud-offline" [hidden]="networkConnection.isConnected"></ion-icon>
		</ion-buttons>
    </ion-toolbar>
</ion-header>