import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterViewInit, AfterContentInit, DoCheck } from "@angular/core";
import { AppConfigurationService } from "src/app/core/services/app-configuration.service";
import { ModalController } from "@ionic/angular";
import { ShowGalleryPage } from "./show-gallery.page";
import { GalleryItem } from "../../../backend-api";

@Component({
	selector: "app-gallery-list",
	templateUrl: "./gallery-list.component.html",
	styleUrls: ["./gallery-list.component.scss"],
})
export class GalleryListComponent implements OnInit, AfterContentInit, DoCheck {

	private _items: GalleryItem[] = [];
	public firstItems: GalleryItem[] = [];
	private _maxItems = 4;

	public tdWidth: string;
	public tdCursor: string;

	@Input() clickable: boolean;
	@Input() canDelete: boolean;
	@Output() deleteItemClicked = new EventEmitter();

	private _width = null;
	private _height = null;
	private _loading = true;

	// dav 030620, utilizza l'immagine grande o piccola in base alle dimensioni del componente 
	bigSize = false;

	constructor(
		public appConfigService: AppConfigurationService,
		public modalController: ModalController,
		private hostElement: ElementRef
	) { }

	ngAfterContentInit() {

		// aggiorna col puntatore giusto
		this._loading = false;
	}

	ngDoCheck(): void {

		if (!this._width && !this._height && !this._loading) {

			this._width = this.hostElement.nativeElement.offsetHeight;
			this._height = this.hostElement.nativeElement.offsetWidth;

			if (this._width && this._height) {
				this.bigSize = (this._width > 200 || this._height > 200);
			}
		}
	}

	// Ritorna l'url per l'immagine del dato elemento
	getImage(item: GalleryItem): string {

		// se c'è solo un'immagine e la dimensione è grande usa l'immagine grande
		if (this.bigSize && this?._items?.length === 1) {
			return this.appConfigService.getPictureUrlFromStorageFile(item.storageFile);
		}

		return this.appConfigService.getThumbUrlFromStorageFile(item.storageFile);
	}

	_itemsChanged() {

		// calcola gli elementi da mostrare
		this.firstItems = (this.items || []).slice(0, this.maxItems - 1);

		this.tdWidth = 100 / (this.firstItems.length + 1) + "%";
		if (this.clickable) {
			this.tdCursor = "pointer";
		}
		else {
			this.tdCursor = "";
		}
	}

	@Input()
	set items(items: GalleryItem[]) {

		this._items = items;

		this._itemsChanged();
	}
	get items(): GalleryItem[] {
		return this._items || [];
	}

	// scatena l'evento o elimina direttamente
	internalDeleteItemClicked(item: GalleryItem, event: Event) {

		this.deleteItemClicked.emit(item);

	}

	@Input()
	set maxItems(m: number) {
		this._maxItems = Math.max(m, 2);

		this._itemsChanged();
	}
	get maxItems(): number {
		return this._maxItems;
	}

	clickPressed() {
		if (this.clickable) {
			this.showModalGallery();
		}
	}

	ngOnInit() { }

	async showModalGallery() {
		const modal = await this.modalController.create({
			component: ShowGalleryPage,
			componentProps: {
				items: this.items
			},
		});
		return await modal.present();
	}

}
