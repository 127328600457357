import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { ModalController, IonSearchbar } from "@ionic/angular";
import { ISelectSearchOptions } from "./select-search.component";
import { safeAwaitArray } from "../../utils";
import { PaginatedResponse, Pagination } from "src/app/backend-api";

// dati passati al popup di ricerca
export interface ISelectSearchOptionsEx extends ISelectSearchOptions {
	filter: string;
}

@Component({
	selector: "app-search-panel",
	templateUrl: "./search-panel.page.html",
	styleUrls: ["./search-panel.page.scss"],
})
export class SearchPanelPage implements OnInit {

	filterValue: string;
	searching = false;
	items: any[];
	private _nextSearchCursor = null;
	private hasMoreData: boolean;
	loaded = false;

	@ViewChild("search", { static: true }) search: IonSearchbar;

	// opzioni passate dal controllo inline
	@Input() options: ISelectSearchOptionsEx;

	constructor(
		private modalController: ModalController
	) {

	}

	ngOnInit() {

		// defaults /////////////////////////////////
		this.options = this.options || {} as ISelectSearchOptionsEx;
		this.options.resultsInPage = this.options.resultsInPage || 20;
		this.options.itemRenderer = this.options.itemRenderer || ((item: any) => {
			return item;
		});
		/////////////////////////////////////////////////
	}

	ionViewDidEnter() {
		// fuoco sul campo di ricerca
		setTimeout(() => {
			try {
				this.search.setFocus();
			}
			catch (e) { }
		}, 100);

		// imposta il default
		if (this.options.filter) {
			this.search.value = this.options.filter;
		}

		this.loaded = true;
	}

	// cambio del filtro
	async filterChanged() {

		if (this.filterValue.length > 0) {
			// ricerca pulita
			await this._search(true);
		}
		else {
			this.items = [];
			this._nextSearchCursor = null;
			this.hasMoreData = false;
		}

	}

	// ricerca paginata
	private async _search(newSearch: boolean) {

		if (this.options.execPaginatedCall) {

			// ricerca in corso
			this.searching = true;

			// per nuova ricerca sbianca il cursore
			if (newSearch) {
				this._nextSearchCursor = null;
			}

			// legge n news alla volta
			const pagination: Pagination = {
				first: this.options.resultsInPage,
				after: this._nextSearchCursor
			};

			// richiama la callback fornita dal client
			let err;
			let pr: PaginatedResponse<any>;
			[err, pr] = await safeAwaitArray(this.options.execPaginatedCall(this.filterValue, pagination));

			if (err) {
				this._nextSearchCursor = null;
				this.hasMoreData = false;
			}
			else if (pr) {

				// salva il prox indice
				this._nextSearchCursor = pr.pageInfo.endCursor;
				this.hasMoreData = pr.pageInfo.hasMoreData;

				if (newSearch) {
					this.items = pr.edges.map(e => e.node);
				}
				else {
					this.items = this.items.concat(pr.edges.map(e => e.node));
				}
			}

			// nasconde lo spinner di ricerca
			this.searching = false;
		}
	}

	// ionInfinite su ricerca prodotto
	async ionInfiniteArrivedOnSearchProduct(event) {
		if (this.hasMoreData) {
			await this._search(false);
		}

		event.target.complete();
	}

	// elemento selezionato
	itemSelected(item: any) {
		if (this.options.itemSelected) {
			this.options.itemSelected(item);
		}
		this.modalController.dismiss({
			confirmed: true
		});
	}

	onClearSearch() {
		// if (this.plt === "ios" || this.plt === "android") {
		// 	this.keyboard.hide();
		// }
	}

}
