import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { safeAwaitOrDefault } from "src/app/core/utils";
import { AddBtPrinterPage } from "../../pages/add-bt-printer/add-bt-printer.page";
import { AddLsPrinterPage } from "../../pages/add-ls-printer/add-ls-printer.page";
import { IKPrinter, IKPrinters } from "../../printer.interface";
import { PrinterService } from "../../printer.service";

@Component({
	selector: "app-printers",
	templateUrl: "./printers.component.html",
	styleUrls: ["./printers.component.scss"],
})
export class PrintersComponent implements OnInit {

	@Input() editable: boolean;

	installedPrinters: IKPrinters;
	addPrinterClicked = false;

	constructor(
		private printerService: PrinterService,
		private modalController: ModalController
	) { }

	async ngOnInit(): Promise<void> {
		this.installedPrinters = await safeAwaitOrDefault(this.printerService.getInstalledPrinters());
	}

	async addBTPrinterCB(): Promise<void> {

		const modal = await this.modalController.create({
			component: AddBtPrinterPage,
			// componentProps: {
			// 	options: {
			// 		filter: this.txt.value,
			// 		searchPlaceholder: this.options.searchPlaceholder,
			// 		itemRenderer: this.options.itemRenderer,
			// 		execPaginatedCall: this.options.execPaginatedCall,
			// 		resultsInPage: this.options.resultsInPage,
			// 		popupTitle: this.options.popupTitle,
			// 		popupInfo: this.options.popupInfo,
			// 		itemSelected: (item: any) => {

			// 			// imposta l'elemento corrente
			// 			this.item = item;

			// 			// chiama quello originale
			// 			if (this.options.itemSelected) {
			// 				this.options.itemSelected(item);
			// 			}
			// 		}
			// 	} as ISelectSearchOptionsEx
			// },
		});

		await modal.present();
		const ret = await modal.onDidDismiss() as any; // torna data.confirmed

		this.addPrinterClicked = false;
	}

	async addLSPrinterCB(): Promise<void> {
		const modal = await this.modalController.create({
			component: AddLsPrinterPage,
			// componentProps: {
			// 	options: {
			// 		filter: this.txt.value,
			// 		searchPlaceholder: this.options.searchPlaceholder,
			// 		itemRenderer: this.options.itemRenderer,
			// 		execPaginatedCall: this.options.execPaginatedCall,
			// 		resultsInPage: this.options.resultsInPage,
			// 		popupTitle: this.options.popupTitle,
			// 		popupInfo: this.options.popupInfo,
			// 		itemSelected: (item: any) => {

			// 			// imposta l'elemento corrente
			// 			this.item = item;

			// 			// chiama quello originale
			// 			if (this.options.itemSelected) {
			// 				this.options.itemSelected(item);
			// 			}
			// 		}
			// 	} as ISelectSearchOptionsEx
			// },
		});

		await modal.present();
		const ret = await modal.onDidDismiss() as any; // torna data.confirmed

		this.addPrinterClicked = false;
	}

	async deleteClicked(p: IKPrinter): Promise<void> {
		await this.printerService.removePrinter(p);
	}
}
